<template>
  <div class="a-search">
    <van-nav-bar
      left-arrow
      right-text="搜索"
      :border="false"
      @click-left="onClickLeft"
      @click-right="goSearch(title)"
    >

      <template #title>
        <van-search
          shape="round"
          v-model="title"
          placeholder="请输入搜索关键词"
        />
      </template>
    </van-nav-bar>
    <main v-if="searchRecord.length" class="main">
      <div class="hot-search">
        <h4 class="h3">搜索记录</h4>
        <div class="flex text-gray-700">
          <span
                  v-for="(item, index) in searchRecord"
                  :key="index"
                  class="item bg-white"
                  @click='goSearch(item.name)'
          >{{item.name}}</span
          >
        </div>
      </div>
    </main>

    <main class="main">
      <div class="hot-search">
        <h4 class="h3">热门搜索</h4>
        <div class="flex text-gray-700">
          <span
            v-for="(item, index) in hotSearch"
            :key="index"
            class="item bg-white"
            @click='goSearch(item.name)'
            >{{item.name}}</span
          >
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import { rqGetHotSearch,rqSaveSearchRecord,rqGetSearchRecord } from "@/api/index";

export default {
  name: "Home",

  data() {
    return {
      title: "",
      hotSearch: [],
        searchRecord:[],
    };
  },
  mounted() {

    rqGetHotSearch().then((res) => {
      console.log(res)
      this.hotSearch = res;
    });
      rqGetSearchRecord().then((res)=>{
          console.log(res);
          this.searchRecord =res;
      });
  },
  methods: {
    onClickLeft() {this.$router.go(-1)},

    goSearch(searchValue){
        const  data ={
            names:searchValue,
        }
       rqSaveSearchRecord(data).then((res)=>{
            console.log(res);
        });
      this.$router.push({name:'BookList',query:{keywords:searchValue}})
    }
  },
};
</script>

<style lang='scss' scope>
.a-search {
  .main {
    padding: 10px;
    .h3 {
      margin: 14px 0;
    }
    .hot-search {
      .flex {
        flex-wrap: wrap;
        flex-shrink: 0;
        .item {
          padding: 6px 12px;
          margin: 3px;
          border-radius: 4px;
        }
      }
    }
  }
}
.van-search {
  padding: 0 !important;
}
.van-nav-bar__title {
  // max-width: 100% !important;
  width: 270px !important;
}
</style>
