
var provinces = [
	{
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 16,
	"level": 0,
	"name": "北京市"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 34,
	"level": 0,
	"name": "天津市"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 52,
	"level": 0,
	"name": "河北省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 247,
	"level": 0,
	"name": "山西省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 389,
	"level": 0,
	"name": "内蒙古自治区"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 512,
	"level": 0,
	"name": "辽宁省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 641,
	"level": 0,
	"name": "吉林省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 719,
	"level": 0,
	"name": "黑龙江省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 873,
	"level": 0,
	"name": "上海市"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 892,
	"level": 0,
	"name": "江苏省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 1023,
	"level": 0,
	"name": "浙江省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 1136,
	"level": 0,
	"name": "安徽省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 1274,
	"level": 0,
	"name": "福建省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 1378,
	"level": 0,
	"name": "江西省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 1501,
	"level": 0,
	"name": "山东省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 1676,
	"level": 0,
	"name": "河南省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 1871,
	"level": 0,
	"name": "湖北省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 2001,
	"level": 0,
	"name": "湖南省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 2151,
	"level": 0,
	"name": "广东省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 2313,
	"level": 0,
	"name": "广西壮族自治区"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 2451,
	"level": 0,
	"name": "海南省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 2480,
	"level": 0,
	"name": "重庆市"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 2520,
	"level": 0,
	"name": "四川省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 2741,
	"level": 0,
	"name": "贵州省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 2844,
	"level": 0,
	"name": "云南省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 2998,
	"level": 0,
	"name": "西藏自治区"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 3080,
	"level": 0,
	"name": "陕西省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 3208,
	"level": 0,
	"name": "甘肃省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 3321,
	"level": 0,
	"name": "青海省"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 3374,
	"level": 0,
	"name": "宁夏回族自治区"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 3407,
	"level": 0,
	"name": "新疆维吾尔自治区"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 3683,
	"level": 0,
	"name": "澳门特别行政区"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 3684,
	"level": 0,
	"name": "香港特别行政区"
}, {
	"active": 1,
	"allFiledsKeys": [],
	"allFiledsValues": [],
	"fatherId": 0,
	"id": 3793,
	"level": 1,
	"name": "台湾省"
}]

var citys = {
	16: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 16,
		"id": 17,
		"level": 1,
		"name": "北京市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 16,
		"id": 3523,
		"level": 1,
		"name": "北京市区五环内"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 16,
		"id": 3532,
		"level": 1,
		"name": "北京郊区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 16,
		"id": 3544,
		"level": 1,
		"name": "北京五环外"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 16,
		"id": 3550,
		"level": 1,
		"name": "(EDU)北京高校区"
	}],
	34: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 34,
		"id": 35,
		"level": 1,
		"name": "天津市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 34,
		"id": 3679,
		"level": 1,
		"name": "(EDU)侯台大学城"
	}],
	52: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 53,
		"level": 1,
		"name": "石家庄市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 78,
		"level": 1,
		"name": "唐山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 94,
		"level": 1,
		"name": "秦皇岛市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 103,
		"level": 1,
		"name": "邯郸市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 124,
		"level": 1,
		"name": "邢台市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 145,
		"level": 1,
		"name": "保定市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 172,
		"level": 1,
		"name": "张家口市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 191,
		"level": 1,
		"name": "承德市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 204,
		"level": 1,
		"name": "沧州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 222,
		"level": 1,
		"name": "廊坊市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 234,
		"level": 1,
		"name": "衡水市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 52,
		"id": 3701,
		"level": 1,
		"name": "其它城市"
	}],
	247: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 248,
		"level": 1,
		"name": "太原市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 260,
		"level": 1,
		"name": "大同市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 273,
		"level": 1,
		"name": "阳泉市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 280,
		"level": 1,
		"name": "长治市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 295,
		"level": 1,
		"name": "晋城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 303,
		"level": 1,
		"name": "朔州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 311,
		"level": 1,
		"name": "晋中市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 324,
		"level": 1,
		"name": "运城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 339,
		"level": 1,
		"name": "忻州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 355,
		"level": 1,
		"name": "临汾市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 374,
		"level": 1,
		"name": "吕梁市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 247,
		"id": 3700,
		"level": 1,
		"name": "其它城市"
	}],
	389: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 390,
		"level": 1,
		"name": "呼和浩特市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 401,
		"level": 1,
		"name": "包头市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 412,
		"level": 1,
		"name": "乌海市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 417,
		"level": 1,
		"name": "赤峰市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 431,
		"level": 1,
		"name": "通辽市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 441,
		"level": 1,
		"name": "鄂尔多斯市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 451,
		"level": 1,
		"name": "呼伦贝尔市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 466,
		"level": 1,
		"name": "巴彦淖尔市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 475,
		"level": 1,
		"name": "乌兰察布市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 488,
		"level": 1,
		"name": "兴安盟"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 495,
		"level": 1,
		"name": "锡林郭勒盟"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 508,
		"level": 1,
		"name": "阿拉善盟"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 389,
		"id": 3690,
		"level": 1,
		"name": "其它城市"
	}],
	512: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 513,
		"level": 1,
		"name": "沈阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 528,
		"level": 1,
		"name": "大连市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 540,
		"level": 1,
		"name": "鞍山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 549,
		"level": 1,
		"name": "抚顺市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 558,
		"level": 1,
		"name": "本溪市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 566,
		"level": 1,
		"name": "丹东市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 574,
		"level": 1,
		"name": "锦州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 583,
		"level": 1,
		"name": "营口市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 591,
		"level": 1,
		"name": "阜新市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 600,
		"level": 1,
		"name": "辽阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 609,
		"level": 1,
		"name": "盘锦市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 615,
		"level": 1,
		"name": "铁岭市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 624,
		"level": 1,
		"name": "朝阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 633,
		"level": 1,
		"name": "葫芦岛市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 512,
		"id": 3699,
		"level": 1,
		"name": "其它城市"
	}],
	641: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 642,
		"level": 1,
		"name": "长春市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 654,
		"level": 1,
		"name": "吉林市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 665,
		"level": 1,
		"name": "四平市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 673,
		"level": 1,
		"name": "辽源市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 679,
		"level": 1,
		"name": "通化市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 688,
		"level": 1,
		"name": "白山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 696,
		"level": 1,
		"name": "松原市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 703,
		"level": 1,
		"name": "白城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 710,
		"level": 1,
		"name": "延边朝鲜族自治州"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 641,
		"id": 3698,
		"level": 1,
		"name": "其它城市"
	}],
	719: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 720,
		"level": 1,
		"name": "哈尔滨市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 740,
		"level": 1,
		"name": "齐齐哈尔市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 758,
		"level": 1,
		"name": "鸡西市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 769,
		"level": 1,
		"name": "鹤岗市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 779,
		"level": 1,
		"name": "双鸭山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 789,
		"level": 1,
		"name": "大庆市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 800,
		"level": 1,
		"name": "伊春市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 819,
		"level": 1,
		"name": "佳木斯市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 831,
		"level": 1,
		"name": "七台河市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 837,
		"level": 1,
		"name": "牡丹江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 849,
		"level": 1,
		"name": "黑河市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 857,
		"level": 1,
		"name": "绥化市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 869,
		"level": 1,
		"name": "大兴安岭地区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 719,
		"id": 3694,
		"level": 1,
		"name": "其它城市"
	}],
	873: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 873,
		"id": 874,
		"level": 1,
		"name": "上海市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 873,
		"id": 3682,
		"level": 1,
		"name": "(EDU)上海高校区"
	}],
	892: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 893,
		"level": 1,
		"name": "南京市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 908,
		"level": 1,
		"name": "无锡市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 918,
		"level": 1,
		"name": "徐州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 930,
		"level": 1,
		"name": "常州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 939,
		"level": 1,
		"name": "苏州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 952,
		"level": 1,
		"name": "南通市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 962,
		"level": 1,
		"name": "连云港市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 971,
		"level": 1,
		"name": "淮安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 981,
		"level": 1,
		"name": "盐城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 992,
		"level": 1,
		"name": "扬州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 1000,
		"level": 1,
		"name": "镇江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 1008,
		"level": 1,
		"name": "泰州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 1016,
		"level": 1,
		"name": "宿迁市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 3680,
		"level": 1,
		"name": "(EDU)南京高校区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 3681,
		"level": 1,
		"name": "(EDU)苏州高校区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 892,
		"id": 3704,
		"level": 1,
		"name": "其它城市"
	}],
	1023: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1024,
		"level": 1,
		"name": "杭州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1039,
		"level": 1,
		"name": "宁波市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1052,
		"level": 1,
		"name": "温州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1065,
		"level": 1,
		"name": "嘉兴市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1074,
		"level": 1,
		"name": "湖州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1081,
		"level": 1,
		"name": "绍兴市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1089,
		"level": 1,
		"name": "金华市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1100,
		"level": 1,
		"name": "衢州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1108,
		"level": 1,
		"name": "舟山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1114,
		"level": 1,
		"name": "台州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 1125,
		"level": 1,
		"name": "丽水市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 3677,
		"level": 1,
		"name": "(EDU)杭州高校区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1023,
		"id": 3708,
		"level": 1,
		"name": "其它城市"
	}],
	1136: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1137,
		"level": 1,
		"name": "合肥市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1148,
		"level": 1,
		"name": "芜湖市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1158,
		"level": 1,
		"name": "蚌埠市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1167,
		"level": 1,
		"name": "淮南市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1175,
		"level": 1,
		"name": "马鞍山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1183,
		"level": 1,
		"name": "淮北市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1189,
		"level": 1,
		"name": "铜陵市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1195,
		"level": 1,
		"name": "安庆市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1208,
		"level": 1,
		"name": "黄山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1217,
		"level": 1,
		"name": "滁州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1227,
		"level": 1,
		"name": "阜阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1237,
		"level": 1,
		"name": "宿州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1244,
		"level": 1,
		"name": "六安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1253,
		"level": 1,
		"name": "亳州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1259,
		"level": 1,
		"name": "池州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 1265,
		"level": 1,
		"name": "宣城市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 3678,
		"level": 1,
		"name": "(EDU)合肥高校区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1136,
		"id": 3703,
		"level": 1,
		"name": "其它城市"
	}],
	1274: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 1275,
		"level": 1,
		"name": "福州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 1290,
		"level": 1,
		"name": "厦门市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 1298,
		"level": 1,
		"name": "莆田市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 1305,
		"level": 1,
		"name": "三明市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 1319,
		"level": 1,
		"name": "泉州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 1333,
		"level": 1,
		"name": "漳州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 1346,
		"level": 1,
		"name": "南平市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 1358,
		"level": 1,
		"name": "龙岩市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 1367,
		"level": 1,
		"name": "宁德市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1274,
		"id": 3709,
		"level": 1,
		"name": "其它城市"
	}],
	1378: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1379,
		"level": 1,
		"name": "南昌市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1390,
		"level": 1,
		"name": "景德镇市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1396,
		"level": 1,
		"name": "萍乡市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1403,
		"level": 1,
		"name": "九江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1418,
		"level": 1,
		"name": "新余市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1422,
		"level": 1,
		"name": "鹰潭市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1427,
		"level": 1,
		"name": "赣州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1447,
		"level": 1,
		"name": "吉安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1462,
		"level": 1,
		"name": "宜春市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1474,
		"level": 1,
		"name": "抚州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 1487,
		"level": 1,
		"name": "上饶市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1378,
		"id": 3711,
		"level": 1,
		"name": "其它城市"
	}],
	1501: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1502,
		"level": 1,
		"name": "济南市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1514,
		"level": 1,
		"name": "青岛市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1528,
		"level": 1,
		"name": "淄博市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1538,
		"level": 1,
		"name": "枣庄市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1546,
		"level": 1,
		"name": "东营市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1553,
		"level": 1,
		"name": "烟台市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1567,
		"level": 1,
		"name": "潍坊市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1581,
		"level": 1,
		"name": "济宁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1595,
		"level": 1,
		"name": "泰安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1603,
		"level": 1,
		"name": "威海市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1609,
		"level": 1,
		"name": "日照市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1615,
		"level": 1,
		"name": "莱芜市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1619,
		"level": 1,
		"name": "临沂市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1633,
		"level": 1,
		"name": "德州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1646,
		"level": 1,
		"name": "聊城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1656,
		"level": 1,
		"name": "滨州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 1665,
		"level": 1,
		"name": "菏泽市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1501,
		"id": 3687,
		"level": 1,
		"name": "其它城市"
	}],
	1676: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1677,
		"level": 1,
		"name": "郑州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1691,
		"level": 1,
		"name": "开封市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1703,
		"level": 1,
		"name": "洛阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1720,
		"level": 1,
		"name": "平顶山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1732,
		"level": 1,
		"name": "安阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1743,
		"level": 1,
		"name": "鹤壁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1750,
		"level": 1,
		"name": "新乡市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1764,
		"level": 1,
		"name": "焦作市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1776,
		"level": 1,
		"name": "濮阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1784,
		"level": 1,
		"name": "许昌市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1792,
		"level": 1,
		"name": "漯河市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1799,
		"level": 1,
		"name": "三门峡市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1807,
		"level": 1,
		"name": "南阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1822,
		"level": 1,
		"name": "商丘市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1833,
		"level": 1,
		"name": "信阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1845,
		"level": 1,
		"name": "周口市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1857,
		"level": 1,
		"name": "驻马店市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 1869,
		"level": 1,
		"name": "省直辖县级行政区划"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1676,
		"id": 3702,
		"level": 1,
		"name": "其它城市"
	}],
	1871: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1872,
		"level": 1,
		"name": "武汉市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1887,
		"level": 1,
		"name": "黄石市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1895,
		"level": 1,
		"name": "十堰市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1905,
		"level": 1,
		"name": "宜昌市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1920,
		"level": 1,
		"name": "襄阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1931,
		"level": 1,
		"name": "鄂州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1936,
		"level": 1,
		"name": "荆门市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1943,
		"level": 1,
		"name": "孝感市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1952,
		"level": 1,
		"name": "荆州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1962,
		"level": 1,
		"name": "黄冈市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1974,
		"level": 1,
		"name": "咸宁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1982,
		"level": 1,
		"name": "随州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1987,
		"level": 1,
		"name": "恩施土家族苗族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 1996,
		"level": 1,
		"name": "省直辖县级行政区划"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 3695,
		"level": 1,
		"name": "仙桃市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1871,
		"id": 3696,
		"level": 1,
		"name": "襄樊市"
	}],
	2001: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2002,
		"level": 1,
		"name": "长沙市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2013,
		"level": 1,
		"name": "株洲市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2024,
		"level": 1,
		"name": "湘潭市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2031,
		"level": 1,
		"name": "衡阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2045,
		"level": 1,
		"name": "邵阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2059,
		"level": 1,
		"name": "岳阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2070,
		"level": 1,
		"name": "常德市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2081,
		"level": 1,
		"name": "张家界市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2087,
		"level": 1,
		"name": "益阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2095,
		"level": 1,
		"name": "郴州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2108,
		"level": 1,
		"name": "永州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2121,
		"level": 1,
		"name": "怀化市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2135,
		"level": 1,
		"name": "娄底市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 2142,
		"level": 1,
		"name": "湘西土家族苗族自治州"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2001,
		"id": 3697,
		"level": 1,
		"name": "其它城市"
	}],
	2151: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2152,
		"level": 1,
		"name": "广州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2166,
		"level": 1,
		"name": "韶关市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2178,
		"level": 1,
		"name": "深圳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2186,
		"level": 1,
		"name": "珠海市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2191,
		"level": 1,
		"name": "汕头市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2200,
		"level": 1,
		"name": "佛山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2207,
		"level": 1,
		"name": "江门市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2216,
		"level": 1,
		"name": "湛江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2227,
		"level": 1,
		"name": "茂名市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2235,
		"level": 1,
		"name": "肇庆市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2245,
		"level": 1,
		"name": "惠州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2252,
		"level": 1,
		"name": "梅州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2262,
		"level": 1,
		"name": "汕尾市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2268,
		"level": 1,
		"name": "河源市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2276,
		"level": 1,
		"name": "阳江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2282,
		"level": 1,
		"name": "清远市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2292,
		"level": 1,
		"name": "东莞市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2293,
		"level": 1,
		"name": "中山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2294,
		"level": 1,
		"name": "潮州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2299,
		"level": 1,
		"name": "揭阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 2306,
		"level": 1,
		"name": "云浮市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 3675,
		"level": 1,
		"name": "(EDU)广州大学城"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2151,
		"id": 3676,
		"level": 1,
		"name": "其它城市"
	}],
	2313: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2314,
		"level": 1,
		"name": "南宁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2328,
		"level": 1,
		"name": "柳州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2340,
		"level": 1,
		"name": "桂林市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2359,
		"level": 1,
		"name": "梧州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2368,
		"level": 1,
		"name": "北海市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2374,
		"level": 1,
		"name": "防城港市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2380,
		"level": 1,
		"name": "钦州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2386,
		"level": 1,
		"name": "贵港市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2393,
		"level": 1,
		"name": "玉林市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2401,
		"level": 1,
		"name": "百色市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2415,
		"level": 1,
		"name": "贺州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2421,
		"level": 1,
		"name": "河池市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2434,
		"level": 1,
		"name": "来宾市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 2442,
		"level": 1,
		"name": "崇左市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2313,
		"id": 3712,
		"level": 1,
		"name": "其它城市"
	}],
	2451: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2451,
		"id": 2452,
		"level": 1,
		"name": "海口市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2451,
		"id": 2458,
		"level": 1,
		"name": "三亚市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2451,
		"id": 2460,
		"level": 1,
		"name": "省直辖县级行政区划"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2451,
		"id": 3688,
		"level": 1,
		"name": "其它城市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2451,
		"id": 3689,
		"level": 1,
		"name": "西南中沙群岛办事处（县级）"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2451,
		"id": 3772,
		"level": 1,
		"name": "三沙市"
	}],
	2480: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2480,
		"id": 2481,
		"level": 1,
		"name": "重庆市"
	}],
	2520: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2521,
		"level": 1,
		"name": "成都市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2542,
		"level": 1,
		"name": "自贡市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2550,
		"level": 1,
		"name": "攀枝花市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2557,
		"level": 1,
		"name": "泸州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2566,
		"level": 1,
		"name": "德阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2574,
		"level": 1,
		"name": "绵阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2585,
		"level": 1,
		"name": "广元市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2594,
		"level": 1,
		"name": "遂宁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2601,
		"level": 1,
		"name": "内江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2608,
		"level": 1,
		"name": "乐山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2621,
		"level": 1,
		"name": "南充市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2632,
		"level": 1,
		"name": "眉山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2640,
		"level": 1,
		"name": "宜宾市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2652,
		"level": 1,
		"name": "广安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2659,
		"level": 1,
		"name": "达州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2668,
		"level": 1,
		"name": "雅安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2678,
		"level": 1,
		"name": "巴中市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2684,
		"level": 1,
		"name": "资阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2690,
		"level": 1,
		"name": "阿坝藏族羌族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2704,
		"level": 1,
		"name": "甘孜藏族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 2723,
		"level": 1,
		"name": "凉山彝族自治州"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2520,
		"id": 3693,
		"level": 1,
		"name": "其它城市"
	}],
	2741: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 2742,
		"level": 1,
		"name": "贵阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 2754,
		"level": 1,
		"name": "六盘水市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 2759,
		"level": 1,
		"name": "遵义市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 2775,
		"level": 1,
		"name": "安顺市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 2783,
		"level": 1,
		"name": "毕节市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 2793,
		"level": 1,
		"name": "铜仁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 2805,
		"level": 1,
		"name": "黔西南布依族苗族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 2814,
		"level": 1,
		"name": "黔东南苗族侗族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 2831,
		"level": 1,
		"name": "黔南布依族苗族自治州"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2741,
		"id": 3710,
		"level": 1,
		"name": "其它城市"
	}],
	2844: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2845,
		"level": 1,
		"name": "昆明市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2861,
		"level": 1,
		"name": "曲靖市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2872,
		"level": 1,
		"name": "玉溪市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2883,
		"level": 1,
		"name": "保山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2890,
		"level": 1,
		"name": "昭通市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2903,
		"level": 1,
		"name": "丽江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2910,
		"level": 1,
		"name": "普洱市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2922,
		"level": 1,
		"name": "临沧市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2932,
		"level": 1,
		"name": "楚雄彝族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2943,
		"level": 1,
		"name": "红河哈尼族彝族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2957,
		"level": 1,
		"name": "文山壮族苗族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2966,
		"level": 1,
		"name": "西双版纳傣族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2970,
		"level": 1,
		"name": "大理白族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2983,
		"level": 1,
		"name": "德宏傣族景颇族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2989,
		"level": 1,
		"name": "怒江傈僳族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 2994,
		"level": 1,
		"name": "迪庆藏族自治州"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2844,
		"id": 3707,
		"level": 1,
		"name": "其它城市"
	}],
	2998: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2998,
		"id": 2999,
		"level": 1,
		"name": "拉萨市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2998,
		"id": 3009,
		"level": 1,
		"name": "昌都地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2998,
		"id": 3021,
		"level": 1,
		"name": "山南地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2998,
		"id": 3034,
		"level": 1,
		"name": "日喀则地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2998,
		"id": 3053,
		"level": 1,
		"name": "那曲地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2998,
		"id": 3064,
		"level": 1,
		"name": "阿里地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2998,
		"id": 3072,
		"level": 1,
		"name": "林芝地区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2998,
		"id": 3713,
		"level": 1,
		"name": "其它城市"
	}],
	3080: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3081,
		"level": 1,
		"name": "西安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3096,
		"level": 1,
		"name": "铜川市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3102,
		"level": 1,
		"name": "宝鸡市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3116,
		"level": 1,
		"name": "咸阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3132,
		"level": 1,
		"name": "渭南市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3145,
		"level": 1,
		"name": "延安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3160,
		"level": 1,
		"name": "汉中市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3173,
		"level": 1,
		"name": "榆林市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3187,
		"level": 1,
		"name": "安康市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3199,
		"level": 1,
		"name": "商洛市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3080,
		"id": 3691,
		"level": 1,
		"name": "其它城市"
	}],
	3208: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3209,
		"level": 1,
		"name": "兰州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3219,
		"level": 1,
		"name": "嘉峪关市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3221,
		"level": 1,
		"name": "金昌市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3225,
		"level": 1,
		"name": "白银市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3232,
		"level": 1,
		"name": "天水市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3241,
		"level": 1,
		"name": "武威市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3247,
		"level": 1,
		"name": "张掖市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3255,
		"level": 1,
		"name": "平凉市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3264,
		"level": 1,
		"name": "酒泉市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3273,
		"level": 1,
		"name": "庆阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3283,
		"level": 1,
		"name": "定西市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3292,
		"level": 1,
		"name": "陇南市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3303,
		"level": 1,
		"name": "临夏回族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3312,
		"level": 1,
		"name": "甘南藏族自治州"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3208,
		"id": 3705,
		"level": 1,
		"name": "其它城市"
	}],
	3321: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3321,
		"id": 3322,
		"level": 1,
		"name": "西宁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3321,
		"id": 3331,
		"level": 1,
		"name": "海东地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3321,
		"id": 3338,
		"level": 1,
		"name": "海北藏族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3321,
		"id": 3343,
		"level": 1,
		"name": "黄南藏族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3321,
		"id": 3348,
		"level": 1,
		"name": "海南藏族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3321,
		"id": 3354,
		"level": 1,
		"name": "果洛藏族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3321,
		"id": 3361,
		"level": 1,
		"name": "玉树藏族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3321,
		"id": 3368,
		"level": 1,
		"name": "海西蒙古族藏族自治州"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3321,
		"id": 3692,
		"level": 1,
		"name": "其它城市"
	}],
	3374: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3374,
		"id": 3375,
		"level": 1,
		"name": "银川市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3374,
		"id": 3383,
		"level": 1,
		"name": "石嘴山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3374,
		"id": 3388,
		"level": 1,
		"name": "吴忠市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3374,
		"id": 3395,
		"level": 1,
		"name": "固原市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3374,
		"id": 3402,
		"level": 1,
		"name": "中卫市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3374,
		"id": 3706,
		"level": 1,
		"name": "其它城市"
	}],
	3407: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3408,
		"level": 1,
		"name": "乌鲁木齐市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3418,
		"level": 1,
		"name": "克拉玛依市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3424,
		"level": 1,
		"name": "吐鲁番地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3428,
		"level": 1,
		"name": "哈密地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3432,
		"level": 1,
		"name": "昌吉回族自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3440,
		"level": 1,
		"name": "博尔塔拉蒙古自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3444,
		"level": 1,
		"name": "巴音郭楞蒙古自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3454,
		"level": 1,
		"name": "阿克苏地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3464,
		"level": 1,
		"name": "克孜勒苏柯尔克孜自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3469,
		"level": 1,
		"name": "喀什地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3482,
		"level": 1,
		"name": "和田地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3491,
		"level": 1,
		"name": "伊犁哈萨克自治州"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3502,
		"level": 1,
		"name": "塔城地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3510,
		"level": 1,
		"name": "阿勒泰地区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3407,
		"id": 3518,
		"level": 1,
		"name": "自治区直辖县级行政区划"
	}],
	3683: [{
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3683,
		"id": 3722,
		"level": 1,
		"name": "路氹城"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3683,
		"id": 3797,
		"level": 1,
		"name": "澳门"
	}],
	3684: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3684,
		"id": 3796,
		"level": 1,
		"name": "香港"
	}],
	3793: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3793,
		"id": 3794,
		"level": 0,
		"name": "台湾"
	}]
}
var areas = {
	17: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 18,
		"level": 2,
		"name": "东城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 19,
		"level": 2,
		"name": "西城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 20,
		"level": 2,
		"name": "朝阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 21,
		"level": 2,
		"name": "丰台区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 22,
		"level": 2,
		"name": "石景山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 23,
		"level": 2,
		"name": "海淀区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 24,
		"level": 2,
		"name": "门头沟区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 25,
		"level": 2,
		"name": "房山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 26,
		"level": 2,
		"name": "通州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 27,
		"level": 2,
		"name": "顺义区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 28,
		"level": 2,
		"name": "昌平区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 29,
		"level": 2,
		"name": "大兴区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 30,
		"level": 2,
		"name": "怀柔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 31,
		"level": 2,
		"name": "平谷区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 32,
		"level": 2,
		"name": "密云县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 33,
		"level": 2,
		"name": "延庆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 17,
		"id": 3763,
		"level": 1,
		"name": "宣武区"
	}],
	35: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 36,
		"level": 2,
		"name": "和平区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 37,
		"level": 2,
		"name": "河东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 38,
		"level": 2,
		"name": "河西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 39,
		"level": 2,
		"name": "南开区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 40,
		"level": 2,
		"name": "河北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 41,
		"level": 2,
		"name": "红桥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 42,
		"level": 2,
		"name": "东丽区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 43,
		"level": 2,
		"name": "西青区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 44,
		"level": 2,
		"name": "津南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 45,
		"level": 2,
		"name": "北辰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 46,
		"level": 2,
		"name": "武清区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 47,
		"level": 2,
		"name": "宝坻区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 48,
		"level": 2,
		"name": "滨海新区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 49,
		"level": 2,
		"name": "宁河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 50,
		"level": 2,
		"name": "静海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 35,
		"id": 51,
		"level": 2,
		"name": "蓟县"
	}],
	53: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 55,
		"level": 2,
		"name": "长安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 56,
		"level": 2,
		"name": "桥东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 57,
		"level": 2,
		"name": "桥西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 58,
		"level": 2,
		"name": "新华区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 59,
		"level": 2,
		"name": "井陉矿区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 60,
		"level": 2,
		"name": "裕华区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 61,
		"level": 2,
		"name": "井陉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 62,
		"level": 2,
		"name": "正定县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 63,
		"level": 2,
		"name": "栾城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 64,
		"level": 2,
		"name": "行唐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 65,
		"level": 2,
		"name": "灵寿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 66,
		"level": 2,
		"name": "高邑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 67,
		"level": 2,
		"name": "深泽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 68,
		"level": 2,
		"name": "赞皇县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 69,
		"level": 2,
		"name": "无极县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 70,
		"level": 2,
		"name": "平山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 71,
		"level": 2,
		"name": "元氏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 72,
		"level": 2,
		"name": "赵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 73,
		"level": 2,
		"name": "辛集市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 74,
		"level": 2,
		"name": "藁城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 75,
		"level": 2,
		"name": "晋州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 76,
		"level": 2,
		"name": "新乐市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 53,
		"id": 77,
		"level": 2,
		"name": "鹿泉市"
	}],
	78: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 80,
		"level": 2,
		"name": "路南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 81,
		"level": 2,
		"name": "路北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 82,
		"level": 2,
		"name": "古冶区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 83,
		"level": 2,
		"name": "开平区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 84,
		"level": 2,
		"name": "丰南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 85,
		"level": 2,
		"name": "丰润区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 86,
		"level": 2,
		"name": "滦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 87,
		"level": 2,
		"name": "滦南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 88,
		"level": 2,
		"name": "乐亭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 89,
		"level": 2,
		"name": "迁西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 90,
		"level": 2,
		"name": "玉田县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 91,
		"level": 2,
		"name": "唐海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 92,
		"level": 2,
		"name": "遵化市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 78,
		"id": 93,
		"level": 2,
		"name": "迁安市"
	}],
	94: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 94,
		"id": 96,
		"level": 2,
		"name": "海港区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 94,
		"id": 97,
		"level": 2,
		"name": "山海关区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 94,
		"id": 98,
		"level": 2,
		"name": "北戴河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 94,
		"id": 99,
		"level": 2,
		"name": "青龙满族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 94,
		"id": 100,
		"level": 2,
		"name": "昌黎县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 94,
		"id": 101,
		"level": 2,
		"name": "抚宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 94,
		"id": 102,
		"level": 2,
		"name": "卢龙县"
	}],
	103: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 105,
		"level": 2,
		"name": "邯山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 106,
		"level": 2,
		"name": "丛台区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 107,
		"level": 2,
		"name": "复兴区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 108,
		"level": 2,
		"name": "峰峰矿区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 109,
		"level": 2,
		"name": "邯郸县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 110,
		"level": 2,
		"name": "临漳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 111,
		"level": 2,
		"name": "成安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 112,
		"level": 2,
		"name": "大名县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 113,
		"level": 2,
		"name": "涉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 114,
		"level": 2,
		"name": "磁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 115,
		"level": 2,
		"name": "肥乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 116,
		"level": 2,
		"name": "永年县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 117,
		"level": 2,
		"name": "邱县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 118,
		"level": 2,
		"name": "鸡泽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 119,
		"level": 2,
		"name": "广平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 120,
		"level": 2,
		"name": "馆陶县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 121,
		"level": 2,
		"name": "魏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 122,
		"level": 2,
		"name": "曲周县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 103,
		"id": 123,
		"level": 2,
		"name": "武安市"
	}],
	124: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 126,
		"level": 2,
		"name": "桥东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 127,
		"level": 2,
		"name": "桥西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 128,
		"level": 2,
		"name": "邢台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 129,
		"level": 2,
		"name": "临城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 130,
		"level": 2,
		"name": "内丘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 131,
		"level": 2,
		"name": "柏乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 132,
		"level": 2,
		"name": "隆尧县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 133,
		"level": 2,
		"name": "任县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 134,
		"level": 2,
		"name": "南和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 135,
		"level": 2,
		"name": "宁晋县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 136,
		"level": 2,
		"name": "巨鹿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 137,
		"level": 2,
		"name": "新河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 138,
		"level": 2,
		"name": "广宗县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 139,
		"level": 2,
		"name": "平乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 140,
		"level": 2,
		"name": "威县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 141,
		"level": 2,
		"name": "清河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 142,
		"level": 2,
		"name": "临西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 143,
		"level": 2,
		"name": "南宫市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 124,
		"id": 144,
		"level": 2,
		"name": "沙河市"
	}],
	145: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 147,
		"level": 2,
		"name": "新市区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 148,
		"level": 2,
		"name": "北市区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 149,
		"level": 2,
		"name": "南市区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 150,
		"level": 2,
		"name": "满城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 151,
		"level": 2,
		"name": "清苑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 152,
		"level": 2,
		"name": "涞水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 153,
		"level": 2,
		"name": "阜平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 154,
		"level": 2,
		"name": "徐水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 155,
		"level": 2,
		"name": "定兴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 156,
		"level": 2,
		"name": "唐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 157,
		"level": 2,
		"name": "高阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 158,
		"level": 2,
		"name": "容城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 159,
		"level": 2,
		"name": "涞源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 160,
		"level": 2,
		"name": "望都县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 161,
		"level": 2,
		"name": "安新县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 162,
		"level": 2,
		"name": "易县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 163,
		"level": 2,
		"name": "曲阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 164,
		"level": 2,
		"name": "蠡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 165,
		"level": 2,
		"name": "顺平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 166,
		"level": 2,
		"name": "博野县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 167,
		"level": 2,
		"name": "雄县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 168,
		"level": 2,
		"name": "涿州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 169,
		"level": 2,
		"name": "定州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 170,
		"level": 2,
		"name": "安国市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 145,
		"id": 171,
		"level": 2,
		"name": "高碑店市"
	}],
	172: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 174,
		"level": 2,
		"name": "桥东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 175,
		"level": 2,
		"name": "桥西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 176,
		"level": 2,
		"name": "宣化区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 177,
		"level": 2,
		"name": "下花园区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 178,
		"level": 2,
		"name": "宣化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 179,
		"level": 2,
		"name": "张北县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 180,
		"level": 2,
		"name": "康保县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 181,
		"level": 2,
		"name": "沽源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 182,
		"level": 2,
		"name": "尚义县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 183,
		"level": 2,
		"name": "蔚县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 184,
		"level": 2,
		"name": "阳原县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 185,
		"level": 2,
		"name": "怀安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 186,
		"level": 2,
		"name": "万全县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 187,
		"level": 2,
		"name": "怀来县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 188,
		"level": 2,
		"name": "涿鹿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 189,
		"level": 2,
		"name": "赤城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 172,
		"id": 190,
		"level": 2,
		"name": "崇礼县"
	}],
	191: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 193,
		"level": 2,
		"name": "双桥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 194,
		"level": 2,
		"name": "双滦区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 195,
		"level": 2,
		"name": "鹰手营子矿区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 196,
		"level": 2,
		"name": "承德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 197,
		"level": 2,
		"name": "兴隆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 198,
		"level": 2,
		"name": "平泉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 199,
		"level": 2,
		"name": "滦平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 200,
		"level": 2,
		"name": "隆化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 201,
		"level": 2,
		"name": "丰宁满族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 202,
		"level": 2,
		"name": "宽城满族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 191,
		"id": 203,
		"level": 2,
		"name": "围场满族蒙古族自治县"
	}],
	204: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 206,
		"level": 2,
		"name": "新华区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 207,
		"level": 2,
		"name": "运河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 208,
		"level": 2,
		"name": "沧县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 209,
		"level": 2,
		"name": "青县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 210,
		"level": 2,
		"name": "东光县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 211,
		"level": 2,
		"name": "海兴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 212,
		"level": 2,
		"name": "盐山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 213,
		"level": 2,
		"name": "肃宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 214,
		"level": 2,
		"name": "南皮县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 215,
		"level": 2,
		"name": "吴桥县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 216,
		"level": 2,
		"name": "献县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 217,
		"level": 2,
		"name": "孟村回族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 218,
		"level": 2,
		"name": "泊头市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 219,
		"level": 2,
		"name": "任丘市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 220,
		"level": 2,
		"name": "黄骅市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 204,
		"id": 221,
		"level": 2,
		"name": "河间市"
	}],
	222: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 224,
		"level": 2,
		"name": "安次区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 225,
		"level": 2,
		"name": "广阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 226,
		"level": 2,
		"name": "固安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 227,
		"level": 2,
		"name": "永清县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 228,
		"level": 2,
		"name": "香河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 229,
		"level": 2,
		"name": "大城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 230,
		"level": 2,
		"name": "文安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 231,
		"level": 2,
		"name": "大厂回族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 232,
		"level": 2,
		"name": "霸州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 222,
		"id": 233,
		"level": 2,
		"name": "三河市"
	}],
	234: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 236,
		"level": 2,
		"name": "桃城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 237,
		"level": 2,
		"name": "枣强县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 238,
		"level": 2,
		"name": "武邑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 239,
		"level": 2,
		"name": "武强县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 240,
		"level": 2,
		"name": "饶阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 241,
		"level": 2,
		"name": "安平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 242,
		"level": 2,
		"name": "故城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 243,
		"level": 2,
		"name": "景县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 244,
		"level": 2,
		"name": "阜城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 245,
		"level": 2,
		"name": "冀州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 234,
		"id": 246,
		"level": 2,
		"name": "深州市"
	}],
	248: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 250,
		"level": 2,
		"name": "小店区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 251,
		"level": 2,
		"name": "迎泽区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 252,
		"level": 2,
		"name": "杏花岭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 253,
		"level": 2,
		"name": "尖草坪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 254,
		"level": 2,
		"name": "万柏林区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 255,
		"level": 2,
		"name": "晋源区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 256,
		"level": 2,
		"name": "清徐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 257,
		"level": 2,
		"name": "阳曲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 258,
		"level": 2,
		"name": "娄烦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 248,
		"id": 259,
		"level": 2,
		"name": "古交市"
	}],
	260: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 262,
		"level": 2,
		"name": "城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 263,
		"level": 2,
		"name": "矿区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 264,
		"level": 2,
		"name": "南郊区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 265,
		"level": 2,
		"name": "新荣区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 266,
		"level": 2,
		"name": "阳高县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 267,
		"level": 2,
		"name": "天镇县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 268,
		"level": 2,
		"name": "广灵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 269,
		"level": 2,
		"name": "灵丘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 270,
		"level": 2,
		"name": "浑源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 271,
		"level": 2,
		"name": "左云县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 260,
		"id": 272,
		"level": 2,
		"name": "大同县"
	}],
	273: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 273,
		"id": 275,
		"level": 2,
		"name": "城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 273,
		"id": 276,
		"level": 2,
		"name": "矿区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 273,
		"id": 277,
		"level": 2,
		"name": "郊区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 273,
		"id": 278,
		"level": 2,
		"name": "平定县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 273,
		"id": 279,
		"level": 2,
		"name": "盂县"
	}],
	280: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 282,
		"level": 2,
		"name": "城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 283,
		"level": 2,
		"name": "郊区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 284,
		"level": 2,
		"name": "长治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 285,
		"level": 2,
		"name": "襄垣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 286,
		"level": 2,
		"name": "屯留县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 287,
		"level": 2,
		"name": "平顺县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 288,
		"level": 2,
		"name": "黎城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 289,
		"level": 2,
		"name": "壶关县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 290,
		"level": 2,
		"name": "长子县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 291,
		"level": 2,
		"name": "武乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 292,
		"level": 2,
		"name": "沁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 293,
		"level": 2,
		"name": "沁源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 280,
		"id": 294,
		"level": 2,
		"name": "潞城市"
	}],
	295: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 295,
		"id": 297,
		"level": 2,
		"name": "城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 295,
		"id": 298,
		"level": 2,
		"name": "沁水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 295,
		"id": 299,
		"level": 2,
		"name": "阳城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 295,
		"id": 300,
		"level": 2,
		"name": "陵川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 295,
		"id": 301,
		"level": 2,
		"name": "泽州县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 295,
		"id": 302,
		"level": 2,
		"name": "高平市"
	}],
	303: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 303,
		"id": 305,
		"level": 2,
		"name": "朔城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 303,
		"id": 306,
		"level": 2,
		"name": "平鲁区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 303,
		"id": 307,
		"level": 2,
		"name": "山阴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 303,
		"id": 308,
		"level": 2,
		"name": "应县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 303,
		"id": 309,
		"level": 2,
		"name": "右玉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 303,
		"id": 310,
		"level": 2,
		"name": "怀仁县"
	}],
	311: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 313,
		"level": 2,
		"name": "榆次区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 314,
		"level": 2,
		"name": "榆社县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 315,
		"level": 2,
		"name": "左权县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 316,
		"level": 2,
		"name": "和顺县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 317,
		"level": 2,
		"name": "昔阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 318,
		"level": 2,
		"name": "寿阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 319,
		"level": 2,
		"name": "太谷县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 320,
		"level": 2,
		"name": "祁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 321,
		"level": 2,
		"name": "平遥县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 322,
		"level": 2,
		"name": "灵石县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 311,
		"id": 323,
		"level": 2,
		"name": "介休市"
	}],
	324: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 326,
		"level": 2,
		"name": "盐湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 327,
		"level": 2,
		"name": "临猗县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 328,
		"level": 2,
		"name": "万荣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 329,
		"level": 2,
		"name": "闻喜县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 330,
		"level": 2,
		"name": "稷山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 331,
		"level": 2,
		"name": "新绛县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 332,
		"level": 2,
		"name": "绛县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 333,
		"level": 2,
		"name": "垣曲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 334,
		"level": 2,
		"name": "夏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 335,
		"level": 2,
		"name": "平陆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 336,
		"level": 2,
		"name": "芮城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 337,
		"level": 2,
		"name": "永济市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 324,
		"id": 338,
		"level": 2,
		"name": "河津市"
	}],
	339: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 341,
		"level": 2,
		"name": "忻府区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 342,
		"level": 2,
		"name": "定襄县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 343,
		"level": 2,
		"name": "五台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 344,
		"level": 2,
		"name": "代县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 345,
		"level": 2,
		"name": "繁峙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 346,
		"level": 2,
		"name": "宁武县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 347,
		"level": 2,
		"name": "静乐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 348,
		"level": 2,
		"name": "神池县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 349,
		"level": 2,
		"name": "五寨县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 350,
		"level": 2,
		"name": "岢岚县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 351,
		"level": 2,
		"name": "河曲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 352,
		"level": 2,
		"name": "保德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 353,
		"level": 2,
		"name": "偏关县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 339,
		"id": 354,
		"level": 2,
		"name": "原平市"
	}],
	355: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 357,
		"level": 2,
		"name": "尧都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 358,
		"level": 2,
		"name": "曲沃县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 359,
		"level": 2,
		"name": "翼城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 360,
		"level": 2,
		"name": "襄汾县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 361,
		"level": 2,
		"name": "洪洞县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 362,
		"level": 2,
		"name": "古县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 363,
		"level": 2,
		"name": "安泽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 364,
		"level": 2,
		"name": "浮山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 365,
		"level": 2,
		"name": "吉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 366,
		"level": 2,
		"name": "乡宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 367,
		"level": 2,
		"name": "大宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 368,
		"level": 2,
		"name": "隰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 369,
		"level": 2,
		"name": "永和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 370,
		"level": 2,
		"name": "蒲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 371,
		"level": 2,
		"name": "汾西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 372,
		"level": 2,
		"name": "侯马市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 355,
		"id": 373,
		"level": 2,
		"name": "霍州市"
	}],
	374: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 376,
		"level": 2,
		"name": "离石区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 377,
		"level": 2,
		"name": "文水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 378,
		"level": 2,
		"name": "交城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 379,
		"level": 2,
		"name": "兴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 380,
		"level": 2,
		"name": "临县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 381,
		"level": 2,
		"name": "柳林县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 382,
		"level": 2,
		"name": "石楼县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 383,
		"level": 2,
		"name": "岚县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 384,
		"level": 2,
		"name": "方山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 385,
		"level": 2,
		"name": "中阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 386,
		"level": 2,
		"name": "交口县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 387,
		"level": 2,
		"name": "孝义市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 374,
		"id": 388,
		"level": 2,
		"name": "汾阳市"
	}],
	390: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 390,
		"id": 392,
		"level": 2,
		"name": "新城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 390,
		"id": 393,
		"level": 2,
		"name": "回民区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 390,
		"id": 394,
		"level": 2,
		"name": "玉泉区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 390,
		"id": 395,
		"level": 2,
		"name": "赛罕区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 390,
		"id": 396,
		"level": 2,
		"name": "土默特左旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 390,
		"id": 397,
		"level": 2,
		"name": "托克托县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 390,
		"id": 398,
		"level": 2,
		"name": "和林格尔县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 390,
		"id": 399,
		"level": 2,
		"name": "清水河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 390,
		"id": 400,
		"level": 2,
		"name": "武川县"
	}],
	401: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 401,
		"id": 403,
		"level": 2,
		"name": "东河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 401,
		"id": 404,
		"level": 2,
		"name": "昆都仑区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 401,
		"id": 405,
		"level": 2,
		"name": "青山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 401,
		"id": 406,
		"level": 2,
		"name": "石拐区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 401,
		"id": 407,
		"level": 2,
		"name": "白云鄂博矿区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 401,
		"id": 408,
		"level": 2,
		"name": "九原区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 401,
		"id": 409,
		"level": 2,
		"name": "土默特右旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 401,
		"id": 410,
		"level": 2,
		"name": "固阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 401,
		"id": 411,
		"level": 2,
		"name": "达尔罕茂明安联合旗"
	}],
	412: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 412,
		"id": 414,
		"level": 2,
		"name": "海勃湾区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 412,
		"id": 415,
		"level": 2,
		"name": "海南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 412,
		"id": 416,
		"level": 2,
		"name": "乌达区"
	}],
	417: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 419,
		"level": 2,
		"name": "红山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 420,
		"level": 2,
		"name": "元宝山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 421,
		"level": 2,
		"name": "松山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 422,
		"level": 2,
		"name": "阿鲁科尔沁旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 423,
		"level": 2,
		"name": "巴林左旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 424,
		"level": 2,
		"name": "巴林右旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 425,
		"level": 2,
		"name": "林西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 426,
		"level": 2,
		"name": "克什克腾旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 427,
		"level": 2,
		"name": "翁牛特旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 428,
		"level": 2,
		"name": "喀喇沁旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 429,
		"level": 2,
		"name": "宁城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 417,
		"id": 430,
		"level": 2,
		"name": "敖汉旗"
	}],
	431: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 431,
		"id": 433,
		"level": 2,
		"name": "科尔沁区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 431,
		"id": 434,
		"level": 2,
		"name": "科尔沁左翼中旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 431,
		"id": 435,
		"level": 2,
		"name": "科尔沁左翼后旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 431,
		"id": 436,
		"level": 2,
		"name": "开鲁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 431,
		"id": 437,
		"level": 2,
		"name": "库伦旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 431,
		"id": 438,
		"level": 2,
		"name": "奈曼旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 431,
		"id": 439,
		"level": 2,
		"name": "扎鲁特旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 431,
		"id": 440,
		"level": 2,
		"name": "霍林郭勒市"
	}],
	441: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 441,
		"id": 443,
		"level": 2,
		"name": "东胜区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 441,
		"id": 444,
		"level": 2,
		"name": "达拉特旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 441,
		"id": 445,
		"level": 2,
		"name": "准格尔旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 441,
		"id": 446,
		"level": 2,
		"name": "鄂托克前旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 441,
		"id": 447,
		"level": 2,
		"name": "鄂托克旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 441,
		"id": 448,
		"level": 2,
		"name": "杭锦旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 441,
		"id": 449,
		"level": 2,
		"name": "乌审旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 441,
		"id": 450,
		"level": 2,
		"name": "伊金霍洛旗"
	}],
	451: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 453,
		"level": 2,
		"name": "海拉尔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 454,
		"level": 2,
		"name": "阿荣旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 455,
		"level": 2,
		"name": "莫力达瓦达斡尔族自治旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 456,
		"level": 2,
		"name": "鄂伦春自治旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 457,
		"level": 2,
		"name": "鄂温克族自治旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 458,
		"level": 2,
		"name": "陈巴尔虎旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 459,
		"level": 2,
		"name": "新巴尔虎左旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 460,
		"level": 2,
		"name": "新巴尔虎右旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 461,
		"level": 2,
		"name": "满洲里市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 462,
		"level": 2,
		"name": "牙克石市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 463,
		"level": 2,
		"name": "扎兰屯市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 464,
		"level": 2,
		"name": "额尔古纳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 451,
		"id": 465,
		"level": 2,
		"name": "根河市"
	}],
	466: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 466,
		"id": 468,
		"level": 2,
		"name": "临河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 466,
		"id": 469,
		"level": 2,
		"name": "五原县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 466,
		"id": 470,
		"level": 2,
		"name": "磴口县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 466,
		"id": 471,
		"level": 2,
		"name": "乌拉特前旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 466,
		"id": 472,
		"level": 2,
		"name": "乌拉特中旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 466,
		"id": 473,
		"level": 2,
		"name": "乌拉特后旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 466,
		"id": 474,
		"level": 2,
		"name": "杭锦后旗"
	}],
	475: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 477,
		"level": 2,
		"name": "集宁区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 478,
		"level": 2,
		"name": "卓资县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 479,
		"level": 2,
		"name": "化德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 480,
		"level": 2,
		"name": "商都县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 481,
		"level": 2,
		"name": "兴和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 482,
		"level": 2,
		"name": "凉城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 483,
		"level": 2,
		"name": "察哈尔右翼前旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 484,
		"level": 2,
		"name": "察哈尔右翼中旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 485,
		"level": 2,
		"name": "察哈尔右翼后旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 486,
		"level": 2,
		"name": "四子王旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 475,
		"id": 487,
		"level": 2,
		"name": "丰镇市"
	}],
	488: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 488,
		"id": 489,
		"level": 2,
		"name": "乌兰浩特市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 488,
		"id": 490,
		"level": 2,
		"name": "阿尔山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 488,
		"id": 491,
		"level": 2,
		"name": "科尔沁右翼前旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 488,
		"id": 492,
		"level": 2,
		"name": "科尔沁右翼中旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 488,
		"id": 493,
		"level": 2,
		"name": "扎赉特旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 488,
		"id": 494,
		"level": 2,
		"name": "突泉县"
	}],
	495: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 496,
		"level": 2,
		"name": "二连浩特市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 497,
		"level": 2,
		"name": "锡林浩特市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 498,
		"level": 2,
		"name": "阿巴嘎旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 499,
		"level": 2,
		"name": "苏尼特左旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 500,
		"level": 2,
		"name": "苏尼特右旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 501,
		"level": 2,
		"name": "东乌珠穆沁旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 502,
		"level": 2,
		"name": "西乌珠穆沁旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 503,
		"level": 2,
		"name": "太仆寺旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 504,
		"level": 2,
		"name": "镶黄旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 505,
		"level": 2,
		"name": "正镶白旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 506,
		"level": 2,
		"name": "正蓝旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 495,
		"id": 507,
		"level": 2,
		"name": "多伦县"
	}],
	508: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 508,
		"id": 509,
		"level": 2,
		"name": "阿拉善左旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 508,
		"id": 510,
		"level": 2,
		"name": "阿拉善右旗"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 508,
		"id": 511,
		"level": 2,
		"name": "额济纳旗"
	}],
	513: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 515,
		"level": 2,
		"name": "和平区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 516,
		"level": 2,
		"name": "沈河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 517,
		"level": 2,
		"name": "大东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 518,
		"level": 2,
		"name": "皇姑区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 519,
		"level": 2,
		"name": "铁西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 520,
		"level": 2,
		"name": "苏家屯区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 521,
		"level": 2,
		"name": "东陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 522,
		"level": 2,
		"name": "沈北新区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 523,
		"level": 2,
		"name": "于洪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 524,
		"level": 2,
		"name": "辽中县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 525,
		"level": 2,
		"name": "康平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 526,
		"level": 2,
		"name": "法库县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 527,
		"level": 2,
		"name": "新民市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 513,
		"id": 3726,
		"level": 2,
		"name": "浑南新区"
	}],
	528: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 530,
		"level": 2,
		"name": "中山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 531,
		"level": 2,
		"name": "西岗区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 532,
		"level": 2,
		"name": "沙河口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 533,
		"level": 2,
		"name": "甘井子区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 534,
		"level": 2,
		"name": "旅顺口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 535,
		"level": 2,
		"name": "金州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 536,
		"level": 2,
		"name": "长海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 537,
		"level": 2,
		"name": "瓦房店市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 538,
		"level": 2,
		"name": "普兰店市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 528,
		"id": 539,
		"level": 2,
		"name": "庄河市"
	}],
	540: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 540,
		"id": 542,
		"level": 2,
		"name": "铁东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 540,
		"id": 543,
		"level": 2,
		"name": "铁西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 540,
		"id": 544,
		"level": 2,
		"name": "立山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 540,
		"id": 545,
		"level": 2,
		"name": "千山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 540,
		"id": 546,
		"level": 2,
		"name": "台安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 540,
		"id": 547,
		"level": 2,
		"name": "岫岩满族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 540,
		"id": 548,
		"level": 2,
		"name": "海城市"
	}],
	549: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 549,
		"id": 551,
		"level": 2,
		"name": "新抚区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 549,
		"id": 552,
		"level": 2,
		"name": "东洲区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 549,
		"id": 553,
		"level": 2,
		"name": "望花区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 549,
		"id": 554,
		"level": 2,
		"name": "顺城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 549,
		"id": 555,
		"level": 2,
		"name": "抚顺县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 549,
		"id": 556,
		"level": 2,
		"name": "新宾满族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 549,
		"id": 557,
		"level": 2,
		"name": "清原满族自治县"
	}],
	558: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 558,
		"id": 560,
		"level": 2,
		"name": "平山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 558,
		"id": 561,
		"level": 2,
		"name": "溪湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 558,
		"id": 562,
		"level": 2,
		"name": "明山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 558,
		"id": 563,
		"level": 2,
		"name": "南芬区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 558,
		"id": 564,
		"level": 2,
		"name": "本溪满族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 558,
		"id": 565,
		"level": 2,
		"name": "桓仁满族自治县"
	}],
	566: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 566,
		"id": 568,
		"level": 2,
		"name": "元宝区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 566,
		"id": 569,
		"level": 2,
		"name": "振兴区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 566,
		"id": 570,
		"level": 2,
		"name": "振安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 566,
		"id": 571,
		"level": 2,
		"name": "宽甸满族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 566,
		"id": 572,
		"level": 2,
		"name": "东港市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 566,
		"id": 573,
		"level": 2,
		"name": "凤城市"
	}],
	574: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 574,
		"id": 576,
		"level": 2,
		"name": "古塔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 574,
		"id": 577,
		"level": 2,
		"name": "凌河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 574,
		"id": 578,
		"level": 2,
		"name": "太和区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 574,
		"id": 579,
		"level": 2,
		"name": "黑山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 574,
		"id": 580,
		"level": 2,
		"name": "义县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 574,
		"id": 581,
		"level": 2,
		"name": "凌海市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 574,
		"id": 582,
		"level": 2,
		"name": "北镇市"
	}],
	583: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 583,
		"id": 585,
		"level": 2,
		"name": "站前区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 583,
		"id": 586,
		"level": 2,
		"name": "西市区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 583,
		"id": 587,
		"level": 2,
		"name": "鲅鱼圈区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 583,
		"id": 588,
		"level": 2,
		"name": "老边区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 583,
		"id": 589,
		"level": 2,
		"name": "盖州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 583,
		"id": 590,
		"level": 2,
		"name": "大石桥市"
	}],
	591: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 591,
		"id": 593,
		"level": 2,
		"name": "海州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 591,
		"id": 594,
		"level": 2,
		"name": "新邱区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 591,
		"id": 595,
		"level": 2,
		"name": "太平区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 591,
		"id": 596,
		"level": 2,
		"name": "清河门区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 591,
		"id": 597,
		"level": 2,
		"name": "细河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 591,
		"id": 598,
		"level": 2,
		"name": "阜新蒙古族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 591,
		"id": 599,
		"level": 2,
		"name": "彰武县"
	}],
	600: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 600,
		"id": 602,
		"level": 2,
		"name": "白塔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 600,
		"id": 603,
		"level": 2,
		"name": "文圣区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 600,
		"id": 604,
		"level": 2,
		"name": "宏伟区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 600,
		"id": 605,
		"level": 2,
		"name": "弓长岭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 600,
		"id": 606,
		"level": 2,
		"name": "太子河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 600,
		"id": 607,
		"level": 2,
		"name": "辽阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 600,
		"id": 608,
		"level": 2,
		"name": "灯塔市"
	}],
	609: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 609,
		"id": 611,
		"level": 2,
		"name": "双台子区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 609,
		"id": 612,
		"level": 2,
		"name": "兴隆台区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 609,
		"id": 613,
		"level": 2,
		"name": "大洼县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 609,
		"id": 614,
		"level": 2,
		"name": "盘山县"
	}],
	615: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 615,
		"id": 617,
		"level": 2,
		"name": "银州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 615,
		"id": 618,
		"level": 2,
		"name": "清河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 615,
		"id": 619,
		"level": 2,
		"name": "铁岭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 615,
		"id": 620,
		"level": 2,
		"name": "西丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 615,
		"id": 621,
		"level": 2,
		"name": "昌图县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 615,
		"id": 622,
		"level": 2,
		"name": "调兵山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 615,
		"id": 623,
		"level": 2,
		"name": "开原市"
	}],
	624: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 624,
		"id": 626,
		"level": 2,
		"name": "双塔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 624,
		"id": 627,
		"level": 2,
		"name": "龙城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 624,
		"id": 628,
		"level": 2,
		"name": "朝阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 624,
		"id": 629,
		"level": 2,
		"name": "建平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 624,
		"id": 630,
		"level": 2,
		"name": "喀喇沁左翼蒙古族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 624,
		"id": 631,
		"level": 2,
		"name": "北票市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 624,
		"id": 632,
		"level": 2,
		"name": "凌源市"
	}],
	633: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 633,
		"id": 635,
		"level": 2,
		"name": "连山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 633,
		"id": 636,
		"level": 2,
		"name": "龙港区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 633,
		"id": 637,
		"level": 2,
		"name": "南票区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 633,
		"id": 638,
		"level": 2,
		"name": "绥中县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 633,
		"id": 639,
		"level": 2,
		"name": "建昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 633,
		"id": 640,
		"level": 2,
		"name": "兴城市"
	}],
	642: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 644,
		"level": 2,
		"name": "南关区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 645,
		"level": 2,
		"name": "宽城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 646,
		"level": 2,
		"name": "朝阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 647,
		"level": 2,
		"name": "二道区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 648,
		"level": 2,
		"name": "绿园区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 649,
		"level": 2,
		"name": "双阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 650,
		"level": 2,
		"name": "农安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 651,
		"level": 2,
		"name": "九台市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 652,
		"level": 2,
		"name": "榆树市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 642,
		"id": 653,
		"level": 2,
		"name": "德惠市"
	}],
	654: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 654,
		"id": 656,
		"level": 2,
		"name": "昌邑区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 654,
		"id": 657,
		"level": 2,
		"name": "龙潭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 654,
		"id": 658,
		"level": 2,
		"name": "船营区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 654,
		"id": 659,
		"level": 2,
		"name": "丰满区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 654,
		"id": 660,
		"level": 2,
		"name": "永吉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 654,
		"id": 661,
		"level": 2,
		"name": "蛟河市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 654,
		"id": 662,
		"level": 2,
		"name": "桦甸市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 654,
		"id": 663,
		"level": 2,
		"name": "舒兰市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 654,
		"id": 664,
		"level": 2,
		"name": "磐石市"
	}],
	665: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 665,
		"id": 667,
		"level": 2,
		"name": "铁西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 665,
		"id": 668,
		"level": 2,
		"name": "铁东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 665,
		"id": 669,
		"level": 2,
		"name": "梨树县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 665,
		"id": 670,
		"level": 2,
		"name": "伊通满族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 665,
		"id": 671,
		"level": 2,
		"name": "公主岭市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 665,
		"id": 672,
		"level": 2,
		"name": "双辽市"
	}],
	673: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 673,
		"id": 675,
		"level": 2,
		"name": "龙山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 673,
		"id": 676,
		"level": 2,
		"name": "西安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 673,
		"id": 677,
		"level": 2,
		"name": "东丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 673,
		"id": 678,
		"level": 2,
		"name": "东辽县"
	}],
	679: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 679,
		"id": 681,
		"level": 2,
		"name": "东昌区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 679,
		"id": 682,
		"level": 2,
		"name": "二道江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 679,
		"id": 683,
		"level": 2,
		"name": "通化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 679,
		"id": 684,
		"level": 2,
		"name": "辉南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 679,
		"id": 685,
		"level": 2,
		"name": "柳河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 679,
		"id": 686,
		"level": 2,
		"name": "梅河口市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 679,
		"id": 687,
		"level": 2,
		"name": "集安市"
	}],
	688: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 688,
		"id": 690,
		"level": 2,
		"name": "八道江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 688,
		"id": 691,
		"level": 2,
		"name": "江源区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 688,
		"id": 692,
		"level": 2,
		"name": "抚松县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 688,
		"id": 693,
		"level": 2,
		"name": "靖宇县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 688,
		"id": 694,
		"level": 2,
		"name": "长白朝鲜族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 688,
		"id": 695,
		"level": 2,
		"name": "临江市"
	}],
	696: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 696,
		"id": 698,
		"level": 2,
		"name": "宁江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 696,
		"id": 699,
		"level": 2,
		"name": "前郭尔罗斯蒙古族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 696,
		"id": 700,
		"level": 2,
		"name": "长岭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 696,
		"id": 701,
		"level": 2,
		"name": "乾安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 696,
		"id": 702,
		"level": 2,
		"name": "扶余县"
	}],
	703: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 703,
		"id": 705,
		"level": 2,
		"name": "洮北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 703,
		"id": 706,
		"level": 2,
		"name": "镇赉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 703,
		"id": 707,
		"level": 2,
		"name": "通榆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 703,
		"id": 708,
		"level": 2,
		"name": "洮南市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 703,
		"id": 709,
		"level": 2,
		"name": "大安市"
	}],
	710: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 710,
		"id": 711,
		"level": 2,
		"name": "延吉市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 710,
		"id": 712,
		"level": 2,
		"name": "图们市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 710,
		"id": 713,
		"level": 2,
		"name": "敦化市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 710,
		"id": 714,
		"level": 2,
		"name": "珲春市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 710,
		"id": 715,
		"level": 2,
		"name": "龙井市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 710,
		"id": 716,
		"level": 2,
		"name": "和龙市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 710,
		"id": 717,
		"level": 2,
		"name": "汪清县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 710,
		"id": 718,
		"level": 2,
		"name": "安图县"
	}],
	720: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 722,
		"level": 2,
		"name": "道里区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 723,
		"level": 2,
		"name": "南岗区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 724,
		"level": 2,
		"name": "道外区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 725,
		"level": 2,
		"name": "平房区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 726,
		"level": 2,
		"name": "松北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 727,
		"level": 2,
		"name": "香坊区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 728,
		"level": 2,
		"name": "呼兰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 729,
		"level": 2,
		"name": "阿城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 730,
		"level": 2,
		"name": "依兰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 731,
		"level": 2,
		"name": "方正县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 732,
		"level": 2,
		"name": "宾县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 733,
		"level": 2,
		"name": "巴彦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 734,
		"level": 2,
		"name": "木兰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 735,
		"level": 2,
		"name": "通河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 736,
		"level": 2,
		"name": "延寿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 737,
		"level": 2,
		"name": "双城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 738,
		"level": 2,
		"name": "尚志市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 720,
		"id": 739,
		"level": 2,
		"name": "五常市"
	}],
	740: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 742,
		"level": 2,
		"name": "龙沙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 743,
		"level": 2,
		"name": "建华区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 744,
		"level": 2,
		"name": "铁锋区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 745,
		"level": 2,
		"name": "昂昂溪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 746,
		"level": 2,
		"name": "富拉尔基区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 747,
		"level": 2,
		"name": "碾子山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 748,
		"level": 2,
		"name": "梅里斯达斡尔族区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 749,
		"level": 2,
		"name": "龙江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 750,
		"level": 2,
		"name": "依安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 751,
		"level": 2,
		"name": "泰来县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 752,
		"level": 2,
		"name": "甘南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 753,
		"level": 2,
		"name": "富裕县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 754,
		"level": 2,
		"name": "克山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 755,
		"level": 2,
		"name": "克东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 756,
		"level": 2,
		"name": "拜泉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 740,
		"id": 757,
		"level": 2,
		"name": "讷河市"
	}],
	758: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 758,
		"id": 760,
		"level": 2,
		"name": "鸡冠区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 758,
		"id": 761,
		"level": 2,
		"name": "恒山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 758,
		"id": 762,
		"level": 2,
		"name": "滴道区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 758,
		"id": 763,
		"level": 2,
		"name": "梨树区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 758,
		"id": 764,
		"level": 2,
		"name": "城子河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 758,
		"id": 765,
		"level": 2,
		"name": "麻山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 758,
		"id": 766,
		"level": 2,
		"name": "鸡东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 758,
		"id": 767,
		"level": 2,
		"name": "虎林市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 758,
		"id": 768,
		"level": 2,
		"name": "密山市"
	}],
	769: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 769,
		"id": 771,
		"level": 2,
		"name": "向阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 769,
		"id": 772,
		"level": 2,
		"name": "工农区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 769,
		"id": 773,
		"level": 2,
		"name": "南山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 769,
		"id": 774,
		"level": 2,
		"name": "兴安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 769,
		"id": 775,
		"level": 2,
		"name": "东山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 769,
		"id": 776,
		"level": 2,
		"name": "兴山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 769,
		"id": 777,
		"level": 2,
		"name": "萝北县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 769,
		"id": 778,
		"level": 2,
		"name": "绥滨县"
	}],
	779: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 779,
		"id": 781,
		"level": 2,
		"name": "尖山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 779,
		"id": 782,
		"level": 2,
		"name": "岭东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 779,
		"id": 783,
		"level": 2,
		"name": "四方台区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 779,
		"id": 784,
		"level": 2,
		"name": "宝山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 779,
		"id": 785,
		"level": 2,
		"name": "集贤县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 779,
		"id": 786,
		"level": 2,
		"name": "友谊县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 779,
		"id": 787,
		"level": 2,
		"name": "宝清县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 779,
		"id": 788,
		"level": 2,
		"name": "饶河县"
	}],
	789: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 789,
		"id": 791,
		"level": 2,
		"name": "萨尔图区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 789,
		"id": 792,
		"level": 2,
		"name": "龙凤区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 789,
		"id": 793,
		"level": 2,
		"name": "让胡路区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 789,
		"id": 794,
		"level": 2,
		"name": "红岗区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 789,
		"id": 795,
		"level": 2,
		"name": "大同区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 789,
		"id": 796,
		"level": 2,
		"name": "肇州县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 789,
		"id": 797,
		"level": 2,
		"name": "肇源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 789,
		"id": 798,
		"level": 2,
		"name": "林甸县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 789,
		"id": 799,
		"level": 2,
		"name": "杜尔伯特蒙古族自治县"
	}],
	800: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 802,
		"level": 2,
		"name": "伊春区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 803,
		"level": 2,
		"name": "南岔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 804,
		"level": 2,
		"name": "友好区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 805,
		"level": 2,
		"name": "西林区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 806,
		"level": 2,
		"name": "翠峦区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 807,
		"level": 2,
		"name": "新青区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 808,
		"level": 2,
		"name": "美溪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 809,
		"level": 2,
		"name": "金山屯区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 810,
		"level": 2,
		"name": "五营区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 811,
		"level": 2,
		"name": "乌马河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 812,
		"level": 2,
		"name": "汤旺河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 813,
		"level": 2,
		"name": "带岭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 814,
		"level": 2,
		"name": "乌伊岭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 815,
		"level": 2,
		"name": "红星区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 816,
		"level": 2,
		"name": "上甘岭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 817,
		"level": 2,
		"name": "嘉荫县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 800,
		"id": 818,
		"level": 2,
		"name": "铁力市"
	}],
	819: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 821,
		"level": 2,
		"name": "向阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 822,
		"level": 2,
		"name": "前进区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 823,
		"level": 2,
		"name": "东风区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 824,
		"level": 2,
		"name": "郊区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 825,
		"level": 2,
		"name": "桦南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 826,
		"level": 2,
		"name": "桦川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 827,
		"level": 2,
		"name": "汤原县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 828,
		"level": 2,
		"name": "抚远县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 829,
		"level": 2,
		"name": "同江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 819,
		"id": 830,
		"level": 2,
		"name": "富锦市"
	}],
	831: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 831,
		"id": 833,
		"level": 2,
		"name": "新兴区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 831,
		"id": 834,
		"level": 2,
		"name": "桃山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 831,
		"id": 835,
		"level": 2,
		"name": "茄子河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 831,
		"id": 836,
		"level": 2,
		"name": "勃利县"
	}],
	837: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 839,
		"level": 2,
		"name": "东安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 840,
		"level": 2,
		"name": "阳明区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 841,
		"level": 2,
		"name": "爱民区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 842,
		"level": 2,
		"name": "西安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 843,
		"level": 2,
		"name": "东宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 844,
		"level": 2,
		"name": "林口县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 845,
		"level": 2,
		"name": "绥芬河市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 846,
		"level": 2,
		"name": "海林市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 847,
		"level": 2,
		"name": "宁安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 837,
		"id": 848,
		"level": 2,
		"name": "穆棱市"
	}],
	849: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 849,
		"id": 851,
		"level": 2,
		"name": "爱辉区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 849,
		"id": 852,
		"level": 2,
		"name": "嫩江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 849,
		"id": 853,
		"level": 2,
		"name": "逊克县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 849,
		"id": 854,
		"level": 2,
		"name": "孙吴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 849,
		"id": 855,
		"level": 2,
		"name": "北安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 849,
		"id": 856,
		"level": 2,
		"name": "五大连池市"
	}],
	857: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 859,
		"level": 2,
		"name": "北林区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 860,
		"level": 2,
		"name": "望奎县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 861,
		"level": 2,
		"name": "兰西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 862,
		"level": 2,
		"name": "青冈县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 863,
		"level": 2,
		"name": "庆安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 864,
		"level": 2,
		"name": "明水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 865,
		"level": 2,
		"name": "绥棱县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 866,
		"level": 2,
		"name": "安达市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 867,
		"level": 2,
		"name": "肇东市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 857,
		"id": 868,
		"level": 2,
		"name": "海伦市"
	}],
	869: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 869,
		"id": 870,
		"level": 2,
		"name": "呼玛县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 869,
		"id": 871,
		"level": 2,
		"name": "塔河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 869,
		"id": 872,
		"level": 2,
		"name": "漠河县"
	}],
	874: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 875,
		"level": 2,
		"name": "黄浦区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 876,
		"level": 2,
		"name": "徐汇区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 877,
		"level": 2,
		"name": "长宁区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 878,
		"level": 2,
		"name": "静安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 879,
		"level": 2,
		"name": "普陀区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 880,
		"level": 2,
		"name": "闸北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 881,
		"level": 2,
		"name": "虹口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 882,
		"level": 2,
		"name": "杨浦区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 883,
		"level": 2,
		"name": "闵行区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 884,
		"level": 2,
		"name": "宝山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 885,
		"level": 2,
		"name": "嘉定区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 886,
		"level": 2,
		"name": "浦东新区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 887,
		"level": 2,
		"name": "金山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 888,
		"level": 2,
		"name": "松江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 889,
		"level": 2,
		"name": "青浦区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 890,
		"level": 2,
		"name": "奉贤区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 891,
		"level": 2,
		"name": "崇明县"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 874,
		"id": 3727,
		"level": 2,
		"name": "卢湾区"
	}],
	893: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 895,
		"level": 2,
		"name": "玄武区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 896,
		"level": 2,
		"name": "白下区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 897,
		"level": 2,
		"name": "秦淮区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 898,
		"level": 2,
		"name": "建邺区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 899,
		"level": 2,
		"name": "鼓楼区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 900,
		"level": 2,
		"name": "下关区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 901,
		"level": 2,
		"name": "浦口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 902,
		"level": 2,
		"name": "栖霞区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 903,
		"level": 2,
		"name": "雨花台区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 904,
		"level": 2,
		"name": "江宁区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 905,
		"level": 2,
		"name": "六合区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 906,
		"level": 2,
		"name": "溧水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 893,
		"id": 907,
		"level": 2,
		"name": "高淳县"
	}],
	908: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 908,
		"id": 910,
		"level": 2,
		"name": "崇安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 908,
		"id": 911,
		"level": 2,
		"name": "南长区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 908,
		"id": 912,
		"level": 2,
		"name": "北塘区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 908,
		"id": 913,
		"level": 2,
		"name": "锡山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 908,
		"id": 914,
		"level": 2,
		"name": "惠山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 908,
		"id": 915,
		"level": 2,
		"name": "滨湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 908,
		"id": 916,
		"level": 2,
		"name": "江阴市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 908,
		"id": 917,
		"level": 2,
		"name": "宜兴市"
	}],
	918: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 920,
		"level": 2,
		"name": "鼓楼区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 921,
		"level": 2,
		"name": "云龙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 922,
		"level": 2,
		"name": "贾汪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 923,
		"level": 2,
		"name": "泉山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 924,
		"level": 2,
		"name": "铜山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 925,
		"level": 2,
		"name": "丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 926,
		"level": 2,
		"name": "沛县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 927,
		"level": 2,
		"name": "睢宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 928,
		"level": 2,
		"name": "新沂市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 929,
		"level": 2,
		"name": "邳州市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 918,
		"id": 3733,
		"level": 2,
		"name": "九里区"
	}],
	930: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 930,
		"id": 932,
		"level": 2,
		"name": "天宁区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 930,
		"id": 933,
		"level": 2,
		"name": "钟楼区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 930,
		"id": 934,
		"level": 2,
		"name": "戚墅堰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 930,
		"id": 935,
		"level": 2,
		"name": "新北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 930,
		"id": 936,
		"level": 2,
		"name": "武进区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 930,
		"id": 937,
		"level": 2,
		"name": "溧阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 930,
		"id": 938,
		"level": 2,
		"name": "金坛市"
	}],
	939: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 941,
		"level": 2,
		"name": "沧浪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 942,
		"level": 2,
		"name": "平江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 943,
		"level": 2,
		"name": "金阊区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 944,
		"level": 2,
		"name": "虎丘区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 945,
		"level": 2,
		"name": "吴中区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 946,
		"level": 2,
		"name": "相城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 947,
		"level": 2,
		"name": "常熟市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 948,
		"level": 2,
		"name": "张家港市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 949,
		"level": 2,
		"name": "昆山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 950,
		"level": 2,
		"name": "吴江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 951,
		"level": 2,
		"name": "太仓市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 939,
		"id": 3723,
		"level": 2,
		"name": "工业园区"
	}],
	952: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 952,
		"id": 954,
		"level": 2,
		"name": "崇川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 952,
		"id": 955,
		"level": 2,
		"name": "港闸区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 952,
		"id": 956,
		"level": 2,
		"name": "通州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 952,
		"id": 957,
		"level": 2,
		"name": "海安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 952,
		"id": 958,
		"level": 2,
		"name": "如东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 952,
		"id": 959,
		"level": 2,
		"name": "启东市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 952,
		"id": 960,
		"level": 2,
		"name": "如皋市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 952,
		"id": 961,
		"level": 2,
		"name": "海门市"
	}],
	962: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 962,
		"id": 964,
		"level": 2,
		"name": "连云区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 962,
		"id": 965,
		"level": 2,
		"name": "新浦区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 962,
		"id": 966,
		"level": 2,
		"name": "海州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 962,
		"id": 967,
		"level": 2,
		"name": "赣榆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 962,
		"id": 968,
		"level": 2,
		"name": "东海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 962,
		"id": 969,
		"level": 2,
		"name": "灌云县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 962,
		"id": 970,
		"level": 2,
		"name": "灌南县"
	}],
	971: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 971,
		"id": 973,
		"level": 2,
		"name": "清河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 971,
		"id": 974,
		"level": 2,
		"name": "楚州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 971,
		"id": 975,
		"level": 2,
		"name": "淮阴区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 971,
		"id": 976,
		"level": 2,
		"name": "清浦区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 971,
		"id": 977,
		"level": 2,
		"name": "涟水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 971,
		"id": 978,
		"level": 2,
		"name": "洪泽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 971,
		"id": 979,
		"level": 2,
		"name": "盱眙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 971,
		"id": 980,
		"level": 2,
		"name": "金湖县"
	}],
	981: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 981,
		"id": 983,
		"level": 2,
		"name": "亭湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 981,
		"id": 984,
		"level": 2,
		"name": "盐都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 981,
		"id": 985,
		"level": 2,
		"name": "响水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 981,
		"id": 986,
		"level": 2,
		"name": "滨海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 981,
		"id": 987,
		"level": 2,
		"name": "阜宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 981,
		"id": 988,
		"level": 2,
		"name": "射阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 981,
		"id": 989,
		"level": 2,
		"name": "建湖县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 981,
		"id": 990,
		"level": 2,
		"name": "东台市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 981,
		"id": 991,
		"level": 2,
		"name": "大丰市"
	}],
	992: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 992,
		"id": 994,
		"level": 2,
		"name": "广陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 992,
		"id": 995,
		"level": 2,
		"name": "邗江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 992,
		"id": 996,
		"level": 2,
		"name": "江都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 992,
		"id": 997,
		"level": 2,
		"name": "宝应县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 992,
		"id": 998,
		"level": 2,
		"name": "仪征市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 992,
		"id": 999,
		"level": 2,
		"name": "高邮市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 992,
		"id": 3724,
		"level": 2,
		"name": "维扬区"
	}],
	1000: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1000,
		"id": 1002,
		"level": 2,
		"name": "京口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1000,
		"id": 1003,
		"level": 2,
		"name": "润州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1000,
		"id": 1004,
		"level": 2,
		"name": "丹徒区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1000,
		"id": 1005,
		"level": 2,
		"name": "丹阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1000,
		"id": 1006,
		"level": 2,
		"name": "扬中市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1000,
		"id": 1007,
		"level": 2,
		"name": "句容市"
	}],
	1008: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1008,
		"id": 1010,
		"level": 2,
		"name": "海陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1008,
		"id": 1011,
		"level": 2,
		"name": "高港区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1008,
		"id": 1012,
		"level": 2,
		"name": "兴化市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1008,
		"id": 1013,
		"level": 2,
		"name": "靖江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1008,
		"id": 1014,
		"level": 2,
		"name": "泰兴市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1008,
		"id": 1015,
		"level": 2,
		"name": "姜堰市"
	}],
	1016: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1016,
		"id": 1018,
		"level": 2,
		"name": "宿城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1016,
		"id": 1019,
		"level": 2,
		"name": "宿豫区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1016,
		"id": 1020,
		"level": 2,
		"name": "沭阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1016,
		"id": 1021,
		"level": 2,
		"name": "泗阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1016,
		"id": 1022,
		"level": 2,
		"name": "泗洪县"
	}],
	1024: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1026,
		"level": 2,
		"name": "上城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1027,
		"level": 2,
		"name": "下城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1028,
		"level": 2,
		"name": "江干区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1029,
		"level": 2,
		"name": "拱墅区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1030,
		"level": 2,
		"name": "西湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1031,
		"level": 2,
		"name": "滨江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1032,
		"level": 2,
		"name": "萧山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1033,
		"level": 2,
		"name": "余杭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1034,
		"level": 2,
		"name": "桐庐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1035,
		"level": 2,
		"name": "淳安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1036,
		"level": 2,
		"name": "建德市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1037,
		"level": 2,
		"name": "富阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1024,
		"id": 1038,
		"level": 2,
		"name": "临安市"
	}],
	1039: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1041,
		"level": 2,
		"name": "海曙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1043,
		"level": 2,
		"name": "江北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1044,
		"level": 2,
		"name": "北仑区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1045,
		"level": 2,
		"name": "镇海区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1046,
		"level": 2,
		"name": "鄞州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1047,
		"level": 2,
		"name": "象山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1048,
		"level": 2,
		"name": "宁海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1049,
		"level": 2,
		"name": "余姚市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1050,
		"level": 2,
		"name": "慈溪市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1051,
		"level": 2,
		"name": "奉化市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1039,
		"id": 1101,
		"level": 2,
		"name": "江东区"
	}],
	1052: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1054,
		"level": 2,
		"name": "鹿城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1055,
		"level": 2,
		"name": "龙湾区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1056,
		"level": 2,
		"name": "瓯海区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1057,
		"level": 2,
		"name": "洞头县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1058,
		"level": 2,
		"name": "永嘉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1059,
		"level": 2,
		"name": "平阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1060,
		"level": 2,
		"name": "苍南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1061,
		"level": 2,
		"name": "文成县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1062,
		"level": 2,
		"name": "泰顺县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1063,
		"level": 2,
		"name": "瑞安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1052,
		"id": 1064,
		"level": 2,
		"name": "乐清市"
	}],
	1065: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1065,
		"id": 1067,
		"level": 2,
		"name": "南湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1065,
		"id": 1068,
		"level": 2,
		"name": "秀洲区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1065,
		"id": 1069,
		"level": 2,
		"name": "嘉善县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1065,
		"id": 1070,
		"level": 2,
		"name": "海盐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1065,
		"id": 1071,
		"level": 2,
		"name": "海宁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1065,
		"id": 1072,
		"level": 2,
		"name": "平湖市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1065,
		"id": 1073,
		"level": 2,
		"name": "桐乡市"
	}],
	1074: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1074,
		"id": 1076,
		"level": 2,
		"name": "吴兴区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1074,
		"id": 1077,
		"level": 2,
		"name": "南浔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1074,
		"id": 1078,
		"level": 2,
		"name": "德清县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1074,
		"id": 1079,
		"level": 2,
		"name": "长兴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1074,
		"id": 1080,
		"level": 2,
		"name": "安吉县"
	}],
	1081: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1081,
		"id": 1083,
		"level": 2,
		"name": "越城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1081,
		"id": 1084,
		"level": 2,
		"name": "绍兴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1081,
		"id": 1085,
		"level": 2,
		"name": "新昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1081,
		"id": 1086,
		"level": 2,
		"name": "诸暨市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1081,
		"id": 1087,
		"level": 2,
		"name": "上虞市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1081,
		"id": 1088,
		"level": 2,
		"name": "嵊州市"
	}],
	1089: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1089,
		"id": 1091,
		"level": 2,
		"name": "婺城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1089,
		"id": 1092,
		"level": 2,
		"name": "金东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1089,
		"id": 1093,
		"level": 2,
		"name": "武义县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1089,
		"id": 1094,
		"level": 2,
		"name": "浦江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1089,
		"id": 1095,
		"level": 2,
		"name": "磐安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1089,
		"id": 1096,
		"level": 2,
		"name": "兰溪市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1089,
		"id": 1097,
		"level": 2,
		"name": "义乌市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1089,
		"id": 1098,
		"level": 2,
		"name": "东阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1089,
		"id": 1099,
		"level": 2,
		"name": "永康市"
	}],
	1100: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1100,
		"id": 1102,
		"level": 2,
		"name": "柯城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1100,
		"id": 1103,
		"level": 2,
		"name": "衢江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1100,
		"id": 1104,
		"level": 2,
		"name": "常山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1100,
		"id": 1105,
		"level": 2,
		"name": "开化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1100,
		"id": 1106,
		"level": 2,
		"name": "龙游县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1100,
		"id": 1107,
		"level": 2,
		"name": "江山市"
	}],
	1108: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1108,
		"id": 1110,
		"level": 2,
		"name": "定海区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1108,
		"id": 1111,
		"level": 2,
		"name": "普陀区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1108,
		"id": 1112,
		"level": 2,
		"name": "岱山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1108,
		"id": 1113,
		"level": 2,
		"name": "嵊泗县"
	}],
	1114: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1114,
		"id": 1116,
		"level": 2,
		"name": "椒江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1114,
		"id": 1117,
		"level": 2,
		"name": "黄岩区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1114,
		"id": 1118,
		"level": 2,
		"name": "路桥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1114,
		"id": 1119,
		"level": 2,
		"name": "玉环县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1114,
		"id": 1120,
		"level": 2,
		"name": "三门县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1114,
		"id": 1121,
		"level": 2,
		"name": "天台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1114,
		"id": 1122,
		"level": 2,
		"name": "仙居县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1114,
		"id": 1123,
		"level": 2,
		"name": "温岭市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1114,
		"id": 1124,
		"level": 2,
		"name": "临海市"
	}],
	1125: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1125,
		"id": 1127,
		"level": 2,
		"name": "莲都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1125,
		"id": 1128,
		"level": 2,
		"name": "青田县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1125,
		"id": 1129,
		"level": 2,
		"name": "缙云县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1125,
		"id": 1130,
		"level": 2,
		"name": "遂昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1125,
		"id": 1131,
		"level": 2,
		"name": "松阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1125,
		"id": 1132,
		"level": 2,
		"name": "云和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1125,
		"id": 1133,
		"level": 2,
		"name": "庆元县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1125,
		"id": 1134,
		"level": 2,
		"name": "景宁畲族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1125,
		"id": 1135,
		"level": 2,
		"name": "龙泉市"
	}],
	1137: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1137,
		"id": 1139,
		"level": 2,
		"name": "瑶海区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1137,
		"id": 1140,
		"level": 2,
		"name": "庐阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1137,
		"id": 1141,
		"level": 2,
		"name": "蜀山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1137,
		"id": 1142,
		"level": 2,
		"name": "包河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1137,
		"id": 1143,
		"level": 2,
		"name": "长丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1137,
		"id": 1144,
		"level": 2,
		"name": "肥东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1137,
		"id": 1145,
		"level": 2,
		"name": "肥西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1137,
		"id": 1146,
		"level": 2,
		"name": "庐江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1137,
		"id": 1147,
		"level": 2,
		"name": "巢湖市"
	}],
	1148: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1148,
		"id": 1150,
		"level": 2,
		"name": "镜湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1148,
		"id": 1151,
		"level": 2,
		"name": "弋江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1148,
		"id": 1152,
		"level": 2,
		"name": "鸠江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1148,
		"id": 1153,
		"level": 2,
		"name": "三山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1148,
		"id": 1154,
		"level": 2,
		"name": "芜湖县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1148,
		"id": 1155,
		"level": 2,
		"name": "繁昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1148,
		"id": 1156,
		"level": 2,
		"name": "南陵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1148,
		"id": 1157,
		"level": 2,
		"name": "无为县"
	}],
	1158: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1158,
		"id": 1160,
		"level": 2,
		"name": "龙子湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1158,
		"id": 1161,
		"level": 2,
		"name": "蚌山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1158,
		"id": 1162,
		"level": 2,
		"name": "禹会区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1158,
		"id": 1163,
		"level": 2,
		"name": "淮上区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1158,
		"id": 1164,
		"level": 2,
		"name": "怀远县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1158,
		"id": 1165,
		"level": 2,
		"name": "五河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1158,
		"id": 1166,
		"level": 2,
		"name": "固镇县"
	}],
	1167: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1167,
		"id": 1169,
		"level": 2,
		"name": "大通区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1167,
		"id": 1170,
		"level": 2,
		"name": "田家庵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1167,
		"id": 1171,
		"level": 2,
		"name": "谢家集区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1167,
		"id": 1172,
		"level": 2,
		"name": "八公山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1167,
		"id": 1173,
		"level": 2,
		"name": "潘集区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1167,
		"id": 1174,
		"level": 2,
		"name": "凤台县"
	}],
	1175: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1175,
		"id": 1177,
		"level": 2,
		"name": "金家庄区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1175,
		"id": 1178,
		"level": 2,
		"name": "花山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1175,
		"id": 1179,
		"level": 2,
		"name": "雨山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1175,
		"id": 1180,
		"level": 2,
		"name": "当涂县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1175,
		"id": 1181,
		"level": 2,
		"name": "含山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1175,
		"id": 1182,
		"level": 2,
		"name": "和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1175,
		"id": 3774,
		"level": 2,
		"name": "博望区"
	}],
	1183: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1183,
		"id": 1185,
		"level": 2,
		"name": "杜集区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1183,
		"id": 1186,
		"level": 2,
		"name": "相山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1183,
		"id": 1187,
		"level": 2,
		"name": "烈山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1183,
		"id": 1188,
		"level": 2,
		"name": "濉溪县"
	}],
	1189: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1189,
		"id": 1191,
		"level": 2,
		"name": "铜官山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1189,
		"id": 1192,
		"level": 2,
		"name": "狮子山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1189,
		"id": 1193,
		"level": 2,
		"name": "郊区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1189,
		"id": 1194,
		"level": 2,
		"name": "铜陵县"
	}],
	1195: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1197,
		"level": 2,
		"name": "迎江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1198,
		"level": 2,
		"name": "大观区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1199,
		"level": 2,
		"name": "宜秀区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1200,
		"level": 2,
		"name": "怀宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1201,
		"level": 2,
		"name": "枞阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1202,
		"level": 2,
		"name": "潜山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1203,
		"level": 2,
		"name": "太湖县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1204,
		"level": 2,
		"name": "宿松县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1205,
		"level": 2,
		"name": "望江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1206,
		"level": 2,
		"name": "岳西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1195,
		"id": 1207,
		"level": 2,
		"name": "桐城市"
	}],
	1208: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1208,
		"id": 1210,
		"level": 2,
		"name": "屯溪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1208,
		"id": 1211,
		"level": 2,
		"name": "黄山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1208,
		"id": 1212,
		"level": 2,
		"name": "徽州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1208,
		"id": 1213,
		"level": 2,
		"name": "歙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1208,
		"id": 1214,
		"level": 2,
		"name": "休宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1208,
		"id": 1215,
		"level": 2,
		"name": "黟县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1208,
		"id": 1216,
		"level": 2,
		"name": "祁门县"
	}],
	1217: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1217,
		"id": 1219,
		"level": 2,
		"name": "琅琊区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1217,
		"id": 1220,
		"level": 2,
		"name": "南谯区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1217,
		"id": 1221,
		"level": 2,
		"name": "来安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1217,
		"id": 1222,
		"level": 2,
		"name": "全椒县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1217,
		"id": 1223,
		"level": 2,
		"name": "定远县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1217,
		"id": 1224,
		"level": 2,
		"name": "凤阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1217,
		"id": 1225,
		"level": 2,
		"name": "天长市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1217,
		"id": 1226,
		"level": 2,
		"name": "明光市"
	}],
	1227: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1227,
		"id": 1229,
		"level": 2,
		"name": "颍州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1227,
		"id": 1230,
		"level": 2,
		"name": "颍东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1227,
		"id": 1231,
		"level": 2,
		"name": "颍泉区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1227,
		"id": 1232,
		"level": 2,
		"name": "临泉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1227,
		"id": 1233,
		"level": 2,
		"name": "太和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1227,
		"id": 1234,
		"level": 2,
		"name": "阜南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1227,
		"id": 1235,
		"level": 2,
		"name": "颍上县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1227,
		"id": 1236,
		"level": 2,
		"name": "界首市"
	}],
	1237: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1237,
		"id": 1239,
		"level": 2,
		"name": "埇桥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1237,
		"id": 1240,
		"level": 2,
		"name": "砀山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1237,
		"id": 1241,
		"level": 2,
		"name": "萧县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1237,
		"id": 1242,
		"level": 2,
		"name": "灵璧县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1237,
		"id": 1243,
		"level": 2,
		"name": "泗县"
	}],
	1244: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1244,
		"id": 1246,
		"level": 2,
		"name": "金安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1244,
		"id": 1247,
		"level": 2,
		"name": "裕安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1244,
		"id": 1248,
		"level": 2,
		"name": "寿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1244,
		"id": 1249,
		"level": 2,
		"name": "霍邱县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1244,
		"id": 1250,
		"level": 2,
		"name": "舒城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1244,
		"id": 1251,
		"level": 2,
		"name": "金寨县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1244,
		"id": 1252,
		"level": 2,
		"name": "霍山县"
	}],
	1253: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1253,
		"id": 1255,
		"level": 2,
		"name": "谯城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1253,
		"id": 1256,
		"level": 2,
		"name": "涡阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1253,
		"id": 1257,
		"level": 2,
		"name": "蒙城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1253,
		"id": 1258,
		"level": 2,
		"name": "利辛县"
	}],
	1259: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1259,
		"id": 1261,
		"level": 2,
		"name": "贵池区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1259,
		"id": 1262,
		"level": 2,
		"name": "东至县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1259,
		"id": 1263,
		"level": 2,
		"name": "石台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1259,
		"id": 1264,
		"level": 2,
		"name": "青阳县"
	}],
	1265: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1265,
		"id": 1267,
		"level": 2,
		"name": "宣州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1265,
		"id": 1268,
		"level": 2,
		"name": "郎溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1265,
		"id": 1269,
		"level": 2,
		"name": "广德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1265,
		"id": 1270,
		"level": 2,
		"name": "泾县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1265,
		"id": 1271,
		"level": 2,
		"name": "绩溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1265,
		"id": 1272,
		"level": 2,
		"name": "旌德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1265,
		"id": 1273,
		"level": 2,
		"name": "宁国市"
	}],
	1275: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1277,
		"level": 2,
		"name": "鼓楼区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1278,
		"level": 2,
		"name": "台江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1279,
		"level": 2,
		"name": "仓山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1280,
		"level": 2,
		"name": "马尾区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1281,
		"level": 2,
		"name": "晋安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1282,
		"level": 2,
		"name": "闽侯县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1283,
		"level": 2,
		"name": "连江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1284,
		"level": 2,
		"name": "罗源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1285,
		"level": 2,
		"name": "闽清县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1286,
		"level": 2,
		"name": "永泰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1287,
		"level": 2,
		"name": "平潭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1288,
		"level": 2,
		"name": "福清市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1275,
		"id": 1289,
		"level": 2,
		"name": "长乐市"
	}],
	1290: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1290,
		"id": 1292,
		"level": 2,
		"name": "思明区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1290,
		"id": 1293,
		"level": 2,
		"name": "海沧区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1290,
		"id": 1294,
		"level": 2,
		"name": "湖里区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1290,
		"id": 1295,
		"level": 2,
		"name": "集美区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1290,
		"id": 1296,
		"level": 2,
		"name": "同安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1290,
		"id": 1297,
		"level": 2,
		"name": "翔安区"
	}],
	1298: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1298,
		"id": 1300,
		"level": 2,
		"name": "城厢区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1298,
		"id": 1301,
		"level": 2,
		"name": "涵江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1298,
		"id": 1302,
		"level": 2,
		"name": "荔城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1298,
		"id": 1303,
		"level": 2,
		"name": "秀屿区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1298,
		"id": 1304,
		"level": 2,
		"name": "仙游县"
	}],
	1305: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1307,
		"level": 2,
		"name": "梅列区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1308,
		"level": 2,
		"name": "三元区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1309,
		"level": 2,
		"name": "明溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1310,
		"level": 2,
		"name": "清流县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1311,
		"level": 2,
		"name": "宁化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1312,
		"level": 2,
		"name": "大田县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1313,
		"level": 2,
		"name": "尤溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1314,
		"level": 2,
		"name": "沙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1315,
		"level": 2,
		"name": "将乐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1316,
		"level": 2,
		"name": "泰宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1317,
		"level": 2,
		"name": "建宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1305,
		"id": 1318,
		"level": 2,
		"name": "永安市"
	}],
	1319: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1321,
		"level": 2,
		"name": "鲤城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1322,
		"level": 2,
		"name": "丰泽区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1323,
		"level": 2,
		"name": "洛江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1324,
		"level": 2,
		"name": "泉港区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1325,
		"level": 2,
		"name": "惠安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1326,
		"level": 2,
		"name": "安溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1327,
		"level": 2,
		"name": "永春县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1328,
		"level": 2,
		"name": "德化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1329,
		"level": 2,
		"name": "金门县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1330,
		"level": 2,
		"name": "石狮市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1331,
		"level": 2,
		"name": "晋江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1319,
		"id": 1332,
		"level": 2,
		"name": "南安市"
	}],
	1333: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1335,
		"level": 2,
		"name": "芗城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1336,
		"level": 2,
		"name": "龙文区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1337,
		"level": 2,
		"name": "云霄县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1338,
		"level": 2,
		"name": "漳浦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1339,
		"level": 2,
		"name": "诏安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1340,
		"level": 2,
		"name": "长泰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1341,
		"level": 2,
		"name": "东山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1342,
		"level": 2,
		"name": "南靖县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1343,
		"level": 2,
		"name": "平和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1344,
		"level": 2,
		"name": "华安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1333,
		"id": 1345,
		"level": 2,
		"name": "龙海市"
	}],
	1346: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1348,
		"level": 2,
		"name": "延平区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1349,
		"level": 2,
		"name": "顺昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1350,
		"level": 2,
		"name": "浦城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1351,
		"level": 2,
		"name": "光泽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1352,
		"level": 2,
		"name": "松溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1353,
		"level": 2,
		"name": "政和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1354,
		"level": 2,
		"name": "邵武市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1355,
		"level": 2,
		"name": "武夷山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1356,
		"level": 2,
		"name": "建瓯市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1346,
		"id": 1357,
		"level": 2,
		"name": "建阳市"
	}],
	1358: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1358,
		"id": 1360,
		"level": 2,
		"name": "新罗区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1358,
		"id": 1361,
		"level": 2,
		"name": "长汀县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1358,
		"id": 1362,
		"level": 2,
		"name": "永定县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1358,
		"id": 1363,
		"level": 2,
		"name": "上杭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1358,
		"id": 1364,
		"level": 2,
		"name": "武平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1358,
		"id": 1365,
		"level": 2,
		"name": "连城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1358,
		"id": 1366,
		"level": 2,
		"name": "漳平市"
	}],
	1367: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1367,
		"id": 1369,
		"level": 2,
		"name": "蕉城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1367,
		"id": 1370,
		"level": 2,
		"name": "霞浦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1367,
		"id": 1371,
		"level": 2,
		"name": "古田县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1367,
		"id": 1372,
		"level": 2,
		"name": "屏南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1367,
		"id": 1373,
		"level": 2,
		"name": "寿宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1367,
		"id": 1374,
		"level": 2,
		"name": "周宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1367,
		"id": 1375,
		"level": 2,
		"name": "柘荣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1367,
		"id": 1376,
		"level": 2,
		"name": "福安市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1367,
		"id": 1377,
		"level": 2,
		"name": "福鼎市"
	}],
	1379: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1379,
		"id": 1381,
		"level": 2,
		"name": "东湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1379,
		"id": 1382,
		"level": 2,
		"name": "西湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1379,
		"id": 1383,
		"level": 2,
		"name": "青云谱区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1379,
		"id": 1384,
		"level": 2,
		"name": "湾里区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1379,
		"id": 1385,
		"level": 2,
		"name": "青山湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1379,
		"id": 1386,
		"level": 2,
		"name": "南昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1379,
		"id": 1387,
		"level": 2,
		"name": "新建县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1379,
		"id": 1388,
		"level": 2,
		"name": "安义县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1379,
		"id": 1389,
		"level": 2,
		"name": "进贤县"
	}],
	1390: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1390,
		"id": 1392,
		"level": 2,
		"name": "昌江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1390,
		"id": 1393,
		"level": 2,
		"name": "珠山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1390,
		"id": 1394,
		"level": 2,
		"name": "浮梁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1390,
		"id": 1395,
		"level": 2,
		"name": "乐平市"
	}],
	1396: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1396,
		"id": 1398,
		"level": 2,
		"name": "安源区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1396,
		"id": 1399,
		"level": 2,
		"name": "湘东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1396,
		"id": 1400,
		"level": 2,
		"name": "莲花县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1396,
		"id": 1401,
		"level": 2,
		"name": "上栗县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1396,
		"id": 1402,
		"level": 2,
		"name": "芦溪县"
	}],
	1403: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1405,
		"level": 2,
		"name": "庐山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1406,
		"level": 2,
		"name": "浔阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1407,
		"level": 2,
		"name": "九江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1408,
		"level": 2,
		"name": "武宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1409,
		"level": 2,
		"name": "修水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1410,
		"level": 2,
		"name": "永修县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1411,
		"level": 2,
		"name": "德安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1412,
		"level": 2,
		"name": "星子县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1413,
		"level": 2,
		"name": "都昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1414,
		"level": 2,
		"name": "湖口县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1415,
		"level": 2,
		"name": "彭泽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1416,
		"level": 2,
		"name": "瑞昌市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1403,
		"id": 1417,
		"level": 2,
		"name": "共青城市"
	}],
	1418: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1418,
		"id": 1420,
		"level": 2,
		"name": "渝水区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1418,
		"id": 1421,
		"level": 2,
		"name": "分宜县"
	}],
	1422: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1422,
		"id": 1424,
		"level": 2,
		"name": "月湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1422,
		"id": 1425,
		"level": 2,
		"name": "余江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1422,
		"id": 1426,
		"level": 2,
		"name": "贵溪市"
	}],
	1427: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1429,
		"level": 2,
		"name": "章贡区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1430,
		"level": 2,
		"name": "赣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1431,
		"level": 2,
		"name": "信丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1432,
		"level": 2,
		"name": "大余县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1433,
		"level": 2,
		"name": "上犹县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1434,
		"level": 2,
		"name": "崇义县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1435,
		"level": 2,
		"name": "安远县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1436,
		"level": 2,
		"name": "龙南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1437,
		"level": 2,
		"name": "定南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1438,
		"level": 2,
		"name": "全南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1439,
		"level": 2,
		"name": "宁都县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1440,
		"level": 2,
		"name": "于都县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1441,
		"level": 2,
		"name": "兴国县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1442,
		"level": 2,
		"name": "会昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1443,
		"level": 2,
		"name": "寻乌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1444,
		"level": 2,
		"name": "石城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1445,
		"level": 2,
		"name": "瑞金市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1427,
		"id": 1446,
		"level": 2,
		"name": "南康市"
	}],
	1447: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1449,
		"level": 2,
		"name": "吉州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1450,
		"level": 2,
		"name": "青原区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1451,
		"level": 2,
		"name": "吉安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1452,
		"level": 2,
		"name": "吉水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1453,
		"level": 2,
		"name": "峡江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1454,
		"level": 2,
		"name": "新干县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1455,
		"level": 2,
		"name": "永丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1456,
		"level": 2,
		"name": "泰和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1457,
		"level": 2,
		"name": "遂川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1458,
		"level": 2,
		"name": "万安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1459,
		"level": 2,
		"name": "安福县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1460,
		"level": 2,
		"name": "永新县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1447,
		"id": 1461,
		"level": 2,
		"name": "井冈山市"
	}],
	1462: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1464,
		"level": 2,
		"name": "袁州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1465,
		"level": 2,
		"name": "奉新县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1466,
		"level": 2,
		"name": "万载县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1467,
		"level": 2,
		"name": "上高县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1468,
		"level": 2,
		"name": "宜丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1469,
		"level": 2,
		"name": "靖安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1470,
		"level": 2,
		"name": "铜鼓县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1471,
		"level": 2,
		"name": "丰城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1472,
		"level": 2,
		"name": "樟树市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1462,
		"id": 1473,
		"level": 2,
		"name": "高安市"
	}],
	1474: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1476,
		"level": 2,
		"name": "临川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1477,
		"level": 2,
		"name": "南城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1478,
		"level": 2,
		"name": "黎川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1479,
		"level": 2,
		"name": "南丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1480,
		"level": 2,
		"name": "崇仁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1481,
		"level": 2,
		"name": "乐安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1482,
		"level": 2,
		"name": "宜黄县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1483,
		"level": 2,
		"name": "金溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1484,
		"level": 2,
		"name": "资溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1485,
		"level": 2,
		"name": "东乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1474,
		"id": 1486,
		"level": 2,
		"name": "广昌县"
	}],
	1487: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1489,
		"level": 2,
		"name": "信州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1490,
		"level": 2,
		"name": "上饶县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1491,
		"level": 2,
		"name": "广丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1492,
		"level": 2,
		"name": "玉山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1493,
		"level": 2,
		"name": "铅山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1494,
		"level": 2,
		"name": "横峰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1495,
		"level": 2,
		"name": "弋阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1496,
		"level": 2,
		"name": "余干县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1497,
		"level": 2,
		"name": "鄱阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1498,
		"level": 2,
		"name": "万年县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1499,
		"level": 2,
		"name": "婺源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1487,
		"id": 1500,
		"level": 2,
		"name": "德兴市"
	}],
	1502: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1504,
		"level": 2,
		"name": "历下区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1505,
		"level": 2,
		"name": "市中区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1506,
		"level": 2,
		"name": "槐荫区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1507,
		"level": 2,
		"name": "天桥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1508,
		"level": 2,
		"name": "历城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1509,
		"level": 2,
		"name": "长清区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1510,
		"level": 2,
		"name": "平阴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1511,
		"level": 2,
		"name": "济阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1512,
		"level": 2,
		"name": "商河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1502,
		"id": 1513,
		"level": 2,
		"name": "章丘市"
	}],
	1514: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1516,
		"level": 2,
		"name": "市南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1517,
		"level": 2,
		"name": "市北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1518,
		"level": 2,
		"name": "四方区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1519,
		"level": 2,
		"name": "黄岛区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1520,
		"level": 2,
		"name": "崂山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1521,
		"level": 2,
		"name": "李沧区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1522,
		"level": 2,
		"name": "城阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1523,
		"level": 2,
		"name": "胶州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1524,
		"level": 2,
		"name": "即墨市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1525,
		"level": 2,
		"name": "平度市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1526,
		"level": 2,
		"name": "胶南市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1514,
		"id": 1527,
		"level": 2,
		"name": "莱西市"
	}],
	1528: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1528,
		"id": 1530,
		"level": 2,
		"name": "淄川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1528,
		"id": 1531,
		"level": 2,
		"name": "张店区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1528,
		"id": 1532,
		"level": 2,
		"name": "博山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1528,
		"id": 1533,
		"level": 2,
		"name": "临淄区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1528,
		"id": 1534,
		"level": 2,
		"name": "周村区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1528,
		"id": 1535,
		"level": 2,
		"name": "桓台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1528,
		"id": 1536,
		"level": 2,
		"name": "高青县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1528,
		"id": 1537,
		"level": 2,
		"name": "沂源县"
	}],
	1538: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1538,
		"id": 1540,
		"level": 2,
		"name": "市中区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1538,
		"id": 1541,
		"level": 2,
		"name": "薛城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1538,
		"id": 1542,
		"level": 2,
		"name": "峄城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1538,
		"id": 1543,
		"level": 2,
		"name": "台儿庄区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1538,
		"id": 1544,
		"level": 2,
		"name": "山亭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1538,
		"id": 1545,
		"level": 2,
		"name": "滕州市"
	}],
	1546: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1546,
		"id": 1548,
		"level": 2,
		"name": "东营区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1546,
		"id": 1549,
		"level": 2,
		"name": "河口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1546,
		"id": 1550,
		"level": 2,
		"name": "垦利县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1546,
		"id": 1551,
		"level": 2,
		"name": "利津县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1546,
		"id": 1552,
		"level": 2,
		"name": "广饶县"
	}],
	1553: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1555,
		"level": 2,
		"name": "芝罘区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1556,
		"level": 2,
		"name": "福山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1557,
		"level": 2,
		"name": "牟平区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1558,
		"level": 2,
		"name": "莱山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1559,
		"level": 2,
		"name": "长岛县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1560,
		"level": 2,
		"name": "龙口市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1561,
		"level": 2,
		"name": "莱阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1562,
		"level": 2,
		"name": "莱州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1563,
		"level": 2,
		"name": "蓬莱市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1564,
		"level": 2,
		"name": "招远市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1565,
		"level": 2,
		"name": "栖霞市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1553,
		"id": 1566,
		"level": 2,
		"name": "海阳市"
	}],
	1567: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1569,
		"level": 2,
		"name": "潍城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1570,
		"level": 2,
		"name": "寒亭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1571,
		"level": 2,
		"name": "坊子区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1572,
		"level": 2,
		"name": "奎文区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1573,
		"level": 2,
		"name": "临朐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1574,
		"level": 2,
		"name": "昌乐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1575,
		"level": 2,
		"name": "青州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1576,
		"level": 2,
		"name": "诸城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1577,
		"level": 2,
		"name": "寿光市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1578,
		"level": 2,
		"name": "安丘市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1579,
		"level": 2,
		"name": "高密市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1567,
		"id": 1580,
		"level": 2,
		"name": "昌邑市"
	}],
	1581: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1583,
		"level": 2,
		"name": "市中区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1584,
		"level": 2,
		"name": "任城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1585,
		"level": 2,
		"name": "微山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1586,
		"level": 2,
		"name": "鱼台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1587,
		"level": 2,
		"name": "金乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1588,
		"level": 2,
		"name": "嘉祥县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1589,
		"level": 2,
		"name": "汶上县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1590,
		"level": 2,
		"name": "泗水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1591,
		"level": 2,
		"name": "梁山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1592,
		"level": 2,
		"name": "曲阜市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1593,
		"level": 2,
		"name": "兖州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1581,
		"id": 1594,
		"level": 2,
		"name": "邹城市"
	}],
	1595: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1595,
		"id": 1597,
		"level": 2,
		"name": "泰山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1595,
		"id": 1598,
		"level": 2,
		"name": "岱岳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1595,
		"id": 1599,
		"level": 2,
		"name": "宁阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1595,
		"id": 1600,
		"level": 2,
		"name": "东平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1595,
		"id": 1601,
		"level": 2,
		"name": "新泰市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1595,
		"id": 1602,
		"level": 2,
		"name": "肥城市"
	}],
	1603: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1603,
		"id": 1605,
		"level": 2,
		"name": "环翠区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1603,
		"id": 1606,
		"level": 2,
		"name": "文登市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1603,
		"id": 1607,
		"level": 2,
		"name": "荣成市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1603,
		"id": 1608,
		"level": 2,
		"name": "乳山市"
	}],
	1609: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1609,
		"id": 1611,
		"level": 2,
		"name": "东港区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1609,
		"id": 1612,
		"level": 2,
		"name": "岚山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1609,
		"id": 1613,
		"level": 2,
		"name": "五莲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1609,
		"id": 1614,
		"level": 2,
		"name": "莒县"
	}],
	1615: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1615,
		"id": 1617,
		"level": 2,
		"name": "莱城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1615,
		"id": 1618,
		"level": 2,
		"name": "钢城区"
	}],
	1619: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1621,
		"level": 2,
		"name": "兰山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1622,
		"level": 2,
		"name": "罗庄区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1623,
		"level": 2,
		"name": "河东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1624,
		"level": 2,
		"name": "沂南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1625,
		"level": 2,
		"name": "郯城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1626,
		"level": 2,
		"name": "沂水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1627,
		"level": 2,
		"name": "苍山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1628,
		"level": 2,
		"name": "费县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1629,
		"level": 2,
		"name": "平邑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1630,
		"level": 2,
		"name": "莒南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1631,
		"level": 2,
		"name": "蒙阴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1619,
		"id": 1632,
		"level": 2,
		"name": "临沭县"
	}],
	1633: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1635,
		"level": 2,
		"name": "德城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1636,
		"level": 2,
		"name": "陵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1637,
		"level": 2,
		"name": "宁津县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1638,
		"level": 2,
		"name": "庆云县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1639,
		"level": 2,
		"name": "临邑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1640,
		"level": 2,
		"name": "齐河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1641,
		"level": 2,
		"name": "平原县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1642,
		"level": 2,
		"name": "夏津县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1643,
		"level": 2,
		"name": "武城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1644,
		"level": 2,
		"name": "乐陵市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1633,
		"id": 1645,
		"level": 2,
		"name": "禹城市"
	}],
	1646: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1646,
		"id": 1648,
		"level": 2,
		"name": "东昌府区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1646,
		"id": 1649,
		"level": 2,
		"name": "阳谷县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1646,
		"id": 1650,
		"level": 2,
		"name": "莘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1646,
		"id": 1651,
		"level": 2,
		"name": "茌平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1646,
		"id": 1652,
		"level": 2,
		"name": "东阿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1646,
		"id": 1653,
		"level": 2,
		"name": "冠县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1646,
		"id": 1654,
		"level": 2,
		"name": "高唐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1646,
		"id": 1655,
		"level": 2,
		"name": "临清市"
	}],
	1656: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1656,
		"id": 1658,
		"level": 2,
		"name": "滨城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1656,
		"id": 1659,
		"level": 2,
		"name": "惠民县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1656,
		"id": 1660,
		"level": 2,
		"name": "阳信县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1656,
		"id": 1661,
		"level": 2,
		"name": "无棣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1656,
		"id": 1662,
		"level": 2,
		"name": "沾化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1656,
		"id": 1663,
		"level": 2,
		"name": "博兴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1656,
		"id": 1664,
		"level": 2,
		"name": "邹平县"
	}],
	1665: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1665,
		"id": 1667,
		"level": 2,
		"name": "牡丹区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1665,
		"id": 1668,
		"level": 2,
		"name": "曹县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1665,
		"id": 1669,
		"level": 2,
		"name": "单县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1665,
		"id": 1670,
		"level": 2,
		"name": "成武县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1665,
		"id": 1671,
		"level": 2,
		"name": "巨野县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1665,
		"id": 1672,
		"level": 2,
		"name": "郓城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1665,
		"id": 1673,
		"level": 2,
		"name": "鄄城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1665,
		"id": 1674,
		"level": 2,
		"name": "定陶县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1665,
		"id": 1675,
		"level": 2,
		"name": "东明县"
	}],
	1677: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1679,
		"level": 2,
		"name": "中原区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1680,
		"level": 2,
		"name": "二七区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1681,
		"level": 2,
		"name": "管城回族区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1682,
		"level": 2,
		"name": "金水区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1683,
		"level": 2,
		"name": "上街区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1684,
		"level": 2,
		"name": "惠济区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1685,
		"level": 2,
		"name": "中牟县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1686,
		"level": 2,
		"name": "巩义市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1687,
		"level": 2,
		"name": "荥阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1688,
		"level": 2,
		"name": "新密市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1689,
		"level": 2,
		"name": "新郑市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1677,
		"id": 1690,
		"level": 2,
		"name": "登封市"
	}],
	1691: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1693,
		"level": 2,
		"name": "龙亭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1694,
		"level": 2,
		"name": "顺河回族区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1695,
		"level": 2,
		"name": "鼓楼区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1696,
		"level": 2,
		"name": "禹王台区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1697,
		"level": 2,
		"name": "金明区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1698,
		"level": 2,
		"name": "杞县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1699,
		"level": 2,
		"name": "通许县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1700,
		"level": 2,
		"name": "尉氏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1701,
		"level": 2,
		"name": "开封县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1691,
		"id": 1702,
		"level": 2,
		"name": "兰考县"
	}],
	1703: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1705,
		"level": 2,
		"name": "老城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1706,
		"level": 2,
		"name": "西工区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1707,
		"level": 2,
		"name": "瀍河回族区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1708,
		"level": 2,
		"name": "涧西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1709,
		"level": 2,
		"name": "吉利区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1710,
		"level": 2,
		"name": "洛龙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1711,
		"level": 2,
		"name": "孟津县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1712,
		"level": 2,
		"name": "新安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1713,
		"level": 2,
		"name": "栾川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1714,
		"level": 2,
		"name": "嵩县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1715,
		"level": 2,
		"name": "汝阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1716,
		"level": 2,
		"name": "宜阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1717,
		"level": 2,
		"name": "洛宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1718,
		"level": 2,
		"name": "伊川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1703,
		"id": 1719,
		"level": 2,
		"name": "偃师市"
	}],
	1720: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1722,
		"level": 2,
		"name": "新华区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1723,
		"level": 2,
		"name": "卫东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1724,
		"level": 2,
		"name": "石龙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1725,
		"level": 2,
		"name": "湛河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1726,
		"level": 2,
		"name": "宝丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1727,
		"level": 2,
		"name": "叶县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1728,
		"level": 2,
		"name": "鲁山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1729,
		"level": 2,
		"name": "郏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1730,
		"level": 2,
		"name": "舞钢市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1720,
		"id": 1731,
		"level": 2,
		"name": "汝州市"
	}],
	1732: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1732,
		"id": 1734,
		"level": 2,
		"name": "文峰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1732,
		"id": 1735,
		"level": 2,
		"name": "北关区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1732,
		"id": 1736,
		"level": 2,
		"name": "殷都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1732,
		"id": 1737,
		"level": 2,
		"name": "龙安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1732,
		"id": 1738,
		"level": 2,
		"name": "安阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1732,
		"id": 1739,
		"level": 2,
		"name": "汤阴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1732,
		"id": 1740,
		"level": 2,
		"name": "滑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1732,
		"id": 1741,
		"level": 2,
		"name": "内黄县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1732,
		"id": 1742,
		"level": 2,
		"name": "林州市"
	}],
	1743: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1743,
		"id": 1745,
		"level": 2,
		"name": "鹤山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1743,
		"id": 1746,
		"level": 2,
		"name": "山城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1743,
		"id": 1747,
		"level": 2,
		"name": "淇滨区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1743,
		"id": 1748,
		"level": 2,
		"name": "浚县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1743,
		"id": 1749,
		"level": 2,
		"name": "淇县"
	}],
	1750: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1752,
		"level": 2,
		"name": "红旗区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1753,
		"level": 2,
		"name": "卫滨区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1754,
		"level": 2,
		"name": "凤泉区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1755,
		"level": 2,
		"name": "牧野区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1756,
		"level": 2,
		"name": "新乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1757,
		"level": 2,
		"name": "获嘉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1758,
		"level": 2,
		"name": "原阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1759,
		"level": 2,
		"name": "延津县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1760,
		"level": 2,
		"name": "封丘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1761,
		"level": 2,
		"name": "长垣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1762,
		"level": 2,
		"name": "卫辉市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1750,
		"id": 1763,
		"level": 2,
		"name": "辉县市"
	}],
	1764: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1766,
		"level": 2,
		"name": "解放区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1767,
		"level": 2,
		"name": "中站区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1768,
		"level": 2,
		"name": "马村区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1769,
		"level": 2,
		"name": "山阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1770,
		"level": 2,
		"name": "修武县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1771,
		"level": 2,
		"name": "博爱县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1772,
		"level": 2,
		"name": "武陟县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1773,
		"level": 2,
		"name": "温县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1774,
		"level": 2,
		"name": "沁阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1764,
		"id": 1775,
		"level": 2,
		"name": "孟州市"
	}],
	1776: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1776,
		"id": 1778,
		"level": 2,
		"name": "华龙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1776,
		"id": 1779,
		"level": 2,
		"name": "清丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1776,
		"id": 1780,
		"level": 2,
		"name": "南乐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1776,
		"id": 1781,
		"level": 2,
		"name": "范县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1776,
		"id": 1782,
		"level": 2,
		"name": "台前县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1776,
		"id": 1783,
		"level": 2,
		"name": "濮阳县"
	}],
	1784: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1784,
		"id": 1786,
		"level": 2,
		"name": "魏都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1784,
		"id": 1787,
		"level": 2,
		"name": "许昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1784,
		"id": 1788,
		"level": 2,
		"name": "鄢陵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1784,
		"id": 1789,
		"level": 2,
		"name": "襄城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1784,
		"id": 1790,
		"level": 2,
		"name": "禹州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1784,
		"id": 1791,
		"level": 2,
		"name": "长葛市"
	}],
	1792: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1792,
		"id": 1794,
		"level": 2,
		"name": "源汇区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1792,
		"id": 1795,
		"level": 2,
		"name": "郾城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1792,
		"id": 1796,
		"level": 2,
		"name": "召陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1792,
		"id": 1797,
		"level": 2,
		"name": "舞阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1792,
		"id": 1798,
		"level": 2,
		"name": "临颍县"
	}],
	1799: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1799,
		"id": 1801,
		"level": 2,
		"name": "湖滨区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1799,
		"id": 1802,
		"level": 2,
		"name": "渑池县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1799,
		"id": 1803,
		"level": 2,
		"name": "陕县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1799,
		"id": 1804,
		"level": 2,
		"name": "卢氏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1799,
		"id": 1805,
		"level": 2,
		"name": "义马市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1799,
		"id": 1806,
		"level": 2,
		"name": "灵宝市"
	}],
	1807: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1809,
		"level": 2,
		"name": "宛城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1810,
		"level": 2,
		"name": "卧龙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1811,
		"level": 2,
		"name": "南召县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1812,
		"level": 2,
		"name": "方城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1813,
		"level": 2,
		"name": "西峡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1814,
		"level": 2,
		"name": "镇平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1815,
		"level": 2,
		"name": "内乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1816,
		"level": 2,
		"name": "淅川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1817,
		"level": 2,
		"name": "社旗县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1818,
		"level": 2,
		"name": "唐河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1819,
		"level": 2,
		"name": "新野县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1820,
		"level": 2,
		"name": "桐柏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1807,
		"id": 1821,
		"level": 2,
		"name": "邓州市"
	}],
	1822: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1822,
		"id": 1824,
		"level": 2,
		"name": "梁园区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1822,
		"id": 1825,
		"level": 2,
		"name": "睢阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1822,
		"id": 1826,
		"level": 2,
		"name": "民权县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1822,
		"id": 1827,
		"level": 2,
		"name": "睢县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1822,
		"id": 1828,
		"level": 2,
		"name": "宁陵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1822,
		"id": 1829,
		"level": 2,
		"name": "柘城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1822,
		"id": 1830,
		"level": 2,
		"name": "虞城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1822,
		"id": 1831,
		"level": 2,
		"name": "夏邑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1822,
		"id": 1832,
		"level": 2,
		"name": "永城市"
	}],
	1833: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1835,
		"level": 2,
		"name": "浉河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1836,
		"level": 2,
		"name": "平桥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1837,
		"level": 2,
		"name": "罗山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1838,
		"level": 2,
		"name": "光山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1839,
		"level": 2,
		"name": "新县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1840,
		"level": 2,
		"name": "商城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1841,
		"level": 2,
		"name": "固始县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1842,
		"level": 2,
		"name": "潢川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1843,
		"level": 2,
		"name": "淮滨县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1833,
		"id": 1844,
		"level": 2,
		"name": "息县"
	}],
	1845: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1847,
		"level": 2,
		"name": "川汇区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1848,
		"level": 2,
		"name": "扶沟县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1849,
		"level": 2,
		"name": "西华县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1850,
		"level": 2,
		"name": "商水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1851,
		"level": 2,
		"name": "沈丘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1852,
		"level": 2,
		"name": "郸城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1853,
		"level": 2,
		"name": "淮阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1854,
		"level": 2,
		"name": "太康县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1855,
		"level": 2,
		"name": "鹿邑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1845,
		"id": 1856,
		"level": 2,
		"name": "项城市"
	}],
	1857: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1859,
		"level": 2,
		"name": "驿城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1860,
		"level": 2,
		"name": "西平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1861,
		"level": 2,
		"name": "上蔡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1862,
		"level": 2,
		"name": "平舆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1863,
		"level": 2,
		"name": "正阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1864,
		"level": 2,
		"name": "确山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1865,
		"level": 2,
		"name": "泌阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1866,
		"level": 2,
		"name": "汝南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1867,
		"level": 2,
		"name": "遂平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1857,
		"id": 1868,
		"level": 2,
		"name": "新蔡县"
	}],
	1869: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1869,
		"id": 1870,
		"level": 2,
		"name": "济源市"
	}],
	1872: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1874,
		"level": 2,
		"name": "江岸区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1875,
		"level": 2,
		"name": "江汉区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1876,
		"level": 2,
		"name": "硚口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1877,
		"level": 2,
		"name": "汉阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1878,
		"level": 2,
		"name": "武昌区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1879,
		"level": 2,
		"name": "青山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1880,
		"level": 2,
		"name": "洪山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1881,
		"level": 2,
		"name": "东西湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1882,
		"level": 2,
		"name": "汉南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1883,
		"level": 2,
		"name": "蔡甸区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1884,
		"level": 2,
		"name": "江夏区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1885,
		"level": 2,
		"name": "黄陂区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1872,
		"id": 1886,
		"level": 2,
		"name": "新洲区"
	}],
	1887: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1887,
		"id": 1889,
		"level": 2,
		"name": "黄石港区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1887,
		"id": 1890,
		"level": 2,
		"name": "西塞山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1887,
		"id": 1891,
		"level": 2,
		"name": "下陆区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1887,
		"id": 1892,
		"level": 2,
		"name": "铁山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1887,
		"id": 1893,
		"level": 2,
		"name": "阳新县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1887,
		"id": 1894,
		"level": 2,
		"name": "大冶市"
	}],
	1895: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1895,
		"id": 1897,
		"level": 2,
		"name": "茅箭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1895,
		"id": 1898,
		"level": 2,
		"name": "张湾区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1895,
		"id": 1899,
		"level": 2,
		"name": "郧县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1895,
		"id": 1900,
		"level": 2,
		"name": "郧西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1895,
		"id": 1901,
		"level": 2,
		"name": "竹山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1895,
		"id": 1902,
		"level": 2,
		"name": "竹溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1895,
		"id": 1903,
		"level": 2,
		"name": "房县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1895,
		"id": 1904,
		"level": 2,
		"name": "丹江口市"
	}],
	1905: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1907,
		"level": 2,
		"name": "西陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1908,
		"level": 2,
		"name": "伍家岗区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1909,
		"level": 2,
		"name": "点军区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1910,
		"level": 2,
		"name": "猇亭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1911,
		"level": 2,
		"name": "夷陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1912,
		"level": 2,
		"name": "远安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1913,
		"level": 2,
		"name": "兴山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1914,
		"level": 2,
		"name": "秭归县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1915,
		"level": 2,
		"name": "长阳土家族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1916,
		"level": 2,
		"name": "五峰土家族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1917,
		"level": 2,
		"name": "宜都市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1918,
		"level": 2,
		"name": "当阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1905,
		"id": 1919,
		"level": 2,
		"name": "枝江市"
	}],
	1920: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 1922,
		"level": 2,
		"name": "襄城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 1923,
		"level": 2,
		"name": "樊城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 1924,
		"level": 2,
		"name": "襄州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 1925,
		"level": 2,
		"name": "南漳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 1926,
		"level": 2,
		"name": "谷城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 1927,
		"level": 2,
		"name": "保康县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 1928,
		"level": 2,
		"name": "老河口市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 1929,
		"level": 2,
		"name": "枣阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 1930,
		"level": 2,
		"name": "宜城市"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1920,
		"id": 3725,
		"level": 2,
		"name": "襄阳区"
	}],
	1931: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1931,
		"id": 1933,
		"level": 2,
		"name": "梁子湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1931,
		"id": 1934,
		"level": 2,
		"name": "华容区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1931,
		"id": 1935,
		"level": 2,
		"name": "鄂城区"
	}],
	1936: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1936,
		"id": 1938,
		"level": 2,
		"name": "东宝区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1936,
		"id": 1939,
		"level": 2,
		"name": "掇刀区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1936,
		"id": 1940,
		"level": 2,
		"name": "京山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1936,
		"id": 1941,
		"level": 2,
		"name": "沙洋县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1936,
		"id": 1942,
		"level": 2,
		"name": "钟祥市"
	}],
	1943: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1943,
		"id": 1945,
		"level": 2,
		"name": "孝南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1943,
		"id": 1946,
		"level": 2,
		"name": "孝昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1943,
		"id": 1947,
		"level": 2,
		"name": "大悟县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1943,
		"id": 1948,
		"level": 2,
		"name": "云梦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1943,
		"id": 1949,
		"level": 2,
		"name": "应城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1943,
		"id": 1950,
		"level": 2,
		"name": "安陆市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1943,
		"id": 1951,
		"level": 2,
		"name": "汉川市"
	}],
	1952: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1952,
		"id": 1954,
		"level": 2,
		"name": "沙市区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1952,
		"id": 1955,
		"level": 2,
		"name": "荆州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1952,
		"id": 1956,
		"level": 2,
		"name": "公安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1952,
		"id": 1957,
		"level": 2,
		"name": "监利县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1952,
		"id": 1958,
		"level": 2,
		"name": "江陵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1952,
		"id": 1959,
		"level": 2,
		"name": "石首市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1952,
		"id": 1960,
		"level": 2,
		"name": "洪湖市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1952,
		"id": 1961,
		"level": 2,
		"name": "松滋市"
	}],
	1962: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1964,
		"level": 2,
		"name": "黄州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1965,
		"level": 2,
		"name": "团风县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1966,
		"level": 2,
		"name": "红安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1967,
		"level": 2,
		"name": "罗田县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1968,
		"level": 2,
		"name": "英山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1969,
		"level": 2,
		"name": "浠水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1970,
		"level": 2,
		"name": "蕲春县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1971,
		"level": 2,
		"name": "黄梅县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1972,
		"level": 2,
		"name": "麻城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1962,
		"id": 1973,
		"level": 2,
		"name": "武穴市"
	}],
	1974: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1974,
		"id": 1976,
		"level": 2,
		"name": "咸安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1974,
		"id": 1977,
		"level": 2,
		"name": "嘉鱼县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1974,
		"id": 1978,
		"level": 2,
		"name": "通城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1974,
		"id": 1979,
		"level": 2,
		"name": "崇阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1974,
		"id": 1980,
		"level": 2,
		"name": "通山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1974,
		"id": 1981,
		"level": 2,
		"name": "赤壁市"
	}],
	1982: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1982,
		"id": 1984,
		"level": 2,
		"name": "曾都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1982,
		"id": 1985,
		"level": 2,
		"name": "随县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1982,
		"id": 1986,
		"level": 2,
		"name": "广水市"
	}],
	1987: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1987,
		"id": 1988,
		"level": 2,
		"name": "恩施市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1987,
		"id": 1989,
		"level": 2,
		"name": "利川市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1987,
		"id": 1990,
		"level": 2,
		"name": "建始县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1987,
		"id": 1991,
		"level": 2,
		"name": "巴东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1987,
		"id": 1992,
		"level": 2,
		"name": "宣恩县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1987,
		"id": 1993,
		"level": 2,
		"name": "咸丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1987,
		"id": 1994,
		"level": 2,
		"name": "来凤县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1987,
		"id": 1995,
		"level": 2,
		"name": "鹤峰县"
	}],
	1996: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1996,
		"id": 1997,
		"level": 2,
		"name": "仙桃市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1996,
		"id": 1998,
		"level": 2,
		"name": "潜江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1996,
		"id": 1999,
		"level": 2,
		"name": "天门市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 1996,
		"id": 2000,
		"level": 2,
		"name": "神农架林区"
	}],
	2002: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2002,
		"id": 2004,
		"level": 2,
		"name": "芙蓉区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2002,
		"id": 2005,
		"level": 2,
		"name": "天心区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2002,
		"id": 2006,
		"level": 2,
		"name": "岳麓区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2002,
		"id": 2007,
		"level": 2,
		"name": "开福区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2002,
		"id": 2008,
		"level": 2,
		"name": "雨花区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2002,
		"id": 2009,
		"level": 2,
		"name": "望城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2002,
		"id": 2010,
		"level": 2,
		"name": "长沙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2002,
		"id": 2011,
		"level": 2,
		"name": "宁乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2002,
		"id": 2012,
		"level": 2,
		"name": "浏阳市"
	}],
	2013: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2013,
		"id": 2015,
		"level": 2,
		"name": "荷塘区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2013,
		"id": 2016,
		"level": 2,
		"name": "芦淞区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2013,
		"id": 2017,
		"level": 2,
		"name": "石峰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2013,
		"id": 2018,
		"level": 2,
		"name": "天元区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2013,
		"id": 2019,
		"level": 2,
		"name": "株洲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2013,
		"id": 2020,
		"level": 2,
		"name": "攸县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2013,
		"id": 2021,
		"level": 2,
		"name": "茶陵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2013,
		"id": 2022,
		"level": 2,
		"name": "炎陵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2013,
		"id": 2023,
		"level": 2,
		"name": "醴陵市"
	}],
	2024: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2024,
		"id": 2026,
		"level": 2,
		"name": "雨湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2024,
		"id": 2027,
		"level": 2,
		"name": "岳塘区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2024,
		"id": 2028,
		"level": 2,
		"name": "湘潭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2024,
		"id": 2029,
		"level": 2,
		"name": "湘乡市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2024,
		"id": 2030,
		"level": 2,
		"name": "韶山市"
	}],
	2031: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2033,
		"level": 2,
		"name": "珠晖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2034,
		"level": 2,
		"name": "雁峰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2035,
		"level": 2,
		"name": "石鼓区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2036,
		"level": 2,
		"name": "蒸湘区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2037,
		"level": 2,
		"name": "南岳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2038,
		"level": 2,
		"name": "衡阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2039,
		"level": 2,
		"name": "衡南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2040,
		"level": 2,
		"name": "衡山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2041,
		"level": 2,
		"name": "衡东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2042,
		"level": 2,
		"name": "祁东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2043,
		"level": 2,
		"name": "耒阳市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2031,
		"id": 2044,
		"level": 2,
		"name": "常宁市"
	}],
	2045: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2047,
		"level": 2,
		"name": "双清区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2048,
		"level": 2,
		"name": "大祥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2049,
		"level": 2,
		"name": "北塔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2050,
		"level": 2,
		"name": "邵东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2051,
		"level": 2,
		"name": "新邵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2052,
		"level": 2,
		"name": "邵阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2053,
		"level": 2,
		"name": "隆回县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2054,
		"level": 2,
		"name": "洞口县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2055,
		"level": 2,
		"name": "绥宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2056,
		"level": 2,
		"name": "新宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2057,
		"level": 2,
		"name": "城步苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2045,
		"id": 2058,
		"level": 2,
		"name": "武冈市"
	}],
	2059: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2059,
		"id": 2061,
		"level": 2,
		"name": "岳阳楼区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2059,
		"id": 2062,
		"level": 2,
		"name": "云溪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2059,
		"id": 2063,
		"level": 2,
		"name": "君山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2059,
		"id": 2064,
		"level": 2,
		"name": "岳阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2059,
		"id": 2065,
		"level": 2,
		"name": "华容县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2059,
		"id": 2066,
		"level": 2,
		"name": "湘阴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2059,
		"id": 2067,
		"level": 2,
		"name": "平江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2059,
		"id": 2068,
		"level": 2,
		"name": "汨罗市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2059,
		"id": 2069,
		"level": 2,
		"name": "临湘市"
	}],
	2070: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2070,
		"id": 2072,
		"level": 2,
		"name": "武陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2070,
		"id": 2073,
		"level": 2,
		"name": "鼎城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2070,
		"id": 2074,
		"level": 2,
		"name": "安乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2070,
		"id": 2075,
		"level": 2,
		"name": "汉寿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2070,
		"id": 2076,
		"level": 2,
		"name": "澧县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2070,
		"id": 2077,
		"level": 2,
		"name": "临澧县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2070,
		"id": 2078,
		"level": 2,
		"name": "桃源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2070,
		"id": 2079,
		"level": 2,
		"name": "石门县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2070,
		"id": 2080,
		"level": 2,
		"name": "津市市"
	}],
	2081: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2081,
		"id": 2083,
		"level": 2,
		"name": "永定区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2081,
		"id": 2084,
		"level": 2,
		"name": "武陵源区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2081,
		"id": 2085,
		"level": 2,
		"name": "慈利县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2081,
		"id": 2086,
		"level": 2,
		"name": "桑植县"
	}],
	2087: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2087,
		"id": 2089,
		"level": 2,
		"name": "资阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2087,
		"id": 2090,
		"level": 2,
		"name": "赫山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2087,
		"id": 2091,
		"level": 2,
		"name": "南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2087,
		"id": 2092,
		"level": 2,
		"name": "桃江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2087,
		"id": 2093,
		"level": 2,
		"name": "安化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2087,
		"id": 2094,
		"level": 2,
		"name": "沅江市"
	}],
	2095: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2097,
		"level": 2,
		"name": "北湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2098,
		"level": 2,
		"name": "苏仙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2099,
		"level": 2,
		"name": "桂阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2100,
		"level": 2,
		"name": "宜章县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2101,
		"level": 2,
		"name": "永兴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2102,
		"level": 2,
		"name": "嘉禾县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2103,
		"level": 2,
		"name": "临武县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2104,
		"level": 2,
		"name": "汝城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2105,
		"level": 2,
		"name": "桂东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2106,
		"level": 2,
		"name": "安仁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2095,
		"id": 2107,
		"level": 2,
		"name": "资兴市"
	}],
	2108: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2110,
		"level": 2,
		"name": "零陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2111,
		"level": 2,
		"name": "冷水滩区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2112,
		"level": 2,
		"name": "祁阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2113,
		"level": 2,
		"name": "东安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2114,
		"level": 2,
		"name": "双牌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2115,
		"level": 2,
		"name": "道县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2116,
		"level": 2,
		"name": "江永县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2117,
		"level": 2,
		"name": "宁远县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2118,
		"level": 2,
		"name": "蓝山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2119,
		"level": 2,
		"name": "新田县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2108,
		"id": 2120,
		"level": 2,
		"name": "江华瑶族自治县"
	}],
	2121: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2123,
		"level": 2,
		"name": "鹤城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2124,
		"level": 2,
		"name": "中方县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2125,
		"level": 2,
		"name": "沅陵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2126,
		"level": 2,
		"name": "辰溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2127,
		"level": 2,
		"name": "溆浦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2128,
		"level": 2,
		"name": "会同县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2129,
		"level": 2,
		"name": "麻阳苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2130,
		"level": 2,
		"name": "新晃侗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2131,
		"level": 2,
		"name": "芷江侗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2132,
		"level": 2,
		"name": "靖州苗族侗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2133,
		"level": 2,
		"name": "通道侗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2121,
		"id": 2134,
		"level": 2,
		"name": "洪江市"
	}],
	2135: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2135,
		"id": 2137,
		"level": 2,
		"name": "娄星区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2135,
		"id": 2138,
		"level": 2,
		"name": "双峰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2135,
		"id": 2139,
		"level": 2,
		"name": "新化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2135,
		"id": 2140,
		"level": 2,
		"name": "冷水江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2135,
		"id": 2141,
		"level": 2,
		"name": "涟源市"
	}],
	2142: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2142,
		"id": 2143,
		"level": 2,
		"name": "吉首市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2142,
		"id": 2144,
		"level": 2,
		"name": "泸溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2142,
		"id": 2145,
		"level": 2,
		"name": "凤凰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2142,
		"id": 2146,
		"level": 2,
		"name": "花垣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2142,
		"id": 2147,
		"level": 2,
		"name": "保靖县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2142,
		"id": 2148,
		"level": 2,
		"name": "古丈县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2142,
		"id": 2149,
		"level": 2,
		"name": "永顺县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2142,
		"id": 2150,
		"level": 2,
		"name": "龙山县"
	}],
	2152: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2154,
		"level": 2,
		"name": "荔湾区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2155,
		"level": 2,
		"name": "越秀区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2156,
		"level": 2,
		"name": "海珠区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2157,
		"level": 2,
		"name": "天河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2158,
		"level": 2,
		"name": "白云区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2159,
		"level": 2,
		"name": "黄埔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2160,
		"level": 2,
		"name": "番禺区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2161,
		"level": 2,
		"name": "花都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2162,
		"level": 2,
		"name": "南沙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2163,
		"level": 2,
		"name": "萝岗区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2164,
		"level": 2,
		"name": "增城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2152,
		"id": 2165,
		"level": 2,
		"name": "从化市"
	}],
	2166: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2168,
		"level": 2,
		"name": "武江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2169,
		"level": 2,
		"name": "浈江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2170,
		"level": 2,
		"name": "曲江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2171,
		"level": 2,
		"name": "始兴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2172,
		"level": 2,
		"name": "仁化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2173,
		"level": 2,
		"name": "翁源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2174,
		"level": 2,
		"name": "乳源瑶族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2175,
		"level": 2,
		"name": "新丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2176,
		"level": 2,
		"name": "乐昌市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2166,
		"id": 2177,
		"level": 2,
		"name": "南雄市"
	}],
	2178: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2178,
		"id": 2180,
		"level": 2,
		"name": "罗湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2178,
		"id": 2181,
		"level": 2,
		"name": "福田区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2178,
		"id": 2182,
		"level": 2,
		"name": "南山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2178,
		"id": 2183,
		"level": 2,
		"name": "宝安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2178,
		"id": 2184,
		"level": 2,
		"name": "龙岗区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2178,
		"id": 2185,
		"level": 2,
		"name": "盐田区"
	}],
	2186: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2186,
		"id": 2188,
		"level": 2,
		"name": "香洲区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2186,
		"id": 2189,
		"level": 2,
		"name": "斗门区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2186,
		"id": 2190,
		"level": 2,
		"name": "金湾区"
	}],
	2191: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2191,
		"id": 2193,
		"level": 2,
		"name": "龙湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2191,
		"id": 2194,
		"level": 2,
		"name": "金平区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2191,
		"id": 2195,
		"level": 2,
		"name": "濠江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2191,
		"id": 2196,
		"level": 2,
		"name": "潮阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2191,
		"id": 2197,
		"level": 2,
		"name": "潮南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2191,
		"id": 2198,
		"level": 2,
		"name": "澄海区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2191,
		"id": 2199,
		"level": 2,
		"name": "南澳县"
	}],
	2200: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2200,
		"id": 2202,
		"level": 2,
		"name": "禅城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2200,
		"id": 2203,
		"level": 2,
		"name": "南海区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2200,
		"id": 2204,
		"level": 2,
		"name": "顺德区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2200,
		"id": 2205,
		"level": 2,
		"name": "三水区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2200,
		"id": 2206,
		"level": 2,
		"name": "高明区"
	}],
	2207: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2207,
		"id": 2209,
		"level": 2,
		"name": "蓬江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2207,
		"id": 2210,
		"level": 2,
		"name": "江海区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2207,
		"id": 2211,
		"level": 2,
		"name": "新会区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2207,
		"id": 2212,
		"level": 2,
		"name": "台山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2207,
		"id": 2213,
		"level": 2,
		"name": "开平市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2207,
		"id": 2214,
		"level": 2,
		"name": "鹤山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2207,
		"id": 2215,
		"level": 2,
		"name": "恩平市"
	}],
	2216: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2216,
		"id": 2218,
		"level": 2,
		"name": "赤坎区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2216,
		"id": 2219,
		"level": 2,
		"name": "霞山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2216,
		"id": 2220,
		"level": 2,
		"name": "坡头区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2216,
		"id": 2221,
		"level": 2,
		"name": "麻章区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2216,
		"id": 2222,
		"level": 2,
		"name": "遂溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2216,
		"id": 2223,
		"level": 2,
		"name": "徐闻县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2216,
		"id": 2224,
		"level": 2,
		"name": "廉江市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2216,
		"id": 2225,
		"level": 2,
		"name": "雷州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2216,
		"id": 2226,
		"level": 2,
		"name": "吴川市"
	}],
	2227: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2227,
		"id": 2229,
		"level": 2,
		"name": "茂南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2227,
		"id": 2230,
		"level": 2,
		"name": "茂港区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2227,
		"id": 2231,
		"level": 2,
		"name": "电白县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2227,
		"id": 2232,
		"level": 2,
		"name": "高州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2227,
		"id": 2233,
		"level": 2,
		"name": "化州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2227,
		"id": 2234,
		"level": 2,
		"name": "信宜市"
	}],
	2235: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2235,
		"id": 2237,
		"level": 2,
		"name": "端州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2235,
		"id": 2238,
		"level": 2,
		"name": "鼎湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2235,
		"id": 2239,
		"level": 2,
		"name": "广宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2235,
		"id": 2240,
		"level": 2,
		"name": "怀集县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2235,
		"id": 2241,
		"level": 2,
		"name": "封开县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2235,
		"id": 2242,
		"level": 2,
		"name": "德庆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2235,
		"id": 2243,
		"level": 2,
		"name": "高要市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2235,
		"id": 2244,
		"level": 2,
		"name": "四会市"
	}],
	2245: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2245,
		"id": 2247,
		"level": 2,
		"name": "惠城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2245,
		"id": 2248,
		"level": 2,
		"name": "惠阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2245,
		"id": 2249,
		"level": 2,
		"name": "博罗县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2245,
		"id": 2250,
		"level": 2,
		"name": "惠东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2245,
		"id": 2251,
		"level": 2,
		"name": "龙门县"
	}],
	2252: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2252,
		"id": 2254,
		"level": 2,
		"name": "梅江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2252,
		"id": 2255,
		"level": 2,
		"name": "梅县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2252,
		"id": 2256,
		"level": 2,
		"name": "大埔县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2252,
		"id": 2257,
		"level": 2,
		"name": "丰顺县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2252,
		"id": 2258,
		"level": 2,
		"name": "五华县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2252,
		"id": 2259,
		"level": 2,
		"name": "平远县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2252,
		"id": 2260,
		"level": 2,
		"name": "蕉岭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2252,
		"id": 2261,
		"level": 2,
		"name": "兴宁市"
	}],
	2262: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2262,
		"id": 2264,
		"level": 2,
		"name": "城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2262,
		"id": 2265,
		"level": 2,
		"name": "海丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2262,
		"id": 2266,
		"level": 2,
		"name": "陆河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2262,
		"id": 2267,
		"level": 2,
		"name": "陆丰市"
	}],
	2268: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2268,
		"id": 2270,
		"level": 2,
		"name": "源城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2268,
		"id": 2271,
		"level": 2,
		"name": "紫金县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2268,
		"id": 2272,
		"level": 2,
		"name": "龙川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2268,
		"id": 2273,
		"level": 2,
		"name": "连平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2268,
		"id": 2274,
		"level": 2,
		"name": "和平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2268,
		"id": 2275,
		"level": 2,
		"name": "东源县"
	}],
	2276: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2276,
		"id": 2278,
		"level": 2,
		"name": "江城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2276,
		"id": 2279,
		"level": 2,
		"name": "阳西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2276,
		"id": 2280,
		"level": 2,
		"name": "阳东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2276,
		"id": 2281,
		"level": 2,
		"name": "阳春市"
	}],
	2282: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2282,
		"id": 2284,
		"level": 2,
		"name": "清城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2282,
		"id": 2285,
		"level": 2,
		"name": "佛冈县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2282,
		"id": 2286,
		"level": 2,
		"name": "阳山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2282,
		"id": 2287,
		"level": 2,
		"name": "连山壮族瑶族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2282,
		"id": 2288,
		"level": 2,
		"name": "连南瑶族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2282,
		"id": 2289,
		"level": 2,
		"name": "清新县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2282,
		"id": 2290,
		"level": 2,
		"name": "英德市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2282,
		"id": 2291,
		"level": 2,
		"name": "连州市"
	}],
	2292: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2292,
		"id": 3728,
		"level": 2,
		"name": "南城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2292,
		"id": 3729,
		"level": 2,
		"name": "莞城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2292,
		"id": 3730,
		"level": 2,
		"name": "万江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2292,
		"id": 3731,
		"level": 2,
		"name": "东城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2292,
		"id": 3765,
		"level": 2,
		"name": "厚街镇"
	}],
	2293: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2293,
		"id": 3764,
		"level": 1,
		"name": "中山市区"
	}],
	2294: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2294,
		"id": 2296,
		"level": 2,
		"name": "湘桥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2294,
		"id": 2297,
		"level": 2,
		"name": "潮安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2294,
		"id": 2298,
		"level": 2,
		"name": "饶平县"
	}],
	2299: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2299,
		"id": 2301,
		"level": 2,
		"name": "榕城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2299,
		"id": 2302,
		"level": 2,
		"name": "揭东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2299,
		"id": 2303,
		"level": 2,
		"name": "揭西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2299,
		"id": 2304,
		"level": 2,
		"name": "惠来县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2299,
		"id": 2305,
		"level": 2,
		"name": "普宁市"
	}],
	2306: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2306,
		"id": 2308,
		"level": 2,
		"name": "云城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2306,
		"id": 2309,
		"level": 2,
		"name": "新兴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2306,
		"id": 2310,
		"level": 2,
		"name": "郁南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2306,
		"id": 2311,
		"level": 2,
		"name": "云安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2306,
		"id": 2312,
		"level": 2,
		"name": "罗定市"
	}],
	2314: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2316,
		"level": 2,
		"name": "兴宁区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2317,
		"level": 2,
		"name": "青秀区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2318,
		"level": 2,
		"name": "江南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2319,
		"level": 2,
		"name": "西乡塘区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2320,
		"level": 2,
		"name": "良庆区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2321,
		"level": 2,
		"name": "邕宁区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2322,
		"level": 2,
		"name": "武鸣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2323,
		"level": 2,
		"name": "隆安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2324,
		"level": 2,
		"name": "马山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2325,
		"level": 2,
		"name": "上林县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2326,
		"level": 2,
		"name": "宾阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2314,
		"id": 2327,
		"level": 2,
		"name": "横县"
	}],
	2328: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2330,
		"level": 2,
		"name": "城中区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2331,
		"level": 2,
		"name": "鱼峰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2332,
		"level": 2,
		"name": "柳南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2333,
		"level": 2,
		"name": "柳北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2334,
		"level": 2,
		"name": "柳江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2335,
		"level": 2,
		"name": "柳城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2336,
		"level": 2,
		"name": "鹿寨县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2337,
		"level": 2,
		"name": "融安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2338,
		"level": 2,
		"name": "融水苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2328,
		"id": 2339,
		"level": 2,
		"name": "三江侗族自治县"
	}],
	2340: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2342,
		"level": 2,
		"name": "秀峰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2343,
		"level": 2,
		"name": "叠彩区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2344,
		"level": 2,
		"name": "象山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2345,
		"level": 2,
		"name": "七星区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2346,
		"level": 2,
		"name": "雁山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2347,
		"level": 2,
		"name": "阳朔县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2348,
		"level": 2,
		"name": "临桂县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2349,
		"level": 2,
		"name": "灵川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2350,
		"level": 2,
		"name": "全州县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2351,
		"level": 2,
		"name": "兴安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2352,
		"level": 2,
		"name": "永福县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2353,
		"level": 2,
		"name": "灌阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2354,
		"level": 2,
		"name": "龙胜各族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2355,
		"level": 2,
		"name": "资源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2356,
		"level": 2,
		"name": "平乐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2357,
		"level": 2,
		"name": "荔蒲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2340,
		"id": 2358,
		"level": 2,
		"name": "恭城瑶族自治县"
	}],
	2359: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2359,
		"id": 2361,
		"level": 2,
		"name": "万秀区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2359,
		"id": 2362,
		"level": 2,
		"name": "蝶山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2359,
		"id": 2363,
		"level": 2,
		"name": "长洲区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2359,
		"id": 2364,
		"level": 2,
		"name": "苍梧县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2359,
		"id": 2365,
		"level": 2,
		"name": "藤县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2359,
		"id": 2366,
		"level": 2,
		"name": "蒙山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2359,
		"id": 2367,
		"level": 2,
		"name": "岑溪市"
	}],
	2368: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2368,
		"id": 2370,
		"level": 2,
		"name": "海城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2368,
		"id": 2371,
		"level": 2,
		"name": "银海区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2368,
		"id": 2372,
		"level": 2,
		"name": "铁山港区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2368,
		"id": 2373,
		"level": 2,
		"name": "合浦县"
	}],
	2374: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2374,
		"id": 2376,
		"level": 2,
		"name": "港口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2374,
		"id": 2377,
		"level": 2,
		"name": "防城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2374,
		"id": 2378,
		"level": 2,
		"name": "上思县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2374,
		"id": 2379,
		"level": 2,
		"name": "东兴市"
	}],
	2380: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2380,
		"id": 2382,
		"level": 2,
		"name": "钦南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2380,
		"id": 2383,
		"level": 2,
		"name": "钦北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2380,
		"id": 2384,
		"level": 2,
		"name": "灵山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2380,
		"id": 2385,
		"level": 2,
		"name": "浦北县"
	}],
	2386: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2386,
		"id": 2388,
		"level": 2,
		"name": "港北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2386,
		"id": 2389,
		"level": 2,
		"name": "港南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2386,
		"id": 2390,
		"level": 2,
		"name": "覃塘区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2386,
		"id": 2391,
		"level": 2,
		"name": "平南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2386,
		"id": 2392,
		"level": 2,
		"name": "桂平市"
	}],
	2393: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2393,
		"id": 2395,
		"level": 2,
		"name": "玉州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2393,
		"id": 2396,
		"level": 2,
		"name": "容县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2393,
		"id": 2397,
		"level": 2,
		"name": "陆川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2393,
		"id": 2398,
		"level": 2,
		"name": "博白县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2393,
		"id": 2399,
		"level": 2,
		"name": "兴业县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2393,
		"id": 2400,
		"level": 2,
		"name": "北流市"
	}],
	2401: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2403,
		"level": 2,
		"name": "右江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2404,
		"level": 2,
		"name": "田阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2405,
		"level": 2,
		"name": "田东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2406,
		"level": 2,
		"name": "平果县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2407,
		"level": 2,
		"name": "德保县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2408,
		"level": 2,
		"name": "靖西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2409,
		"level": 2,
		"name": "那坡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2410,
		"level": 2,
		"name": "凌云县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2411,
		"level": 2,
		"name": "乐业县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2412,
		"level": 2,
		"name": "田林县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2413,
		"level": 2,
		"name": "西林县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2401,
		"id": 2414,
		"level": 2,
		"name": "隆林各族自治县"
	}],
	2415: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2415,
		"id": 2417,
		"level": 2,
		"name": "八步区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2415,
		"id": 2418,
		"level": 2,
		"name": "昭平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2415,
		"id": 2419,
		"level": 2,
		"name": "钟山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2415,
		"id": 2420,
		"level": 2,
		"name": "富川瑶族自治县"
	}],
	2421: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2423,
		"level": 2,
		"name": "金城江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2424,
		"level": 2,
		"name": "南丹县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2425,
		"level": 2,
		"name": "天峨县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2426,
		"level": 2,
		"name": "凤山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2427,
		"level": 2,
		"name": "东兰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2428,
		"level": 2,
		"name": "罗城仫佬族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2429,
		"level": 2,
		"name": "环江毛南族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2430,
		"level": 2,
		"name": "巴马瑶族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2431,
		"level": 2,
		"name": "都安瑶族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2432,
		"level": 2,
		"name": "大化瑶族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2421,
		"id": 2433,
		"level": 2,
		"name": "宜州市"
	}],
	2434: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2434,
		"id": 2436,
		"level": 2,
		"name": "兴宾区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2434,
		"id": 2437,
		"level": 2,
		"name": "忻城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2434,
		"id": 2438,
		"level": 2,
		"name": "象州县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2434,
		"id": 2439,
		"level": 2,
		"name": "武宣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2434,
		"id": 2440,
		"level": 2,
		"name": "金秀瑶族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2434,
		"id": 2441,
		"level": 2,
		"name": "合山市"
	}],
	2442: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2442,
		"id": 2444,
		"level": 2,
		"name": "江洲区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2442,
		"id": 2445,
		"level": 2,
		"name": "扶绥县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2442,
		"id": 2446,
		"level": 2,
		"name": "宁明县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2442,
		"id": 2447,
		"level": 2,
		"name": "龙州县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2442,
		"id": 2448,
		"level": 2,
		"name": "大新县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2442,
		"id": 2449,
		"level": 2,
		"name": "天等县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2442,
		"id": 2450,
		"level": 2,
		"name": "凭祥市"
	}],
	2452: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2452,
		"id": 2454,
		"level": 2,
		"name": "秀英区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2452,
		"id": 2455,
		"level": 2,
		"name": "龙华区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2452,
		"id": 2456,
		"level": 2,
		"name": "琼山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2452,
		"id": 2457,
		"level": 2,
		"name": "美兰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2452,
		"id": 3771,
		"level": 3,
		"name": "市辖区"
	}],
	2458: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2458,
		"id": 3766,
		"level": 1,
		"name": "市辖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2458,
		"id": 3767,
		"level": 1,
		"name": "海棠区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2458,
		"id": 3768,
		"level": 1,
		"name": "吉阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2458,
		"id": 3769,
		"level": 1,
		"name": "天涯区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2458,
		"id": 3770,
		"level": 1,
		"name": "崖州区"
	}],
	2460: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2461,
		"level": 2,
		"name": "五指山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2462,
		"level": 2,
		"name": "琼海市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2463,
		"level": 2,
		"name": "儋州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2464,
		"level": 2,
		"name": "文昌市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2465,
		"level": 2,
		"name": "万宁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2466,
		"level": 2,
		"name": "东方市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2467,
		"level": 2,
		"name": "定安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2468,
		"level": 2,
		"name": "屯昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2469,
		"level": 2,
		"name": "澄迈县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2470,
		"level": 2,
		"name": "临高县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2471,
		"level": 2,
		"name": "白沙黎族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2472,
		"level": 2,
		"name": "昌江黎族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2473,
		"level": 2,
		"name": "乐东黎族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2474,
		"level": 2,
		"name": "陵水黎族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2475,
		"level": 2,
		"name": "保亭黎族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2476,
		"level": 2,
		"name": "琼中黎族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2477,
		"level": 2,
		"name": "西沙群岛"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2478,
		"level": 2,
		"name": "南沙群岛"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2460,
		"id": 2479,
		"level": 2,
		"name": "中沙群岛的岛礁及其海域"
	}],
	2481: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2482,
		"level": 2,
		"name": "万州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2483,
		"level": 2,
		"name": "涪陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2484,
		"level": 2,
		"name": "渝中区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2485,
		"level": 2,
		"name": "大渡口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2486,
		"level": 2,
		"name": "江北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2487,
		"level": 2,
		"name": "沙坪坝区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2488,
		"level": 2,
		"name": "九龙坡区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2489,
		"level": 2,
		"name": "南岸区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2490,
		"level": 2,
		"name": "北碚区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2491,
		"level": 2,
		"name": "綦江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2492,
		"level": 2,
		"name": "大足区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2493,
		"level": 2,
		"name": "渝北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2494,
		"level": 2,
		"name": "巴南区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2495,
		"level": 2,
		"name": "黔江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2496,
		"level": 2,
		"name": "长寿区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2497,
		"level": 2,
		"name": "江津区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2498,
		"level": 2,
		"name": "合川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2499,
		"level": 2,
		"name": "永川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2500,
		"level": 2,
		"name": "南川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2501,
		"level": 2,
		"name": "潼南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2502,
		"level": 2,
		"name": "铜梁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2503,
		"level": 2,
		"name": "荣昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2504,
		"level": 2,
		"name": "璧山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2505,
		"level": 2,
		"name": "梁平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2506,
		"level": 2,
		"name": "城口县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2507,
		"level": 2,
		"name": "丰都县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2508,
		"level": 2,
		"name": "垫江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2509,
		"level": 2,
		"name": "武隆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2510,
		"level": 2,
		"name": "忠县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2511,
		"level": 2,
		"name": "开县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2512,
		"level": 2,
		"name": "云阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2513,
		"level": 2,
		"name": "奉节县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2514,
		"level": 2,
		"name": "巫山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2515,
		"level": 2,
		"name": "巫溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2516,
		"level": 2,
		"name": "石柱土家族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2517,
		"level": 2,
		"name": "秀山土家族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2518,
		"level": 2,
		"name": "酉阳土家族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 2519,
		"level": 2,
		"name": "彭水苗族土家族自治县"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2481,
		"id": 3732,
		"level": 2,
		"name": "万盛区"
	}],
	2521: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2523,
		"level": 2,
		"name": "锦江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2524,
		"level": 2,
		"name": "青羊区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2525,
		"level": 2,
		"name": "金牛区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2526,
		"level": 2,
		"name": "武侯区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2527,
		"level": 2,
		"name": "成华区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2528,
		"level": 2,
		"name": "龙泉驿区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2529,
		"level": 2,
		"name": "青白江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2530,
		"level": 2,
		"name": "新都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2531,
		"level": 2,
		"name": "温江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2532,
		"level": 2,
		"name": "金堂县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2533,
		"level": 2,
		"name": "双流县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2534,
		"level": 2,
		"name": "郫县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2535,
		"level": 2,
		"name": "大邑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2536,
		"level": 2,
		"name": "蒲江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2537,
		"level": 2,
		"name": "新津县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2538,
		"level": 2,
		"name": "都江堰市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2539,
		"level": 2,
		"name": "彭州市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2540,
		"level": 2,
		"name": "邛崃市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2521,
		"id": 2541,
		"level": 2,
		"name": "崇州市"
	}],
	2542: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2542,
		"id": 2544,
		"level": 2,
		"name": "自流井区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2542,
		"id": 2545,
		"level": 2,
		"name": "贡井区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2542,
		"id": 2546,
		"level": 2,
		"name": "大安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2542,
		"id": 2547,
		"level": 2,
		"name": "沿滩区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2542,
		"id": 2548,
		"level": 2,
		"name": "荣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2542,
		"id": 2549,
		"level": 2,
		"name": "富顺县"
	}],
	2550: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2550,
		"id": 2552,
		"level": 2,
		"name": "东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2550,
		"id": 2553,
		"level": 2,
		"name": "西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2550,
		"id": 2554,
		"level": 2,
		"name": "仁和区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2550,
		"id": 2555,
		"level": 2,
		"name": "米易县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2550,
		"id": 2556,
		"level": 2,
		"name": "盐边县"
	}],
	2557: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2557,
		"id": 2559,
		"level": 2,
		"name": "江阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2557,
		"id": 2560,
		"level": 2,
		"name": "纳溪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2557,
		"id": 2561,
		"level": 2,
		"name": "龙马潭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2557,
		"id": 2562,
		"level": 2,
		"name": "泸县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2557,
		"id": 2563,
		"level": 2,
		"name": "合江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2557,
		"id": 2564,
		"level": 2,
		"name": "叙永县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2557,
		"id": 2565,
		"level": 2,
		"name": "古蔺县"
	}],
	2566: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2566,
		"id": 2568,
		"level": 2,
		"name": "旌阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2566,
		"id": 2569,
		"level": 2,
		"name": "中江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2566,
		"id": 2570,
		"level": 2,
		"name": "罗江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2566,
		"id": 2571,
		"level": 2,
		"name": "广汉市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2566,
		"id": 2572,
		"level": 2,
		"name": "什邡市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2566,
		"id": 2573,
		"level": 2,
		"name": "绵竹市"
	}],
	2574: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2574,
		"id": 2576,
		"level": 2,
		"name": "涪城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2574,
		"id": 2577,
		"level": 2,
		"name": "游仙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2574,
		"id": 2578,
		"level": 2,
		"name": "三台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2574,
		"id": 2579,
		"level": 2,
		"name": "盐亭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2574,
		"id": 2580,
		"level": 2,
		"name": "安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2574,
		"id": 2581,
		"level": 2,
		"name": "梓潼县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2574,
		"id": 2582,
		"level": 2,
		"name": "北川羌族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2574,
		"id": 2583,
		"level": 2,
		"name": "平武县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2574,
		"id": 2584,
		"level": 2,
		"name": "江油市"
	}],
	2585: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2585,
		"id": 2587,
		"level": 2,
		"name": "利州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2585,
		"id": 2588,
		"level": 2,
		"name": "元坝区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2585,
		"id": 2589,
		"level": 2,
		"name": "朝天区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2585,
		"id": 2590,
		"level": 2,
		"name": "旺苍县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2585,
		"id": 2591,
		"level": 2,
		"name": "青川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2585,
		"id": 2592,
		"level": 2,
		"name": "剑阁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2585,
		"id": 2593,
		"level": 2,
		"name": "苍溪县"
	}],
	2594: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2594,
		"id": 2596,
		"level": 2,
		"name": "船山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2594,
		"id": 2597,
		"level": 2,
		"name": "安居区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2594,
		"id": 2598,
		"level": 2,
		"name": "蓬溪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2594,
		"id": 2599,
		"level": 2,
		"name": "射洪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2594,
		"id": 2600,
		"level": 2,
		"name": "大英县"
	}],
	2601: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2601,
		"id": 2603,
		"level": 2,
		"name": "市中区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2601,
		"id": 2604,
		"level": 2,
		"name": "东兴区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2601,
		"id": 2605,
		"level": 2,
		"name": "威远县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2601,
		"id": 2606,
		"level": 2,
		"name": "资中县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2601,
		"id": 2607,
		"level": 2,
		"name": "隆昌县"
	}],
	2608: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2610,
		"level": 2,
		"name": "市中区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2611,
		"level": 2,
		"name": "沙湾区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2612,
		"level": 2,
		"name": "五通桥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2613,
		"level": 2,
		"name": "金口河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2614,
		"level": 2,
		"name": "犍为县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2615,
		"level": 2,
		"name": "井研县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2616,
		"level": 2,
		"name": "夹江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2617,
		"level": 2,
		"name": "沐川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2618,
		"level": 2,
		"name": "峨边彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2619,
		"level": 2,
		"name": "马边彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2608,
		"id": 2620,
		"level": 2,
		"name": "峨眉山市"
	}],
	2621: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2621,
		"id": 2623,
		"level": 2,
		"name": "顺庆区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2621,
		"id": 2624,
		"level": 2,
		"name": "高坪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2621,
		"id": 2625,
		"level": 2,
		"name": "嘉陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2621,
		"id": 2626,
		"level": 2,
		"name": "南部县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2621,
		"id": 2627,
		"level": 2,
		"name": "营山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2621,
		"id": 2628,
		"level": 2,
		"name": "蓬安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2621,
		"id": 2629,
		"level": 2,
		"name": "仪陇县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2621,
		"id": 2630,
		"level": 2,
		"name": "西充县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2621,
		"id": 2631,
		"level": 2,
		"name": "阆中市"
	}],
	2632: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2632,
		"id": 2634,
		"level": 2,
		"name": "东坡区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2632,
		"id": 2635,
		"level": 2,
		"name": "仁寿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2632,
		"id": 2636,
		"level": 2,
		"name": "彭山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2632,
		"id": 2637,
		"level": 2,
		"name": "洪雅县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2632,
		"id": 2638,
		"level": 2,
		"name": "丹棱县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2632,
		"id": 2639,
		"level": 2,
		"name": "青神县"
	}],
	2640: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2642,
		"level": 2,
		"name": "翠屏区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2643,
		"level": 2,
		"name": "南溪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2644,
		"level": 2,
		"name": "宜宾县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2645,
		"level": 2,
		"name": "江安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2646,
		"level": 2,
		"name": "长宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2647,
		"level": 2,
		"name": "高县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2648,
		"level": 2,
		"name": "珙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2649,
		"level": 2,
		"name": "筠连县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2650,
		"level": 2,
		"name": "兴文县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2640,
		"id": 2651,
		"level": 2,
		"name": "屏山县"
	}],
	2652: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2652,
		"id": 2654,
		"level": 2,
		"name": "广安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2652,
		"id": 2655,
		"level": 2,
		"name": "岳池县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2652,
		"id": 2656,
		"level": 2,
		"name": "武胜县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2652,
		"id": 2657,
		"level": 2,
		"name": "邻水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2652,
		"id": 2658,
		"level": 2,
		"name": "华蓥市"
	}],
	2659: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2659,
		"id": 2661,
		"level": 2,
		"name": "通川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2659,
		"id": 2662,
		"level": 2,
		"name": "达县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2659,
		"id": 2663,
		"level": 2,
		"name": "宣汉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2659,
		"id": 2664,
		"level": 2,
		"name": "开江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2659,
		"id": 2665,
		"level": 2,
		"name": "大竹县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2659,
		"id": 2666,
		"level": 2,
		"name": "渠县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2659,
		"id": 2667,
		"level": 2,
		"name": "万源市"
	}],
	2668: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2668,
		"id": 2670,
		"level": 2,
		"name": "雨城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2668,
		"id": 2671,
		"level": 2,
		"name": "名山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2668,
		"id": 2672,
		"level": 2,
		"name": "荥经县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2668,
		"id": 2673,
		"level": 2,
		"name": "汉源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2668,
		"id": 2674,
		"level": 2,
		"name": "石棉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2668,
		"id": 2675,
		"level": 2,
		"name": "天全县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2668,
		"id": 2676,
		"level": 2,
		"name": "芦山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2668,
		"id": 2677,
		"level": 2,
		"name": "宝兴县"
	}],
	2678: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2678,
		"id": 2680,
		"level": 2,
		"name": "巴州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2678,
		"id": 2681,
		"level": 2,
		"name": "通江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2678,
		"id": 2682,
		"level": 2,
		"name": "南江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2678,
		"id": 2683,
		"level": 2,
		"name": "平昌县"
	}],
	2684: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2684,
		"id": 2686,
		"level": 2,
		"name": "雁江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2684,
		"id": 2687,
		"level": 2,
		"name": "安岳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2684,
		"id": 2688,
		"level": 2,
		"name": "乐至县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2684,
		"id": 2689,
		"level": 2,
		"name": "简阳市"
	}],
	2690: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2691,
		"level": 2,
		"name": "汶川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2692,
		"level": 2,
		"name": "理县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2693,
		"level": 2,
		"name": "茂县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2694,
		"level": 2,
		"name": "松潘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2695,
		"level": 2,
		"name": "九寨沟县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2696,
		"level": 2,
		"name": "金川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2697,
		"level": 2,
		"name": "小金县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2698,
		"level": 2,
		"name": "黑水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2699,
		"level": 2,
		"name": "马尔康县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2700,
		"level": 2,
		"name": "壤塘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2701,
		"level": 2,
		"name": "阿坝县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2702,
		"level": 2,
		"name": "若尔盖县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2690,
		"id": 2703,
		"level": 2,
		"name": "红原县"
	}],
	2704: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2705,
		"level": 2,
		"name": "康定县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2706,
		"level": 2,
		"name": "泸定县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2707,
		"level": 2,
		"name": "丹巴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2708,
		"level": 2,
		"name": "九龙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2709,
		"level": 2,
		"name": "雅江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2710,
		"level": 2,
		"name": "道孚县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2711,
		"level": 2,
		"name": "炉霍县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2712,
		"level": 2,
		"name": "甘孜县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2713,
		"level": 2,
		"name": "新龙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2714,
		"level": 2,
		"name": "德格县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2715,
		"level": 2,
		"name": "白玉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2716,
		"level": 2,
		"name": "石渠县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2717,
		"level": 2,
		"name": "色达县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2718,
		"level": 2,
		"name": "理塘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2719,
		"level": 2,
		"name": "巴塘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2720,
		"level": 2,
		"name": "乡城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2721,
		"level": 2,
		"name": "稻城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2704,
		"id": 2722,
		"level": 2,
		"name": "得荣县"
	}],
	2723: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2724,
		"level": 2,
		"name": "西昌市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2725,
		"level": 2,
		"name": "木里藏族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2726,
		"level": 2,
		"name": "盐源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2727,
		"level": 2,
		"name": "德昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2728,
		"level": 2,
		"name": "会理县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2729,
		"level": 2,
		"name": "会东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2730,
		"level": 2,
		"name": "宁南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2731,
		"level": 2,
		"name": "普格县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2732,
		"level": 2,
		"name": "布拖县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2733,
		"level": 2,
		"name": "金阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2734,
		"level": 2,
		"name": "昭觉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2735,
		"level": 2,
		"name": "喜德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2736,
		"level": 2,
		"name": "冕宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2737,
		"level": 2,
		"name": "越西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2738,
		"level": 2,
		"name": "甘洛县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2739,
		"level": 2,
		"name": "美姑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2723,
		"id": 2740,
		"level": 2,
		"name": "雷波县"
	}],
	2742: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2744,
		"level": 2,
		"name": "南明区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2745,
		"level": 2,
		"name": "云岩区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2746,
		"level": 2,
		"name": "花溪区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2747,
		"level": 2,
		"name": "乌当区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2748,
		"level": 2,
		"name": "白云区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2749,
		"level": 2,
		"name": "小河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2750,
		"level": 2,
		"name": "开阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2751,
		"level": 2,
		"name": "息烽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2752,
		"level": 2,
		"name": "修文县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2742,
		"id": 2753,
		"level": 2,
		"name": "清镇市"
	}],
	2754: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2754,
		"id": 2755,
		"level": 2,
		"name": "钟山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2754,
		"id": 2756,
		"level": 2,
		"name": "六枝特区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2754,
		"id": 2757,
		"level": 2,
		"name": "水城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2754,
		"id": 2758,
		"level": 2,
		"name": "盘县"
	}],
	2759: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2761,
		"level": 2,
		"name": "红花岗区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2762,
		"level": 2,
		"name": "汇川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2763,
		"level": 2,
		"name": "遵义县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2764,
		"level": 2,
		"name": "桐梓县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2765,
		"level": 2,
		"name": "绥阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2766,
		"level": 2,
		"name": "正安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2767,
		"level": 2,
		"name": "道真仡佬族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2768,
		"level": 2,
		"name": "务川仡佬族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2769,
		"level": 2,
		"name": "凤冈县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2770,
		"level": 2,
		"name": "湄潭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2771,
		"level": 2,
		"name": "余庆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2772,
		"level": 2,
		"name": "习水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2773,
		"level": 2,
		"name": "赤水市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2759,
		"id": 2774,
		"level": 2,
		"name": "仁怀市"
	}],
	2775: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2775,
		"id": 2777,
		"level": 2,
		"name": "西秀区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2775,
		"id": 2778,
		"level": 2,
		"name": "平坝县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2775,
		"id": 2779,
		"level": 2,
		"name": "普定县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2775,
		"id": 2780,
		"level": 2,
		"name": "镇宁布依族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2775,
		"id": 2781,
		"level": 2,
		"name": "关岭布依族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2775,
		"id": 2782,
		"level": 2,
		"name": "紫云苗族布依族自治县"
	}],
	2783: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2783,
		"id": 2785,
		"level": 2,
		"name": "七星关区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2783,
		"id": 2786,
		"level": 2,
		"name": "大方县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2783,
		"id": 2787,
		"level": 2,
		"name": "黔西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2783,
		"id": 2788,
		"level": 2,
		"name": "金沙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2783,
		"id": 2789,
		"level": 2,
		"name": "织金县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2783,
		"id": 2790,
		"level": 2,
		"name": "纳雍县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2783,
		"id": 2791,
		"level": 2,
		"name": "威宁彝族回族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2783,
		"id": 2792,
		"level": 2,
		"name": "赫章县"
	}],
	2793: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2794,
		"level": 2,
		"name": "市辖区a"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2795,
		"level": 2,
		"name": "碧江区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2796,
		"level": 2,
		"name": "万山区aaaaaaaaaaa"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2797,
		"level": 2,
		"name": "江口县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2798,
		"level": 2,
		"name": "玉屏侗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2799,
		"level": 2,
		"name": "石阡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2800,
		"level": 2,
		"name": "思南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2801,
		"level": 2,
		"name": "印江土家族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2802,
		"level": 2,
		"name": "德江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2803,
		"level": 2,
		"name": "沿河土家族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 2804,
		"level": 2,
		"name": "松桃苗族自治县"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2793,
		"id": 3734,
		"level": 2,
		"name": "万山特区"
	}],
	2805: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2805,
		"id": 2806,
		"level": 2,
		"name": "兴义市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2805,
		"id": 2807,
		"level": 2,
		"name": "兴仁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2805,
		"id": 2808,
		"level": 2,
		"name": "普安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2805,
		"id": 2809,
		"level": 2,
		"name": "晴隆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2805,
		"id": 2810,
		"level": 2,
		"name": "贞丰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2805,
		"id": 2811,
		"level": 2,
		"name": "望谟县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2805,
		"id": 2812,
		"level": 2,
		"name": "册亨县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2805,
		"id": 2813,
		"level": 2,
		"name": "安龙县"
	}],
	2814: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2815,
		"level": 2,
		"name": "凯里市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2816,
		"level": 2,
		"name": "黄平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2817,
		"level": 2,
		"name": "施秉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2818,
		"level": 2,
		"name": "三穗县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2819,
		"level": 2,
		"name": "镇远县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2820,
		"level": 2,
		"name": "岑巩县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2821,
		"level": 2,
		"name": "天柱县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2822,
		"level": 2,
		"name": "锦屏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2823,
		"level": 2,
		"name": "剑河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2824,
		"level": 2,
		"name": "台江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2825,
		"level": 2,
		"name": "黎平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2826,
		"level": 2,
		"name": "榕江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2827,
		"level": 2,
		"name": "从江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2828,
		"level": 2,
		"name": "雷山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2829,
		"level": 2,
		"name": "麻江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2814,
		"id": 2830,
		"level": 2,
		"name": "丹寨县"
	}],
	2831: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2832,
		"level": 2,
		"name": "都匀市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2833,
		"level": 2,
		"name": "福泉市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2834,
		"level": 2,
		"name": "荔波县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2835,
		"level": 2,
		"name": "贵定县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2836,
		"level": 2,
		"name": "瓮安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2837,
		"level": 2,
		"name": "独山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2838,
		"level": 2,
		"name": "平塘县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2839,
		"level": 2,
		"name": "罗甸县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2840,
		"level": 2,
		"name": "长顺县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2841,
		"level": 2,
		"name": "龙里县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2842,
		"level": 2,
		"name": "惠水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2831,
		"id": 2843,
		"level": 2,
		"name": "三都水族自治县"
	}],
	2845: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2847,
		"level": 2,
		"name": "五华区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2848,
		"level": 2,
		"name": "盘龙区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2849,
		"level": 2,
		"name": "官渡区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2850,
		"level": 2,
		"name": "西山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2851,
		"level": 2,
		"name": "东川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2852,
		"level": 2,
		"name": "呈贡区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2853,
		"level": 2,
		"name": "晋宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2854,
		"level": 2,
		"name": "富民县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2855,
		"level": 2,
		"name": "宜良县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2856,
		"level": 2,
		"name": "石林彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2857,
		"level": 2,
		"name": "嵩明县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2858,
		"level": 2,
		"name": "禄劝彝族苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2859,
		"level": 2,
		"name": "寻甸回族彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2845,
		"id": 2860,
		"level": 2,
		"name": "安宁市"
	}],
	2861: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2861,
		"id": 2863,
		"level": 2,
		"name": "麒麟区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2861,
		"id": 2864,
		"level": 2,
		"name": "马龙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2861,
		"id": 2865,
		"level": 2,
		"name": "陆良县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2861,
		"id": 2866,
		"level": 2,
		"name": "师宗县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2861,
		"id": 2867,
		"level": 2,
		"name": "罗平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2861,
		"id": 2868,
		"level": 2,
		"name": "富源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2861,
		"id": 2869,
		"level": 2,
		"name": "会泽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2861,
		"id": 2870,
		"level": 2,
		"name": "沾益县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2861,
		"id": 2871,
		"level": 2,
		"name": "宣威市"
	}],
	2872: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2872,
		"id": 2874,
		"level": 2,
		"name": "红塔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2872,
		"id": 2875,
		"level": 2,
		"name": "江川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2872,
		"id": 2876,
		"level": 2,
		"name": "澄江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2872,
		"id": 2877,
		"level": 2,
		"name": "通海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2872,
		"id": 2878,
		"level": 2,
		"name": "华宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2872,
		"id": 2879,
		"level": 2,
		"name": "易门县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2872,
		"id": 2880,
		"level": 2,
		"name": "峨山彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2872,
		"id": 2881,
		"level": 2,
		"name": "新平彝族傣族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2872,
		"id": 2882,
		"level": 2,
		"name": "元江哈尼族彝族傣族自治县"
	}],
	2883: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2883,
		"id": 2885,
		"level": 2,
		"name": "隆阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2883,
		"id": 2886,
		"level": 2,
		"name": "施甸县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2883,
		"id": 2887,
		"level": 2,
		"name": "腾冲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2883,
		"id": 2888,
		"level": 2,
		"name": "龙陵县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2883,
		"id": 2889,
		"level": 2,
		"name": "昌宁县"
	}],
	2890: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2892,
		"level": 2,
		"name": "昭阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2893,
		"level": 2,
		"name": "鲁甸县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2894,
		"level": 2,
		"name": "巧家县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2895,
		"level": 2,
		"name": "盐津县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2896,
		"level": 2,
		"name": "大关县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2897,
		"level": 2,
		"name": "永善县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2898,
		"level": 2,
		"name": "绥江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2899,
		"level": 2,
		"name": "镇雄县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2900,
		"level": 2,
		"name": "彝良县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2901,
		"level": 2,
		"name": "威信县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2890,
		"id": 2902,
		"level": 2,
		"name": "水富县"
	}],
	2903: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2903,
		"id": 2905,
		"level": 2,
		"name": "古城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2903,
		"id": 2906,
		"level": 2,
		"name": "玉龙纳西族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2903,
		"id": 2907,
		"level": 2,
		"name": "永胜县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2903,
		"id": 2908,
		"level": 2,
		"name": "华坪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2903,
		"id": 2909,
		"level": 2,
		"name": "宁蒗彝族自治县"
	}],
	2910: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2912,
		"level": 2,
		"name": "思茅区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2913,
		"level": 2,
		"name": "宁洱哈尼族彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2914,
		"level": 2,
		"name": "墨江哈尼族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2915,
		"level": 2,
		"name": "景东彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2916,
		"level": 2,
		"name": "景谷傣族彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2917,
		"level": 2,
		"name": "镇沅彝族哈尼族拉祜族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2918,
		"level": 2,
		"name": "江城哈尼族彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2919,
		"level": 2,
		"name": "孟连傣族拉祜族佤族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2920,
		"level": 2,
		"name": "澜沧拉祜族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2910,
		"id": 2921,
		"level": 2,
		"name": "西盟佤族自治县"
	}],
	2922: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2922,
		"id": 2924,
		"level": 2,
		"name": "临翔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2922,
		"id": 2925,
		"level": 2,
		"name": "凤庆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2922,
		"id": 2926,
		"level": 2,
		"name": "云县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2922,
		"id": 2927,
		"level": 2,
		"name": "永德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2922,
		"id": 2928,
		"level": 2,
		"name": "镇康县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2922,
		"id": 2929,
		"level": 2,
		"name": "双江拉祜族佤族布朗族傣族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2922,
		"id": 2930,
		"level": 2,
		"name": "耿马傣族佤族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2922,
		"id": 2931,
		"level": 2,
		"name": "沧源佤族自治县"
	}],
	2932: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2933,
		"level": 2,
		"name": "楚雄市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2934,
		"level": 2,
		"name": "双柏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2935,
		"level": 2,
		"name": "牟定县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2936,
		"level": 2,
		"name": "南华县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2937,
		"level": 2,
		"name": "姚安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2938,
		"level": 2,
		"name": "大姚县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2939,
		"level": 2,
		"name": "永仁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2940,
		"level": 2,
		"name": "元谋县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2941,
		"level": 2,
		"name": "武定县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2932,
		"id": 2942,
		"level": 2,
		"name": "禄丰县"
	}],
	2943: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2944,
		"level": 2,
		"name": "个旧市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2945,
		"level": 2,
		"name": "开远市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2946,
		"level": 2,
		"name": "蒙自市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2947,
		"level": 2,
		"name": "屏边苗族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2948,
		"level": 2,
		"name": "建水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2949,
		"level": 2,
		"name": "石屏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2950,
		"level": 2,
		"name": "弥勒县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2951,
		"level": 2,
		"name": "泸西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2952,
		"level": 2,
		"name": "元阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2953,
		"level": 2,
		"name": "红河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2954,
		"level": 2,
		"name": "金平苗族瑶族傣族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2955,
		"level": 2,
		"name": "绿春县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2943,
		"id": 2956,
		"level": 2,
		"name": "河口瑶族自治县"
	}],
	2957: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2957,
		"id": 2958,
		"level": 2,
		"name": "文山市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2957,
		"id": 2959,
		"level": 2,
		"name": "砚山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2957,
		"id": 2960,
		"level": 2,
		"name": "西畴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2957,
		"id": 2961,
		"level": 2,
		"name": "麻栗坡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2957,
		"id": 2962,
		"level": 2,
		"name": "马关县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2957,
		"id": 2963,
		"level": 2,
		"name": "丘北县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2957,
		"id": 2964,
		"level": 2,
		"name": "广南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2957,
		"id": 2965,
		"level": 2,
		"name": "富宁县"
	}],
	2966: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2966,
		"id": 2967,
		"level": 2,
		"name": "景洪市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2966,
		"id": 2968,
		"level": 2,
		"name": "勐海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2966,
		"id": 2969,
		"level": 2,
		"name": "勐腊县"
	}],
	2970: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2971,
		"level": 2,
		"name": "大理市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2972,
		"level": 2,
		"name": "漾濞彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2973,
		"level": 2,
		"name": "祥云县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2974,
		"level": 2,
		"name": "宾川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2975,
		"level": 2,
		"name": "弥渡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2976,
		"level": 2,
		"name": "南涧彝族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2977,
		"level": 2,
		"name": "巍山彝族回族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2978,
		"level": 2,
		"name": "永平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2979,
		"level": 2,
		"name": "云龙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2980,
		"level": 2,
		"name": "洱源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2981,
		"level": 2,
		"name": "剑川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2970,
		"id": 2982,
		"level": 2,
		"name": "鹤庆县"
	}],
	2983: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2983,
		"id": 2984,
		"level": 2,
		"name": "瑞丽市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2983,
		"id": 2985,
		"level": 2,
		"name": "芒市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2983,
		"id": 2986,
		"level": 2,
		"name": "梁河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2983,
		"id": 2987,
		"level": 2,
		"name": "盈江县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2983,
		"id": 2988,
		"level": 2,
		"name": "陇川县"
	}],
	2989: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2989,
		"id": 2990,
		"level": 2,
		"name": "泸水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2989,
		"id": 2991,
		"level": 2,
		"name": "福贡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2989,
		"id": 2992,
		"level": 2,
		"name": "贡山独龙族怒族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2989,
		"id": 2993,
		"level": 2,
		"name": "兰坪白族普米族自治县"
	}],
	2994: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2994,
		"id": 2995,
		"level": 2,
		"name": "香格里拉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2994,
		"id": 2996,
		"level": 2,
		"name": "德钦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2994,
		"id": 2997,
		"level": 2,
		"name": "维西傈僳族自治县"
	}],
	2999: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2999,
		"id": 3001,
		"level": 2,
		"name": "城关区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2999,
		"id": 3002,
		"level": 2,
		"name": "林周县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2999,
		"id": 3003,
		"level": 2,
		"name": "当雄县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2999,
		"id": 3004,
		"level": 2,
		"name": "尼木县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2999,
		"id": 3005,
		"level": 2,
		"name": "曲水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2999,
		"id": 3006,
		"level": 2,
		"name": "堆龙德庆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2999,
		"id": 3007,
		"level": 2,
		"name": "达孜县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 2999,
		"id": 3008,
		"level": 2,
		"name": "墨竹工卡县"
	}],
	3009: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3010,
		"level": 2,
		"name": "昌都县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3011,
		"level": 2,
		"name": "江达县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3012,
		"level": 2,
		"name": "贡觉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3013,
		"level": 2,
		"name": "类乌齐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3014,
		"level": 2,
		"name": "丁青县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3015,
		"level": 2,
		"name": "察雅县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3016,
		"level": 2,
		"name": "八宿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3017,
		"level": 2,
		"name": "左贡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3018,
		"level": 2,
		"name": "芒康县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3019,
		"level": 2,
		"name": "洛隆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3009,
		"id": 3020,
		"level": 2,
		"name": "边坝县"
	}],
	3021: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3022,
		"level": 2,
		"name": "乃东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3023,
		"level": 2,
		"name": "扎囊县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3024,
		"level": 2,
		"name": "贡嘎县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3025,
		"level": 2,
		"name": "桑日县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3026,
		"level": 2,
		"name": "琼结县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3027,
		"level": 2,
		"name": "曲松县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3028,
		"level": 2,
		"name": "措美县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3029,
		"level": 2,
		"name": "洛扎县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3030,
		"level": 2,
		"name": "加查县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3031,
		"level": 2,
		"name": "隆子县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3032,
		"level": 2,
		"name": "错那县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3021,
		"id": 3033,
		"level": 2,
		"name": "浪卡子县"
	}],
	3034: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3035,
		"level": 2,
		"name": "日喀则市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3036,
		"level": 2,
		"name": "南木林县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3037,
		"level": 2,
		"name": "江孜县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3038,
		"level": 2,
		"name": "定日县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3039,
		"level": 2,
		"name": "萨迦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3040,
		"level": 2,
		"name": "拉孜县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3041,
		"level": 2,
		"name": "昂仁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3042,
		"level": 2,
		"name": "谢通门县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3043,
		"level": 2,
		"name": "白朗县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3044,
		"level": 2,
		"name": "仁布县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3045,
		"level": 2,
		"name": "康马县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3046,
		"level": 2,
		"name": "定结县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3047,
		"level": 2,
		"name": "仲巴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3048,
		"level": 2,
		"name": "亚东县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3049,
		"level": 2,
		"name": "吉隆县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3050,
		"level": 2,
		"name": "聂拉木县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3051,
		"level": 2,
		"name": "萨嘎县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3034,
		"id": 3052,
		"level": 2,
		"name": "岗巴县"
	}],
	3053: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3054,
		"level": 2,
		"name": "那曲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3055,
		"level": 2,
		"name": "嘉黎县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3056,
		"level": 2,
		"name": "比如县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3057,
		"level": 2,
		"name": "聂荣县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3058,
		"level": 2,
		"name": "安多县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3059,
		"level": 2,
		"name": "申扎县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3060,
		"level": 2,
		"name": "索县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3061,
		"level": 2,
		"name": "班戈县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3062,
		"level": 2,
		"name": "巴青县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3053,
		"id": 3063,
		"level": 2,
		"name": "尼玛县"
	}],
	3064: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3064,
		"id": 3065,
		"level": 2,
		"name": "普兰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3064,
		"id": 3066,
		"level": 2,
		"name": "札达县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3064,
		"id": 3067,
		"level": 2,
		"name": "噶尔县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3064,
		"id": 3068,
		"level": 2,
		"name": "日土县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3064,
		"id": 3069,
		"level": 2,
		"name": "革吉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3064,
		"id": 3070,
		"level": 2,
		"name": "改则县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3064,
		"id": 3071,
		"level": 2,
		"name": "措勤县"
	}],
	3072: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3072,
		"id": 3073,
		"level": 2,
		"name": "林芝县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3072,
		"id": 3074,
		"level": 2,
		"name": "工布江达县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3072,
		"id": 3075,
		"level": 2,
		"name": "米林县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3072,
		"id": 3076,
		"level": 2,
		"name": "墨脱县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3072,
		"id": 3077,
		"level": 2,
		"name": "波密县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3072,
		"id": 3078,
		"level": 2,
		"name": "察隅县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3072,
		"id": 3079,
		"level": 2,
		"name": "朗县"
	}],
	3081: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3083,
		"level": 2,
		"name": "新城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3084,
		"level": 2,
		"name": "碑林区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3085,
		"level": 2,
		"name": "莲湖区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3086,
		"level": 2,
		"name": "灞桥区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3087,
		"level": 2,
		"name": "未央区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3088,
		"level": 2,
		"name": "雁塔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3089,
		"level": 2,
		"name": "阎良区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3090,
		"level": 2,
		"name": "临潼区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3091,
		"level": 2,
		"name": "长安区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3092,
		"level": 2,
		"name": "蓝田县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3093,
		"level": 2,
		"name": "周至县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3094,
		"level": 2,
		"name": "户县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3081,
		"id": 3095,
		"level": 2,
		"name": "高陵县"
	}],
	3096: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3096,
		"id": 3098,
		"level": 2,
		"name": "王益区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3096,
		"id": 3099,
		"level": 2,
		"name": "印台区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3096,
		"id": 3100,
		"level": 2,
		"name": "耀州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3096,
		"id": 3101,
		"level": 2,
		"name": "宜君县"
	}],
	3102: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3104,
		"level": 2,
		"name": "渭滨区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3105,
		"level": 2,
		"name": "金台区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3106,
		"level": 2,
		"name": "陈仓区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3107,
		"level": 2,
		"name": "凤翔县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3108,
		"level": 2,
		"name": "岐山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3109,
		"level": 2,
		"name": "扶风县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3110,
		"level": 2,
		"name": "眉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3111,
		"level": 2,
		"name": "陇县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3112,
		"level": 2,
		"name": "千阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3113,
		"level": 2,
		"name": "麟游县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3114,
		"level": 2,
		"name": "凤县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3102,
		"id": 3115,
		"level": 2,
		"name": "太白县"
	}],
	3116: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3118,
		"level": 2,
		"name": "秦都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3119,
		"level": 2,
		"name": "杨陵区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3120,
		"level": 2,
		"name": "渭城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3121,
		"level": 2,
		"name": "三原县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3122,
		"level": 2,
		"name": "泾阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3123,
		"level": 2,
		"name": "乾县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3124,
		"level": 2,
		"name": "礼泉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3125,
		"level": 2,
		"name": "永寿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3126,
		"level": 2,
		"name": "彬县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3127,
		"level": 2,
		"name": "长武县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3128,
		"level": 2,
		"name": "旬邑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3129,
		"level": 2,
		"name": "淳化县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3130,
		"level": 2,
		"name": "武功县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3116,
		"id": 3131,
		"level": 2,
		"name": "兴平市"
	}],
	3132: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3134,
		"level": 2,
		"name": "临渭区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3135,
		"level": 2,
		"name": "华县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3136,
		"level": 2,
		"name": "潼关县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3137,
		"level": 2,
		"name": "大荔县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3138,
		"level": 2,
		"name": "合阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3139,
		"level": 2,
		"name": "澄城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3140,
		"level": 2,
		"name": "蒲城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3141,
		"level": 2,
		"name": "白水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3142,
		"level": 2,
		"name": "富平县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3143,
		"level": 2,
		"name": "韩城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3132,
		"id": 3144,
		"level": 2,
		"name": "华阴市"
	}],
	3145: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3147,
		"level": 2,
		"name": "宝塔区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3148,
		"level": 2,
		"name": "延长县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3149,
		"level": 2,
		"name": "延川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3150,
		"level": 2,
		"name": "子长县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3151,
		"level": 2,
		"name": "安塞县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3152,
		"level": 2,
		"name": "志丹县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3153,
		"level": 2,
		"name": "吴起县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3154,
		"level": 2,
		"name": "甘泉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3155,
		"level": 2,
		"name": "富县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3156,
		"level": 2,
		"name": "洛川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3157,
		"level": 2,
		"name": "宜川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3158,
		"level": 2,
		"name": "黄龙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3145,
		"id": 3159,
		"level": 2,
		"name": "黄陵县"
	}],
	3160: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3162,
		"level": 2,
		"name": "汉台区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3163,
		"level": 2,
		"name": "南郑县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3164,
		"level": 2,
		"name": "城固县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3165,
		"level": 2,
		"name": "洋县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3166,
		"level": 2,
		"name": "西乡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3167,
		"level": 2,
		"name": "勉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3168,
		"level": 2,
		"name": "宁强县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3169,
		"level": 2,
		"name": "略阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3170,
		"level": 2,
		"name": "镇巴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3171,
		"level": 2,
		"name": "留坝县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3160,
		"id": 3172,
		"level": 2,
		"name": "佛坪县"
	}],
	3173: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3175,
		"level": 2,
		"name": "榆阳区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3176,
		"level": 2,
		"name": "神木县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3177,
		"level": 2,
		"name": "府谷县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3178,
		"level": 2,
		"name": "横山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3179,
		"level": 2,
		"name": "靖边县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3180,
		"level": 2,
		"name": "定边县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3181,
		"level": 2,
		"name": "绥德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3182,
		"level": 2,
		"name": "米脂县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3183,
		"level": 2,
		"name": "佳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3184,
		"level": 2,
		"name": "吴堡县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3185,
		"level": 2,
		"name": "清涧县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3173,
		"id": 3186,
		"level": 2,
		"name": "子洲县"
	}],
	3187: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3189,
		"level": 2,
		"name": "汉滨区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3190,
		"level": 2,
		"name": "汉阴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3191,
		"level": 2,
		"name": "石泉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3192,
		"level": 2,
		"name": "宁陕县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3193,
		"level": 2,
		"name": "紫阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3194,
		"level": 2,
		"name": "岚皋县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3195,
		"level": 2,
		"name": "平利县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3196,
		"level": 2,
		"name": "镇坪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3197,
		"level": 2,
		"name": "旬阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3187,
		"id": 3198,
		"level": 2,
		"name": "白河县"
	}],
	3199: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3199,
		"id": 3201,
		"level": 2,
		"name": "商州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3199,
		"id": 3202,
		"level": 2,
		"name": "洛南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3199,
		"id": 3203,
		"level": 2,
		"name": "丹凤县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3199,
		"id": 3204,
		"level": 2,
		"name": "商南县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3199,
		"id": 3205,
		"level": 2,
		"name": "山阳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3199,
		"id": 3206,
		"level": 2,
		"name": "镇安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3199,
		"id": 3207,
		"level": 2,
		"name": "柞水县"
	}],
	3209: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3209,
		"id": 3211,
		"level": 2,
		"name": "城关区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3209,
		"id": 3212,
		"level": 2,
		"name": "七里河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3209,
		"id": 3213,
		"level": 2,
		"name": "西固区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3209,
		"id": 3214,
		"level": 2,
		"name": "安宁区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3209,
		"id": 3215,
		"level": 2,
		"name": "红古区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3209,
		"id": 3216,
		"level": 2,
		"name": "永登县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3209,
		"id": 3217,
		"level": 2,
		"name": "皋兰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3209,
		"id": 3218,
		"level": 2,
		"name": "榆中县"
	}],
	3219: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3219,
		"id": 3773,
		"level": 1,
		"name": "城区"
	}],
	3221: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3221,
		"id": 3223,
		"level": 2,
		"name": "金川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3221,
		"id": 3224,
		"level": 2,
		"name": "永昌县"
	}],
	3225: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3225,
		"id": 3227,
		"level": 2,
		"name": "白银区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3225,
		"id": 3228,
		"level": 2,
		"name": "平川区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3225,
		"id": 3229,
		"level": 2,
		"name": "靖远县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3225,
		"id": 3230,
		"level": 2,
		"name": "会宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3225,
		"id": 3231,
		"level": 2,
		"name": "景泰县"
	}],
	3232: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3232,
		"id": 3234,
		"level": 2,
		"name": "秦州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3232,
		"id": 3235,
		"level": 2,
		"name": "麦积区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3232,
		"id": 3236,
		"level": 2,
		"name": "清水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3232,
		"id": 3237,
		"level": 2,
		"name": "秦安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3232,
		"id": 3238,
		"level": 2,
		"name": "甘谷县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3232,
		"id": 3239,
		"level": 2,
		"name": "武山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3232,
		"id": 3240,
		"level": 2,
		"name": "张家川回族自治县"
	}],
	3241: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3241,
		"id": 3243,
		"level": 2,
		"name": "凉州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3241,
		"id": 3244,
		"level": 2,
		"name": "民勤县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3241,
		"id": 3245,
		"level": 2,
		"name": "古浪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3241,
		"id": 3246,
		"level": 2,
		"name": "天祝藏族自治县"
	}],
	3247: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3247,
		"id": 3249,
		"level": 2,
		"name": "甘州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3247,
		"id": 3250,
		"level": 2,
		"name": "肃南裕固族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3247,
		"id": 3251,
		"level": 2,
		"name": "民乐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3247,
		"id": 3252,
		"level": 2,
		"name": "临泽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3247,
		"id": 3253,
		"level": 2,
		"name": "高台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3247,
		"id": 3254,
		"level": 2,
		"name": "山丹县"
	}],
	3255: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3255,
		"id": 3257,
		"level": 2,
		"name": "崆峒区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3255,
		"id": 3258,
		"level": 2,
		"name": "泾川县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3255,
		"id": 3259,
		"level": 2,
		"name": "灵台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3255,
		"id": 3260,
		"level": 2,
		"name": "崇信县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3255,
		"id": 3261,
		"level": 2,
		"name": "华亭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3255,
		"id": 3262,
		"level": 2,
		"name": "庄浪县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3255,
		"id": 3263,
		"level": 2,
		"name": "静宁县"
	}],
	3264: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3264,
		"id": 3266,
		"level": 2,
		"name": "肃州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3264,
		"id": 3267,
		"level": 2,
		"name": "金塔县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3264,
		"id": 3268,
		"level": 2,
		"name": "瓜州县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3264,
		"id": 3269,
		"level": 2,
		"name": "肃北蒙古族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3264,
		"id": 3270,
		"level": 2,
		"name": "阿克塞哈萨克族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3264,
		"id": 3271,
		"level": 2,
		"name": "玉门市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3264,
		"id": 3272,
		"level": 2,
		"name": "敦煌市"
	}],
	3273: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3273,
		"id": 3275,
		"level": 2,
		"name": "西峰区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3273,
		"id": 3276,
		"level": 2,
		"name": "庆城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3273,
		"id": 3277,
		"level": 2,
		"name": "环县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3273,
		"id": 3278,
		"level": 2,
		"name": "华池县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3273,
		"id": 3279,
		"level": 2,
		"name": "合水县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3273,
		"id": 3280,
		"level": 2,
		"name": "正宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3273,
		"id": 3281,
		"level": 2,
		"name": "宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3273,
		"id": 3282,
		"level": 2,
		"name": "镇原县"
	}],
	3283: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3283,
		"id": 3285,
		"level": 2,
		"name": "安定区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3283,
		"id": 3286,
		"level": 2,
		"name": "通渭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3283,
		"id": 3287,
		"level": 2,
		"name": "陇西县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3283,
		"id": 3288,
		"level": 2,
		"name": "渭源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3283,
		"id": 3289,
		"level": 2,
		"name": "临洮县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3283,
		"id": 3290,
		"level": 2,
		"name": "漳县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3283,
		"id": 3291,
		"level": 2,
		"name": "岷县"
	}],
	3292: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3292,
		"id": 3294,
		"level": 2,
		"name": "武都区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3292,
		"id": 3295,
		"level": 2,
		"name": "成县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3292,
		"id": 3296,
		"level": 2,
		"name": "文县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3292,
		"id": 3297,
		"level": 2,
		"name": "宕昌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3292,
		"id": 3298,
		"level": 2,
		"name": "康县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3292,
		"id": 3299,
		"level": 2,
		"name": "西和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3292,
		"id": 3300,
		"level": 2,
		"name": "礼县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3292,
		"id": 3301,
		"level": 2,
		"name": "徽县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3292,
		"id": 3302,
		"level": 2,
		"name": "两当县"
	}],
	3303: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3303,
		"id": 3304,
		"level": 2,
		"name": "临夏市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3303,
		"id": 3305,
		"level": 2,
		"name": "临夏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3303,
		"id": 3306,
		"level": 2,
		"name": "康乐县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3303,
		"id": 3307,
		"level": 2,
		"name": "永靖县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3303,
		"id": 3308,
		"level": 2,
		"name": "广河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3303,
		"id": 3309,
		"level": 2,
		"name": "和政县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3303,
		"id": 3310,
		"level": 2,
		"name": "东乡族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3303,
		"id": 3311,
		"level": 2,
		"name": "积石山保安族东乡族撒拉族自治县"
	}],
	3312: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3312,
		"id": 3313,
		"level": 2,
		"name": "合作市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3312,
		"id": 3314,
		"level": 2,
		"name": "临潭县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3312,
		"id": 3315,
		"level": 2,
		"name": "卓尼县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3312,
		"id": 3316,
		"level": 2,
		"name": "舟曲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3312,
		"id": 3317,
		"level": 2,
		"name": "迭部县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3312,
		"id": 3318,
		"level": 2,
		"name": "玛曲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3312,
		"id": 3319,
		"level": 2,
		"name": "碌曲县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3312,
		"id": 3320,
		"level": 2,
		"name": "夏河县"
	}],
	3322: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3322,
		"id": 3324,
		"level": 2,
		"name": "城东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3322,
		"id": 3325,
		"level": 2,
		"name": "城中区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3322,
		"id": 3326,
		"level": 2,
		"name": "城西区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3322,
		"id": 3327,
		"level": 2,
		"name": "城北区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3322,
		"id": 3328,
		"level": 2,
		"name": "大通回族土族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3322,
		"id": 3329,
		"level": 2,
		"name": "湟中县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3322,
		"id": 3330,
		"level": 2,
		"name": "湟源县"
	}],
	3331: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3331,
		"id": 3332,
		"level": 2,
		"name": "平安县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3331,
		"id": 3333,
		"level": 2,
		"name": "民和回族土族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3331,
		"id": 3334,
		"level": 2,
		"name": "乐都县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3331,
		"id": 3335,
		"level": 2,
		"name": "互助土族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3331,
		"id": 3336,
		"level": 2,
		"name": "化隆回族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3331,
		"id": 3337,
		"level": 2,
		"name": "循化撒拉族自治县"
	}],
	3338: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3338,
		"id": 3339,
		"level": 2,
		"name": "门源回族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3338,
		"id": 3340,
		"level": 2,
		"name": "祁连县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3338,
		"id": 3341,
		"level": 2,
		"name": "海晏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3338,
		"id": 3342,
		"level": 2,
		"name": "刚察县"
	}],
	3343: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3343,
		"id": 3344,
		"level": 2,
		"name": "同仁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3343,
		"id": 3345,
		"level": 2,
		"name": "尖扎县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3343,
		"id": 3346,
		"level": 2,
		"name": "泽库县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3343,
		"id": 3347,
		"level": 2,
		"name": "河南蒙古族自治县"
	}],
	3348: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3348,
		"id": 3349,
		"level": 2,
		"name": "共和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3348,
		"id": 3350,
		"level": 2,
		"name": "同德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3348,
		"id": 3351,
		"level": 2,
		"name": "贵德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3348,
		"id": 3352,
		"level": 2,
		"name": "兴海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3348,
		"id": 3353,
		"level": 2,
		"name": "贵南县"
	}],
	3354: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3354,
		"id": 3355,
		"level": 2,
		"name": "玛沁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3354,
		"id": 3356,
		"level": 2,
		"name": "班玛县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3354,
		"id": 3357,
		"level": 2,
		"name": "甘德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3354,
		"id": 3358,
		"level": 2,
		"name": "达日县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3354,
		"id": 3359,
		"level": 2,
		"name": "久治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3354,
		"id": 3360,
		"level": 2,
		"name": "玛多县"
	}],
	3361: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3361,
		"id": 3362,
		"level": 2,
		"name": "玉树县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3361,
		"id": 3363,
		"level": 2,
		"name": "杂多县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3361,
		"id": 3364,
		"level": 2,
		"name": "称多县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3361,
		"id": 3365,
		"level": 2,
		"name": "治多县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3361,
		"id": 3366,
		"level": 2,
		"name": "囊谦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3361,
		"id": 3367,
		"level": 2,
		"name": "曲麻莱县"
	}],
	3368: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3368,
		"id": 3369,
		"level": 2,
		"name": "格尔木市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3368,
		"id": 3370,
		"level": 2,
		"name": "德令哈市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3368,
		"id": 3371,
		"level": 2,
		"name": "乌兰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3368,
		"id": 3372,
		"level": 2,
		"name": "都兰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3368,
		"id": 3373,
		"level": 2,
		"name": "天峻县"
	}],
	3375: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3375,
		"id": 3377,
		"level": 2,
		"name": "兴庆区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3375,
		"id": 3378,
		"level": 2,
		"name": "西夏区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3375,
		"id": 3379,
		"level": 2,
		"name": "金凤区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3375,
		"id": 3380,
		"level": 2,
		"name": "永宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3375,
		"id": 3381,
		"level": 2,
		"name": "贺兰县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3375,
		"id": 3382,
		"level": 2,
		"name": "灵武市"
	}],
	3383: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3383,
		"id": 3385,
		"level": 2,
		"name": "大武口区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3383,
		"id": 3386,
		"level": 2,
		"name": "惠农区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3383,
		"id": 3387,
		"level": 2,
		"name": "平罗县"
	}],
	3388: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3388,
		"id": 3390,
		"level": 2,
		"name": "利通区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3388,
		"id": 3391,
		"level": 2,
		"name": "红寺堡区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3388,
		"id": 3392,
		"level": 2,
		"name": "盐池县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3388,
		"id": 3393,
		"level": 2,
		"name": "同心县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3388,
		"id": 3394,
		"level": 2,
		"name": "青铜峡市"
	}],
	3395: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3395,
		"id": 3397,
		"level": 2,
		"name": "原州区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3395,
		"id": 3398,
		"level": 2,
		"name": "西吉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3395,
		"id": 3399,
		"level": 2,
		"name": "隆德县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3395,
		"id": 3400,
		"level": 2,
		"name": "泾源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3395,
		"id": 3401,
		"level": 2,
		"name": "彭阳县"
	}],
	3402: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3402,
		"id": 3404,
		"level": 2,
		"name": "沙坡头区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3402,
		"id": 3405,
		"level": 2,
		"name": "中宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3402,
		"id": 3406,
		"level": 2,
		"name": "海原县"
	}],
	3408: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3408,
		"id": 3410,
		"level": 2,
		"name": "天山区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3408,
		"id": 3411,
		"level": 2,
		"name": "沙依巴克区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3408,
		"id": 3412,
		"level": 2,
		"name": "新市区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3408,
		"id": 3413,
		"level": 2,
		"name": "水磨沟区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3408,
		"id": 3414,
		"level": 2,
		"name": "头屯河区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3408,
		"id": 3415,
		"level": 2,
		"name": "达坂城区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3408,
		"id": 3416,
		"level": 2,
		"name": "米东区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3408,
		"id": 3417,
		"level": 2,
		"name": "乌鲁木齐县"
	}],
	3418: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3418,
		"id": 3420,
		"level": 2,
		"name": "独山子区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3418,
		"id": 3421,
		"level": 2,
		"name": "克拉玛依区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3418,
		"id": 3422,
		"level": 2,
		"name": "白碱滩区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3418,
		"id": 3423,
		"level": 2,
		"name": "乌尔禾区"
	}],
	3424: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3424,
		"id": 3425,
		"level": 2,
		"name": "吐鲁番市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3424,
		"id": 3426,
		"level": 2,
		"name": "鄯善县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3424,
		"id": 3427,
		"level": 2,
		"name": "托克逊县"
	}],
	3428: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3428,
		"id": 3429,
		"level": 2,
		"name": "哈密市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3428,
		"id": 3430,
		"level": 2,
		"name": "巴里坤哈萨克自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3428,
		"id": 3431,
		"level": 2,
		"name": "伊吾县"
	}],
	3432: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3432,
		"id": 3433,
		"level": 2,
		"name": "昌吉市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3432,
		"id": 3434,
		"level": 2,
		"name": "阜康市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3432,
		"id": 3435,
		"level": 2,
		"name": "呼图壁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3432,
		"id": 3436,
		"level": 2,
		"name": "玛纳斯县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3432,
		"id": 3437,
		"level": 2,
		"name": "奇台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3432,
		"id": 3438,
		"level": 2,
		"name": "吉木萨尔县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3432,
		"id": 3439,
		"level": 2,
		"name": "木垒哈萨克自治县"
	}],
	3440: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3440,
		"id": 3441,
		"level": 2,
		"name": "博乐市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3440,
		"id": 3442,
		"level": 2,
		"name": "精河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3440,
		"id": 3443,
		"level": 2,
		"name": "温泉县"
	}],
	3444: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3444,
		"id": 3445,
		"level": 2,
		"name": "库尔勒市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3444,
		"id": 3446,
		"level": 2,
		"name": "轮台县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3444,
		"id": 3447,
		"level": 2,
		"name": "尉犁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3444,
		"id": 3448,
		"level": 2,
		"name": "若羌县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3444,
		"id": 3449,
		"level": 2,
		"name": "且末县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3444,
		"id": 3450,
		"level": 2,
		"name": "焉耆回族自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3444,
		"id": 3451,
		"level": 2,
		"name": "和静县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3444,
		"id": 3452,
		"level": 2,
		"name": "和硕县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3444,
		"id": 3453,
		"level": 2,
		"name": "博湖县"
	}],
	3454: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3454,
		"id": 3455,
		"level": 2,
		"name": "阿克苏市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3454,
		"id": 3456,
		"level": 2,
		"name": "温宿县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3454,
		"id": 3457,
		"level": 2,
		"name": "库车县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3454,
		"id": 3458,
		"level": 2,
		"name": "沙雅县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3454,
		"id": 3459,
		"level": 2,
		"name": "新和县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3454,
		"id": 3460,
		"level": 2,
		"name": "拜城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3454,
		"id": 3461,
		"level": 2,
		"name": "乌什县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3454,
		"id": 3462,
		"level": 2,
		"name": "阿瓦提县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3454,
		"id": 3463,
		"level": 2,
		"name": "柯坪县"
	}],
	3464: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3464,
		"id": 3465,
		"level": 2,
		"name": "阿图什市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3464,
		"id": 3466,
		"level": 2,
		"name": "阿克陶县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3464,
		"id": 3467,
		"level": 2,
		"name": "阿合奇县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3464,
		"id": 3468,
		"level": 2,
		"name": "乌恰县"
	}],
	3469: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3470,
		"level": 2,
		"name": "喀什市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3471,
		"level": 2,
		"name": "疏附县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3472,
		"level": 2,
		"name": "疏勒县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3473,
		"level": 2,
		"name": "英吉沙县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3474,
		"level": 2,
		"name": "泽普县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3475,
		"level": 2,
		"name": "莎车县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3476,
		"level": 2,
		"name": "叶城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3477,
		"level": 2,
		"name": "麦盖提县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3478,
		"level": 2,
		"name": "岳普湖县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3479,
		"level": 2,
		"name": "伽师县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3480,
		"level": 2,
		"name": "巴楚县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3469,
		"id": 3481,
		"level": 2,
		"name": "塔什库尔干塔吉克自治县"
	}],
	3482: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3482,
		"id": 3483,
		"level": 2,
		"name": "和田市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3482,
		"id": 3484,
		"level": 2,
		"name": "和田县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3482,
		"id": 3485,
		"level": 2,
		"name": "墨玉县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3482,
		"id": 3486,
		"level": 2,
		"name": "皮山县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3482,
		"id": 3487,
		"level": 2,
		"name": "洛浦县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3482,
		"id": 3488,
		"level": 2,
		"name": "策勒县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3482,
		"id": 3489,
		"level": 2,
		"name": "于田县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3482,
		"id": 3490,
		"level": 2,
		"name": "民丰县"
	}],
	3491: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3492,
		"level": 2,
		"name": "伊宁市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3493,
		"level": 2,
		"name": "奎屯市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3494,
		"level": 2,
		"name": "伊宁县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3495,
		"level": 2,
		"name": "察布查尔锡伯自治县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3496,
		"level": 2,
		"name": "霍城县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3497,
		"level": 2,
		"name": "巩留县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3498,
		"level": 2,
		"name": "新源县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3499,
		"level": 2,
		"name": "昭苏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3500,
		"level": 2,
		"name": "特克斯县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3491,
		"id": 3501,
		"level": 2,
		"name": "尼勒克县"
	}],
	3502: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3502,
		"id": 3503,
		"level": 2,
		"name": "塔城市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3502,
		"id": 3504,
		"level": 2,
		"name": "乌苏市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3502,
		"id": 3505,
		"level": 2,
		"name": "额敏县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3502,
		"id": 3506,
		"level": 2,
		"name": "沙湾县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3502,
		"id": 3507,
		"level": 2,
		"name": "托里县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3502,
		"id": 3508,
		"level": 2,
		"name": "裕民县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3502,
		"id": 3509,
		"level": 2,
		"name": "和布克赛尔蒙古自治县"
	}],
	3510: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3510,
		"id": 3511,
		"level": 2,
		"name": "阿勒泰市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3510,
		"id": 3512,
		"level": 2,
		"name": "布尔津县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3510,
		"id": 3513,
		"level": 2,
		"name": "富蕴县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3510,
		"id": 3514,
		"level": 2,
		"name": "福海县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3510,
		"id": 3515,
		"level": 2,
		"name": "哈巴河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3510,
		"id": 3516,
		"level": 2,
		"name": "青河县"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3510,
		"id": 3517,
		"level": 2,
		"name": "吉木乃县"
	}],
	3518: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3518,
		"id": 3519,
		"level": 2,
		"name": "石河子市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3518,
		"id": 3520,
		"level": 2,
		"name": "阿拉尔市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3518,
		"id": 3521,
		"level": 2,
		"name": "图木舒克市"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3518,
		"id": 3522,
		"level": 2,
		"name": "五家渠市"
	}],
	3523: [{
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3523,
		"id": 3524,
		"level": 2,
		"name": "东城区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3523,
		"id": 3525,
		"level": 2,
		"name": "西城区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3523,
		"id": 3526,
		"level": 2,
		"name": "崇文区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3523,
		"id": 3527,
		"level": 2,
		"name": "宣武区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3523,
		"id": 3528,
		"level": 2,
		"name": "朝阳区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3523,
		"id": 3529,
		"level": 2,
		"name": "丰台区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3523,
		"id": 3530,
		"level": 2,
		"name": "石景山区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3523,
		"id": 3531,
		"level": 2,
		"name": "海淀区"
	}],
	3532: [{
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3533,
		"level": 2,
		"name": "门头沟区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3535,
		"level": 2,
		"name": "房山区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3536,
		"level": 2,
		"name": "通州区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3537,
		"level": 2,
		"name": "顺义区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3538,
		"level": 2,
		"name": "昌平区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3539,
		"level": 2,
		"name": "大兴区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3540,
		"level": 2,
		"name": "怀柔区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3541,
		"level": 2,
		"name": "平谷区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3542,
		"level": 2,
		"name": "密云县"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3532,
		"id": 3543,
		"level": 2,
		"name": "延庆县"
	}],
	3544: [{
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3544,
		"id": 3545,
		"level": 2,
		"name": "朝阳区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3544,
		"id": 3546,
		"level": 2,
		"name": "丰台区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3544,
		"id": 3547,
		"level": 2,
		"name": "石景山区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3544,
		"id": 3548,
		"level": 2,
		"name": "海淀区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3544,
		"id": 3549,
		"level": 2,
		"name": "昌平区（天通苑、回龙观社区）"
	}],
	3550: [{
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3551,
		"level": 2,
		"name": "清华大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3552,
		"level": 2,
		"name": "北京大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3553,
		"level": 2,
		"name": "北京航空航天大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3554,
		"level": 2,
		"name": "北京邮电大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3555,
		"level": 2,
		"name": "北京交通大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3556,
		"level": 2,
		"name": "中国人民大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3557,
		"level": 2,
		"name": "北京林业大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3558,
		"level": 2,
		"name": "中国农业大学（东区）"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3559,
		"level": 2,
		"name": "北京大学医学部"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3560,
		"level": 2,
		"name": "北京科技大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3561,
		"level": 2,
		"name": "北京理工大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3562,
		"level": 2,
		"name": "中央财经大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3563,
		"level": 2,
		"name": "地质大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3564,
		"level": 2,
		"name": "北京外国语大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3565,
		"level": 2,
		"name": "中科院（限中关村）"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3566,
		"level": 2,
		"name": "大运村"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3567,
		"level": 2,
		"name": "万柳学生公寓"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3568,
		"level": 2,
		"name": "北京师范大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3569,
		"level": 2,
		"name": "中国政法大学（海淀区）"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3570,
		"level": 2,
		"name": "中央民族大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3571,
		"level": 2,
		"name": "矿业大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3572,
		"level": 2,
		"name": "北京联大应用文理学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3573,
		"level": 2,
		"name": "北京联大电子工程学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3574,
		"level": 2,
		"name": "北京联大信息学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3575,
		"level": 2,
		"name": "北京联大管理学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3576,
		"level": 2,
		"name": "北京联大自动化学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3577,
		"level": 2,
		"name": "北京气象学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3578,
		"level": 2,
		"name": "北京化工大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3579,
		"level": 2,
		"name": "北京中医药大学（西区）"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3580,
		"level": 2,
		"name": "北京服装学院（不含昌平校区）"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3581,
		"level": 2,
		"name": "对外经济贸易大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3582,
		"level": 2,
		"name": "首都体育学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3583,
		"level": 2,
		"name": "中华女子学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3584,
		"level": 2,
		"name": "北京语言大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3585,
		"level": 2,
		"name": "农科院（蜜蜂所、原子能所除外）"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3586,
		"level": 2,
		"name": "中国社会科学院民族所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3587,
		"level": 2,
		"name": "中国人民银行研究生部"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3588,
		"level": 2,
		"name": "北京有色金属研究总院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3589,
		"level": 2,
		"name": "钢铁研究总院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3590,
		"level": 2,
		"name": "北京电影学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3591,
		"level": 2,
		"name": "首都师范大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3592,
		"level": 2,
		"name": "北京工商大学（不含房山校区）"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3593,
		"level": 2,
		"name": "中冶集团建筑研究总院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3594,
		"level": 2,
		"name": "北京大学技术物理楼"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3595,
		"level": 2,
		"name": "中科院半导体所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3596,
		"level": 2,
		"name": "中国青年政治学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3597,
		"level": 2,
		"name": "生态环境研究中心"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3598,
		"level": 2,
		"name": "青藏高原所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3599,
		"level": 2,
		"name": "北京信息科技大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3600,
		"level": 2,
		"name": "中科院微电子所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3601,
		"level": 2,
		"name": "大气物理所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3602,
		"level": 2,
		"name": "北京城学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3603,
		"level": 2,
		"name": "北京电子科技学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3604,
		"level": 2,
		"name": "北京电子科技职业学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3605,
		"level": 2,
		"name": "北京工业大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3606,
		"level": 2,
		"name": "北京工业大学艺术设计学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3607,
		"level": 2,
		"name": "北京广播电视大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3608,
		"level": 2,
		"name": "北京建筑工程学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3609,
		"level": 2,
		"name": "北京教育学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3610,
		"level": 2,
		"name": "北京金融学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3611,
		"level": 2,
		"name": "北京控制工程研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3612,
		"level": 2,
		"name": "北京矿冶研究总院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3613,
		"level": 2,
		"name": "北京联合大学特殊教育学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3614,
		"level": 2,
		"name": "北京师范大学继续教育与教师培训学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3615,
		"level": 2,
		"name": "北京电气工程学校"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3616,
		"level": 2,
		"name": "北京自动化工程学校"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3617,
		"level": 2,
		"name": "北京体育大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3618,
		"level": 2,
		"name": "北京舞蹈学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3619,
		"level": 2,
		"name": "北京戏曲艺术职业学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3620,
		"level": 2,
		"name": "北京信息管理学校"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3621,
		"level": 2,
		"name": "北京信息控制研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3622,
		"level": 2,
		"name": "北京艺术设计职业学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3623,
		"level": 2,
		"name": "北京应用技术大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3624,
		"level": 2,
		"name": "北京应用物理与计算数学研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3625,
		"level": 2,
		"name": "财政部财政科学研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3626,
		"level": 2,
		"name": "电信科学技术研究院研究生部"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3627,
		"level": 2,
		"name": "国际关系学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3628,
		"level": 2,
		"name": "华北计算机系统工程研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3629,
		"level": 2,
		"name": "机械工业自动化研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3630,
		"level": 2,
		"name": "机械科学研究院北京机械工业自动化研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3631,
		"level": 2,
		"name": "商务部国际贸易经济合作研究院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3632,
		"level": 2,
		"name": "石油勘探开发研究院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3633,
		"level": 2,
		"name": "首都经济贸易大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3634,
		"level": 2,
		"name": "首都医科大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3636,
		"level": 2,
		"name": "首钢工学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3637,
		"level": 2,
		"name": "铁道科学研究院研究生部"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3638,
		"level": 2,
		"name": "外交学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3639,
		"level": 2,
		"name": "协和医科大学医药生物技术所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3640,
		"level": 2,
		"name": "冶金自动化研究设计院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3641,
		"level": 2,
		"name": "中共中央党校"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3642,
		"level": 2,
		"name": "中国测绘科学研究院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3643,
		"level": 2,
		"name": "中国传媒大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3644,
		"level": 2,
		"name": "中国地震局地球物理研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3645,
		"level": 2,
		"name": "中国地震局地质研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3646,
		"level": 2,
		"name": "中国地质科学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3647,
		"level": 2,
		"name": "中国电力科学研究院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3648,
		"level": 2,
		"name": "中国电力科学研究院研究生部"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3649,
		"level": 2,
		"name": "中国钢研科技集团公司"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3651,
		"level": 2,
		"name": "中国工程物理研究院北京研究生部"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3652,
		"level": 2,
		"name": "中国管理软件学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3653,
		"level": 2,
		"name": "中国建筑设计研究院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3654,
		"level": 2,
		"name": "中国建筑设计研究院研究生部"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3655,
		"level": 2,
		"name": "中国劳动关系学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3656,
		"level": 2,
		"name": "中国农业科学院研究生院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3657,
		"level": 2,
		"name": "中国人民公安大学木樨地校区"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3658,
		"level": 2,
		"name": "中国人民解放军国防大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3659,
		"level": 2,
		"name": "中国人民解放军后勤指挥学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3660,
		"level": 2,
		"name": "中国人民解放军军事医学科学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3661,
		"level": 2,
		"name": "中国人民解放军陆军航空兵学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3662,
		"level": 2,
		"name": "中国人民解放军艺术学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3663,
		"level": 2,
		"name": "中国戏曲学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3664,
		"level": 2,
		"name": "中国协和医科大学药物研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3665,
		"level": 2,
		"name": "中国信息产业部电子第六研究所"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3666,
		"level": 2,
		"name": "中国艺术研究院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3667,
		"level": 2,
		"name": "中国音乐学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3668,
		"level": 2,
		"name": "中国政法大学研究生部"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3669,
		"level": 2,
		"name": "中央美术学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3670,
		"level": 2,
		"name": "中央戏剧学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3671,
		"level": 2,
		"name": "中央音乐学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3672,
		"level": 2,
		"name": "装甲兵工程学院"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3673,
		"level": 2,
		"name": "协和医科大学"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3550,
		"id": 3674,
		"level": 2,
		"name": "中国钢研科技集团公司（钢铁研究总院）研究生部"
	}],
	3675: [],
	3676: [],
	3677: [],
	3678: [],
	3679: [],
	3680: [],
	3681: [],
	3682: [],
	3687: [],
	3688: [],
	3689: [],
	3690: [],
	3691: [],
	3692: [],
	3693: [],
	3694: [],
	3695: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3775,
		"level": 2,
		"name": "干河街道"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3776,
		"level": 2,
		"name": "龙华山街道"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3777,
		"level": 2,
		"name": "沙嘴街道"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3778,
		"level": 2,
		"name": "郑场镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3779,
		"level": 2,
		"name": "毛嘴镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3780,
		"level": 2,
		"name": "剅河镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3781,
		"level": 2,
		"name": "三伏潭镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3782,
		"level": 2,
		"name": "胡场镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3783,
		"level": 2,
		"name": "长埫口镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3784,
		"level": 2,
		"name": "西流河镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3785,
		"level": 2,
		"name": "彭场镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3786,
		"level": 2,
		"name": "沙湖镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3787,
		"level": 2,
		"name": "杨林尾镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3788,
		"level": 2,
		"name": "张沟镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3789,
		"level": 2,
		"name": "郭河镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3790,
		"level": 2,
		"name": "沔城回族镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3791,
		"level": 2,
		"name": "通海口镇"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3695,
		"id": 3792,
		"level": 2,
		"name": "陈场镇"
	}],
	3696: [],
	3697: [],
	3698: [],
	3699: [],
	3700: [],
	3701: [],
	3702: [],
	3703: [],
	3704: [],
	3705: [],
	3706: [],
	3707: [],
	3708: [],
	3709: [],
	3710: [],
	3711: [],
	3712: [],
	3713: [],
	3722: [],
	3772: [],
	3794: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3794,
		"id": 3795,
		"level": 0,
		"name": "台湾"
	}],
	3796: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3796,
		"id": 3714,
		"level": 1,
		"name": "九龙"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3796,
		"id": 3715,
		"level": 1,
		"name": "香港特别行政区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3796,
		"id": 3716,
		"level": 1,
		"name": "新界东"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3796,
		"id": 3717,
		"level": 1,
		"name": "新界西"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3796,
		"id": 3718,
		"level": 1,
		"name": "香港岛"
	}],
	3797: [{
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3797,
		"id": 3719,
		"level": 1,
		"name": "澳门特别行政区"
	}, {
		"active": 1,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3797,
		"id": 3720,
		"level": 1,
		"name": "澳门半岛"
	}, {
		"active": 0,
		"allFiledsKeys": [],
		"allFiledsValues": [],
		"fatherId": 3797,
		"id": 3721,
		"level": 1,
		"name": "澳门离岛"
	}]
}

export default {
  citys,
  provinces,
  areas
}
