<template>
  <lDfault title="我的反馈">
    <van-list
      v-model="loading"
      :finished="finished"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      @load="onLoad"
    >
      <div
        class="feed-panel shadow"
        v-for="(item, index) in table"
        :key="index"
      >
        <div class="ask">
          <div class="content text-lg font-semibold ">{{ item.content }}</div>

          <div class="flex  text-black-300 justify-between">
            <span> {{ item.title }}</span> <span> {{ item.type }} </span>
          </div>
          <div class=" text-black-300">
            <!-- <span>{{ item.cdate }}</span> -->
          </div>
        </div>
        <div >
          <hr />
          <div v-if="!item.answer" class="tac pd-v text-black-900">-- 暂无回复 --</div>
          <div v-else class="answer text-black-900 ">
            <div class="pd-b">
            <div class="mg-b text-black text-lg"> 客服回复：</div>{{ item.answer }}
            </div>
            <div class="text-black-500 flex justify-end">
              {{ item.adate }}
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </lDfault>
</template>

<script>
import lDfault from "@/templates/Default.vue";
import { rqGetComplaintsAll } from "@/api";

export default {
  components: {
    lDfault,
  },
  data() {
    return {
      table: [],
      dataCount: "",
      page: 1,
      loading: false,
      finished: false,
      error: false,
    };
  },
  // mounted() {
  //     rqGetComplaintsAll({page:this.page}).then(res=>{
  //       this.table=res.table;
  //       this.dataCount=res.dataCount;
  //     })
  // },
  methods: {
    onLoad() {
      // 异步更新数据
      rqGetComplaintsAll({ page: this.page }).then((res) => {
        /*      if (res.code!=200){
                        this.error = true;
                        return
                    }*/
        if (res.table.length != 0) {
          this.table = res.table;
          this.dataCount = res.dataCount;
        } else {
          this.finished = true;
        }
        this.loading = false;
      });
      this.page++;
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-panel {
  background: white;
  margin: 10px;
  border-radius: 4px;

  .ask,
  .answer {
    padding: 10px;
  }

  .ask {
    .content {
      margin: 8px 0 4px;
    }
  }
}
</style>
