<template>
  <div class="home">
        <router-view :key='key' />
    <!-- <div style="height:50px"></div> -->
    <van-tabbar v-if="$route.meta.showTab" route :placeholder='true'>
      <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/category" icon="qr">分类</van-tabbar-item>
      <van-tabbar-item replace to="/shoppingCart" icon="shopping-cart-o" :badge="$store.state.cartCount">购物车</van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  computed:{
    key(){
      return this.$route.name?this.$route.name+new Date():this.$route+new Date();
    }
  }
}
</script>

<style>
</style>
