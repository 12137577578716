<template>
  <div class="order-card mg-b">
    <div class="bd-radius-6 pd shadow pl-4 bg-white text-base font-bold">
      {{ orderInfo.shpoId === 1 ? "蔚蓝书店" : "教材" }}
    </div>
    <GoodsSummary
      :goodsList="orderInfo.itemInfo"
      @click="getGoods"
    ></GoodsSummary>
    <Invoice v-if="orderInfo.giftList" v-on="$listeners"></Invoice>
    <Coupon
    v-if="hasCoupon"
      :usecouponList="orderInfo.couponListJS"
      :unUsecouponList="orderInfo.unUsecouponList"
      v-on="$listeners"
    ></Coupon>
    <GiftPanel
    v-bind='$attrs'
      v-if="hasGiftList"
      v-on="$listeners"
      :useGiftUnpaidMoney='useGiftUnpaidMoney'
      :giftList="orderInfo.giftList | giftList"
    ></GiftPanel>
    <Balance
     :useGiftUnpaidMoney='useGiftUnpaidMoney'
      v-if="hasBalance"
      v-on="$listeners"
      :balanceable="orderInfo.balance"
    ></Balance>
    <OrderMessage v-if="orderInfo.giftList" v-on="$listeners"></OrderMessage>
    <van-cell id="freight" title="运费"> {{ orderInfo.sendFee===0?'包邮':orderInfo.sendFee }} </van-cell>
    <van-cell id="totalPrice" title="商品总价">
      <span class="text-xs">￥</span>{{ orderInfo.sumPrice | money }}
    </van-cell>
  </div>
</template>

<script>
import GoodsSummary from "./GoodsSummary.vue";
import Coupon from "@/components/Coupon.vue";
import Invoice from "./Invoice.vue";
import OrderMessage from "./OrderMessage.vue";
import Balance from "./Balance.vue";

import GiftPanel from "./GiftPanel.vue";
export default {
  name: "OrderCard",
  components: {
    GoodsSummary,
    Coupon,
    Invoice,
    OrderMessage,
    GiftPanel,
    Balance,
  },
  props: {
    orderInfo: {
      type: Object,
    },
    useGiftUnpaidMoney:Number
  },

  filters: {
    giftList: function(value) {
      return value.map((item) => {
        return {
          id:item.id,
          actTime: item.actTime,
          cardNum: item.cardNum,
          remain: item.aumonut,
          state: "用户已激活",
          valid: item.validTime,
          value: item.cardmoney,
        };
      });
    },
  },
  computed: {
    hasCoupon() {
      return this.orderInfo.couponListJS?.length||this.orderInfo.unUsecouponList?.length;
    },
    hasGiftList() {
      return this.orderInfo.giftList?.length;
    },
    hasBalance() {
      return this.orderInfo.balance;
    },
  },
  created() {
    console.log(this.$listeners);
  },
  methods: {
    getGoods(couponId, couponMoney) {
      this.couponId = couponId;
      this.couponMoney = couponMoney;
    },
  },
};
</script>

<style></style>
