import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vant,{obj} from './utils/vant'
import store from './store'
import utils from './utils/utils';
import dayjs from 'dayjs';
import VueClipboard from "vue-clipboard2";
import wxShare from './utils/wxShare';
import 'vue-popperjs/dist/vue-popper.css';
Vue.use(VueClipboard);
Vue.use(utils);//将全局函数当做插件来进行注册
Vue.use(vant)
Vue.use(obj)

Vue.prototype.$wxShare=wxShare
Vue.prototype.$getPicture =(url,local=false)=> {
  if(local){
    return require(url)
  }else{
    return process.env.VUE_APP_IMG_BASE1+url
  }
}

// 接受一个请求方法
// Vue.prototype.$lock =(func)=> {
//   console.log(func)
//   let lock=false
//   return fun
//   if(!lock){
//     lock=true
//     console.log('====')
//     return
//     func
//   }
// }


// Vue.config.productionTip = false

// 加零
Vue.filter('money', function (value) {
  const temp = (value??0).toString().split(".");
  if (temp.length === 1) {
    temp.push("00");
  }else if(temp[1].length===1){
    temp[1]+=0
  }
  return temp.join('.');
})
Vue.filter('discount', function (value) {
  return value/10
})
Vue.filter('zero', function (value) {
  return value??0
})
// 过滤时间 yyyy-mm-dd
Vue.filter('date',function(date){
  return dayjs(date).format("YYYY-MM-DD")
})
// 过滤时间 yyyy-mm-dd HH-mm-ss
Vue.filter('datetime',function(date){
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss")
})

Vue.mixin({
  methods:{
    getImg(url) {
      return require(`@/images/${url}`);
    },
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
