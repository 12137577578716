<template>
  <lDfault title="客服/帮助">
    
    <!-- <van-cell title="常见问题" is-link to="goFeedback" /> -->
    <van-cell class="mg-t" title="意见/反馈" is-link to="goFeedback" />
    <van-cell class="mg-t" title="我的反馈" is-link to="myFeedback" />


    <div v-for="(item,index) in customerServices" :key="index">
      <div class="feedback">
        <div class="left">
          <icon size='50px' title='phone-signal'></icon>
        </div>
        <div class="right">
          <div class="text-black-600">
            客服电话：<span class="text-blue-500">{{item.servicePhone}}</span>
          </div>
          <div class="service-text text-black-300">{{item.serviceClues}}</div>
        </div>
      </div>
    </div>
    <div v-for="(item,index) in customerServicesEmail" :key="index">
      <div class="feedback">
        <div class="left">
          <icon size='50px' title='emailmarketing'></icon>
        </div>
        <div class="right">
          <div class="text-black-600">
            投诉邮箱：<span class="text-blue-500">{{item.complaintsEmail}}</span>
          </div>
          <div class="service-text text-black-300">{{item.serviceClues}}</div>
        </div>
      </div>
    </div>

     <!-- <div class="feedback">
      <div class="left">
        <icon title='dianhua'></icon>
      </div>
      <div class="right">
        <div class="text">
          团购热线：18888888888
        </div>
        <div>团购更优惠</div>
      </div>
    </div>
       <div class="feedback">
      <div class="left">
        <icon title='dianhua'></icon>
      </div>
      <div class="right">
        <div class="text">
          投诉信箱：18888888888@qq.com
        </div>
        <div>周一到周日，30小时竭诚为您服务</div>
      </div>
    </div> -->
  </lDfault>
</template>

<script>
// @ is an alias to /src

import lDfault from "@/templates/Default.vue";
import icon from "@/components/Icon.vue";
import {rqGetServicePhone,rqGetServiceEmail}from "@/api";

export default {
  components: {
      lDfault,
    icon,
  },
  data(){
    return {
      //客服
      customerServices:[],
      customerServicesEmail:[],
      index:"",
    }
  },
  mounted() {
    rqGetServicePhone().then(res=>{
      this.customerServices=res
    })
    rqGetServiceEmail().then(res=>{
      this.customerServicesEmail=res
    })
  }
}
</script>

<style lang='scss' scoped>
.van-cell{
  font-size: 16px;
  padding: 20px 10px;
}
.feedback{
  display: flex;background-color: white;
  padding: 16px;
  margin: 5px 0;
  .left{
    margin-left: 10px;
  }
  .right{
    margin-left: 25px;
  }
  .service-text{
    margin-top: 15px;
  }
}
</style>
