export default {
    state:{
        currentAddressId: sessionStorage.getItem('currentAddressId')
    },
    mutations:{
        set_currentAddress(state,addressId){
            state.currentAddressId=addressId
            sessionStorage.setItem('currentAddressId',addressId)
        }
    }
}



