<template>
    <div @click.stop @scroll.stop class="overlay">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name:"BaseOverlay"
    }
</script>

<style lang="scss" scoped>
.overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color:rgba(0, 0, 0, 0.3) ;
}
</style>