import request from "@/utils/request";

//查询是否到校发放方式
export function getGrantMethod(params) {
    return request({
        url: "/miniapp/consumer",
        method: "get",
        params
    });
}

//提交订单
export function createOrder(params) {
    return request({
        url: "/mobile/order/jc/save",
        method: "post",
        params
    });
}
