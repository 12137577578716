<template>
  <div class="dialog">
      <slot>

      </slot>
    <div class="footer">
      <div @click='$emit("cancel")' class="cancel">取消</div>
      <div @click='$emit("confirm")' class="confirm">确认</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDialog",
};
</script>

<style lang="scss" scoped>

.dialog {
  position: fixed;
  z-index: 1000;
  top: 45%;
  left: 50%;
  width: 85.333vw;
  overflow: hidden;
  font-size: 4.267vw;
  background-color: #fff;
  border-radius: 4.267vw;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;

  .footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;

    .button {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      height: 11.733vw;
      margin: 0;
      padding: 0;
      font-size: 4.267vw;
      line-height: 1.2;
      text-align: center;
      border-radius: 0.533vw;
      cursor: pointer;
      -webkit-transition: opacity 0.2s;
      transition: opacity 0.2s;
      -webkit-appearance: none;
    }
    .cancel,.confirm{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cancel {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      height: 12.8vw;
      margin: 0;
      border: 0;
    }
    .confirm {
      color: #ee0a24;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      height: 12.8vw;
      margin: 0;
      border: 0;
    }
  }
}
</style>
