<template>
  <div class="recommend">
    <slot name="title">
      <h3 class="modal-title tac">-- 精品推荐 --</h3>
    </slot>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <!-- <router-link to="bookDetail" >asdf</router-link> -->
      <div class="list">
        <div
          class="book-item2"
          v-for="(item, index) in list"
          :key="index"
          data-skuId="1"
          @click="goBookDetail(item.skuId)"
        >
          <div class="box">
            <van-image width="185" height="186" fit="cover" :src="item.img" />
            <div class="subbox">
              <div class="book-title2 truncate-2">{{ item.name }}</div>
              <div class="book-money pre-money flex-j-b">
                <Money class="m-money" :money="'' + item.wl_price"></Money>
                <van-goods-action-icon
                  icon="cart-o"
                  color="red"
                  @click.native.stop="addShoppingCart(item.skuId)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
// @ is an alias to /src
import Money from "@/components/Money.vue";
import { rqRecommend } from "@/api";
// import icon from "@/components/Icon.vue";
export default {
  name: "Recommend",
  components: {
    // icon,
    Money,
  },
  props: {
    refresh: {
      default: "",
      type: String,
      validator: (value) => {
        return ["", "shoppingCart"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      list: [],
      pageNum: 1,
      loading: false,
      finished: false,
    };
  },
  methods: {
    async addShoppingCart(skuId) {
      await this.$addShoppingCart(skuId);
      this.$emit("refresh");
    },
    goBookDetail(skuId) {
      // const skuId=e.currentTarget.dataset.skuid
      this.$router.push({ name: "BookDetail", params: { skuId } });
    },
    async onLoad() {
      this.loading = false;
      const res = await rqRecommend({ pageNum: this.pageNum++ });
      if (res.bookList.length < 10) {
        console.log(123)
        this.finished = true;
      }
      this.list.splice(this.list.length, 0, ...res.bookList);
    },
  },
};
</script>
<style>
.recommend .van-icon.van-icon-cart-o.van-goods-action-icon__icon {
  color: red;
  border: 1px solid red;
  padding: 2px 2px 0;
  border-radius: 6px;
  /* width: 100%; */
}
</style>
<style lang="scss" scoped>
.m-money {
  line-height: 1.8;
}
.recommend {
  .list {
    display: flex;
    flex-wrap: wrap;
    .book-item2 {
      min-height: 200px;
      width: calc(50% - 7.5px);
      box-sizing: border-box;
      // padding-bottom: 6px;
      border-radius: 6px;
      overflow: hidden;
      margin: 5px;
      margin-top: 0;
      &:nth-child(2n + 1) {
        // padding-right: 6px;
        margin-right: 0;
      }
      .box {
        background-color: white;
        .subbox {
          padding: 7px;
          .book-title2 {
            height: 32px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
</style>
