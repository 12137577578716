<template>
  <lDfault title="快速注册">
    <mcode
      @imgCodePassed="imgCodePassed"
      @click="codeUpdate"
      :valiImg="imgCode"
      :show="showCode"
    ></mcode>
    <!-- <toast text='该手机号已经注册过了，请换一个手机号'></toast> -->
    <main class="sign-up">
      <h3>用手机号注册</h3>
      <van-field v-model="phone" label="手机号" placeholder="请输入手机号" />

      <van-button
        :disabled="!isOk"
        class="submit"
        type="primary"
        @click="onSubmit"
        block
        >立即注册</van-button
      >
    </main>
  </lDfault>
</template>

<script>
import validator from "validator";
// @ is an alias to /src
import mcode from "./children/Code";
import { isRegister,getImgCode } from "@/api";
import lDfault from "@/templates/Default.vue";
// import toast from "@/components/Toast.vue";
// import icon from "@/components/Icon.vue";
export default {
  components: {
    lDfault,mcode
    // icon,
  },
  data() {
    return {
      phone: "",
      isOk: false,
      imgCode: {},
      showCode: false,
    };
  },
  watch: {
    phone(nv) {
      this.isOk = nv.length ? true : false;
    },
  },
  methods: {
    imgCodePassed(code) {

   sessionStorage.setItem('verify',JSON.stringify({
          way:'signup',
          phone: this.phone,
          getCode: 'getRegisterCode',
          handler: 'loginPhone',
          imgCodeValue: code,
          nextStation:{name:"ModifyPasswd",params:{phone:this.phone}},
           textGroup: {
            title: "校验验证码",
            subTitle: "",
            placeholder: "请输入验证码",
            button: "校验",
          },
        },))


      // rqGetFindpwdCode({ phone: this.phone, veriValidCode: code });
      this.$router.push({
        name: "VerifyCode",
      });
    },
    codeUpdate() {
      this.showCode = false;
    },
    refreshCode() {
      getImgCode().then((res) => {
        this.imgCode = res;
      });
    },
    passedCode(isOk) {
      console.log(isOk);
      this.isOk = isOk;
    },
    onSubmit() {
      if (!validator.isMobilePhone(this.phone, "zh-CN")) {
        this.$toast("请检查你的手机号");
        return;
      }
      isRegister({ phone: this.phone }).then((res) => {
        if (res) {
          // 该手机已经被注册，请更换其它手机
          this.$toast("该手机已经被注册，请更换其它手机");
        } else {
          // go to 注册
           this.showCode=true
           this.refreshCode()
          // getCode({ phone: this.phone }).then((res) => {
          //   console.log(res);
          // });
          // this.$router.push("signup2/" + this.phone);
          // this.$router.push("signup2/" + this.phone);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-up {
  padding: 0 30px;
  .submit {
    margin-top: 20px;
  }
}
</style>
