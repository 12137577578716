import request from "@/utils/request";
// 查询收货地址列表
  export function rqGetAddressList() {
    return request({
      url: '/mobile/vip/personal/adress/list',
      method: 'get',
    })
}

// 设为默认收货地址
// addressID
export function rqSetAddressDefault(params) {
    return request({
        url: '/mobile/vip/personal/adress/default',
        method: 'post',
        params
    })
}

// 删除收货地址
export function rqDeleteAddress(params) {
    return request({
      url: '/mobile/vip/personal/adress/delete',
      method: 'post',
      params
    })
}

// 添加一个地址
export function rqAddAdreess(data,loading=true) {
    return request({
      url: '/mobile/vip/personal/adress/add',
      method: 'post',
      params:data,
      loading
    })
}

// 获取 一个 地址详情 ，用于编辑
export function rqGetAddressOne(params) {
    return request({
        url: '/mobile/vip/personal/adress/one',
        method: 'get',
        params
    })
}

// 编辑一个收货地址
// name 收货人姓名
// phoneNum
// provincesID
// cityID
// areaID
// adress
// default
// postalCode

export function rqEditAddress(params) {
    return request({
        url: '/mobile/vip/personal/adress/edit',
        method: 'post',
        params,
    })
}
// | shopId        |  店铺ID                             |     必须    | Integer  |        |
// | recieveId    |收货地址ID                           |     必须    | Integer  |        |
// http://192.168.0.17:8181/mobile/order/countFreight?shopId=1&recieveId=123456789
export function rqCountFreight(params) {
    return request({
        url: '/mobile/order/countFreight',
        method: 'post',
        params,
    })
}
// {
//     "wlFreight"：50.55
//  }
