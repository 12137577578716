import axios from "axios";
import Vue from "vue";
import { Toast } from "vant";
import store from "@/store";
import router from "../router/index";

Vue.use(Toast);

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  headers: {
    appKey: "wlnet.wxapp",
    accessToken: "21020bg5f46hh1e7b7dbgy888e2fa116c5f8c4abac03eaa322798726",
  },
});

service.interceptors.request.use(
  (config) => {
    console.log("请求 --- ", config);
    const Authorization =
      localStorage.getItem("token") || store.state.user.h5Token;

    // if (config.url.indexOf("mobile/vip") !== -11) {
    config.headers.Authorization = Authorization;
    // }

    // do something before request is sent
    if (config.loading) {
      Toast.loading({
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
    }

    return config;
  },
  (error) => {
    // do something with request error
    console.log("请求错误 --- ", error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */

  (response) => {
    console.log("响应 --- ", response.config.url, response.data);
    Toast.clear();

    const res = response.data;
    const status = res.status || res[0]?.status;
    const msg = res.msg;
    if (response.config.url === "/miniapp/min/login") {
      if (response.status === 200) {
        router.push("ChooseBook");
      }
    }
    if (status === false || status === -1 || status === "500") {
      //false 500
      Toast.fail(msg);
    }
    if (status === -11 && response.config.url !== "/mobile/vip/index") {
      //删除教材登录信息
      localStorage.removeItem("token");
      localStorage.removeItem("uid");
      localStorage.removeItem("consumerCode");
      localStorage.removeItem("classId");
      // 强制登陆
      router.replace({ name: "Login" });
    }
    if (status === true || status === "200") {
      // true 200
      // Toast.success(msg);
    }
    //token失效重新登录
    if (res.state === 555) {
      localStorage.removeItem("token");
      localStorage.removeItem("uid");
      localStorage.removeItem("consumerCode");
      localStorage.removeItem("classId");
      router.push("InputStudents");
    }
    return res;
  },
  (error) => {
    console.warn("响应err ---" + error); // for debug
    // Toast.fail(error.message);
    return Promise.reject(error);
  }
);

export default service;
