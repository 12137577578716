<template>
  <lDfault title="确认订单">
    <van-address-list
      class="tebiedete"
      :list="areaList"
      :switchable="false"
      default-tag-text="默认"
      @click-item="onSelectAddress"
    />

    <div v-if="goodsList.bookList&&goodsList.bookList.length > 0" class="goods">
      <div
        class="flex"
        v-for="(item, index) in goodsList.bookList"
        :key="index"
      >
        <van-card
          class="w-100"
          :price="item.skuPrice | money"
          :title="item.itemName"
          :num='item.buyNum'
        >
          <template #thumb>
            <van-image height="87" width="87" fit="contain" :src="item.img" />
          </template>
        </van-card>
      </div>
    </div>
    <van-cell-group>
      <van-cell title="运费" :value="goodsList.sendFee==0?'包邮':goodsList.sendFee" />
      <van-cell title="优惠价格" :value="goodsList.savePrice" />
      <van-cell title="总定价" :value="goodsList.totalOriginPrice" />
      <van-cell title="总蔚蓝价" :value="goodsList.totalPrice" />
      <van-cell title="实付款(运费+总销售价)" :value="goodsList.realPayment" />
    </van-cell-group>

    <div class="stuffing"></div>
    <van-submit-bar
      :price="goodsList.totalPrice * 100"
      button-text="提交订单"
      @submit="toPay"
    >
    </van-submit-bar>
  </lDfault>
</template>

<script>
import lDfault from "@/templates/Default.vue";
import { getJcCartInfo, createOrder } from "@/api";

export default {
  components: {
    lDfault,
  },
  name: "confirmOrder",
  data() {
    return {
      goodsList: {},
      areaList: [],
      receiverId: "",
    };
  },
  mounted() {
    const data = {
      uid: localStorage.getItem("uid"),
      consumerCode: localStorage.getItem("consumerCode"),
      addressId:this.$store.state.app.currentAddressId||''
    };
    getJcCartInfo(data).then((res) => {
        if(res.status){
            this.goodsList = res;
            if (!this.areaList?.length) {
                this.areaList = res.defaultRecieve;
            }
            this.receiverId = res.defaultRecieve[0].id;
        }else{
          this.goodsList = {totalPrice:0};
          this.$dialog
              .confirm({
                  title: "提示",
                  message: "请填写收货地址",
              })
              .then(() => {
              // on confirm  //确定 进入收货地址系只能
              this.$router.push({
              name: "AddAdress",
              params: {
                  storeId: this.storeId,
                  type: "OrderConfirm",
                  isSelect: true,
              },
              query: this.$route.query,
          });
      })
      .catch(() => {
              // on cancel //取消 返回购物车
              this.$router.push({ name: "JcCart" });
      });
      }

    });
  },
  methods: {
    onSelectAddress() {
      this.$router.push({
        name: "Address",
        params: { type: "ConfirmOrder", storeId: this.storeId },
        query: this.$route.query,
      });
    },

    toPay() {
      let data = {
        receiverId: this.receiverId,
        uid: localStorage.getItem("uid"),
        consumerCode: localStorage.getItem("consumerCode"),
      };
      createOrder(data).then((res) => {
        if (res.flag) {
          this.$router.push({
            name: "JcPayment",
            query: {
              tradeno: res.orderId,
              money: res.money,
            },
          });
        } else {
            this.$dialog
                .confirm({
                    title: "提示",
                    message: "请填写收货地址",
                })
                .then(() => {
                // on confirm  //确定 进入收货地址系只能
                this.$router.push({
                name: "AddAdress",
                params: {
                    storeId: this.storeId,
                    type: "OrderConfirm",
                    isSelect: true,
                },
                query: this.$route.query,
            });
        })
        .catch(() => {
                // on cancel //取消 返回购物车
                this.$router.push({ name: "JcCart" });
        });
        }
      });
    },
  },
};
</script>

<style scoped></style>
