<template>
  <div>
    <div class="shadow order-wrapper">
      <div @click='$push({name:"BookDetail",params:{skuId:order.skuId}})' class="flex">
        <div>
          <img style='object-fit:cover'  height="75" width="75" :src="order.smallImage" alt="" />
        </div>
        <div class="table mg-l">
          <dl>
            <dt>书名：{{ order.bname }}</dt>
            <dt>条码：{{ order.isbn }}</dt>
            <dt>作者：{{ order.authorName }}</dt>
            <dt>出版社：{{ order.brandName }}</dt>
            <dt>共{{ order.buyNum }}件，每件金额 ￥{{ order.wlPrice }}</dt>
          </dl>
        </div>
      </div>
      <div class="bottom tar bd-radius">
        <van-button @click="onBuyGoods" round plain type="primary"
          >再次购买</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { rqAddShoppingCartJC } from "@/api";
export default {
  props: {
    order: Object,
  },
  methods: {
    onBuyGoods() {
      const data = {
        skuId: this.order.skuId,
        buyNum: 1,
      };
      if (this.order.typesId === 7) {
        console.log("教材产品==" + this.order.typesId);
        rqAddShoppingCartJC(data).then((res) => {
          if (res.status === "false  ") {
            this.$toast(res.msg);
          } else {
            this.$toast(res.msg);
          }
        });
      } else {
        this.$addShoppingCart(this.order.skuId,1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-wrapper {
  position: relative;
  background: white;
  border-radius: 6px;
  margin: 15px;
  padding: 10px;
  .status {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }
  .flex {
    justify-content: space-around;
  }
}
.van-button--plain.van-button--primary {
  height: 28px;
}
.table {
  margin-right: auto;
}
</style>
