import request from "@/utils/request";

//查询用户是否登录
export function isLogin() {
  return request({
    url: "/mobile/isLogin",
    method: "get",
  });
}

// 获取图片验证码
export function getImgCode() {
  return request({
    url: "/mobile/login/imgCode",
    method: "post",
  });
}

// 获取登陆验证码
export function getCode(data) {
  return request({
    url: "/mobile/login/code",
    method: "post",
    params: data,
  });
}

// 获取注册验证码
export function getRegisterCode(data) {
  return request({
    url: "/mobile/reg/code",
    method: "post",
    params: data,
  });
}

// 获取验证码 - 找回密码
export function rqGetFindpwdCode(params) {
  return request({
    url: "/mobile/user/findpwd/code",
    method: "post",
    params,
  });
}

// 用户密码登陆
export function login(data) {
  return request({
    url: "/mobile/login",
    method: "post",
    params: data,
    // data:data,
  });
}

// 登出
export function rqLogout() {
  return request({
    url: "/mobile/logout",
    method: "get",
    // data:data
  });
}

// 手机号登陆校验
// 用户登录发送短信
// phone
// code
export function loginPhone(params) {
  return request({
    url: "/mobile/code/check",
    method: "post",
    params,
  });
}

// 找回密码 - 校验验证码
export function vertifyFindPWCode(params) {
  return request({
    url: "/mobile/user/findpwd/code/check",
    method: "post",
    params,
  });
}

// // 找回密码 - 校验验证码
// export function vertifyFindPWCode(params) {
//   return request({
//     url: "/mobile/usually/code/check",
//     method: "post",
//     params,
//   });
// }
// 修改密码
export function updatePwd(params) {
  return request({
    url: "/mobile/user/findpwd/updPwd",
    method: "post",
    params,
  });
}

// 校验该手机号是否注册过
export function isRegister(params) {
  return request({
    url: "/mobile/user/findpwd/phone",
    method: "post",
    params,
  });
}
// 用手机号注册
export function registerPhone(params) {
  console.log(params);
  return request({
    url: "/mobile/registerToPhone",
    method: "post",
    params,
  });
}

export function aliPayLogin() {
  return request({
    url: " /mobile/thirdPartyLogin/ali_login",
    method: "get",
  });
}
export function qqLogin() {
  return request({
    url: "/mobile/thirdPartyLogin/qq/code",
    method: "get",
  });
}
export function wxLogin() {
  return request({
    url: "/mobile/thirdPartyLogin/wx/code",
    method: "get",
  });
}
