var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow order-wrapper text-color-1 text-md"},[_c('div',{staticClass:"card-header mg-b"},[_c('div',{staticClass:"flex-j-b"},[_c('span',{staticClass:"text-lg"},[_vm._v(" "+_vm._s(_vm.getShopName)+" ")]),_c('span',{staticClass:"text-color-2",class:{ 'text-money': _vm.order.state === '等待付款' }},[_vm._v(" "+_vm._s(_vm.order.state)+" ")])])]),_c('div',{staticClass:"card-body mg-v"},[_vm._t("card-body",function(){return [(_vm.order.list.length === 1)?_c('div',[_c('van-card',{attrs:{"num":_vm.order.list[0].buyNum,"price":_vm._f("money")(_vm.order.list[0].wlPrice),"title":_vm.order.list[0].itemName,"thumb":_vm.order.list[0].itemImageUrl},on:{"click":function($event){return _vm.$push({
              name: 'OrderDetail',
              params: { orderId: _vm.order.list[0].orderId },
            })}}})],1):_c('div',[_c('div',{staticClass:"flex-start",on:{"click":function($event){return _vm.$push({
              name: 'OrderDetail',
              params: { orderId: _vm.order.id },
            })}}},[_vm._l((_vm.getOrderList(_vm.order.list)),function(item,index){return _c('div',{key:index,staticClass:"mulImages"},[_c('img',{staticClass:"right-img bd-radius-4",attrs:{"src":item.itemImageUrl,"alt":""}}),_c('div',{staticClass:"buynum"},[_vm._v("x"+_vm._s(item.buyNum))])])}),(_vm.order.list.length > 4)?_c('div',{staticClass:"right-img"},[_c('icon',{attrs:{"title":"cc-plus-square","color":"#ccc","size":"75px"}})],1):_vm._e()],2)])]})],2),_c('div',{staticClass:"card-footer bottom tar bd-radius"},[_c('div',{staticClass:"mg-v flex-j-b text-black-800"},[_c('span',{staticClass:"bacd text-color-3"},[_vm._v(_vm._s(_vm._f("datetime")(_vm.order.createDate)))]),(!_vm.order.userPayment)?_c('span',[_vm._v(" 共"+_vm._s(_vm.getTotalCount)+"本书，实付 "),_c('span',{staticClass:"text-lg font-bold"},[_c('span',{staticClass:"text-md"},[_vm._v("￥")]),_vm._v(_vm._s(_vm._f("money")(_vm.order.alreadyPayed)))])]):_c('span',[_vm._v(" 共"+_vm._s(_vm.getTotalCount)+"本书，应付 "),_c('span',{staticClass:"text-lg font-bold"},[_c('span',{staticClass:"text-md"},[_vm._v("￥")]),_vm._v(_vm._s(_vm._f("money")(_vm.order.userPayment)))])])]),(_vm.order.stateId === 100 || _vm.order.stateId === 10)?_c('van-button',{staticClass:"mg-h",attrs:{"round":"","plain":"","type":"primary"},on:{"click":function($event){return _vm.rqCancleOrder(_vm.order.id)}}},[_vm._v("取消订单")]):_vm._e(),(_vm.order.stateId === 10)?_c('van-button',{staticClass:"mg-h",attrs:{"round":"","plain":"","type":"primary"},on:{"click":function($event){return _vm.onPayment(_vm.order.userPayment, _vm.order.id)}}},[_vm._v("支付")]):_vm._e(),(_vm.order.stateId === 300)?_c('van-button',{staticClass:"mg-h",attrs:{"round":"","plain":"","type":"primary"},on:{"click":function($event){return _vm.$push({
          name: 'ReturnGoodsDetail',
          query: { serviceId: _vm.order.id },
        })}}},[_vm._v("申请退换货")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }