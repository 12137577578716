<template>
<!-- 忘记密码页面 -->
  <lDfault :title="title">
    <mcode
      @imgCodePassed="imgCodePassed"
      @click="codeUpdate"
      :valiImg="imgCode"
      :show="showCode"
    ></mcode>

    <main class="sign-up">
      <h3>请输入要找回的账号</h3>
      <van-field v-model="phone" label="手机号" placeholder="请输入手机号" />

      <van-button
        :disabled="!isOk"
        class="submit"
        type="primary"
        @click="onSubmit"
        block
        >找回密码</van-button
      >
    </main>
  </lDfault>
</template>

<script>
// @ is an alias to /src
import {  isRegister, getImgCode } from "@/api";
import validator from "validator";

import mcode from "./children/Code";
import lDfault from "@/templates/Default.vue";
// import icon from "@/components/Icon.vue";
export default {
  components: {
    lDfault,
    mcode,
  },
  props: {
    way: {
      type: String,
    },
  },
  data() {
    return {
      title:"忘记密码",
      phone: "",
      isOk: false,
      imgCode: {},
      showCode: false,
    };
  },
  watch: {
    phone(nv) {
      this.isOk = nv.length ? true : false;
    },
  },
  mounted() {
    if(this.way==='updatePasswd'){
      this.title='修改密码'
    }
  },
  methods: {
    imgCodePassed(code) {
         sessionStorage.setItem('verify',JSON.stringify({
          phone: this.phone,
          getCode: 'rqGetFindpwdCode',
          handler: 'vertifyFindPWCode',
          imgCodeValue: code,
          nextStation:{name:"ModifyPasswd",params:{phone:this.phone}},
           textGroup: {
            title: "校验验证码",
            subTitle: "",
            placeholder: "请输入验证码",
            button: "校验",
          },
        },))


      this.$router.push({
        name: "VerifyCode",
      });
    },
    codeUpdate() {
      this.showCode = false;
    },
    refreshCode() {
      getImgCode().then((res) => {
        this.imgCode = res;
      });
    },
    onSubmit() {
      if (!validator.isMobilePhone(this.phone, "zh-CN")) {
        this.$toast("请检查你的手机号");
        return;
      }

      isRegister({ phone: this.phone }).then((res) => {
        if (res) {
          this.showCode = true;
          this.refreshCode();

        } else {
          this.$toast("该手机号尚未注册");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-up {
  padding: 0 30px;
  .submit {
    margin-top: 20px;
  }
}
</style>
