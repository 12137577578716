export default {
  methods: {
    goPayment(orderMoney,orderId, isPush = true) {
      this.$store.commit("setCurrentOrderInfo",
        orderMoney,
      );
      if (isPush) {
        this.$router.push({
          name: "Payment",
          query:{orderId}
        });
      } else {
        this.$router.replace({
          name: "Payment",
          query:{orderId}
        });
      }
    },
  },
};
