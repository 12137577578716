import { render, staticRenderFns } from "./JcOrderList.vue?vue&type=template&id=474cb434&scoped=true&"
import script from "./JcOrderList.vue?vue&type=script&lang=js&"
export * from "./JcOrderList.vue?vue&type=script&lang=js&"
import style0 from "./JcOrderList.vue?vue&type=style&index=0&id=474cb434&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474cb434",
  null
  
)

export default component.exports