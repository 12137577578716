<template>
  <lDfault title="优惠券">
    <template v-if="couponList.length">
      <div
        v-for="(item, index) in couponList"
        :key="index"
        class="van-coupon__content bg-white mg bd-radius-4 shadow"
      >
        <div class="van-coupon__head">
          <h2 class="van-coupon__amount">2<span>元</span></h2>
          <p class="van-coupon__condition">{{ item.use }}</p>
        </div>
        <div class="van-coupon__body">
          <p class="van-coupon__name">{{ item.type }}</p>
          <p class="van-coupon__valid">
            {{ item.stime | date }} - {{ item.etime | date }}
          </p>
        </div>
      </div>
    </template>
    <div v-else class="couponDefault">
      <img src="../../images/couponDefault.png" alt="" />
      <p class="text-black-400">暂无优惠券</p>
    </div>
  </lDfault>
</template>

<script>
import { rqGetCouponAll } from "@/api";
import lDfault from "@/templates/Default.vue";

import dayjs from "dayjs";

export default {
  name: "Coupon",
  components: { lDfault },
  data() {
    return {
      couponList: [],
    };
  },
  mounted() {
    rqGetCouponAll({ page: 1 }).then((res) => {
      res.couponDtoList.forEach((item) => {
        item.available = 1;
        // console.log(d)
        item.name = item.type;
        // item.condition = item.direct||"";
        item.condition = "无使用门槛\n最多优惠12元";
        item.value = item.reduce * 100;
        item.valueDesc = item.reduce;
        item.unitDesc = "元";
        item.startAt = dayjs(item.stime).valueOf() / 1000;
        item.endAt = dayjs(item.etime).valueOf() / 1000;
      });
      this.couponList = res.couponDtoList;
    });
  },
};
</script>

<style scoped>
.van-coupon__content {
  padding: 0;
}
.couponDefault {
  text-align: center;
  margin-top: 100px;
}
</style>
