<template>
  <div class="coupon">
    <!-- 优惠券单元格 -->
    <van-coupon-cell
      :coupons="usecouponList"
      :chosen-coupon="chosenCoupon"
      @click="showList = true"
    />
    <!-- 优惠券列表 -->
    <van-popup
      v-model="showList"
      round
      position="bottom"
      style="height: 90%; padding-top: 4px"
    >
      <van-coupon-list
        :show-exchange-bar="false"
        :coupons="usecouponList | formatCoupon"
        :chosen-coupon="chosenCoupon"
        :disabled-coupons="unUsecouponList | formatCoupon"
        @change="onChange"
        @exchange="onExchange"
      />
    </van-popup>
  </div>
</template>

<script>
import { rqGetCouponAll } from "@/api";
import dayjs from "dayjs";
// @ is an alias to /src
// const coupon = {
//   available: 1,
//   condition: "无使用门槛\n最多优惠12元",
//   reason: "",
//   value: 150,
//   name: "优惠券名称",
//   startAt: 1489104000,
//   endAt: 1514592000,
//   valueDesc: "1.5",
//   unitDesc: "元",
// };

export default {
  props: {
    'unUsecouponList':Array,
    'usecouponList':{
      type:Array,
      default:()=>{return []}
    },
  },
  data() {
    return {
      chosenCoupon: -1,
      coupons: [],
      disabledCoupons: [],
      showList: false,
    };
  },
  mounted() {
    rqGetCouponAll({ page: 1 }).then((res) => {
      console.log(res)
    //   this.coupons = this.formatCoupon(res.couponDtoList)
    //   this.disabledCoupons=this.formatCoupon(res.unUseCouponDtoList)
    });
  },
  filters:{  
      formatCoupon(couponList){
        console.log(couponList)
        couponList?.forEach((item) => {
        item.available = 1;
        item.name = item.type;
        item.condition = item.use;
        item.value = item.reduce * 100;
        item.valueDesc = item.reduce;
        item.unitDesc = "元";
        item.startAt = dayjs(item.stime).valueOf() / 1000;
        item.endAt = dayjs(item.etime).valueOf() / 1000;
      });
      console.log(couponList)
      return couponList
    },
    },
  methods: {

    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
      this.$emit("getCouponInfo", this.usecouponList[index]);
    },
    onExchange(code) {
      console.log(code);
      // this.coupons.push(coupon);
    },
  },
};
</script>

<style lang="scss" scope>
.coupon {
}
</style>
