<template>
  <div class="basedrawer">
    <div class="inner">
      <slot> </slot>
      <div class="button-group">

      <van-button @click="$emit('click-cancel')" round plain type="default">取消</van-button>
      <van-button
        @click="$emit('click-confirm')" 
        class="buttonconfirm"
        round
        color="linear-gradient(to right, #ff6034, #ee0a24)"
      >
        确定
      </van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDrawer",
  data() {
    return {
      show: true,
    };
  },
};
</script>
<style>
.basedrawer .van-button {
  height: 30px;
}
</style>
<style lang="scss" scoped>
.basedrawer {
  background: white;
  border-bottom: 1px solid #eee;
  // position: absolute;
  // left: 0;
  // right: 0;
  overflow: hidden;
  color:#323232;
  z-index: 1000;
  height: 0;
  transition: all 0.4s ease-in-out;

  &.show {
    transition: all 0.4s out-in-ease;
    height: 130px;
  }
  .inner {
    padding: 15px;
    .buttonconfirm {
      // margin-top: 100%;
      margin-left: 10px;
      &::before {
        right: 0;
      }
    }
  }
}
.button-group{
    display: flex;
    justify-content: flex-end;
}
</style>
