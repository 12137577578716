import request from "@/utils/request";

//添加购物车
export function rqAddShoppingCart(data,loading=true) {
  console.log(loading)
  return request({
    url: "/mobile/cart/add",
    method: "post",
    params: data,
    loading
  });
}
//添加购物车
export function rqAddShoppingCartJC(data,loading=true) {
    console.log(loading)
    return request({
        url: "/mobile/cart/addJC",
        method: "post",
        params: data,
        loading
    });
}

// 购物车List
export function rqCartQuery() {
  return request({
    url: '/mobile/cart/query',
    method: 'get',
  })
}

// 更新购物车数量
// 更新成功，返回新列表
export function rqCartUpdate(data,loading=true) {
  return request({
    url: '/mobile/cart/update',
    method: 'post',
    params:data,
    loading,
  })
}

// 删除购物车商品
export function rqCartDelete(params,loading=true) {
  return request({
    url: '/mobile/cart/del',
    method: 'post',
    data:params,
    loading
  })
}

// 默认
export function rqDefCart() {
  return request({
    url: '/mobile/cart/defcart',
    method: 'post',
  })
}
