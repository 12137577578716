const {  rqLogout,rqAddShoppingCart } = require("../api");


exports.install = function(Vue) {


  Vue.prototype.$isWeChatBrowser = function(userAgent = navigator.userAgent) {
    return userAgent.match("MicroMessenger");
  };
  Vue.prototype.$addShoppingCart =async function(skuId,buyNum=1,loading=true) {
    const res= await rqAddShoppingCart({skuId,buyNum},loading)
    if(res.status){
        this.$store.commit('SET_CART_COUNT',res.totalCount)
        this.$toast("添加购物车成功！");
    }else{
      this.$toast('没有库存')
    }
  };

  Vue.prototype.$genOrder = function(query) {

    if(this.$store.state.user.isLogin){
      this.$router.push({name:'OrderConfirm',query});
    }else{
      this.$router.push({name:"Login"});
    }

  };
  Vue.prototype.$back = function() {
    //全局函数1
    if(history.length>1){
      this.$router.go(-1);
    }else{
      this.$router.push({name:'Home'})
    }
  };
  Vue.prototype.$push = function(name) {
    //全局函数2
    this.$router.push(name);
  };
  Vue.prototype.$pushHN = function() {
    //全局函数3
  };
  // Vue.prototype.$login = function(targetRoute) {
  //   //全局函数3

  // };
  Vue.prototype.$logout = function() {
    sessionStorage.removeItem("userInfo");
    sessionStorage.removeItem("avatar");
    // 改变登陆状态
    sessionStorage.removeItem("isLogin");

    //删除教材登录信息
    localStorage.removeItem("token");
    localStorage.removeItem("uid");
    localStorage.removeItem("consumerCode");
    localStorage.removeItem("classId");
    //全局函数3
    rqLogout().then((res) => {
      if (res) {
        this.$router.replace({ name: "My" });
        location.reload();
        // 删除本地用户信息
      }
    });
  };

  // Vue.prototype.$logout = function(message,options){
  //     Toast(message,options)
  // }
  // Vue.prototype.$logout = function() { //全局函数3
  //     isRegister
  // };
};

// import Toast from './toast'

// let currentToast

// export default {
//   install (Vue, options) {
//     Vue.prototype.$toast = function (message, toastOptions) {
//       if (currentToast) {
//         currentToast.close()
//       }
//       currentToast = createToast({
//         Vue,
//         message,
//         propsData: toastOptions,
//         onClose: () => {
//           currentToast = null
//         }
//       })
//     }
//   }
// }
// /* helpers */
// function createToast ({Vue, message, propsData, onClose}) {
//     let Constructor = Vue.extend(Toast)
//     let toast = new Constructor({propsData})
//     toast.$slots.default = [message]
//     toast.$mount()
//     toast.$on('close', onClose)
//     document.body.appendChild(toast.$el)
//     return toast
//   }
