<template>
  <!-- 校验验证码页面 -->
  <lDfault :title="textGroup.title || ''">
    <mcode
      @imgCodePassed="imgCodePassed"
      @click="codeUpdate"
      :valiImg="imgCode"
      :show="showCode"
    ></mcode>

    <main class="sign-up">
      <h3>{{ textGroup.subTitle || "" }}</h3>
      <div class="flex" style="position:relative">
        <van-field
          class="code"
          v-model="code"
          type="text"
          :placeholder="textGroup.placeholder"
        />
        <div
          @click="onGetCode"
          class="timer text-black-300"
          :class="{ disabled: isOK }"
        >
          <span v-if="second > 0"> {{ second }}s </span>
          <span v-else>
            重发
          </span>
        </div>
      </div>
      <van-button class="submit" type="primary" @click="doLogin" block>{{
        textGroup.button
      }}</van-button>
    </main>
  </lDfault>
</template>

<script>
// @ is an alias to /src
import lDfault from "@/templates/Default.vue";
import mcode from "./children/Code";

import {
  getCode,
  loginPhone,
  getRegisterCode,
  rqGetFindpwdCode,
  vertifyFindPWCode,
} from "@/api";
// import icon from "@/components/Icon.vue";
export default {
  components: {
    lDfault,
    // icon,
    mcode
  },
  props: {},
  data() {
    return {
      imgCode: {},
      showCode: false,

      code: "",
      second: 60,
      isOK: false,

      way: "", //"login", "signup"
      phone: "",
      getCode: () => {},
      handler: () => {},
      nextStation: {},
      imgCodeValue: "",
      textGroup: {
        title: "校验验证码",
        subTitle: "",
        placeholder: "请输入验证码",
        button: "校验",
      },
    };
  },
  mounted() {
    const verify = JSON.parse(sessionStorage.getItem("verify"));
    console.log(verify, !verify);
    if (!verify) {
      this.$router.replace("login");
    }
    this.phone = verify.phone;
    this.way = verify.way;
    if (verify.handler === "loginPhone") {
      this.handler = loginPhone;
    } else if (verify.handler === "vertifyFindPWCode") {
      this.handler = vertifyFindPWCode;
    }
    if (verify.getCode === "getCode") {
      this.getCode = getCode;
    } else if (verify.getCode === "getRegisterCode") {
      this.getCode = getRegisterCode;
    } else if (verify.getCode === "rqGetFindpwdCode") {
      this.getCode = rqGetFindpwdCode;
    }
    this.imgCodeValue = verify.imgCodeValue;
    this.nextStation = verify.nextStation;
    this.textGroup = verify.textGroup;

    this.timer();
    this.getCode({ phone: this.phone, veriValidCode: this.imgCodeValue });
  },
  methods: {
    imgCodePassed(code) {
      this.code=code

    },
    codeUpdate() {
      this.showCode = false;
    },
    timer() {
      const timerId = setInterval(() => {
        this.second -= 1;
        if (this.second <= 0) {
          this.isOK = true;
          clearInterval(timerId);
        }
      }, 1000);
    },
    async onGetCode() {
      if (this.second <= 0) {
        this.isOK = false;
        this.second = 60;
        this.timer();

        this.getCode({ phone: this.phone, veriValidCode: this.imgCodeValue });
      }
    },
    doLogin() {
      const data = {
        phone: this.phone,
        code: this.code,
      };
      this.handler(data).then((res) => {
        console.log(res, this.nextStation);
        if (res.status !== -1) {
          if (this.way === "login") {
            this.$router.replace(JSON.parse(sessionStorage.getItem('loginRouter')));
          } else {
            this.nextStation.params.code = this.code;
            sessionStorage.setItem("code", this.code);
            if (this.way === "signup") {
              this.nextStation.params.way = this.way;
            }
            console.log(this.nextStation, this.code);
            this.$router.push(this.nextStation);
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-up {
  padding: 0 30px;
  .submit {
    margin-top: 20px;
  }
}
.code {
  margin-right: 70px;
}
.timer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  align-self: center;
  width: 50px;
  margin-left: 10px;
  text-align: center;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.timer.disabled {
  color: rgb(230, 65, 65);
  border-color: rgb(230, 65, 65);
}
</style>
