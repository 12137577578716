<template>
    <lDfault title="退换货">
        <div class="return-goods wrapper pd-h">
            <van-card
                    v-for="(item, index) in this.orderItem"
                    :key="index"
                    :title="item.bname"
                    :skuid="item.skuId"
                    :thumb="item.smallImage"
            >
                <template #price>
                    <div style="width: 100%">
                        <div>￥{{ item.price }}</div>
                    </div>
                </template>
                <template #num>
                    <div class="flex-j-b">
                        <div class="flex-center">购买数量：{{ item.buyNum }}</div>
                        <div class="flex-j-b">
                            <span class="flex-center"> 申请数量： </span>
<!--
                            <BaseStepper
                                    @click.native.stop
                                    :mininum="0"
                                    :disable-minus="false"
                                    :maxnum="item.buyNum"
                                    :count="item.amountSended"
                                    @change="(value) => onChange(item, value)"
                            ></BaseStepper>-->

                            <van-stepper v-model="item.amountSended" integer="true" min="0" v-bind:max="item.buyNum"
                                         @change="(value) => onChange(item, value)"/>
                        </div>
                    </div>
                </template>
            </van-card>

            <van-form @submit="onSubmit">
                <van-field
                        v-model="message"
                        rows="5"
                        autosize
                        type="textarea"
                        maxlength="500"
                        placeholder="请描述申请售后服务的具体原因"
                        show-word-limit
                />
                <van-cell is-link title="希望处理方式" @click="typeshow = true">
                    {{ typeName }}
                </van-cell>
                <van-action-sheet
                        description="请选择一项分类"
                        v-model="typeshow"
                        :actions="returnTypeActions"
                        @select="onSelectType"
                />
                <van-cell is-link title="商品状态" @click="itemshow = true">
                    {{ itemName }}
                </van-cell>
                <van-action-sheet
                        description="请选择一项分类"
                        v-model="itemshow"
                        :actions="goodsStatusActions"
                        @select="onSelectItem"
                />
                <van-cell is-link title="包装状态" @click="packageshow = true">
                    {{ packageName }}
                </van-cell>
                <van-action-sheet
                        description="请选择一项分类"
                        v-model="packageshow"
                        :actions="packageStatusActions"
                        @select="onSelectPackge"
                />

                <!-- <van-cell value="退货收货地址" /> -->
                <p class="text-black-900 text-lg" v-if="typeID == 1">收货地址</p>
                <van-address-list
                        v-if="typeID == 1"
                        class="tebiedete"
                        :list="areaList"
                        :switchable="false"
                        default-tag-text="默认"
                        @click-item="onAdd"
                />
                <p class="text-black-900 text-lg">商家收货地址</p>
                <div
                        v-clipboard:copy="returnAreaList[0].address"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        class="bg-white pd text-black-800 shadow"
                >
                    <div class="mg-v">
                        <span>{{ returnAreaList[0].name }} </span>
                        <span>{{ returnAreaList[0].tel }} </span>
                    </div>
                    <div class="pd-t">
                        {{ returnAreaList[0].address }}
                        <icon title="svg" size="1rem"></icon>
                    </div>
                </div>

                <div style="margin: 16px">
                    <van-button round block type="info" native-type="submit">
                        提交
                    </van-button>
                </div>
            </van-form>
        </div>
    </lDfault>
</template>

<script>
    // @ is an alias to /src

    import {returnOrderCheck, returnOrderSubmit} from "@/api/rerurnOrder";
    import lDfault from "@/templates/Default.vue";
    // import icon from "@/components/Icon.vue";
    export default {
        components: {
            lDfault,
            // icon,
        },
        data() {
            return {
                orderId: this.$route.query.serviceId,

                areaList: "", //收货地址
                message: "",
                typeName: "", // 希望操作名称
                typeID: "", //希望操作ID
                itemName: "", //商品状态名称
                itemId: "", //商品状态Id
                packageName: "", //包装状态名称
                packageId: "", //包装状态Id
                bookid: "", //书目ID
                typeshow: false,
                itemshow: false,
                packageshow: false,
                orderItem: "", //订单明细
                returnTypeActions: [], //退货/换货
                goodsStatusActions: [], //商品状态
                packageStatusActions: [], //包装状态
                saveStatus: "", //保存返回值
                returnAreaList: [],
            };
        },
        computed: {
            hideAdressArrow() {
                return {hideArrow: !this.typeID ? true : false};
            },
        },
        mounted() {
            // orderItem.forEach((v,index)=>{
            //   v.amountSended=
            // })

            const data1 = {
                orderId: this.orderId,
                //orderId:"3022842",
            };
            returnOrderCheck(data1).then((res) => {
                this.orderItem = res.dto.items.map((v) => {
                    v.amountSended = 0
                    return v;
                });
                this.areaList = [
                    {
                        id: res.defaultReceInfoId.id,
                        name: res.defaultReceInfoId.name,
                        tel: res.defaultReceInfoId.phoneNum,
                        address: res.defaultReceInfoId.streetAdd,
                        isDefault: true,
                    },
                ];
                this.returnTypeActions = res.deprecatedTypeMap;
                this.goodsStatusActions = res.itemStatusMap;
                this.packageStatusActions = res.packageStatusMap;
                this.returnAreaList = [
                    {
                        id: res.backReceInfo.id,
                        name: res.backReceInfo.serviceName,
                        tel: res.backReceInfo.servicePhone,
                        address: res.backReceInfo.serviceClues,
                    },
                ];
            });
        },
        methods: {
            onChange(item, count) {
                item.amountSended = count;
            },
            onCopy: function () {
                this.$toast("地址复制成功");
            },
            onError: function () {
                this.$toast("当前不支持复制");
            },
            onSubmit(values) {
                console.log("submit", values);
                console.log(
                    "submit",
                    this.typeID + "_" + this.itemId + "_" + this.packageId
                );
                if (this.message.length < 10) {
                    this.$toast("请至少输入10个汉字，更详细的描述您的问题");
                    return;
                }

                if (this.typeID === "" || typeof this.typeID === "undefined") {
                    this.$toast("请选择希望处理方式");
                    return;
                }
                if (this.itemId === "" || typeof this.itemId === "undefined") {
                    this.$toast("请选择商品状态");
                    return;
                }
                if (this.packageId === "" || typeof this.packageId === "undefined") {
                    this.$toast("请选择包装状态");
                    return;
                }

                //获取退换货书目ID和退换货数量
                var getBookids = "";
                this.orderItem.forEach(function (c) {

                    if(c.amountSended == 0){
                        return;
                    }

                    getBookids += c.skuId + "_" + c.amountSended + ",";
                });
                if(getBookids==""){
                    this.$toast("请输入退换货商品的数量！");
                    return;
                }
                getBookids = getBookids.substring(0, getBookids.length - 1);
                this.bookid = getBookids;
                console.log(getBookids + "_" + this.bookid);

                const submitData = {
                    order: this.orderId, //订单ID
                    deprecatedType: this.typeID, //退换货类型
                    remark: this.message, //说明
                    itemStatus: this.itemId, //商品状态
                    packageStatus: this.packageId, //包装状态
                    returnType: "0", //自选快递
                    receInfo: this.areaList[0].id, //收货地址ID
                    bookIds: this.bookid, //书目ID
                };
                console.log(submitData);
                //returnOrderSubmit;
                returnOrderSubmit(submitData).then((res) => {
                    this.saveStatus = res;
                    if (this.saveStatus) {
                        this.$router.push({name: "PostSaleList"});
                    }
                });
            },
            onSelectType(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.typeshow = false;
                this.typeName = item.name;
                this.typeID = item.value;
            },
            onSelectItem(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.itemshow = false;
                this.itemName = item.name;
                this.itemId = item.value;
            },
            onSelectPackge(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.packageshow = false;
                this.packageName = item.name;
                this.packageId = item.value;
            },

            onAdd() {
                this.$router.push({
                    name: "Address",
                    query: {serviceId: this.$route.query.serviceId},
                    params: {type: "ReturnGoodsDetail", isSelect: true},
                });
            },
        },
    };
</script>
<style>
    .return-goods .van-card__num {
        width: 100%;
    }

    .return-goods.wrapper .van-field__control {
        background-color: rgb(246, 246, 246);
        border-radius: 4px;
    }

    .return-goods.wrapper .van-card__price {
        color: #333 !important;
    }

    .return-goods .hideArrow .van-address-item__edit {
        display: none;
    }
</style>
<style lang="scss" scoped>
    .wrapper {
    }
</style>
