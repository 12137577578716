<template>
  <lDfault title="退换货">
    <van-tabs v-model="active">
      <van-tab title="进行中">
        <div v-if="returnOrderAl.length" class="order-list">
          <div v-for="(item, index) in returnOrderAl" :key="index">
            <ReturnGoodsItem :goods="item"></ReturnGoodsItem>
          </div>
        </div>
        <div v-else>
          <div class="tac fixed-center">
            <img src="../../images/orderDefault.png" alt="" />
            <div class="mg-t text-lg text-black-400 tac">暂无订单</div>
          </div>
        </div>
      </van-tab>
      <van-tab title="已完成">
        <div v-if="returnOrderFinish.length" class="order-list">
          <div v-for="(item, index) in returnOrderFinish" :key="index">
            <ReturnGoodsItem :goods="item"></ReturnGoodsItem>
          </div>
        </div>
          <div v-else>
          <div class="tac fixed-center">
            <img src="../../images/orderDefault.png" alt="" />
            <div class="mg-t text-lg text-black-400 tac">暂无订单</div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </lDfault>
</template>

<script>
// @ is an alias to /src

import { selectReturnOrder } from "@/api/rerurnOrder.js";
import lDfault from "@/templates/Default.vue";
import ReturnGoodsItem from "./children/ReturnGoodsItem.vue";
// import icon from "@/components/Icon.vue";
export default {
  components: {
    lDfault,
    ReturnGoodsItem,
    // icon,
  },

  data() {
    return {
      active: 0,
      returnOrderAl: "",
      returnOrderFinish: "",
    };
  },
  mounted() {
    const data1 = {
      pageNo: 1,
      stateId: "701,702,703,704,707",
      dayNum: 30,
    };
      const data2 = {
          pageNo: 1,
          stateId: "705,706",
          dayNum: 30,
      };

    selectReturnOrder(data1).then((res) => {
        console.log("数据==》"+res);
      this.returnOrderAl = res;

    });
      selectReturnOrder(data2).then((res) => {
          console.log("数据==》"+res);
      this.returnOrderFinish = res;

  });

  },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
.feedback {
}
</style>
