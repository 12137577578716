/* eslint-disable */
import wx from "weixin-js-sdk";
import { wxSDKConfig } from "@/api";

function wxShare(shareInfo,debug=false) {
  wxSDKConfig({
    url: shareInfo.link,
  }).then((res) => {
    wx.config({
      debug:debug,
      appId: res.appid,
      timestamp: res.timestamp,
      nonceStr: res.nonce_str,
      signature: res.signature,
      jsApiList: ['checkJsApi', 'hideMenuItems',"updateAppMessageShareData", "updateTimelineShareData"],
    });
    wx.ready(() => {

      wx.checkJsApi({
        jsApiList : [ 'hideMenuItems' ],
        success : function(res) {

        }
      });

      // 隐藏菜单
      wx.hideMenuItems({
        menuList: ["menuItem:exposeArticle","menuItem:share:qq","menuItem:share:weiboApp","menuItem:favorite","menuItem:share:facebook",
          "menuItem:share:QZone","menuItem:editTag","menuItem:delete","menuItem:copyUrl","menuItem:originPage","menuItem:readMode",
          "menuItem:openWithQQBrowser","menuItem:openWithSafari","menuItem:share:email"] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
      });

      wx.updateAppMessageShareData({
        title: shareInfo.title || "", // 分享标题
        desc: shareInfo.desc || "", // 分享描述
        link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareInfo.imgUrl || "", // 分享图标
        success: function() {
          console.log("分享成功");
        },
        fail: function() {
          console.log("分享失败");
        },
        cancel: function() {
          console.log("取消分享");
        },
      });
      /*wx.onMenuShareAppMessage({
        title: shareInfo.title || "", // 分享标题
        desc: shareInfo.desc || "", // 分享描述
        link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareInfo.imgUrl || "", // 分享图标
        success: function () {
          // 用户确认分享后执行的回调函数
        },
        cancel: function () {
          // 用户取消分享后执行的回调函数
        }
      });*/

      wx.updateTimelineShareData({
        title: shareInfo.title || "", // 分享标题
        link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareInfo.imgUrl || "", // 分享图标
        success: function() {
          // 设置成功
        },
      });
     /* wx.onMenuShareTimeline({
        title: shareInfo.title || "", // 分享标题
        link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareInfo.imgUrl || "", // 分享图标
        success: function () {
          // 用户确认分享后执行的回调函数
        },
        cancel: function () {
          // 用户取消分享后执行的回调函数
        }
      });*/
    });
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    wx.error(function(res) {
      console.log("分享失败: error", res);
    });
  });
}
//导出工具类
export default wxShare;
