import Vue from "vue";
import VueRouter from "vue-router";
import routes2 from "./personalCenter";
import routes3 from "./textbook";

import Home from "../views/Home.vue";
import Category from "../views/Category.vue";
import My from "../views/My.vue";
import ShoppingCart from "../views/shoppingCart/ShoppingCart.vue";
import Search from "../views/Search.vue";
import BookList from "../views/BookList.vue";
import BookDetail from "../views/bookDetail/BookDetail.vue";
import Login from "../views/login/Login.vue";
import VerifyCode from "../views/login/VerifyCode.vue";
import SignUp from "../views/login/SignUp.vue";
import ForgetPasswd from "../views/login/ForgetPasswd.vue";
import ModifyPasswd from "../views/login/ModifyPasswd.vue";
import ThirdLogin from "../views/login/ThirdLogin.vue";

import OrderConfirm from "../views/order/OrderConfirm.vue";
import OrderList from "../views/orderList/OrderList.vue";
import Payment from "../views/order/Payment.vue";
import PaymentSuccess from "../views/order/PaymentSuccess.vue";
import PaymentFinished from "../views/order/PaymentFinished.vue";
import Freight from "../views/Freight.vue";
import OrderDetail from "../views/order/OrderDetail.vue";
import Logists from "../views/order/logiststList.vue";
import P404 from "../views/P404.vue";

// import Test from '../views/Test.vue'

Vue.use(VueRouter);

let routes = [
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: Test,
  // },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      showTab: true,
    },
  },
  {
    path: "/category",
    name: "Category",
    component: Category,
    meta: {
      showTab: true,
    },
  },
  {
    path: "/shoppingCart",
    name: "ShoppingCart",
    component: ShoppingCart,
    meta: {
      showTab: true,
    },
  },
  {
    path: "/my",
    name: "My",
    component: My,
    meta: {
      showTab: true,
    },
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/bookList/:title?",
    name: "BookList",
    component: BookList,
    props: true,
  },
  {
    path: "/bookDetail/:skuId",
    name: "BookDetail",
    component: BookDetail,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/verifyCode",
    name: "VerifyCode",
    component: VerifyCode,
    props: true,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },

  {
    path: "/forgetPasswd",
    name: "ForgetPasswd",
    component: ForgetPasswd,
    props: true,
  },
  {
    path: "/modifyPasswd/:phone",
    name: "ModifyPasswd",
    component: ModifyPasswd,
    props: true,
  },
  {
    path: "/thirdLogin/:way",
    name: "ThirdLogin",
    component: ThirdLogin,
  },
  {
    path: "/orderConfirm",
    name: "OrderConfirm",
    component: OrderConfirm,
    props: true,
  },

  {
    path: "/orderList",
    name: "OrderList",
    component: OrderList,
    props: true,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    props: true,
  },
  {
    path: "/paymentSuccess/:success",
    name: "PaymentSuccess",
    component: PaymentSuccess,
    props: true,
  },
  {
    path: "/paymentFinished",
    name: "PaymentFinished",
    component: PaymentFinished,
    props: true,
  },
  {
    path: "/freight",
    name: "Freight",
    component: Freight,
  },
  {
    path: "/OrderDetal/:orderId",
    name: "OrderDetail",
    component: OrderDetail,
  },
  {
    path: "/LogistsLits/:toOrderId",
    name: "LogistsLits",
    component: Logists,
  },{
    path: "*",
    name: "P404",
    component: P404,
  },
  // {
  //   path: '/freight',
  //   name: 'Freight',
  //   component: Freight
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

routes = [...routes2, ...routes3, ...routes];

const router = new VueRouter({
  mode: "history",
 base: '/',
  // base: process.env.BASE_URL,  //当不好用时候，可以加上这句话再试试
  routes,
});

// import { Dialog } from "vant";
// Vue.use(Dialog);
import store from '../store';
router.beforeEach(function(to, from, next) {
//   console.log('----',to, from);
  if(store.state.user.isLogin&&to.name==="Login"){
    next(from)
  }
//   if (from.path === "/payment") {
//     // 只执行一次
//     console.log(333333)
//     Dialog.confirm({
//       title: "确认要离开支付页面？",
//       message: "您的订单在24小时内未支付将被取消，请尽快完成支付",
//       confirmButtonText: "继续支付",
//       cancelButtonText: "确认离开",
//       closeOnPopstate:false,
//     })
//       .then(() => {
//         // on close
//         console.log(111111111111)
//         next(false);
//       })
//       .catch(() => {
//         console.log(2222222222222)

//         next();
//         // on cancel
//       });
//   } else {
    next();
//   }
});
export default router;

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
//如果以上代码失效，可以把两种都写上，就可以解决了
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
