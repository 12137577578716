import request from "@/utils/request";

//查询充值记录
export function queryRechargeRecord(params) {
    return request({
        url: '/mobile/vip/balance/account',
        method: 'get',
        params,
        // data:params
    })
}

//查询可退款的订单
export function queryWithdraw(params) {
    return request({
        url: '/mobile/vip/balance/refund/apply/getlist',
        method: 'get',
        params,
        // data:params
    })
}
//点击申请退款,返回可退款的信息
export function queryWithdrawInfo(params) {
    return request({
        url: '/mobile/vip/balance/to/refund',
        method: 'get',
        params,
        // data:params
    })
}

//点击申请退款(在线支付)
export function onlineWithdraw(params) {
    return request({
        url: '/mobile/vip/balance/apply/online',
        method: 'post',
        params,
        // data:params
    })
}

//查询充值方式
export function queryPayType(params) {
    return request({
        url: '/mobile/recharge/payType',
        method: 'post',
        params,
        // data:params
    })
}

//充值
export function recharge(params) {
    return request({
        url: '/mobile/recharge/create/wx',
        method: 'get',
        params,
        // data:params
    })
}