<template>
  <div class="comment">
      comment
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {
  }
}
</script>

<style lang='scss' scope>
.comment{
  font-size: 4vw;
}
</style>
