<template>
  <div class="base-default">
    <div v-if="hasData" class="goods">
      <slot></slot>
    </div>
    <div v-else class="goods">
      <img width="100%" :src="getImg(defaultObject.imgUrl)" alt="" srcset="" />
      <div class=" goods-desc tac text-black-500 text-lg">
        {{ defaultObject.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDefault",
  props: {
    hasData: { type: Boolean, default: false },
    height: {
      type: Number,
    },
    position: {
      type: String,
      default: "fixed-center",
      validator: (value) => {
        return ["fixed-center"].indexOf(value) !== -1;
      },
    },
    defaultObject: {
      type: Object,
      default: () => {
        return {
          title: "你的书包空空如也，去选一本书吧~",
          imgUrl: "shoppingCartDefault.png",
        };
      },
    },
  },
};
</script>

<style></style>
