<template>
  <lDfault title="账户余额">
    <div class="cash-value">￥<em>{{money}}</em></div>
    <!-- <van-cell class="mg-t" title="余额使用记录" is-link to="UserBalanceLog" /> -->
    <van-cell class="mg-t" title="充值" is-link to="recharge" />
    <van-cell class="mg-t" title="提现" is-link to="withdraw" />
    <van-cell class="mg-t" title="余额记录" is-link to="rechargeRecord" />
    <div class="cash-desc">
      <p>当您使用微信和支付宝支付等方式产生的退款将返还到您的账户中。</p>

      <p>在订单结算时，您可选择使用账户余额。使用余额需设置支付密码。如果您未设置支付密码，可到安全中心中去设置。</p>
    </div>
  </lDfault>
</template>

<script>
// @ is an alias to /src

import {getUserInfo} from "@/api";
export default {

  data(){
    return{
      money:0,
    }
  },
  mounted() {
    getUserInfo().then(res=>{
      this.money=res.userInfoDTO.money
    })
  }
}
</script>

<style lang='scss' scoped>

.cash-value {
  overflow: hidden;
  line-height: 8rem;
  text-align: center;
  font-size: 4rem;
  font-family: Arial;
  color: #ddd;
}
.cash-desc, .points-desc {
  margin: 20px 0;
  padding: 0 10px;
  font-size: 14px;
  color: #969696;
}
</style>
