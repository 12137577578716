import request from "@/utils/request";
import qs from 'qs'

// 获取openid
export function queryOpenId(params) {
    return request({
        url: "/mobile/jc/openId",
        method: "get",
        params
    });
}

// 获取级联下的书籍信息
export function getBookInClass(params) {
    return request({
        url: "/miniapp/consumer/detail/book",
        method: "get",
        params
    });
}

// 批量添加图书到购物车
export function addAllBookToCart(params) {
    let a =qs.stringify(params,{ indices: false })
    return request({
        url: "/miniapp/user/cart/batch/add",
        method: "post",
        data:a,
    });
}

// 添加图书到购物车
export function addBookToCart(params) {
    return request({
        url: "/miniapp/user/cart",
        method: "POST",
        params
    });
}
// 添加图书到购物车
export function getConsumerPicture(params) {
    return request({
        url: "/miniapp/consumer/picture",
        method: "GET",
        params
    });
}
