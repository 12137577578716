<template>
  <lDfault title="罗宾逊住过的岛">
    <div class="island tac">
      <!-- Generator: Adobe Illustrator 23.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg

        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        style="enable-background:new 0 0 64 64;"
        xml:space="preserve"
      >
        <g>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="32"
            y1="32.1667"
            x2="32"
            y2="-14.8478"
          >
            <stop offset="0.0176" style="stop-color:#97EFE7" />
            <stop offset="0.5207" style="stop-color:#3DB6D3" />
            <stop offset="1" style="stop-color:#3D6FD3" />
          </linearGradient>
          <circle style="fill:url(#SVGID_1_);" cx="32" cy="32" r="28" />
          <linearGradient
            id="SVGID_2_"
            gradientUnits="userSpaceOnUse"
            x1="32"
            y1="32.1667"
            x2="32"
            y2="-14.8478"
          >
            <stop offset="0.0176" style="stop-color:#97EFE7" />
            <stop offset="0.5207" style="stop-color:#3DB6D3" />
            <stop offset="1" style="stop-color:#3D6FD3" />
          </linearGradient>
          <circle style="fill:url(#SVGID_2_);" cx="32" cy="32" r="28" />
          <g>
            <g>
              <g>
                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="181.4357"
                  y1="-220.2534"
                  x2="164.1024"
                  y2="-198.0868"
                  gradientTransform="matrix(-1 0 0 1 26 0)"
                >
                  <stop offset="0" style="stop-color:#D1EFE7" />
                  <stop offset="0.5302" style="stop-color:#97EFE7" />
                  <stop offset="1" style="stop-color:#3CCBE3" />
                </linearGradient>
                <polygon
                  style="fill:url(#SVGID_3_);"
                  points="36.453,33.844 36.803,34 41.651,23.089 41.301,22.933 				"
                />

                <linearGradient
                  id="SVGID_4_"
                  gradientUnits="userSpaceOnUse"
                  x1="351.8168"
                  y1="192.433"
                  x2="334.4828"
                  y2="214.6005"
                  gradientTransform="matrix(-0.9135 -0.4068 -0.4068 0.9135 335.9947 -429.617)"
                >
                  <stop offset="0" style="stop-color:#D1EFE7" />
                  <stop offset="0.5302" style="stop-color:#97EFE7" />
                  <stop offset="1" style="stop-color:#3CCBE3" />
                </linearGradient>
                <path
                  style="fill:url(#SVGID_4_);"
                  d="M35.667,20.775c1.193-0.57,3.438-0.383,5.819,0.553c0.195,0.077,0.418,0.002,0.524-0.18
					l0.296-0.506c0.041-0.069,0.12-0.109,0.199-0.094c0.111,0.022,0.174,0.132,0.144,0.235l-0.169,0.584
					c-0.057,0.198,0.037,0.407,0.221,0.499c2.299,1.141,3.949,2.685,4.326,3.956c0.049,0.166-0.108,0.318-0.275,0.269
					c-0.165-0.048-0.323-0.078-0.466-0.09c-0.145-0.012-0.28-0.076-0.386-0.175c-0.183-0.171-0.438-0.338-0.737-0.471
					c-0.299-0.133-0.594-0.21-0.844-0.232c-0.147-0.013-0.286-0.074-0.394-0.175c-0.183-0.171-0.438-0.338-0.738-0.471
					c-0.299-0.133-0.594-0.21-0.844-0.232c-0.147-0.012-0.286-0.074-0.394-0.175c-0.183-0.171-0.438-0.338-0.738-0.471
					c-0.299-0.133-0.594-0.21-0.844-0.232c-0.147-0.013-0.286-0.074-0.394-0.175c-0.184-0.171-0.438-0.338-0.738-0.471
					c-0.299-0.133-0.594-0.21-0.844-0.231c-0.147-0.013-0.286-0.074-0.394-0.175c-0.183-0.171-0.438-0.338-0.738-0.471
					c-0.299-0.133-0.594-0.21-0.844-0.231c-0.145-0.012-0.283-0.07-0.388-0.169c-0.104-0.098-0.232-0.195-0.379-0.285
					C35.504,21.069,35.51,20.85,35.667,20.775z"
                />
              </g>
              <g>
                <linearGradient
                  id="SVGID_5_"
                  gradientUnits="userSpaceOnUse"
                  x1="31.1815"
                  y1="82.7639"
                  x2="34.1077"
                  y2="48.2345"
                >
                  <stop offset="0.0176" style="stop-color:#97EFE7" />
                  <stop offset="0.5207" style="stop-color:#3DB6D3" />
                  <stop offset="1" style="stop-color:#3D6FD3" />
                </linearGradient>
                <path
                  style="fill:url(#SVGID_5_);"
                  d="M29.708,28.193c0,4.694-3.806,8.5-8.5,8.5h-0.685c-1.248,0-2.26,1.012-2.26,2.261v0.505
					c0,3.259-2.642,5.9-5.9,5.9h-0.314c-1.391,0-2.519,1.128-2.519,2.519v0.439c0,0.122-0.011,0.242-0.018,0.363
					C14.613,55.548,22.786,60,32,60c15.464,0,28-12.536,28-28c0-1.487-0.118-2.946-0.342-4.37H30.271
					C29.96,27.63,29.708,27.882,29.708,28.193z"
                />
                <linearGradient
                  id="SVGID_6_"
                  gradientUnits="userSpaceOnUse"
                  x1="62.4196"
                  y1="24.4983"
                  x2="18.5862"
                  y2="55.3316"
                >
                  <stop offset="0.0017" style="stop-color:#FFEBA7" />
                  <stop offset="0.5144" style="stop-color:#F6A000" />
                  <stop offset="1" style="stop-color:#FF6D00" />
                </linearGradient>
                <path
                  style="fill:url(#SVGID_6_);"
                  d="M44.161,27.913c1.657-0.177,4.246,0.988,6.683,3.1c0.2,0.173,0.496,0.177,0.698,0.006
					l0.564-0.477c0.077-0.065,0.188-0.079,0.275-0.028c0.123,0.073,0.151,0.231,0.073,0.34l-0.445,0.623
					c-0.151,0.211-0.127,0.499,0.054,0.686c2.253,2.32,3.566,4.848,3.481,6.516c-0.011,0.218-0.262,0.332-0.44,0.204
					c-0.176-0.127-0.351-0.229-0.516-0.302c-0.167-0.075-0.301-0.207-0.385-0.37c-0.146-0.28-0.379-0.586-0.678-0.869
					c-0.3-0.284-0.618-0.499-0.906-0.629c-0.17-0.077-0.308-0.208-0.394-0.373c-0.146-0.28-0.379-0.586-0.679-0.87
					c-0.3-0.284-0.618-0.499-0.906-0.63c-0.17-0.077-0.308-0.208-0.394-0.373c-0.116-0.223-0.3-0.459-0.516-0.69l-9.768,10.327
					l-0.351-0.332l9.768-10.327c-0.243-0.202-0.489-0.373-0.718-0.477c-0.17-0.077-0.308-0.208-0.394-0.373
					c-0.146-0.281-0.379-0.586-0.679-0.87c-0.3-0.284-0.618-0.499-0.906-0.63c-0.17-0.077-0.308-0.208-0.394-0.373
					c-0.146-0.281-0.379-0.586-0.679-0.87c-0.3-0.283-0.617-0.499-0.905-0.629c-0.167-0.075-0.307-0.202-0.39-0.364
					c-0.083-0.161-0.194-0.329-0.331-0.498C43.844,28.193,43.943,27.936,44.161,27.913z"
                />
                <linearGradient
                  id="SVGID_7_"
                  gradientUnits="userSpaceOnUse"
                  x1="16.8406"
                  y1="18.4597"
                  x2="16.8406"
                  y2="45.6078"
                >
                  <stop offset="0.0176" style="stop-color:#97EFE7" />
                  <stop offset="0.5207" style="stop-color:#3DB6D3" />
                  <stop offset="1" style="stop-color:#3D6FD3" />
                </linearGradient>
                <path
                  style="fill:url(#SVGID_7_);"
                  d="M25.95,28.404c-0.808,0.277-1.727,0.434-2.703,0.434c-0.975,0-1.895-0.157-2.703-0.434
					c-0.349-0.12-0.727-0.12-1.076,0c-0.808,0.277-1.727,0.434-2.703,0.434c-0.975,0-1.895-0.157-2.703-0.434
					c-0.349-0.12-0.727-0.12-1.076,0c-0.808,0.277-1.727,0.434-2.703,0.434s-1.895-0.157-2.703-0.434
					c-0.349-0.12-0.728-0.119-1.076,0c-0.663,0.227-1.401,0.374-2.184,0.419c-0.051,0.003-0.097,0.02-0.143,0.036
					C4.065,29.891,4,30.938,4,32c0,6.25,2.049,12.02,5.51,16.679c0.008-0.121,0.018-0.24,0.018-0.363v-0.439
					c0-1.391,1.128-2.519,2.519-2.519h0.314c3.259,0,5.9-2.642,5.9-5.9v-0.505c0-1.249,1.012-2.261,2.26-2.261h0.685
					c4.477,0,8.144-3.463,8.473-7.856c-0.957-0.005-1.86-0.16-2.654-0.433C26.678,28.285,26.299,28.284,25.95,28.404z"
                />
                <g>
                  <linearGradient
                    id="SVGID_8_"
                    gradientUnits="userSpaceOnUse"
                    x1="16.6246"
                    y1="19.042"
                    x2="16.6246"
                    y2="64.6966"
                  >
                    <stop offset="0.0176" style="stop-color:#97EFE7" />
                    <stop offset="0.5207" style="stop-color:#3DB6D3" />
                    <stop offset="1" style="stop-color:#3D6FD3" />
                  </linearGradient>
                  <path
                    style="fill:url(#SVGID_8_);"
                    d="M25.95,30.521c-0.808,0.277-1.727,0.434-2.703,0.434c-0.975,0-1.895-0.157-2.703-0.434
						c-0.349-0.12-0.727-0.12-1.076,0c-0.808,0.277-1.727,0.434-2.703,0.434c-0.975,0-1.895-0.157-2.703-0.434
						c-0.349-0.12-0.727-0.12-1.076,0c-0.808,0.277-1.727,0.434-2.703,0.434s-1.895-0.157-2.703-0.434
						c-0.349-0.12-0.728-0.119-1.076,0c-0.663,0.227-1.401,0.374-2.184,0.419c-0.115,0.007-0.215,0.056-0.299,0.124
						C4.013,31.375,4,31.686,4,32c0,0.052,0.004,0.102,0.004,0.154c0.086-0.077,0.195-0.131,0.319-0.138
						c0.782-0.045,1.521-0.191,2.184-0.419c0.349-0.12,0.728-0.12,1.076,0c0.808,0.277,1.727,0.434,2.703,0.434
						s1.895-0.157,2.703-0.434c0.348-0.12,0.727-0.12,1.076,0c0.808,0.277,1.727,0.434,2.703,0.434c0.975,0,1.895-0.157,2.703-0.434
						c0.348-0.12,0.727-0.12,1.076,0c0.808,0.277,1.727,0.434,2.703,0.434c0.976,0,1.895-0.157,2.703-0.434
						c0.349-0.12,0.728-0.119,1.077,0.001c0.55,0.188,1.152,0.319,1.788,0.384c0.167-0.335,0.312-0.683,0.434-1.041
						c-0.797-0.042-1.548-0.188-2.222-0.419C26.678,30.402,26.299,30.401,25.95,30.521z"
                  />
                  <linearGradient
                    id="SVGID_9_"
                    gradientUnits="userSpaceOnUse"
                    x1="15.803"
                    y1="20.4965"
                    x2="15.803"
                    y2="63.3359"
                  >
                    <stop offset="0.0176" style="stop-color:#97EFE7" />
                    <stop offset="0.5207" style="stop-color:#3DB6D3" />
                    <stop offset="1" style="stop-color:#3D6FD3" />
                  </linearGradient>
                  <path
                    style="fill:url(#SVGID_9_);"
                    d="M25.95,33.749c-0.808,0.277-1.727,0.434-2.703,0.434c-0.975,0-1.895-0.157-2.703-0.434
						c-0.349-0.12-0.727-0.12-1.076,0c-0.808,0.277-1.727,0.434-2.703,0.434c-0.975,0-1.895-0.157-2.703-0.434
						c-0.349-0.12-0.727-0.12-1.076,0c-0.808,0.277-1.727,0.434-2.703,0.434s-1.895-0.157-2.703-0.434
						c-0.349-0.12-0.728-0.119-1.076,0c-0.663,0.227-1.401,0.374-2.184,0.419c-0.083,0.005-0.157,0.033-0.226,0.071
						c0.027,0.347,0.06,0.693,0.1,1.037c0.041-0.013,0.081-0.029,0.126-0.032c0.782-0.045,1.521-0.191,2.184-0.419
						c0.349-0.12,0.728-0.12,1.076,0c0.808,0.277,1.727,0.434,2.703,0.434s1.895-0.157,2.703-0.434c0.348-0.12,0.727-0.12,1.076,0
						c0.808,0.277,1.727,0.434,2.703,0.434c0.975,0,1.895-0.157,2.703-0.434c0.348-0.12,0.727-0.12,1.076,0
						c0.808,0.277,1.727,0.434,2.703,0.434c0.976,0,1.895-0.157,2.703-0.434c0.218-0.075,0.447-0.103,0.674-0.084
						c0.315-0.261,0.611-0.543,0.885-0.846c-0.164-0.044-0.326-0.092-0.482-0.145C26.678,33.63,26.299,33.629,25.95,33.749z"
                  />
                  <linearGradient
                    id="SVGID_10_"
                    gradientUnits="userSpaceOnUse"
                    x1="11.9513"
                    y1="21.4064"
                    x2="11.9513"
                    y2="63.8137"
                  >
                    <stop offset="0.0176" style="stop-color:#97EFE7" />
                    <stop offset="0.5207" style="stop-color:#3DB6D3" />
                    <stop offset="1" style="stop-color:#3D6FD3" />
                  </linearGradient>
                  <path
                    style="fill:url(#SVGID_10_);"
                    d="M14.064,36.976c-0.349-0.12-0.727-0.12-1.076,0c-0.808,0.277-1.727,0.434-2.703,0.434
						s-1.895-0.157-2.703-0.434c-0.349-0.12-0.728-0.119-1.076,0c-0.605,0.208-1.279,0.337-1.986,0.394
						c0.068,0.352,0.146,0.7,0.228,1.048c0.622-0.067,1.22-0.181,1.759-0.366c0.349-0.12,0.728-0.12,1.076,0
						c0.808,0.277,1.727,0.434,2.703,0.434s1.895-0.157,2.703-0.434c0.348-0.12,0.727-0.12,1.076,0
						c0.808,0.277,1.727,0.434,2.703,0.434c0.549,0,1.078-0.051,1.582-0.144c0.16-0.569,0.536-1.046,1.035-1.338
						c-0.787,0.259-1.675,0.407-2.616,0.407C15.791,37.41,14.872,37.254,14.064,36.976z"
                  />
                  <linearGradient
                    id="SVGID_11_"
                    gradientUnits="userSpaceOnUse"
                    x1="11.7405"
                    y1="22.1835"
                    x2="11.7405"
                    y2="64.5909"
                  >
                    <stop offset="0.0176" style="stop-color:#97EFE7" />
                    <stop offset="0.5207" style="stop-color:#3DB6D3" />
                    <stop offset="1" style="stop-color:#3D6FD3" />
                  </linearGradient>
                  <path
                    style="fill:url(#SVGID_11_);"
                    d="M14.064,40.204c-0.349-0.12-0.727-0.12-1.076,0c-0.808,0.277-1.727,0.434-2.703,0.434
						s-1.895-0.157-2.703-0.434c-0.349-0.12-0.728-0.119-1.076,0c-0.373,0.128-0.774,0.222-1.189,0.295
						c0.108,0.338,0.22,0.673,0.34,1.006c0.291-0.064,0.58-0.133,0.849-0.225c0.349-0.12,0.728-0.12,1.076,0
						c0.808,0.277,1.727,0.434,2.703,0.434s1.895-0.157,2.703-0.434c0.348-0.12,0.727-0.12,1.076,0
						c0.808,0.277,1.727,0.434,2.703,0.434c0.367,0,0.726-0.022,1.074-0.065c0.143-0.358,0.252-0.733,0.324-1.122
						c-0.448,0.072-0.916,0.111-1.397,0.111C15.791,40.638,14.872,40.481,14.064,40.204z"
                  />
                  <linearGradient
                    id="SVGID_12_"
                    gradientUnits="userSpaceOnUse"
                    x1="11.3735"
                    y1="22.9606"
                    x2="11.3735"
                    y2="65.368"
                  >
                    <stop offset="0.0176" style="stop-color:#97EFE7" />
                    <stop offset="0.5207" style="stop-color:#3DB6D3" />
                    <stop offset="1" style="stop-color:#3D6FD3" />
                  </linearGradient>
                  <path
                    style="fill:url(#SVGID_12_);"
                    d="M12.988,43.431c-0.808,0.277-1.727,0.434-2.703,0.434s-1.895-0.157-2.703-0.434
						c-0.349-0.12-0.728-0.119-1.076,0c-0.018,0.006-0.04,0.009-0.058,0.015c0.15,0.334,0.304,0.665,0.466,0.992
						c0.225-0.018,0.452-0.006,0.668,0.068c0.808,0.277,1.727,0.434,2.703,0.434s1.895-0.157,2.703-0.434
						c0.348-0.12,0.727-0.12,1.076,0c0.287,0.098,0.588,0.181,0.9,0.247c0.487-0.24,0.935-0.544,1.335-0.902
						c-0.801-0.042-1.558-0.188-2.235-0.421C13.715,43.312,13.336,43.312,12.988,43.431z"
                  />
                </g>
              </g>
            </g>

            <linearGradient
              id="SVGID_13_"
              gradientUnits="userSpaceOnUse"
              x1="1368.5946"
              y1="15.6415"
              x2="1368.5946"
              y2="-5.5525"
              gradientTransform="matrix(1 0 0 1 -1337.8221 0)"
            >
              <stop offset="0" style="stop-color:#D1EFE7" />
              <stop offset="0.5302" style="stop-color:#97EFE7" />
              <stop offset="1" style="stop-color:#3CCBE3" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_13_);"
              d="M35.545,9.024c0-0.252-0.474-0.479-1.233-0.638c-0.567-0.119-1.047-0.476-1.363-0.962
			c-0.269-0.415-0.736-0.689-1.268-0.689c-0.528,0-0.993,0.271-1.263,0.682c-0.02,0.03-0.04,0.06-0.061,0.089
			c-0.365,0.506-0.952,0.803-1.574,0.844C27.175,8.455,26,8.79,26,9.186c0,0.252,0.474,0.479,1.233,0.638
			c0.567,0.119,1.047,0.476,1.363,0.962c0.269,0.415,0.737,0.689,1.268,0.689c0.528,0,0.993-0.271,1.263-0.682
			c0.003-0.004,0.006-0.009,0.008-0.013c0.364-0.547,0.974-0.878,1.63-0.921C34.371,9.755,35.545,9.42,35.545,9.024z"
            />

            <linearGradient
              id="SVGID_14_"
              gradientUnits="userSpaceOnUse"
              x1="1865.8221"
              y1="24.9071"
              x2="1865.8221"
              y2="3.7136"
              gradientTransform="matrix(-1 0 0 1 1875.8221 0)"
            >
              <stop offset="0" style="stop-color:#D1EFE7" />
              <stop offset="0.5302" style="stop-color:#97EFE7" />
              <stop offset="1" style="stop-color:#3CCBE3" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_14_);"
              d="M5.228,18.29c0-0.252,0.475-0.479,1.233-0.638c0.567-0.119,1.047-0.476,1.363-0.962
			C8.092,16.274,8.56,16,9.091,16c0.528,0,0.993,0.271,1.263,0.682c0.02,0.03,0.04,0.06,0.061,0.089
			c0.365,0.506,0.951,0.803,1.574,0.844c1.608,0.105,2.783,0.44,2.783,0.837c0,0.252-0.474,0.479-1.233,0.638
			c-0.567,0.119-1.047,0.476-1.363,0.962c-0.269,0.415-0.737,0.689-1.268,0.689c-0.528,0-0.993-0.271-1.263-0.682
			c-0.003-0.004-0.005-0.008-0.008-0.013c-0.364-0.547-0.974-0.878-1.63-0.921C6.401,19.021,5.228,18.686,5.228,18.29z"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_15_"
              gradientUnits="userSpaceOnUse"
              x1="357.3255"
              y1="-236.9722"
              x2="379.489"
              y2="-196.8942"
              gradientTransform="matrix(0.9828 -0.1844 0.1844 0.9828 -282.6029 308.0524)"
            >
              <stop offset="0" style="stop-color:#FBC19E" />
              <stop offset="0.4831" style="stop-color:#FFB699" />
              <stop offset="0.9992" style="stop-color:#F47E6D" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_15_);"
              d="M49.002,28.226c0,0-6.618,0.519-10.942,0.338c-3.415-0.143,2.438-11.903,2.606-13.486
			c0.168-1.583-1-5.972-2.449-6.007c-1.45-0.035,0.245,5.424-0.153,6.598c-0.398,1.174-11.056,13.652-6.134,18.968
			c6.585,7.114,12.601-0.251,15.352-0.973c2.751-0.722-5.742-1.403-5.742-1.403"
            />

            <linearGradient
              id="SVGID_16_"
              gradientUnits="userSpaceOnUse"
              x1="-135.6109"
              y1="3190.3606"
              x2="-93.9714"
              y2="3233.2158"
              gradientTransform="matrix(-0.0212 0.9998 -0.9998 -0.0212 3262.6963 206.2323)"
            >
              <stop offset="0" style="stop-color:#FBC19E" />
              <stop offset="0.5917" style="stop-color:#24665C" />
              <stop offset="1" style="stop-color:#24365C" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_16_);"
              d="M54.996,29.714c0,0-1.232,3.579-11.035,5.804c-0.387,0.088-0.794,0.054-1.156-0.108
			c-1.01-0.451-2.995-1.611-3.5-3.914c-0.357-1.629,0.619-3.258,2.2-3.79C45.63,26.319,54.638,23.965,54.996,29.714z"
            />
            <linearGradient
              id="SVGID_17_"
              gradientUnits="userSpaceOnUse"
              x1="27.8692"
              y1="27.4875"
              x2="20.8091"
              y2="46.3144"
            >
              <stop offset="0.1095" style="stop-color:#004B87" />
              <stop offset="0.8989" style="stop-color:#00295D" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_17_);"
              d="M32.851,47.005c0,0-3.278-17.432-9.491-17.465c-2.465-0.014-5.896,0.807-12.874,11.76
			c-1.048,1.645-2.034,3.066-2.959,4.305c0.968,1.737,2.116,3.359,3.418,4.844c1.514-1.457,3.448-3.485,5.319-5.939
			c0.005-0.006,0.01-0.012,0.01-0.012c0.283-0.367,0.56-0.742,0.832-1.125c3.397-4.118,3.976,6.04,3.976,6.04
			c-2.802,0.952-5.781,1.87-8.663,2.596c1.636,1.601,3.467,3.001,5.454,4.165C26.413,56.287,34.279,54.465,32.851,47.005z"
            />
            <linearGradient
              id="SVGID_18_"
              gradientUnits="userSpaceOnUse"
              x1="11.0551"
              y1="50.1154"
              x2="47.114"
              y2="50.1154"
            >
              <stop offset="0.1095" style="stop-color:#004B87" />
              <stop offset="0.8989" style="stop-color:#00295D" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_18_);"
              d="M36.583,41.43c0,0-8.671,6.809-25.528,9.144c3.392,3.823,7.812,6.71,12.83,8.227
			c9.445-0.238,18.219-1.973,23.229-6.67L36.583,41.43z"
            />
            <linearGradient
              id="SVGID_19_"
              gradientUnits="userSpaceOnUse"
              x1="26.9687"
              y1="13.4069"
              x2="59.7946"
              y2="60.6194"
            >
              <stop offset="0" style="stop-color:#FBC19E" />
              <stop offset="0.4831" style="stop-color:#24665C" />
              <stop offset="1" style="stop-color:#24365C" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_19_);"
              d="M56.486,29.717c-0.34-1.387-1.297-2.536-2.579-3.164c-3.876-1.9-8.785-0.437-10.966,3.559
			l-1.284,2.352c-0.977,1.79-2.82,2.948-4.858,3.019c-2.871,0.101-6.672,0.813-6.73,3.872c-0.136,7.206,4.07,11.7,8.779,14.468
			c6.006,3.53,11.316-0.809,14.902-6.88C56.077,43.005,57.613,34.32,56.486,29.717z"
            />
            <linearGradient
              id="SVGID_20_"
              gradientUnits="userSpaceOnUse"
              x1="27.469"
              y1="72.4586"
              x2="46.4945"
              y2="37.5786"
            >
              <stop offset="0" style="stop-color:#FBC19E" />
              <stop offset="0.4831" style="stop-color:#24665C" />
              <stop offset="1" style="stop-color:#24365C" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_20_);"
              d="M51.274,33.343c0.138,2.846-0.786,9.743-4.16,8.949c-3.374-0.794-0.206,8.223-8.041,7.84
			c-5.843-0.286-7.812-4.192-8.436-6.16c1.289,4.593,4.601,7.731,8.211,9.852c6.006,3.53,11.316-0.809,14.902-6.88
			c1.285-2.175,2.328-5.797,2.815-9.38C55.29,36.215,51.153,30.865,51.274,33.343z"
            />
            <linearGradient
              id="SVGID_21_"
              gradientUnits="userSpaceOnUse"
              x1="41.2417"
              y1="67.8043"
              x2="53.9337"
              y2="29.0534"
            >
              <stop offset="0" style="stop-color:#FBC19E" />
              <stop offset="0.4831" style="stop-color:#FFB699" />
              <stop offset="0.9992" style="stop-color:#F47E6D" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_21_);"
              d="M53.071,40.41c0,0,0.03,12.535-8.785,16.325c-5.791,2.49-6.265-0.87-8.975,0.1
			c-2.756,0.986-1.895,1.639-1.642,1.999c0.253,0.36,12.484,3.739,20.105-2.983c6.484-5.719,7.402-16.464,4.717-20.516"
            />
            <linearGradient
              id="SVGID_22_"
              gradientUnits="userSpaceOnUse"
              x1="26.2263"
              y1="5.9701"
              x2="68.7616"
              y2="49.7472"
            >
              <stop offset="0" style="stop-color:#FBC19E" />
              <stop offset="0.5917" style="stop-color:#24665C" />
              <stop offset="1" style="stop-color:#24365C" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_22_);"
              d="M53.907,26.553c0,0,3.604,1.156,6.037,10.909c0.096,0.385,0.071,0.793-0.084,1.159
			c-0.43,1.019-1.382,3.684-3.673,4.238c-1.621,0.392-4.214-1.121-4.001-2.776C52.681,36.216,48.166,27.034,53.907,26.553z"
            />
            <linearGradient
              id="SVGID_23_"
              gradientUnits="userSpaceOnUse"
              x1="65.3782"
              y1="26.8022"
              x2="25.7035"
              y2="15.4276"
            >
              <stop offset="0" style="stop-color:#FBC19E" />
              <stop offset="0.4831" style="stop-color:#FFB699" />
              <stop offset="0.9992" style="stop-color:#F47E6D" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_23_);"
              d="M55.927,18.087l-0.809-4.326L55.1,13.866c-1.286-4.387-8.004-5.048-8.248,0.929
			c-0.056,1.371-0.886,2.652-0.584,3.695l-0.003-0.002c0,0,1.955,6.308-0.407,8.527c-2.362,2.219-3.595,6.101-1.651,5.951
			c1.753-0.135,3.252-7.588,8.642-6.839c0,0-1.54-2.31,0-2.883l-0.002-0.001c0.137-0.037,0.273-0.079,0.406-0.131
			c1.231-0.483,1.801-1.802,2.041-3.213c0.06-0.354,0.217-0.683,0.449-0.956C55.935,18.713,56.015,18.396,55.927,18.087z"
            />
            <g>
              <linearGradient
                id="SVGID_24_"
                gradientUnits="userSpaceOnUse"
                x1="65.1026"
                y1="35.3028"
                x2="43.5734"
                y2="14.2802"
              >
                <stop offset="0" style="stop-color:#FBC19E" />
                <stop offset="0.4831" style="stop-color:#FFB699" />
                <stop offset="0.9992" style="stop-color:#F47E6D" />
              </linearGradient>
              <path
                style="fill:url(#SVGID_24_);"
                d="M54.692,14.751c0.102,0.01,0.38-0.065,0.587-0.127l-0.161-0.862L55.1,13.866
				c-0.175-0.596-0.452-1.121-0.798-1.572c-1.351,0.113-3.375,0.313-3.375,0.313l-3.867,4.931l-0.231,3.485
				c0,0,0.805,2.797,1.193,4.587c0.332,1.531-0.915,3.647-1.282,4.227c1.305-1.993,3.101-4.128,6.108-3.71c0,0-1.54-2.31,0-2.883
				l-0.002-0.001c0.137-0.037,0.273-0.079,0.406-0.131c0.018-0.007,0.035-0.016,0.053-0.024l0.003-0.315c0,0,0.743-2.972,0.49-2.957
				s-1.165,1.082-1.874-0.488c-0.709-1.57,0.523-2.972,1.857-2.917c1.334,0.055,0.76,3.035,0.76,3.035v0.574l0.79-0.276
				c0.065-0.256,0.181-0.495,0.341-0.706c-0.006,0.005-0.013,0.011-0.021,0.021c-0.144,0.185-0.587,0.101-0.384-0.271
				C55.469,18.415,54.523,14.734,54.692,14.751z"
              />
              <linearGradient
                id="SVGID_25_"
                gradientUnits="userSpaceOnUse"
                x1="49.3021"
                y1="32.5836"
                x2="43.9831"
                y2="27.3899"
              >
                <stop offset="0" style="stop-color:#FBC19E" />
                <stop offset="0.4831" style="stop-color:#FFB699" />
                <stop offset="0.9992" style="stop-color:#F47E6D" />
              </linearGradient>
              <polygon
                style="fill:url(#SVGID_25_);"
                points="46.641,29.989 46.641,29.989 46.644,29.984 			"
              />
            </g>
            <linearGradient
              id="SVGID_26_"
              gradientUnits="userSpaceOnUse"
              x1="56.7155"
              y1="28.3522"
              x2="48.9475"
              y2="12.3668"
            >
              <stop offset="0.0281" style="stop-color:#4A2C18" />
              <stop offset="1" style="stop-color:#2B1915" />
            </linearGradient>
            <path
              style="fill:url(#SVGID_26_);"
              d="M54.996,12.362c0,0,1.773,1.368-0.279,1.773c-2.052,0.405-4.382,0.557-4.103,3.926
			c0.279,3.369,2.28,2.989,2.66,2.229c0.38-0.76,2.124-0.847,2.468-1.348c0,0-0.321,2.172,0.825,3.248
			c1.646,1.545,1.21,5.521-2.359,4.508c-3.568-1.013-4.379-2.558-4.733-5.293c-0.355-2.736-0.659-3.47-1.444-3.546
			c-0.785-0.076-1.14,1.849-0.557,2.913l-0.644,0.251c0,0-2.167-4.253-0.09-6.406c2.077-2.153,6.079-2.392,6.079-2.392"
            />
            <g>
              <linearGradient
                id="XMLID_2_"
                gradientUnits="userSpaceOnUse"
                x1="386.5729"
                y1="-256.7943"
                x2="387.814"
                y2="-254.8441"
                gradientTransform="matrix(0.981 -0.1942 0.1942 0.981 -282.5475 335.3697)"
              >
                <stop offset="0.1095" style="stop-color:#004B87" />
                <stop offset="0.8989" style="stop-color:#00295D" />
              </linearGradient>
              <path
                id="XMLID_3651_"
                style="fill:url(#XMLID_2_);"
                d="M49.239,10.23c-0.036-0.333-0.334-0.573-0.667-0.537
				c-0.333,0.036-0.573,0.334-0.537,0.667c0.036,0.333,0.334,0.573,0.667,0.537C49.035,10.861,49.275,10.563,49.239,10.23z"
              />

              <linearGradient
                id="XMLID_3_"
                gradientUnits="userSpaceOnUse"
                x1="392.2411"
                y1="-260.5594"
                x2="387.6822"
                y2="-247.3386"
                gradientTransform="matrix(0.981 -0.1942 0.1942 0.981 -282.5475 335.3697)"
              >
                <stop offset="0.1095" style="stop-color:#004B87" />
                <stop offset="0.8989" style="stop-color:#00295D" />
              </linearGradient>
              <path
                id="XMLID_3650_"
                style="fill:url(#XMLID_3_);"
                d="M45.016,14.62c-0.363,1.392,1.37,2.38,2.413,1.389
				c1.7-1.614,2.284-2.283,5.935-3.111c6.115-1.386-1.648-3.979-4.585-2.505C46.984,11.295,45.466,12.891,45.016,14.62z"
              />
            </g>
          </g>
        </g>
      </svg>
      <p class="tac text-black-400 mg-t pd">
        你一睁眼，发现自己来到了一座荒岛。这里四面大海，了无人烟……
      </p>
    </div>
    <Recommend>
      <template #title>
        <div class="tac text-black-700 re-title">
          -- 更多你感兴趣的书籍 --
        </div>
      </template>
    </Recommend>
  </lDfault>
</template>

<script>
// @ is an alias to /src

import lDfault from "@/templates/Default.vue";
import Recommend from "@/components/Recommend.vue";
export default {
  components: {
    lDfault,
    Recommend,
    // icon,
  },
};
</script>

<style lang="scss" scoped>
.island {
  margin-top: 35px;
  margin-bottom: 60px;
  .island-img {
    height: 210px;
    width: 210px;
  }
  .text-black-400 {
    width: 210px;
    margin: auto;
  }
}
svg#Layer_1{
    height: 210px;
}
</style>
