<template>
  <div class="CategoryDetail">
    <Panel :secondCategory="secondCategory" :index="index" :key="index" v-for="(secondCategory,index) in secondCategories"/>
  </div>
</template>

<script>
import Panel from '@/components/Panel.vue'

export default {
  components:{Panel},
  data() {
    return {
   
    }
  },
  props:['secondCategories']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
