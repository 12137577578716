import request from '@/utils/request'

// 获得用户信息
export function getUserInfo() {
  return request({
    url: '/mobile/vip/index',
    method: 'get',
  })
}

// 获得验证码
export function getCode(data) {
  return request({
    url: '/mobile/code',
    method: 'post',
      params:data,
    // data:data
  })
}

// 获得购物车数量
export function rqGetCartCount() {
  return request({
    url: '/mobile/cart/count',
    method: 'get'
  })
}