<template>
  <div class="card shadow mg bd-radius-4" @click='onClick'>
    <slot name="header">
    </slot>
    <div class="father">
    <slot name="main">
      <div class='main flex'>
        <div class="img">
          <img width="100%" height="100%" :src="item[0].itemImageUrl" alt="" />
        </div>
        <div class="info-panel">
          <div class='truncate-2 '>{{ item[0].itemName }}</div>
          <div class="flex-j-b">
            <div class="text-v-center text-color-3">申请数量:{{ item[0].returnNum }}</div>
          </div>
        </div>
      </div>
    </slot>

    <slot name="footer">
            
    </slot>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  props:['item'],

  computed: {
    src() {
      return "../../images/default.jpg";
    },
  },
  methods:{
      onClick(){
          this.$emit('click')
      }
  }
};
</script>

<style lang='scss' scoped>
.card {
  background: white;
  margin-top: 7.5px;
  margin-bottom: 7.5px;

    .father{
        padding-bottom:7.5px;
    }
  .main {
    padding: 15px;
    .img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      border-radius: 4px;
      overflow: hidden;
    }
    .info-panel{
        display: flex;
        flex-grow:1;
        flex-direction: column;
        justify-content: space-between;
    }
  }
}
</style>
