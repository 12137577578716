import Vue from "vue";
import Vuex from "vuex";
import app from './modules/app'
import user from './modules/user'
Vue.use(Vuex);

export default new Vuex.Store({
  modules:{app,user},
  state: {
    cartCount: localStorage.getItem('cartCount') || "",

    addressId: "",
    orderInfo: {},
    totalMoney: 0,

    orderMoney: sessionStorage.getItem('orderMoney'),

    count:0
  },
  actions:{
    increment(context){
      context.commit('increment')
    },
  },
  mutations: {
    increment(state){
      state.count++
    },
    setTotalMoney(state, totalMoney) {
      state.totalMoney = totalMoney;
    },

    SET_CART_COUNT(state, num) {
      if (num ){
        state.cartCount = num;
        localStorage.setItem('cartCount',num)
      }else{
        state.cartCount = ''
        localStorage.removeItem('cartCount')
      }
    },

    setAddressId(state, id) {
      state.addressId = id;
    },
    setOrderInfo(state, orderInfo) {
      console.log(orderInfo);
      state.orderInfo = orderInfo;
      console.log(state.orderInfo);
    },
    setOrderInfoState(state) {
      state.orderInfo.state = 0;
    },

    setCurrentOrderInfo(state,orderMoney) {
      sessionStorage.setItem('orderMoney',orderMoney)
      state.orderMoney = orderMoney;
    },
  },
});
