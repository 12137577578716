<template>
  <div class="shadow order-wrapper text-color-1 text-md">
    <div class="card-header mg-b">
      <div class="flex-j-b">
        <span class="text-lg"> {{ getShopName }} </span>
        <!-- <icon size="4rem" color="#999" title="yiwancheng"></icon> -->
        <span
          :class="{ 'text-money': order.state === '等待付款' }"
          class="text-color-2"
        >
          {{ order.state }}
        </span>
      </div>
    </div>
    <div class="card-body mg-v">
      <slot name="card-body">
        <div v-if="order.list.length === 1">
          <van-card
            @click="
              $push({
                name: 'OrderDetail',
                params: { orderId: order.list[0].orderId },
              })
            "
            :num="order.list[0].buyNum"
            :price="order.list[0].wlPrice | money"
            :title="order.list[0].itemName"
            :thumb="order.list[0].itemImageUrl"
          />
        </div>
        <div v-else>
          <div
            @click="
              $push({
                name: 'OrderDetail',
                params: { orderId: order.id },
              })
            "
            class="flex-start"
          >
            <div
              class="mulImages"
              v-for="(item, index) in getOrderList(order.list)"
              :key="index"
            >
              <img
                class="right-img bd-radius-4"
                :src="item.itemImageUrl"
                alt=""
              />
              <div class="buynum">x{{ item.buyNum }}</div>
            </div>
            <div v-if="order.list.length > 4" class="right-img">
              <icon title="cc-plus-square" color="#ccc" size="75px"></icon>
            </div>
          </div>
        </div>
      </slot>
    </div>

    <div class="card-footer bottom tar bd-radius">
      <div class="mg-v flex-j-b text-black-800">
        <span class="bacd text-color-3">{{ order.createDate | datetime }}</span>
        <span v-if="!order.userPayment">
          共{{ getTotalCount }}本书，实付
          <span class="text-lg font-bold">
            <span class="text-md">￥</span
            >{{ order.alreadyPayed | money }}</span
          >
        </span>
        <span v-else>
          共{{ getTotalCount }}本书，应付
          <span class="text-lg font-bold">
            <span class="text-md">￥</span>{{ order.userPayment | money }}</span
          >
        </span>
      </div>

      <van-button
        class="mg-h"
        round
        plain
        v-if="order.stateId === 100 || order.stateId === 10"
        @click="rqCancleOrder(order.id)"
        type="primary"
        >取消订单</van-button
      >
      <van-button
        class="mg-h"
        round
        plain
        v-if="order.stateId === 10"
        @click="onPayment(order.userPayment, order.id)"
        type="primary"
        >支付</van-button
      >

      <van-button
        class="mg-h"
        round
        plain
        type="primary"
        v-if="order.stateId === 300"
        @click="
          $push({
            name: 'ReturnGoodsDetail',
            query: { serviceId: order.id },
          })
        "
        >申请退换货</van-button
      >
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins";
import { cancleOrder } from "@/api";
export default {
  mixins: [mixin],
  props: {
    order: Object,
  },
  computed: {
    getTotalCount() {
      let count = 0;
      for (let item of this.order.list) {
        count += item.buyNum;
      }
      return count;
    },
    getShopName() {
      return this.order.typesId === 1 ? "蔚蓝" : "教材";
    },
  },
  methods: {
    getOrderList(orderlist) {
      let list = [];
      if (orderlist.length > 4) {
        list.splice(0, 0, ...orderlist?.slice(0, 3));
      } else {
        list = orderlist;
      }
      return list;
    },
    onPayment(orderMoney, orderId) {
      this.goPayment(orderMoney, orderId);
    },
    rqCancleOrder(orderId) {
      this.$dialog
        .confirm({
          title: "取消订单",
          message: `温馨提示：
1.限时特价等购买优惠可能一并取消
2.已经使用的优惠券不会被返回
3.订单一旦取消，无法恢复
          `,
          messageAlign:'left'
        })
        .then(() => {
          // on confirm
          const data = {
            orderId: orderId,
          };
          cancleOrder(data).then((res) => {
            this.$toast(res.msg);
            this.$emit("cancelOrder", this.order);
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-wrapper {
  position: relative;
  background: white;
  border-radius: 6px;
  margin: 15px;
  padding: 10px;
  .status {
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: #444;
  }
  .flex {
    justify-content: space-around;
  }
}
.mg-h {
  margin-left: 3px;
  margin-right: 3px;
}
.van-button--plain.van-button--primary {
  height: 28px;
}

.order-wrapper {
  position: relative;
  background: white;
  border-radius: 6px;
  margin: 15px;
  padding: 10px;
  .status {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }
  .flex {
    justify-content: space-around;
  }
}
.van-button--plain.van-button--primary {
  height: 28px;
}

.order-list {
  .right-img {
    height: 75px;
    width: 75px;
    // margin: 0 3.125px;
    // background: red;
    object-fit: cover;
  }
}

.card-body.mg-v {
  margin: 20px 0;
}
.text-color-3.bacd {
  line-height: 1.4;
}
.mulImages {
  position: relative;
  height: 75px;
  width: 75px;
  border: 1px solid #eee;
  border-radius: 4px;
  &:not(:last-child) {
    margin-right: 5px;
  }
  .buynum {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 2px;
    border-radius: 4px;
    background: rgba(1, 1, 1, 0.4);
    color: white;
  }
}
</style>
