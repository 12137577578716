<template>
  <div class="code">
    <van-overlay :show="show" @click="$emit('click')">
      <div @click.stop class="subCode">
        <div class="text-black-800">为确保您的账户安全，需校验 <span class="text-black-300">（不验证大小写）</span></div>
        <van-form validate-first>
          <van-field
            v-model="code"
            center
            :border="false"
            name="pattern"
            clearable
            @input="validte"
            :rules="[{ validator,}]"
            placeholder="请输入验证码"
          >
            <template #button>
              <!-- <img :src="https://www.wl.cn/reg/veriValidCode" alt="" /> -->
              <img v-if="valiImgProp.base64Str"
                @click="refreshCodeImg"
                :src="'data:image/gif;base64,' + valiImgProp.base64Str"
                alt="" 
              />
            </template>
          </van-field>
        </van-form>

        <div class="tac">
          <van-button type="primary" round @click="onSubmit" class="button"
            >确定</van-button
          >
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import { getImgCode } from "@/api";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    phone: String,
    valiImg: Object,
    length: {
      default: 4,
      type: Number,
    },
  },
  data() {
    return {
      valiImgProp: {},
      code: "",
      isOK: false,
    };
  },
  watch: {
    valiImg(nv) {
      console.log(nv.value)
      this.valiImgProp = nv;
    },
  },
  mounted() {
    // this.refreshCodeImg()
  },
  methods: {
    codeUpdate() {
      this.showCode = false;
    },
    refreshCodeImg() {
      getImgCode().then((res) => {
        this.valiImgProp = res;
      });
    },
    validator() {
      if (this.code.toLowerCase()=== this.valiImgProp.value.toLowerCase()) {
        this.isOK = true;
      } else {
        this.isOK = false;
      }
      return this.isOK;
    },
    validte() {
      if (this.code.length > 4) {
        this.code = this.code.substr(0, 4);
      }
    },
    //   this.thisShow=true
    onSubmit() {
      if (this.isOK) {
        this.$emit("imgCodePassed", this.valiImgProp.value);
      }else{
        this.$toast('请检查验证码')
      }

      // loginPhone({ phone: this.phone, code: this.code }).then((res) => {

      //   if (res) {
      //     this.$router.push({ name: "Login2" });
      //   }
      // });
    },
  },
};
</script>
<style>
.code .van-cell {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
<style lang="scss" scoped>
.code {
}
.button {
  width: 80px;
  height: 35px;
  margin-top: 12px;
}
.subCode {
  background: white;
  border-radius: 10px;
  position: fixed;
  padding: 18px 0 12px;
  top: 50%;
  transform: translateY(-100%);
  left: 20px;
  right: 20px;
}
.text-black-800 {
  padding-left: 16px;
  margin-bottom: 16px;
}
</style>
