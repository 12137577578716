<template>
  <lDfault title="我的订单">
    <van-tabs v-model="activeSelf">
      <!-- 全部订单 待付款 已付款 已发货 已取消 -->
      <van-tab
        v-for="item in orderListState"
        :key="item.stateId"
        :title="item.title"
      >
        <!-- finished-text="没有更多了" -->

        <van-list
          v-model="item.loading"
          :finished="item.finished"
          @load="onLoad(item)"
        >
          <div v-if="item.list.length" class="order-list">
            <div v-for="(orderCardItem, index) in item.list" :key="index">
              <OrderItem @cancelOrder="onCancelOrder" :order="orderCardItem">
              </OrderItem>
              <!-- <OrderItemMul v-else :order="orderCardItem"></OrderItemMul> -->
            </div>
          </div>
          <div v-else>
            <div class="tac height">
              <img src="../../images/orderDefault.png" alt="" />
              <div class="mg-t text-lg text-black-400 tac">暂无订单</div>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>

    <Recommend class="recommend">
      <template #title>
        <div class="text-black-800 tac icontext">
          <icon title="xin" size="1.4rem"></icon>
          你可能还喜欢
        </div>
      </template>
    </Recommend>
  </lDfault>
</template>

<script>
import OrderItem from "./children/OrderItem.vue";
// import OrderItemMul from "./children/OrderItemMul.vue";
import { rqOrderList } from "@/api";
export default {
  components: {
    OrderItem,
    // OrderItemMul,
  },
  props: {
    active: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      show: false, // 筛选
      activeSelf: this.$route.query.active || this.active,
      orderListState: [
        {
          title: "全部订单",
          loading: false,
          finished: false,
          list: [],
          data: { pageNo: 1, stateId: "", dayNum: 365 * 5 },
        },
        {
          title: "待付款",
          loading: false,
          finished: false,
          list: [],
          data: { pageNo: 1, stateId: 10, dayNum: 365 * 5 },
        },
        {
          title: "已付款",
          loading: false,
          finished: false,
          list: [],
          data: {
            pageNo: 1,
            stateId: "100,101,102,103,107,108,701,702,703,704,705,706",
            dayNum: 365 * 5,
          },
        },
        {
          title: "已发货",
          loading: false,
          finished: false,
          list: [],
          data: { pageNo: 1, stateId: 300, dayNum: 365 * 5 },
        },
        {
          title: "已取消",
          loading: false,
          finished: false,
          list: [],
          data: { pageNo: 1, stateId: 601, dayNum: 365 * 5 },
        },
      ],
    };
  },
  methods: {
    onCancelOrder(canceldorder) {
      this.orderListState[0].list.forEach((v) => {
        if(v.id === canceldorder.id){
          v.stateId=601
        }
      });
      this.orderListState[1].list = this.orderListState[1].list.filter((v) => {
        return v.id !== canceldorder.id;
      });
    },
    async onLoad(order) {
      const res = await rqOrderList(order.data);
      if (res.status) {
        order.list.splice(order.list.length, 0, ...res.item);
        order.data.pageNo += 1;
        order.loading = true;
        this.onLoad(order);
      } else {
        order.loading = false;
        order.finished = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
}
.icontext {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.fixed-center {
  top: 40%;
}
.height {
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
</style>
