// 自动按需引入 Vant 组件

import {ShareSheet ,Empty ,NumberKeyboard ,Dialog  ,Overlay,Picker,Loading,ActionSheet ,DropdownMenu, DropdownItem , Form ,CountDown ,RadioGroup, Radio,Sticky ,Step, Steps, Checkbox, CheckboxGroup,SubmitBar ,Popup ,CouponCell, CouponList,AddressEdit, AddressList , Sku ,Stepper ,Cell, CellGroup , Tab, Tabs,GoodsAction, GoodsActionIcon, GoodsActionButton ,NavBar, Card ,Icon,Col, Row,TreeSelect ,Image as VanImage, List ,Grid, GridItem , Tabbar, TabbarItem, Button, Field, Switch, Toast,Swipe, SwipeItem,Search  } from 'vant'
let vantUIs = [ShareSheet ,Empty ,NumberKeyboard ,Dialog  ,Overlay,Picker,Loading,ActionSheet ,DropdownMenu, DropdownItem ,Form ,CountDown ,RadioGroup, Radio,Sticky , Step, Steps, Checkbox, CheckboxGroup,SubmitBar ,Popup ,CouponCell, CouponList,AddressEdit,AddressList , Sku , Stepper , Cell, CellGroup ,Tab, Tabs, GoodsAction, GoodsActionIcon, GoodsActionButton ,NavBar,Card , Icon,Col, Row,TreeSelect ,VanImage,List , Grid, GridItem ,Tabbar, TabbarItem, Button, Field, Switch, Toast,Swipe, SwipeItem,Search ]

export default {
  install (Vue) {
    vantUIs.forEach(vantUI => [
      Vue.component(vantUI.name, vantUI)
      // Vue.use(vantUI)    // 也可以使用 Vue.use()
    ])
  }
}


import lDfault from "@/templates/Default.vue";
import StepperDialog from "@/components/StepperDialog.vue";
import icon from '../components/Icon'
import Recommend from '../components/Recommend'
import BaseDefault from '../components/BaseDefault'
import BaseGoodsCard from '../components/BaseGoodsCard'
import BaseStepper from '../components/stepper'
import BaseDialog from '../components/dialog'
import BaseOverlay from '../components/overlay'
import Money from '../components/Money'
import BaseFor from '../components/vantFor/BaseFor'
import BaseDrawer from '../components/BaseDrawer'
import BaseShare from '../components/BaseShare'

let comp=[BaseShare,BaseFor,BaseDrawer,BaseDialog,BaseOverlay,BaseStepper,Money,BaseGoodsCard,BaseDefault,icon,Recommend,StepperDialog,lDfault]
let obj={
  install(Vue){
    comp.forEach(item=>{
      Vue.component(item.name,item)
    })
  }
}


export {obj}
