import request from "@/utils/request";
import qs from 'qs'
// 获取购物车信息
export function getJcCartInfo(params) {
    return request({
        url: "/mobile/order/user/cart",
        method: "get",
        params
    });
}

// 更新购物车信息
export function updateJcCartInfo(params) {
    return request({
        url: "/miniapp/user/cart/u",
        method: "get",
        params
    });
}

// 批量更新购物车信息
export function batchUpdateJcCartInfo(params) {
    let a =qs.stringify(params,{ indices: false })
    return request({
        url: "/miniapp/user/cart/batch/u",
        method: "POST",
        data:a
    });
}


// 删除购物车信息
export function delJcCartInfo(params) {
    return request({
        url: "/miniapp/user/cart/d",
        method: "get",
        params
    });
}
