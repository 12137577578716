<template>
  <lDfault title="提现">
    <template v-if="refundAccountOrderBills.length">
    <div
      class="bg-white bd-radius-4 mg pd shadow "
      v-for="(item, index) in refundAccountOrderBills"
      :key="index"
    >
      <div class="flex-j-b">
        <div class="text-lg line-height-2">订单编号：<span class="font-semibold">{{ item.orderId }}</span></div>
        <div class="text-black-600 line-height-2">
          本单金额：<span class="text-lg text-money font-semibold">{{ item.remain }}</span>
        </div>
      </div>
      <hr class="mg-v">
      <div class="line-height-5 text-black-800">
        <span>支付方式：{{ item.payType }}</span>
      </div>
      <div class="line-height-5 text-black-800">
        <span>支付单号：{{ item.payId }}</span>
      </div>
      <div class="flex-j-b text-black-300">
        <div class="text-v-center">{{ item.payDate }}</div>
        <van-button
          type="primary"
          size="small"
          @click="ononlineWithdraw(item.orderId, item.remain)"
          >申请退款</van-button
        >
      </div>
    </div>
    </template>
        <van-empty
     v-else
      class="custom-image"
      image="https://img.yzcdn.cn/vant/custom-empty-image.png"
      description="暂无记录"
    />
  </lDfault>
</template>

<script>
import lDfault from "@/templates/Default.vue";
import { queryWithdraw, onlineWithdraw } from "@/api";
export default {
  components: {
    lDfault,
  },
  data() {
    return {
      pageNo: 1,
      type: 0, //0表示查询近三个月交易名细 ,1表示查询三个月以前的交易明细
      refundAccountOrderBills: [], //充值记录
      dataCount: "", //总条数
      orderId: "",
      remain: 0,
      openUser: "",
      refundMethod: {},
      maxRefund: 0,
    };
  },
  mounted() {
    queryWithdraw({ pageNo: this.pageNo }).then((res) => {
      this.refundAccountOrderBills = res.refundAccountOrderBills;
      this.dataCount = res.dataCount;
    });
  },
  methods: {
    ononlineWithdraw(orderId, remain) {
      onlineWithdraw({ applyRefundMoney: remain, refundOrderId: orderId }).then(
        (res) => {
          if (res) {
            this.$toast("操作成功");
            this.$router.push({ name: "Balance" });
          } else {
            this.$toast("操作失败");
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
