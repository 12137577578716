<template>
  <div class="login">
    <lDfault title="登陆/注册">
      <main class="main">
        <MainLogin :fromRoute="fromRoute"></MainLogin>

        <div class="forget-sign">
          <router-link to="forgetPasswd">忘记密码?</router-link>
          <router-link to="signUp">快速注册</router-link>
        </div>

        <div v-if="wechatShow">
          <!--<div class="third-login-msg">微信登陆</div>
          <div class="thrid-login">
            &lt;!&ndash; <icon @click="doLoginTrhid('qq')" class="m-icon" title="qq" /> &ndash;&gt;
            <icon
              @click="doLoginTrhid('wx')"
              class="m-icon"
              title="weixindenglu"
              size="12vw"
            />
            &lt;!&ndash; <icon
            @click="doLoginTrhid('alipay')"
            class="m-icon"
            title="zhifubao"
          /> &ndash;&gt;
          </div>
          <div class="tac text-color-4 mg-t">
            未注册的手机号验证后将自动创建蔚蓝账号。
          </div>-->
        </div>
      </main>
    </lDfault>
  </div>
</template>

<script>
// @ is an alias to /src
import { getCode } from "@/api";
import MainLogin from "./children/MainLogin.vue";
import store from '../../store'

export default {
  components: {MainLogin },
  data() {
    return {
      wechatShow: false,
      code: "",
      isOk: false,
      fromRoute: {},
    };
  },
  async beforeRouteEnter(to, from, next) {
    if(store.state.user.isLogin){next(from)}

    let targetRoute = { name: "My" }; // route
    const permitBack = ["InputStudents","Home", "ShoppingCart"].indexOf(from.name) !== -1;
    if (permitBack) {
      targetRoute.name = from.name;
      targetRoute.query = from.query;
      targetRoute.params = from.params;
    }
    sessionStorage.setItem("loginRouter", JSON.stringify(targetRoute));

    next((vm) => {
      vm.fromRoute = targetRoute;
    });
  },

  mounted() {
    console.log();
    if (this.$isWeChatBrowser()) {
      //this.wechatShow = true;
    }
    // Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.3 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1 wechatdevtools/1.03.2009140 MicroMessenger/7.0.4 Language/zh_CN webview/16057766003974091 webdebugger port/53548
    // Mozilla/5.0 (iPhDone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1
  },
  methods: {
    passedCode(isOk) {
      this.isOk = isOk;
    },
    doLoginTrhid(way) {
      this.$router.push({ name: "ThirdLogin", params: { way } });
    },

    daojishi() {
      this.showDaojishi = false;
    },

    goCode() {
      const data = {
        phone: this.phone,
      };
      getCode(data);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {},
  },
};
</script>

<style lang="scss" scope>
.login {
  .main {
    padding: 40px 20px;
    .submit {
    }
  }
}

.third-login-msg {
  margin-top: 80px;
  margin-bottom: 10px;
  text-align: center;
  color: #b2b2b2;
  font-size: 12px;
  position: relative;
  &::after,
  &::before {
    content: "";
    display: block;
    height: 1px;
    width: 34%;
    background-color: #efefef;
    position: absolute;
    top: calc(50% - 1px);
  }
  &::before {
    left: 0%;
  }
  &::after {
    right: 0%;
  }
}
.thrid-login {
  padding: 0 20px;
  display: flex;
  justify-content: space-around;
}
.forget-sign {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  a {
    color: #07c160;
  }
}
</style>
