<template>
  <!-- 

    emit
    update-num
    cancel-update-num
   -->
  <div class="shoppingNum">
   <van-dialog
      @confirm="$emit('update-num',  count_self)"
      @close="$emit('cancel-update-num')"
      v-model="keyShow"
      show-cancel-button
    >
      <!-- <van-stepper v-model="count_self" step="1" /> -->
    </van-dialog>
 <!-- 
    <van-number-keyboard
      v-model="InputValue"
      :show="Keyshow"
      @input="onInput"
      @delete="onDelete"
      z-index="9999"
    /> -->
  </div>
</template>

<script>
import { Dialog } from "vant";
import Vue from "vue";
Vue.use(Dialog);

export default {
  name: "StepperDialog",
  components: { "van-dialog": Dialog.Component },
  props: ["keyShow", "count"],
  data() {
    return {
      show: true,
      Keyshow_self:this.keyShow,
      count_self: this.count,
      InputValue: "",
    };
  },
  watch: {
    keyShow_(nv) {
      this.Keyshow = nv;
    },
  },
  mounted() {
    // this.$dialog.alert({message:"弹窗内容"})
  },
  methods: {
    onInput(x) {
      console.log(x)
      // this.value = "" + this.value + x;
      // this.$toast(value);
    },
    onDelete() {},
  },
};
</script>

<style>
.shoppingNum .van-dialog__content {
  padding: 30px;
  text-align: center;
}
.shoppingNum .van-stepper__plus,
.shoppingNum .van-stepper__minus {
  height: 50px;
  width: 50px;
}
.shoppingNum .van-stepper__input {
  height: 56px;
  width: 50px;
}
</style>
