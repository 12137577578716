<template>
  <lDfault title="我的地址">
    <div>
      <van-address-list
        class="address-class"
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        :switchable="!!$route.params.type"
        @select="selectOne"
        @add="onAdd"
        @edit="onEdit"
      />
    </div>
    <div v-if="list.length <= 0">
      <div class="fixed-center">
        <img src="../../images/addressDefault.png" alt="" />
        <div class="mg-t text-lg text-black-400 tac">暂无地址</div>
      </div>
    </div>
  </lDfault>
</template>

<script>
// @ is an alias to /src
import { rqGetAddressList } from "@/api/index";
export default {

  props: {
    // 向上抛出地址信息
    type: {
      default: "",
      type: String,
      validator: (value) => {
        return ["OrderConfirm", ""].indexOf(value) !== -1;
      },
    },
    storeId: { type: String },
  },
  data() {
    return {
      chosenAddressId: "1",
      list: [],
    };
  },
  mounted() {
    rqGetAddressList().then((res) => {
      res.item.forEach((item) => {
        this.list.push({
          name: item.name,
          id: item.id,
          tel: item.phoneNum,
          address: item.streetAdd,
          isDefault: item.isDefault,
        });
      });
    });
  },
  methods: {
    selectOne(addressInfo) {
      this.$store.commit('set_currentAddress',addressInfo.id)

      this.$router.replace({
        name:this.$route.params.type,
        query: this.$route.query,
      });
    },
    onAdd() {
      this.$router.push({
        name: "AddAdress",
        params: { type: this.$route.params.type, storeId: this.storeId },
        query:this.$route.query
      });
    },
    onEdit(item) {
      this.$router.push({
        name: "EditAdress",
        params: {
          addressId: item.id,
          type: this.$route.params.type,
          storeId: this.storeId,
        },
        query:this.$route.query
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.address-class {
  margin-bottom: 50px;
}
</style>
