<template>
  <div class="search">
    <van-icon class="icon" name="search" />
    <span class="msg">{{msg}}</span>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  props:['msg'],
  components: {
  }
}
</script>

<style lang='scss' scoped>
.search{
  display: inline-block;
  background-color: #eee;
  width: 85vw;
  margin-left: 2vw;
  // margin: auto;
  border-radius: 100px;
  height: 8vw;
  .icon{
    // line-height: 8vw;
    margin-left: 3vw;
  }
  .msg{
    line-height: 8vw;
    margin-left: 3vw;
    margin-top: 1vw;
    color:#999;
  }
}
</style>
