<template>
  <lDfault title="物流信息">
    <van-cell-group>
      <van-cell title="快递公司" :value="sendMethodName" />
      <van-cell title="物流单号" :value="expressNo" />
    </van-cell-group>

        <div >
          <!--<div >
            <logistsics  :log="item"></logistsics>
          </div>-->
          <van-steps direction="vertical" :active="0"  v-for="(item, index) in logisticsItem" :key="index" >
            <van-step>
              <div>【城市】{{item.context}}</div>
              <p>{{item.ftime}}</p>
            </van-step>
          </van-steps>

<!-- <van-steps direction="vertical" :active="0">
  <van-step>
    <div>【城市】物流状态1</div>
    <p>2016-07-12 12:40</p>
  </van-step>
  <van-step>
    <h3>【城市】物流状态2</h3>
    <p>2016-07-11 10:00</p>
  </van-step>
  <van-step>
    <div>快件已发货</div>
    <p>2016-07-10 09:30</p>
  </van-step>
</van-steps> -->
        </div>


  </lDfault>
</template>

<script>
// @ is an alias to /src
import { getLogistics } from "@/api";
// import icon from "@/components/Icon.vue";
export default {

  props: {
    active: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
        orderId: this.$route.params.toOrderId,
        sendMethodName:"", //快递公司
        expressNo:"", //物流单号
        logisticsItem:"",//物流信息
    };
  },
  mounted() {
      const data = {
          orderId: this.orderId
      };
      getLogistics(data).then((res)=>{
          this.sendMethodName = res.companyName;
          this.expressNo = res.expressNo;
          this.logisticsItem = res.item;

  });
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.feedback {
}
</style>
