<template>
  <!-- button="编辑" @click-right="onEdit" -->
  <lDfault  class="shoppingcart" title="购物车">
    <div
      v-if="this.goodsList.bookList && this.goodsList.bookList.length > 0"
      class="goods"
    >
      <div class="shadow-xs mg-b">
        <van-field
          :border="false"
          v-model="batchNum"
          type="digit"
          label="数量"
          placeholder="请输入批量更新数量"
        >
        </van-field>
        <div class="flex-j-b bg-white pd-h pb-2">
          <van-checkbox
            checked-color="#FB4741"
            v-model="allChecked"
            @click="toggleAll"
            >全选
          </van-checkbox>
          <van-button type="info" size="small" @click="batchUpdate"
            >批量修改</van-button
          >
        </div>
      </div>
      <van-checkbox-group
        checked-color="#FB4741"
        v-model="selected"
        ref="checkboxGroup"
      >
        <div
          class="flex"
          v-for="(item, index) in goodsList.bookList"
          :key="index"
        >
              <van-checkbox  class='mg-v-half bg-white' :name="item.skuId" ref="checkboxes">

          <van-card
            class="w-100 bg-white "
            :price="item.skuPrice | money"
            :title="item.itemName"
          >
            <template #thumb>
              <van-image height="87" width="87" fit="contain" :src="item.img" />
            </template>
            <template #footer>
              <div @click.stop="deleteCart(item.skuId)">
                删除
              </div>
            </template>

            <template #desc>
              <van-stepper
                @click.native.stop="clickStepper"
                async-change
                @focus="onUpdateNum(item)"
                @change="changeGoodsNum(item.buyNum, item.skuId)"
                @plus="plus(item)"
                @minus="minus(item)"
                v-model="item.buyNum"
                integer
              />
            </template>
          </van-card></van-checkbox>
        </div>
        <!-- 商品 -->
      </van-checkbox-group>
      <StepperDialog
        @update-num="onConfirmUpdateNum"
        @cancel-update-num="onCancelUpdateNum"
        :keyShow_="keyShow"
        :goods="goods"
      ></StepperDialog>
    </div>
    <div v-else class="goods">
      <img src="../../images/shoppingCartDefault.png" alt="" srcset="" />
      <div class=" goods-desc tac text-black-700">
        你的书包空空如也，马上去选购
      </div>
    </div>

    <div>
      <van-submit-bar
        :price="goodsList.totalPrice * 100"
        button-text="去结算"
        @submit="onSubmit"
      >
      </van-submit-bar>

      <!-- <van-button type="info" is-link to="confirmOrder">去结算</van-button> -->
      <div class="stuffing"></div>
    </div>
  </lDfault>
</template>

<script>
// @ is an alias to /src
import {
  delJcCartInfo,
  getJcCartInfo,
  updateJcCartInfo,
  batchUpdateJcCartInfo,
} from "@/api";
import StepperDialog from "@/components/StepperDialog.vue";

function cartNumUpdate(item) {
  this.$toast.loading({
    message: "",
    forbidClick: true,
    loadingType: "spinner",
    duration: 0,
  });
  const data = {
    uid: localStorage.getItem("uid"),
    consumerCode: localStorage.getItem("consumerCode"),
    skuid: item.skuId,
    buyNum: item.buyNum,
  };
  console.log(data);
  updateJcCartInfo(data).then((res) => {
    this.$toast.clear();
    this.goodsList = res;
    if (res.failMessage) {
      this.$toast(res.failMessage);
    }
  });
}

export default {
  name: "JcCart",
  components: {
    StepperDialog,
  },
  data() {
    return {
      value: 3,

      selected: [],
      goodsList: {},
      allChecked: false,
      batchNum: "",

      keyShow: false,
      goods: "",
    };
  },
  watch:{
    selected(nv){
      console.log(nv.length,this.goodsList.bookList.length)
        this.allChecked=nv.length===this.goodsList.bookList.length
    }
  },
  computed: {
    totalPrice() {
      let countMoney = 0;
      this.selected?.forEach((element) => {
        countMoney += element.skuPrice * element.buyNum;
      });
      return countMoney;
    },
    hasGoods() {
      return this.goodsList.bookList.length > 0;
    },
  },
  mounted() {
    const data = {
      uid: localStorage.getItem("uid"),
      consumerCode: localStorage.getItem("consumerCode"),
      addressId:''
    };
    getJcCartInfo(data).then((res) => {
      this.goodsList = res;
    });
  },
  methods: {
    onUpdateNum(goods) {
      this.keyShow = true;
      this.goods = goods;
    },
    onCancelUpdateNum() {
      this.keyShow = false;
    },
    onConfirmUpdateNum(goods, value) {
      cartNumUpdate.call(this, goods, value);
    },
    plus(item) {
      cartNumUpdate.call(this, item);
    },
    minus(item) {
      cartNumUpdate.call(this, item);
    },
    changeGoodsNum(buyNum, skuId) {
      const data = {
        uid: localStorage.getItem("uid"),
        consumerCode: localStorage.getItem("consumerCode"),
        skuid: skuId,
        buyNum: buyNum,
      };
      data;
      /*updateJcCartInfo(data).then(res => {
                    this.goodsList = res
                })*/
    },
    clickStepper() {
      console.log(123321);
    },
    onEdit() {
      console.log("编辑");
    },
    deleteCart(skuid) {
      const data = {
        uid: localStorage.getItem("uid"),
        consumerCode: localStorage.getItem("consumerCode"),
        skuid: skuid,
      };

      delJcCartInfo(data).then((res) => {
        this.goodsList = res;
      });
    },
    addGoodsNum() {
      console.log("123");
    },
    minusGoodsNum() {
      console.log("123");
    },

    onSubmit() {
      if (this.goodsList.bookList.length === 0) {
        return this.$toast("请先选择教材再提交订单");
      }
      this.$push("confirmOrder");
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll(this.allChecked);
      console.log(this.selected);
    },
    batchUpdate() {
      let skuIds = [];
      for (let i = 0; i < this.selected.length; i++) {
        skuIds.push(this.selected[i]);
      }
      if (skuIds.length === 0) {
        this.$toast("至少选择一件商品");
        return;
      }
      if (!this.batchNum || this.batchNum < 0) {
        this.$toast("请输入正确的数量");
        return;
      }
      let data = {
        uid: localStorage.getItem("uid"),
        consumerCode: localStorage.getItem("consumerCode"),
        classId: localStorage.getItem("classId"),
        skuIds: skuIds,
        buyNum: this.batchNum,
      };
      batchUpdateJcCartInfo(data).then((res) => {
        this.goodsList = res;
        if(res.failStatus){
          this.$toast('修改完成,'+res.failMessage)
        }else{
          this.$toast('修改成功！')
        }
      });
    },
  },
};
</script>

<style>
.shoppingcart .van-stepper{
  text-align: right;
}
.shoppingcart .van-checkbox__label{
    width: 100vw;
}
.shopping-cart .van-card__thumb {
  text-align: center;
}

.shopping-cart .van-card__thumb .van-image {
  height: 80% !important;
  width: 80% !important;
}

.shopping-cart .van-submit-bar {
  bottom: 50px;
}

.re-title {
  margin-top: 22px;
  margin-bottom: 12px;
}

.van-checkbox {
  background-color: white;
  padding: 0 10px;
}

.van-checkbox__label {
  width: 100%;
}

.van-card {
  padding-left: 0px !important;
}

.check-box {
  margin-top: 8px;
}

.goods-desc {
  margin-top: -30px;
  margin-bottom: 60px;
}

.check-box.van-checkbox {
  display: none;
}
</style>
<style lang="scss" scoped>
.w-100.bg-white{
    background-color: white;
}
</style>
