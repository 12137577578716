import request from "@/utils/request";

// 订单确认
// shopId店铺ID必须
export function rqOrderConfirm(params) {
  return request({
    url: '/mobile/order/checkOrder',
    method: 'post',
    params,
    // data:params
  })
}

// 修改收货地址查询运费
// shopId 店铺ID 必须 Integer
// receiverId 收货地址ID 必须 Integer
export function rqCountFreight(params) {
  return request({
    url: '/mobile/countFreight',
    method: 'post',
    params,
    // data:params
  })
}

// 订单保存
// shopId 店铺ID 必须 Integer
// payType 支付类型 （1 支付宝 17 微信） 必须 Integer
// receiverId 收货地址ID 必须 Integer
// couponId 优惠券id 必须 Integer
// giftCartId 礼品卡Id 必须 Integer
// couponMoney 优惠券金额 必须 Integer
// giftMoney 礼品卡金额 必须 Integer
// pwd 支付密码 必须 Integer
export function rqOrderSave(params,loading=true) {
  return request({
    url: '/mobile/order/saveOrders',
    method: 'post',
    params,
    loading
  })
}

// 订单查询
// uid 用户ID 必须 Integer
// stateId 订单状态  Integer 全部传空  haspay(100, "已付款"),hasdelever(300, "已发货"),hasCancel(601, "已取消"),
// dayNum 天数
export function rqOrderList(params) {
  return request({
    url: '/mobile/order/selectOrderAll',
    method: 'post',
    params,
    // data:params
  })
}
// 查询订单明细
// orderId 订单ID
export function  selectOrderInfo(params) {
    return request({
        url: '/mobile/order/selectOrderInfo',
        method: 'post',
        params,
        // data:params
    })
}

// 取消订单
// orderId 订单ID
export function  cancleOrder(params) {
    return request({
        url: '/mobile/order/cancleOrder',
        method: 'post',
        params,
        // data:params
    })
}


//查询物流接口
export function  getLogistics (params) {
    return request({
        url:'/mobile/order/logistics',
        method: 'post',
        params,
        timeout:20*1000
    })
}


// 支付宝支付
// orderCode 订单编码 必须 Integer
// userId 用户ID 必须 Integer
// money 总金额 必须 Integer
// website 网址 必须 Integer
export function rqAliPay(params) {
  return request({
    url: '/mobile/OrderPayAlipay/aliPayByH5',
    method: 'post',
    params,
    // data:params
  })
}

// 微信支付
// orderCode 订单编码 必须 Integer
// userId 用户ID 必须 Integer
// money 总金额 必须 Integer
// website 网址 必须 Integer
export function rqWeixinPay(params) {
  return request({
    url: '/mobile/OrderPay/weiXinPayByH5',
    method: 'post',
    params,
    // data:params
  })
}
