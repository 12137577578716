<template>
  <lDfault
    title="选择支付"
    :toRouter="{ name: 'OrderList', params: { active: 1 } }"
  >
    <div class="order-msg">
      <div class="left">
        <van-icon size="40px" name="description"></van-icon>
      </div>
      <div class="right text-black-900">
        <p>订单已经生成</p>
        <p>网上支付订单等待支付</p>
        <p>
          您共需要支付：<span class="text-red-500">￥ {{ money }} </span>
        </p>
      </div>
    </div>
    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell
        v-if="!$isWeChatBrowser()"
          size="large"
          clickable
          @click="radio = '1'"
          title="支付宝支付"
          icon="shop-o"
        >
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <icon class="m-icon" title="zhifubao" />
          </template>
          <template #right-icon>
            <van-radio name="1" checked-color="#ff463c" />
          </template>
        </van-cell>
        <van-cell
          clickable
          size="large"
          @click="radio = '2'"
          title="微信支付"
          icon="shop-o"
        >
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <icon class="m-icon" title="weixindenglu" />
          </template>
          <template #right-icon>
            <van-radio name="2" checked-color="#ff463c" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>

    <div class="pd-h submitgroup">
      <div class="right text-black-900" id="url">
        <div v-html="payurl"></div>
      </div>
      <van-button
        color="#ff463c"
        round
        class="submit"
        type="primary"
        @click="onPayment"
        block
        >立即支付</van-button
      >
    </div>
  </lDfault>
</template>

<script>
// @ is an alias to /src

import lDfault from "@/templates/Default.vue";
import icon from "@/components/Icon.vue";
import { rqAliPay, rqWXPayByJSAPI, rqWXPay } from "@/api";

function jsSDK(params,that) {
  if (typeof window.WeixinJSBridge === "undefined") {
    if (document.addEventListener) {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          onBridgeReady(params,that);
        },
        false
      );
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", function () {
        onBridgeReady(params,that);
      });
      document.attachEvent("onWeixinJSBridgeReady", function () {
        onBridgeReady(params,that);
      });
    }
  } else {
    onBridgeReady(params,that);
  }
}

function onBridgeReady(params,that) {
  window.WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId: params.appId, // 公众号名称，由商户传入
      timeStamp: params.timeStamp, // 时间戳，自1970年以来的秒数
      nonceStr: params.nonceStr, // 随机串
      package: params.package,
      signType: params.signType, // 微信签名方式：
      paySign: params.paySign, // 微信签名
    },
    function (res) {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        that.$router.push({name:"PaymentFinished",query:{orderMoney:that.money,orderId:that.orderID}});
      }
    }
  );
}

export default {
  components: {
    lDfault,
    icon,
  },
  data() {
    return {
      money: this.$route.query.money,
      tradeno: this.$route.query.tradeno,
      radio: 1,
      payurl: "",

      //支付
      params: {},
    };
  },
  methods: {
    onPayment() {
      //alert(this.radio)
      //微信支付
      if (this.radio === "2") {
        console.log("微信支付支付---");
        if (this.$isWeChatBrowser()) {
          if (
            navigator.userAgent.toLowerCase().indexOf("micromessenger") != -1
          ) {
            rqWXPayByJSAPI({ tradeno: this.tradeno, money: this.money }).then(
              (res) => {
                if (res.status === "404") {
                  this.$toast("openId为空,请重新授权");
                } else {
                  let o = {
                    appId: res.appId, //公众号名称，由商户传入
                    timeStamp: res.timeStamp, //时间戳，自1970年以来的秒数
                    nonceStr: res.nonceStr, //随机串
                    package: res.package,
                    signType: res.signType, //微信签名方式：
                    paySign: res.paySign, //微信签名
                    Url: "/jcOrder", //支付成功跳转的地址
                  };
                  console.log(o);

                  this.params = o;
                  jsSDK.call( o,this);
                }
              }
            );
          }
        } else {
          const data = {
            orderCode: this.tradeno,
             money:this.money,
            //money: "0.01",
            website: window.location.origin,
          };
          rqWXPay(data).then((res) => {
            this.$nextTick(function () {
              window.location.replace(res.url);
            });
          });
        }
      } else {
        const data = {
          orderCode: this.tradeno,
          money:this.money,
          //money: "0.01",
          website: window.location.origin,
        };
        rqAliPay(data).then((res) => {
          this.payurl = res.requst_Html;
          this.scriptHtml = res.requst_Script;
          this.$nextTick(function () {
            eval("document.forms[0].submit()");
          });
        });
      }
    },
    toggle(index) {
      index;
      console.log(this.result);
      console.log(this.$refs);
    },
  },
  mounted() {},
};
</script>

<style lang='scss' scoped>

.order-msg {
  display: flex;
  align-items: center;
  background-color: #fcfcfc;
  padding: 10px 0px;
  .left {
    padding: 0 20px;
  }
  .right {
    p {
      margin: 5px 0;
    }
  }
  // justify-content: space-around;
}
.submitgroup {
  margin-top: 40px;
}
</style>
