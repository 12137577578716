<template>
  <div class="home">
    <!-- button="编辑" @click-right="onEdit" -->
    <lDfault class="shopping-cart" title="购物车">
      <template #title> </template>

      <BaseDefault :hasData="hasGoods">
        <van-radio-group v-model="storeId">
          <ShopCard
            v-if="h5List.length"
            storeTitle="蔚蓝书店"
            storeId="1"
            :list="h5List"
            @updateGoods="onUpdateGoods"
          >
          </ShopCard>
          <ShopCard
            v-if="textbookList.length"
            storeTitle="教材"
            storeId="2"
            :list="textbookList"
            @updateGoods="onUpdateGoods"
          >
          </ShopCard>
        </van-radio-group>
        <!-- 商品 -->
      </BaseDefault>

      <Recommend refresh="shoppingCart" @refresh="getShoppingCart">
        <template #title>
          <!-- <div class="tac text-black-700 re-title">

            更多你感兴趣的书籍
          </div> -->
          <div class="tac text-black-700 mg-t">
            <!-- 更多你感兴趣的书籍 -->
            <img
              width="80%"
              height="80%"
              src="../../images/gengduo.png"
              alt=""
              srcset=""
            />
          </div>
        </template>
      </Recommend>

      <van-submit-bar
        v-if="hasGoods"
        :price="(currentTotal || 0) * 100"
        button-text="去结算"
        @submit="onSubmit"
      >
      </van-submit-bar>
    </lDfault>
  </div>
</template>

<script>
// @ is an alias to /src
import ShopCard from "./children/ShopCard";
import { rqCartQuery, rqDefCart } from "@/api";

export default {
  name: "ShoppingCart",
  components: { ShopCard },
  data() {
    return {
      h5List: [1, 2, 3],
      textbookList: [],
      moneyTotal: 0,
      totalCount: 0,

      allChecked: false,

      goods: "",

      storeId: "1",
    };
  },
  computed: {
    currentTotal() {
      let total=0;
      if (this.storeId === "1") {
      total=  this.h5List.reduce((first, second) => {
          return first + second.currentPrice * second.count;
        }, 0);
      } else {
       total= this.textbookList.reduce((first, second) => {
          return first + second.currentPrice * second.count;
        }, 0);
      }

      return total;
    },
    hasGoods() {
      return !!this.moneyTotal;
    },
    total() {
      let money = 0;
      this.goodsList.cartItemsList.forEach((item) => {
        money += item.currentPrice;
      });
      // this.selected.forEach((v) => {
      //   let temp = v.count * v.currentPrice;
      //   money += temp;
      // });
      return money * 100;
    },
  },

  mounted() {
    this.getShoppingCart();
  },

  methods: {
    onUpdateGoods(goodsList) {
      this.moneyTotal = goodsList.total;
      this.totalCount = goodsList.totalCount;
      this.$store.commit("SET_CART_COUNT", goodsList.totalCount);
      this.filterGoodsList(goodsList);
    },

    filterGoodsList(data) {
      this.h5List = [];
      this.textbookList = [];
      data.cartItemsList.forEach((goods) => {
        if (goods.storeId === 1) {
          // h5
          this.h5List.push(goods);
        } else if (goods.storeId === 2) {
          // 教材
          this.textbookList.push(goods);
        }
      });
      this.storeId = this.h5List.length ? "1" : "2";
    },
    getShoppingCart() {
      rqDefCart();
      rqCartQuery().then((res) => {
        this.filterGoodsList(res);
        this.moneyTotal = res.total;
        this.totalCount = res.totalCount;
        this.$store.commit("SET_CART_COUNT", res.totalCount);
      });
    },

    onSubmit() {
        if(this.storeId === '1'){
            console.log("蔚蓝店铺");
            this.$genOrder({ storeId: this.storeId });
        }else {
            console.log("教材店铺");
            console.log("学校编码=="+  localStorage.getItem("consumerCode") );
            if ( localStorage.getItem("consumerCode") === null ){
                this.$push("InputStudents");
            }else {
                this.$push("confirmOrder");

            }

        }

    },
  },
};
</script>

<style></style>
<style lang="scss" scoped>
.bg-white {
  background-color: white !important;
}
</style>
