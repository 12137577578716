<template>
  <div class="panel shadow">
    <div class="row " >
      <!-- item是一个二级目录下辖多个三级目录 -->
      <span class="title">{{ secondCategory.name }}</span>
      <!-- <span>更多</span> -->
    </div>
    <van-row class="panel-box">
      <template
        v-for="(thirdCategoryList, index) in secondCategory.childCategorys"
      >
        <van-col class="item" :key="index" v-if="index < statusNum" span="8">
          <div @click='go(thirdCategoryList.link)' class="third-c text-sm text-black-800">
            {{ thirdCategoryList.name }}
          </div>
        </van-col>
      </template>
      <van-col
        class="item-2"
        @click="onOff"
        v-if="secondCategory.childCategorys.length > 6"
        span="8"
        :offset="offSet"
        style="t"
        >{{statusNum>5?'收起':'展开'}}</van-col
      >
    </van-row>
  </div>
</template>

<script>
export default {
  props: ["secondCategory", "index"],
  data() {
    return {
      statusNum: 0, //100 展开 5,6收拢
      offSet: 0,
    };
  },
  mounted() {
    this.statusNum = this.secondCategory.childCategorys.length > 6 ? 5 : 6;
  },
  methods: {
    go(data){
      data
      this.$router.push({name:'BookList',query:{category:data}})
      // this.$router.push({name:'BookList',params:{category:data.id}})
    },
    onOff() {
      if (this.statusNum === 5 || this.statusNum === 6) {
        this.statusNum = 100;
        if (this.secondCategory.childCategorys.length % 3 === 1) {
          this.offSet = 8;
        } else if (this.secondCategory.childCategorys.length % 3 === 0) {
          this.offSet = 16;
        }
      } else if (this.statusNum === 100) {
        this.offSet = 0;
        this.statusNum = this.secondCategory.childCategorys.length > 6 ? 5 : 6;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.panel {
  background-color: #fff;
  margin: 2vw;
  border-radius: 2vw;
  padding: 3vw 2vw;
  .panel-box {
    // max-height: 3rem;
    // overflow: hidden;
  }
  .panel-box {
    margin-top: 6px;
    .item {
      text-align: center;
      margin: 6px auto;
      padding: 0 4px;
      height: 22px;
      &:not(:nth-child(3n)) .sub-item {
        border-right: 1px solid red;
      }
    }
    .item-2 {
      text-align: center;
      margin-top: 1vw;
      margin-bottom: 1vw;
    }
  }
}
</style>
