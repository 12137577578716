import request from "@/utils/request";

// 获取所有优惠券
// page分页页数
export function rqGetCouponAll(params) {
  return request({
    url: "/mobile/vip/coupon/all",
    method: "get",
    params,
  });
}

// 礼品卡激活
// cardNum 卡号     
// password 密码 
export function rqGiftCardActive(params) {
  return request({
    url: "/mobile/vip/gifcard/active",
    method: "post",
    params,
  });
}

// 查询礼品卡
// page分页页数
export function rqGetGiftCard(params) {
  return request({
    url: "/mobile/vip/gifcard/all",
    method: "get",
    params,
  });
}
