<template>
  <div>
    <van-cell
      title="余额"
      @click="show = true"
      is-link
      :value="value"
    />
    <van-action-sheet
      v-model="show"
      :title="('您的账户可用余额为￥' + balanceable) | money"
    >
      <div class="content">
        <van-field
          v-model="count"
          type="number"
          label="使用"
          label-width="40"
          @input="onInput"
          :error-message="errMessage"
          :colon="true"
        >
          <template #button>
            <van-button size="small" @click="onSubmit" type="primary"
              >确认</van-button
            >
          </template></van-field
        >
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  props: {
    balanceable: {
      default: 0,
    },
    useGiftUnpaidMoney: { type: Number, default: 0 },
  },
  data() {
    return {
      show: false,
      value: "",
      count: null,
      errMessage: "",
    };
  },
  watch: {
    useGiftUnpaidMoney(nv) {
      this.count = nv > this.count ? this.count : nv;
      this.errMessage =''
      if (nv < this.count) {
        // 自动改
        this.value = "已使用" + this.count + "元";
      } else {
        this.value = "使用余额支付";
      }
      this.$emit("getBalance", this.count);
    },
  },
  mounted() {
    if (this.balanceable === 0) {
      this.value = "无可用余额";
    } else {
      this.value = "使用余额支付";
    }
  },
  methods: {
    onInput() {
      if (this.count > this.balanceable) {
        this.errMessage = "使用余额不能超过可用余额";
      } else if (this.count > this.useGiftUnpaidMoney) {
        this.errMessage = "使用余额不能超过剩余可支付金额";
      } else {
        this.errMessage = "";
      }
    },
    onSubmit() {
      if (
        this.count > this.balanceable ||
        this.count > this.useGiftUnpaidMoney
      ) {
        return;
      }
      this.show = false;
      this.value = this.count
        ? "已使用" + this.count + "元"
        : (this.value = "使用余额支付");
      this.$emit("getBalance", this.count);
    },
  },
};
</script>

<style lang="scss" scoped></style>
