<template>
  <div>
    <van-cell @click="show = true" title="礼品卡" is-link :value="value" />
    <van-action-sheet v-model="show" :title="'选择使用礼品卡'">
      <div class="content">
        <GiftCardList
          :giftList="giftList"
          :useGiftUnpaidMoney="useGiftUnpaidMoney"
          @hiddenGift='hiddenGift'
          @getGiftCard="handlerGiftInfo"
          v-on="$listeners"
        ></GiftCardList>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// @ is an alias to /src
import GiftCardList from "@/components/GiftCardList.vue";
export default {
  components: {
    GiftCardList,
  },
  props: {
    giftList: {
      type: Array,
    },
    useGiftUnpaidMoney:Number,
    useGiftMoneyCount:{type:Number,default:0}
  },
  data() {
    return {
      show: false,
      value: "",
      count: null,
      errMessage: "",
    };
  },
  watch: {
    giftList() {
      console.log(123321);
    },
    useGiftMoneyCount(){
      if(this.useGiftMoneyCount){
      this.value=`抵用了￥${this.useGiftMoneyCount}`
      }else{
      this.value=this.giftList.length + "张可用";
      }
    }
  },

  mounted() {
    if (this.giftList.length === 0) {
      this.value = "无可用礼品卡";
    } else {
      this.value = this.giftList.length + "张可用";
    }
  },
  methods: {
    handlerGiftInfo(){
      this.show=false
      this.value=`抵用了￥${this.useGiftMoneyCount}`
    },
    hiddenGift(){
      this.show=false
      this.value=this.giftList.length + "张可用";
    },
    onInput() {
      if (this.count > this.balanceable) {
        this.errMessage = "使用余额不能超过可用余额";
      } else {
        this.errMessage = "";
      }
    },
    onSubmit() {
      // if (this.count > this.balanceable) {
      //   return;
      // }
      // this.show = false;
      // if (this.count) {
      //   this.value = "已使用" + this.count + "元";
      //   this.$emit("getGiftCard", this.count);
      // }
    },
  },
};
</script>

<style lang="scss" scoped></style>
