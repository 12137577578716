<template>
  <lDfault :title="title">
    <main  class="mg-t sign-up">
      <van-form validate-first>
        <van-field
          v-model="passwd"
          :type="passwdEye ? 'password' : 'text'"
          :right-icon="passwdEye ? 'closed-eye' : 'eye-o'"
          label="密码"
          placeholder="请输入密码"
          @click-right-icon="onChangeType('passwd')"
        />
        <van-button class="submit" type="primary" @click="onSubmit" block>{{
          buttonText
        }}</van-button>
      </van-form>
    </main>
  </lDfault>
</template>

<script>
// @ is an alias to /src
import { updatePwd, registerPhone } from "@/api";
import lDfault from "@/templates/Default.vue";
// import icon from "@/components/Icon.vue";
export default {
  components: {
    lDfault,
  },
  // props: { code: String, way: String },
  data() {
    return {
code:'',way:'',
      

      passwd: "",
      passwdEye: true,
      title: "",
      buttonText: "",
    };
  },
  mounted() {
    const verify=JSON.parse(sessionStorage.getItem('verify'))
    this.way=verify.way
    this.code=sessionStorage.getItem('code')

    if (this.way === "signup") {
      this.title = "快速注冊";
      this.buttonText = "快速注册";
    } else {
      this.title = "找回密码";
      this.buttonText = "找回密码";
    }
  },
  methods: {
    onSubmit() {
      if (this.passwd.length < 6) {
        this.$toast("请输入六位以上字母与数字");
        return;
      }
      if (this.way === "signup") {
        registerPhone({
          phone: this.$route.params.phone,
          passwd: this.passwd,
          randomNum: this.code,
        }).then((res) => {
          console.log(res);
          if (res.status) {
            this.$toast("注册成功!");
            this.$router.replace({ name: "My" });
          } else {
            this.$toast(res.msg);
          }
        });
      } else {
        updatePwd({
          code: this.code,
          phone: this.$route.params.phone,
          password: this.passwd,
        }).then((res) => {
          if (res) {
            this.$router.push({ name: "Login" });
          } else {
            this.$toast("修改失败");
          }
        });
      }
    },
    onChangeType(type) {
      if (type === "passwd") {
        this.passwdEye = !this.passwdEye;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-up {
  padding: 0 30px;
  .submit {
    margin-top: 20px;
  }
}
</style>
