<template>
  <div @click="chong" class="sort-bar">
    <span class="flex-c " :class="{ active: preActive === '1' }" data-i="0"
      >默认</span
    >
    <!-- <span :class="{ active: preActive === 2 }" data-i="2">销量</span> -->
    <div
      class="xiaoliang"
      :class="{ active: preActive === '3' }"
      data-i="3"
      :reverse="reverse3"
    >
      价格
      <revers-triangle
        class="mg-l-half"
        :isActive="preActive == '3'"
        :active="reverse3"
      ></revers-triangle>
    </div>
    <span class="flex-c" :class="{ active: preActive === '5' }" data-i="5"
      >出版时间</span
    >
    <!-- <span class="flex-c" data-i="999"
      >筛选
      <icon title="shaixuan" size="1rem"></icon>
    </span> -->
  </div>
</template>

<script>
import reverstriangle from "@/components/reverseTriangle";
// import icon from "@/components/Icon.vue";

export default {
  components: {
    "revers-triangle": reverstriangle,
    // icon,
  },
  data() {
    return {
      preActive: '1',
      reverse3: false,
    };
  },
  methods: {
    chong(e) {
      let i = e.target.dataset.i||3;
      let sortType;
      console.log(i,this.reverse3,this.preActive)
      // 销量升序0
      // 销量降序1
      // 上架时间升序2
      // 上架时间降序3
      // 价格递增4
      // 价格递减5
      // 出版时间升序6

      // 出版时间降序7
      // 折扣递增8
      // 折扣递减9
      if (i == 1) {
        // 默认
        sortType = 1;
      } else if (i == 2) {
        // 销量
      } else if (i == 3 && this.reverse3) {
        this.reverse3 = !this.reverse3;
        // 价格 升序
        sortType = 4;
      } else if (i == 3 && !this.reverse3) {
        this.reverse3 = !this.reverse3;
        // 价格 降序
        sortType = 5;
      }else if(i===999){
        return
      } else {
        // 出版时间
        sortType = 7;
      }
      this.$emit("reLoad", sortType);
      this.preActive = i;
    },
  },
};
</script>
<style lang="scss" scope>
.sort-bar {
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  // padding-bottom: 10px;
  height: 30px;
  span,
  .xiaoliang {
    flex-grow: 1;
    text-align: center;
    &.active {
      color: #ff463c;
    }
    color: #777f86;
  }
  .xiaoliang {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
