<template>
  <div class="home">
    <top-search></top-search>
    <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in swipeList" :key="index">
        <a :href="item.url">
          <img :src="item.img" height="100%" width="100%" />
        </a>
      </van-swipe-item>
    </van-swipe>
    <HomeNav></HomeNav>

    <!-- 推荐模块 -->
    <div class="modal" v-for="(item, index) in modalList" :key="index">
      <div class="mg-t-half">
      <!-- <img v-if="getTitlePicture(item.title)" width="100%" height="100%" :src="getTitlePicture(item.title)" alt=""> -->
      <h2 class="modal-title"> {{ item.title }} </h2>
      </div>
      <van-grid class="book" :center="true" :column-num="3">
        <van-grid-item
          v-for="(book, index) in item.bookList"
          :key="index"
          :to="{name:'BookDetail',params:{skuId:book.skuId}}"
          :url='book.url'
        >
          <!-- @click="goBookDetail(book.skuId)" -->

          <div class="img-wrap">
            <img :src="book.img" alt height="100%" width="100%" />
          </div>
          <div class="title tac mg-t truncate">{{ book.name }}</div>
          <Money class="mg-t-half" :money='""+book.wl_price'></Money>
          <!-- <div class="title tac mg-t truncate">{{ book.name }}</div> -->
          <!-- <div class="sub-title">{{ book.subTitle }}</div> -->
          <!-- book.url -->
        </van-grid-item>
      </van-grid>
    </div>
    <div class="mg-b"></div>
    <Recommend>
      <template #title>
           <div >
      <img width="100%" height="100%" :src="getTitlePicture('精品推荐')" alt="">
      </div>
      </template>
    </Recommend>
  </div>
</template>

<script>
// @ is an alias to /src
import Money from "@/components/Money.vue";
import HomeNav from "@/components/HomeNav.vue";
import Recommend from "@/components/Recommend.vue";
import TopSearch from "@/components/TopSearch.vue";
import { swipe, modal } from "@/api";
export default {
  name: "Home",
  components: { Recommend ,TopSearch,Money,HomeNav},

  data() {
    return {
      swipeList: [],
      modalList: [],
      value: "",
    };
  },
  mounted() {
    swipe().then((res) => {
      this.swipeList = res;
    });
    modal().then((res) => {
      this.modalList = res;
    });
  },
  methods: {
    getTitlePicture(title){
      let result
      if(title==='首页推荐')result='shouyetuijian'
      else if(title==='编辑推荐')result='bianjituijian'
      else if(title==='精品推荐')result='jingpingtuijian'
      if(result){
      return require(`../images/${result}.png` )
      }
    },
    goBookDetail(skuId) {
      // const skuId=e.currentTarget.dataset.skuid
      this.$router.push({ name: "BookDetail", params: { skuId } });
    },
  },
};
</script>
<style>
.van-grid-item__content--center {
  justify-content: start !important;
}
</style>
<style lang="scss" >
.home {
  .swipe {
    height: 110px;
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      line-height: 150px;
      text-align: center;
      background-color: #39a9ed;
    }
  }
  .modal {
    .modal-title {
      text-align: center;
    }
    .book {
      .img-wrap {
        width: 80px;
        height: 80px;
        text-align: center;
      }
    }
  }
}
.home .van-grid-item__content .img-wrap img{
  object-fit: contain;
}
.van-grid-item {
  flex-grow: 0;
  flex-shrink: 0;
}
.title{
  width: 106px;
}
h2.modal-title {
    line-height: 1;
}
</style>
