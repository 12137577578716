import request from '@/utils/request'

// 轮播图
export function swipe() {
  return request({
    url: '/mobile/home/swipe',
    method: 'get',
  })
}

// 可配置模块
export function modal() {
  return request({
    url: '/mobile/home/modal',
    method: 'get',
  })
}

// 下拉推荐
export function rqRecommend(params) {
  // console.log(params)
  return request({
    url: '/mobile/home/recommendList',
    method: 'get',
    params
  })
}
// 下拉推荐
export function navigation() {
  // console.log(params)
  return request({
    url: '/mobile/home/navigation',
    method: 'get',
  })
}
