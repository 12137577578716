<template>
  <div class="gift-card-list">
    <van-checkbox-group v-model="result" @change="onChangeGiftCard">
      <div
        @click="$emit('get-gift', item)"
        class="mg pd bd-radius-4 bg-white shadow text-black-800"
        v-for="(item, index) in giftcardDtoList || giftList"
        :key="index"
      >
        <van-checkbox
          :class="{ active: componentType !== 'order' }"
          :name="item.id"
        >
          <div class="flex-j-b">
            <div>
              <icon title="wangguan" size="1.2rem"></icon>
              蔚蓝礼品卡
            </div>
            <div>有效期至：{{ item.valid | date }}</div>
          </div>
          <div class="mg">
            <div class="tac text-2xl text-black-900 font-semibold">
              {{ item.remain | money }}
            </div>
            <div class="tac mg-t-half">面额：{{ item.value | money }}</div>
          </div>
          <div class="flex-j-b">
            <div
              v-if="componentType !== 'order'"
              v-clipboard:copy="item.cardNum"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              卡号：{{ item.cardNum }}
              <icon title="svg" size="1rem"></icon>
            </div>
            <div v-else>卡号：{{ item.cardNum }}</div>
            <div>{{ item.state }}</div>
          </div>
        </van-checkbox>
      </div>
    </van-checkbox-group>

    <div v-if="componentType === 'order'" class="mg-t-bottom">
      <van-goods-action>
        <van-goods-action-button
          @click="onNoGiftCard"
          type="warning"
          text="不使用礼品卡"
        />
        <van-goods-action-button
          :disabled="!result.length"
          @click="onGiftCard"
          type="danger"
          text="使用礼品卡"
        />
      </van-goods-action>
    </div>
  </div>
</template>

<script>
import icon from "@/components/Icon.vue";
import { rqGetGiftCard } from "@/api";
import Clipboard from "clipboard";
export default {
  name: "GiftCardList",
  components: {
    icon,
  },
  props: {
    useGiftUnpaidMoney: Number,
    giftList: {
      type: Array,
    },
  },
  inject: {
    componentType: {
      default: "page", // 可选值 page order
    },
  },
  data() {
    return {
      giftcardDtoList: this.GiftCardInfo,
      page: 1,
      result: [],
    };
  },
  watch: {
    result(nv) {
      this.useGiftUnpaidMoney;
      let giftMoney = 0;
      this.giftList.forEach((v) => {
        nv.forEach((id,index) => {
          if (v.id === id) {
            giftMoney += v.remain;
            if (
              index > 0 &&
              this.useGiftUnpaidMoney <= giftMoney
            ) {
              this.$toast('礼品卡金额已经超过应支付金额')
              this.result.splice(index,1);
            }
          }
        });
      });
    },
  },
  mounted() {
    if (this.componentType === "page") {
      rqGetGiftCard({ page: this.page }).then((res) => {
        this.giftcardDtoList = res.giftcardDtoList;
      });
    }
  },
  methods: {
    onChangeGiftCard() {
      this.result;
    },
    onNoGiftCard() {
      this.result = [];
      this.$emit("hiddenGift");
    },
    onGiftCard() {
      let list = [];
      this.result.forEach((id) => {
        this.giftList.forEach((item) => {
          if (item.id === id) {
            list.push({ id, money: item.remain });
          }
        });
      });
      this.$emit("getGiftCard", list);
    },

    onCopy: function () {
      this.$toast("卡号复制成功");
    },
    onError: function () {
      this.$toast("当前不支持复制");
    },
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", () => {
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style>
.gift-card-list .van-radio__label {
  width: 100%;
}
.mg-t-bottom {
  margin-top: 60px;
}
.gift-card-list .active.van-checkbox .van-checkbox__icon {
  display: none;
}
</style>
