<template>
  <div :style='{"font-size":sized}' class="money text-base">
    <span :style='{"font-size":sized2}' class="text-sm">￥</span>
    <span>{{ count[0] }}.</span>
    <span :style='{"font-size":sized2}' class="text-sm">{{ count[1] }}</span>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name:"Money",
  props: { money: String ,size:Number  },
  computed: {
    count() {
      const temp = this.money.split(".");
      if (temp.length === 1) {
        temp.push("00");
      }else if(temp[1].length===1){
        temp[1]+='0'
      }
      return temp;
    },
    sized(){
      return this.size/3.75+'vw'
    },
    sized2(){
      return this.size/3.75*.6+'vw'

    }
    // size2(){

    // }
  },
};
</script>

<style lang='scss' scoped>
.money {
  color:#f2270c
}
</style>
