<template>
  <div class="detail">
    <h4
     class="bg-white text-black-900 pd mb-mx">出版信息</h4>
    <ul class="publish-info text-13 text-gray-700">
      <li>
        <span class="bookname text-gray-500">书名：</span>
        <div>
          {{ book.products&&book.products.name }}
        </div>
      </li>
      <li v-if="book.isbn">
        <span class="text-gray-500 ">ISBN：</span>{{ book.isbn }}
      </li>
      <li v-if="book.authorName">
        <span class="text-gray-500">作者：</span>{{ book.authorName }}
      </li>
      <li v-if="book.pressName">
        <span class="text-gray-500">出版社</span>{{ book.pressName }}
      </li>
      <li v-if="book.publishedDate">
        <span class="text-gray-500">出版时间：</span>{{ book.publishedDate }}
      </li>

      <li v-if="book.wordNum">
        <span class="text-gray-500">字数：</span>{{ book.wordNum }}
      </li>
      <li v-if="book.pageNum">
        <span class="text-gray-500">页数：</span>{{ book.pageNum }}
      </li>
      <li v-if="book.format">
        <span class="text-gray-500">开本：</span>{{ book.format }}
      </li>
      <li v-if="book.bookBinding">
        <span class="text-gray-500">包装：</span>{{ book.bookBinding }}
      </li>
    </ul>

    <div class="pd-h-3 bg-white pt-px pb-1">
      <div v-if="book.desc">
        <h4>内容简介</h4>
        <div class="text-13" v-html="book.desc "></div>
      </div>
      <div v-if="book.recommend">
        <h4>编辑推荐</h4>
        <div  class="text-13" v-html="book.recommend"></div>
      </div>
      <div v-if="book.index">
        <h4>目录</h4>
        <div  class="text-13" v-html="book.index "></div>
      </div>
      <div v-if="book.summary">
        <h4>摘要与插图</h4>
        <div  class="text-13" v-html="book.summary "></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
  props: ["book"],
  data() {
    return {
      subActive: 0,
    };
  },
  methods: {
     tagBr(value){
      return value.replaceAll('<br>','').replaceAll('<br/>','')
    }
  }
};
</script>
<style>
.book-detail .van-tabs--line .van-tabs__wrap{
  background: white;
  width:100%;
}
.book-detail .van-tabs__nav.van-tabs__nav--line{
  background: white;
  width:60%;
  margin: auto;
}
.pd-h-3.bg-white.pt-px.pb-1{
  line-height: 1.5;
}
</style>
<style lang="scss" scope>
.detail h4{
  margin-bottom: 0;
}

.detail {
  .publish-info {
    padding: 16px 40px;

    li {
      display: flex;

      span {
        width: 80px;
        flex-shrink: 0;
      }
      margin: 15px 0;
    }
  }
}
.sub-active {
  .van-tab.van-tab--active {
    .van-tab__text {
      color: #fc8181 !important;
    }
  }
  .van-tabs__line {
    height: 0 !important;
  }
}
.publish-info {
  background-color: white;
}
</style>
