<template>
  <div>
    <div
      v-for="(item, index) in goodsList"
      :key="index"
    >
      <van-card
      class="base-goodscard"
        :num="item.count"
        :price="item.currentPrice | money"
        :title="item.bookName"
        :thumb="item.imgUrl"
      >
        <img class="goodsItem" :src="item.imgUrl" alt="" />
      </van-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import icon from "@/components/Icon.vue";
export default {
  components: {
    // icon,
  },
  props: ["goodsList"],
  computed: {},
};
</script>
<style>

</style>
<style lang='scss' scoped>
.goodsItem {
  height: 60px;
  width: 60px;
  margin: 4px 4px;
}
.goods-list {
  display: flex;
}
.goods-sub-item {
  display: flex;
  width: 62%;
  overflow: scroll;
}
</style>
