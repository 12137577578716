<template>
    <lDfault title="个人教材信息">
        个人教材信息
    </lDfault>
</template>

<script>
    import lDfault from "@/templates/Default.vue";
    export default {
        components:{
            lDfault
        },
        name: "JcOrder"
    }
</script>

<style scoped>

</style>