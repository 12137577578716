<template>
  <lDfault title="教材线上发售系统">
    <van-form validate-first @failed="onFailed" @submit="onSubmit">
      <van-cell-group>
        <van-field
          readonly
          clickable
          name="schoolPicker"
          label="学校"
          :value="schoolName"
          placeholder="选择学校"
          :rules="[{ required: true, message: '请选择学校' }]"
          @click="showSchools = true"
        />
        <van-popup v-model="showSchools" round position="bottom">
          <van-picker
            show-toolbar
            :columns="schools"
            @cancel="showSchools = false"
            @confirm="onSchoolsConfirm"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          name="banJiPicker"
          label="班级"
          :value="banJiName"
          placeholder="选择班级"
          :rules="[{ required: true, message: '请选择班级' }]"
          @click="banJiEmpty()"
        />
        <van-popup v-model="showBanJi" round position="bottom">
          <van-picker
            show-toolbar
            :columns="banJi"
            @cancel="showBanJi = false"
            @confirm="onBanJiConfirm"
          />
        </van-popup>
        <van-field
          v-model="sNum"
          name="sNum"
          label="学号"
          placeholder="请输入学号"
          :rules="[{ required: true, message: '请填写学号' }]"
        />
      </van-cell-group>

      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </lDfault>
</template>
<script>
import lDfault from "@/templates/Default.vue";
import {
  isLogin,
  getSchools,
  getCascadeInfo,
  updateJcUser,
  checkToken,
} from "@/api";

// import {Toast} from 'vant';

export default {
  components: {
    lDfault,
  },
  data() {
    return {
      schoolName: "",
      showSchools: false,
      schools: [],
      schoolCode: "",
      showBanJi: false,
      banJiName: "",
      banJi: [],
      bCode: "",
      bName:"",
      sNum: "",
    };
  },
  mounted() {
    isLogin().then((res) => {
      //未登录跳转到登录页面
      if (res.status === -11) {
        this.$router.push("Login");
        return;
      }

      let token = localStorage.getItem("token");
      if (token) {
        checkToken({ token: token }).then((res) => {
          res;
        });
      }
    });
    getSchools().then((res) => {
      this.schools = res;
    });
  },
  methods: {
    onSchoolsConfirm(value, index) {
      // alert(value.code)
      this.schoolName = value.text;
      this.schoolCode = value.code;
      this.showSchools = false;
      //通过学校code查询班级信息
      getCascadeInfo({ consumerCode: value.code }).then((res) => {
        this.banJi = res;
      });
      this.banJiName = "";
      index;
    },
    onBanJiConfirm(value, index) {
      if (value === undefined) {
        return;
      }
      this.banJiName = value[0] + "," + value[1] + "," + value[2];
      this.showBanJi = false;
      //获取一级分类
      let one = this.banJi[index[0]];
      //获取二级分类
      let two = one.children[index[1]];
      //获取三级分类
      let three = two.children[index[2]];

      this.bCode = three.code;
      this.bName= value[2];
    },
    //判断是否选择了学校
    banJiEmpty() {
      if (this.schoolName === "") {
        this.$toast("请选择学校");
        return;
      }
      this.showBanJi = true;
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    onSubmit(values) {
      console.log("submit", values.schoolPicker);
      localStorage.setItem('consumer',values.schoolPicker)
      updateJcUser({
        consumerCode: this.schoolCode,
        bCode: this.bCode,
        sNum: values.sNum,
        bName:this.bName
      }).then((res) => {
        if (res.status === "200") {
          localStorage.setItem("token", res.token);
          localStorage.setItem("uid", res.uid);
          localStorage.setItem("consumerCode", res.consumerCode);
          localStorage.setItem("classId", this.bCode);
          this.$router.push({name:"ChooseBook"});
        }
      });
    },
  },
};
</script>
