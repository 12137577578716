<template>
  <lDfault title="礼品卡">
    <GiftCardList></GiftCardList>
    <van-cell class="mg-t" title="激活礼品卡" is-link to="activateGiftCard" />
  </lDfault>
</template>

<script>
// @ is an alias to /src
import GiftCardList from "@/components/GiftCardList.vue";
import lDfault from "@/templates/Default.vue";

export default {
  name: "GiftCard",
  components: {
    lDfault,GiftCardList
  },

  data() {
    return {

    };
  },
  mounted() {
    this.$store.commit('setOrderInfoState')
  },
  methods: {},
};
</script>

<style lang="scss" scope>
.gift {
  font-size: 4vw;
}
</style>
