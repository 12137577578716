import request from "@/utils/request";

//查看token是否过期
export function checkToken(params) {
    return request({
        url: "/miniapp/min/login",
        method: "post",
        params
    });
}

// 获取学校信息
export function getSchools() {
    return request({
        url: "/mobile/jc/consumer",
        method: "get",
    });
}

//获取级联班级信息
export function getCascadeInfo(params) {
    return request({
        url: "/mobile/jc/consumer/detail",
        method: "get",
        params
    });
}

//更新教材用户,保存学校班级信息
export function updateJcUser(params) {
    return request({
        url: "/mobile/jc/login",
        method: "post",
        params
    });
}