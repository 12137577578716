import request from "@/utils/request";
import qs from 'qs';
export * from './address'
export * from './bookDetail'
export * from './couponAndGiftCard'
export * from './home'
export * from './login'
export * from './mine'
export * from './order'
export * from './shoppingCart'
export * from './user'
export * from './textbook'
export * from './textbook/login'
export * from './textbook/chooseBook'
export * from './textbook/jcCart'
export * from './textbook/confirmOrder'
export * from './textbook/jcPayment'

// 获取热点搜索
export function rqGetHotSearch() {
  return request({
    url: "/mobile/search/getSearchDatas",
    method: "get",
  });
}

// 保留搜索记录
export function rqSaveSearchRecord(data) {
    return request({
        url: "/mobile/search/saveSearchRecord",
        method: "get",
        params: data,
    });
}
// 获取搜索记录
export function rqGetSearchRecord() {
    return request({
        url: "/mobile/search/getSearchRecord",
        method: "get",
        headers:{}
    });
}


// 搜索接口
// categoryType 查询的分类id 是  1
// keywords 搜索关键词 是
// wl_price_begin 搜索的蔚蓝价格范围开始
// wl_price_end 搜索的蔚蓝价格范围结束
// orderBy 排序的参数
// discount_begin 折扣范围
// discount_end 折扣范围
// pageNo 分页页数 是  1
export function rqSearch(data){
  return request({
    url: "/search/ajax",
    method: "get",
    params: data,
    // data:data
  });
}

// 获取所有收藏
// page
// typeId 收藏夹id,0 未分类,-1降价商品
// orderType 排序的类型 收藏时间1 降价幅度2
export function rqGetBookmark(data) {
  return request({
    url: '/mobile/bookmark',
    method: 'get',
    params:data,
  })
}
// 添加收藏
export function rqAddBookmark(data) {
  return request({
    url: '/mobile/bookmark/add',
    method: 'post',
    params:data,
  })
}


// 批量删除收藏
// skuIds图书skuid集合
export function rqDelBookmarks(data) {
  data =qs.stringify(data,{ indices: false })
  console.log(data)
  return request({
    url: '/mobile/bookmark/moredel',
    method: 'post',
    data,
  })
}
// 删除收藏
export function rqDelBookmark(data) {
  return request({
    url: '/mobile/bookmark/del',
    method: 'delete',
    params:data,
  })
}

// 修改个人信息
// sex 性别0女 1男
// rname 昵称
// provinceId 省id
// cityId 市id
// countyId 县id
// location 详细地址
export function rqUpdatInfo(data) {
  return request({
    url: '/mobile/vip/person/update/basis',
    method: 'post',
    params:data,
  })
}

// 浏览历史
// pageNum分页页数
export function rqGetHistory(params) {
  return request({
    url: '/mobile/vip/browsing/query',
    method: 'get',
    params
  })
}

// 新增客户反馈
export function rqComplainAdd(params) {
  return request({
    url: '/mobile/vip/complaints/add',
    method: 'post',
    params
  })
}

// 客户反馈查询
// page分页页数
export function rqGetComplaintsAll(params) {
  return request({
    url: '/mobile/vip/complaints/all',
    method: 'get',
    params
  })
}
// 查询客服
export function rqGetServicePhone() {
  return request({
    url: '/mobile/category/queryCustomerServicsPhoneAll',
    method: 'post',
  })
}
// 查询投诉信箱
export function rqGetServiceEmail() {
  return request({
    url: '/mobile/category/queryCustomerServicsEmailAll',
    method: 'post',
  })
}

// 保存系统默认头像
// dfId头像id
export function rqSavePhoto(params) {
  return request({
    url: '/mobile/vip/person/save/photo',
    method: 'post',
    params
  })
}

// 修改头像
// myPhoto头像
export function rqUpdatePhoto(params) {
  return request({
    url: '/mobile/vip/person/update/photo',
    method: 'post',
    params
  })
}

// 查询默认头像
export function rqGetPhoto(params) {
  return request({
    url: '/mobile/vip/person/edit/photo',
    method: 'get',
    params
  })
}



// 获取分类
export function category() {
  return request({
    url: '/mobile/category/all',
    method: 'get',
  })
}
// 获取分类
export function wxSDKConfig(params) {
  return request({
    url: 'mobile/share/config',
    method: 'get',
    params
  })
}
