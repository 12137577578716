// 地址
import Address from '../views/address/Address.vue'
import AddAdress from '../views/address/AddAdress.vue'

import Collection from '../views/personalCenter/Collection.vue'
import Comment from '../views/personalCenter/Comment.vue'
import Coupon from '../views/personalCenter/Coupon.vue'
import Feedback from '../views/personalCenter/Feedback.vue'
import MyFeedback from '../views/personalCenter/MyFeedback.vue'
import GoFeedback from '../views/personalCenter/GoFeedback.vue'
import History from '../views/personalCenter/History.vue'
import GiftCard from '../views/personalCenter/giftcard/GiftCard.vue'
import ActivateGiftCard from '../views/personalCenter/giftcard/ActivateGiftCard.vue'

import MyInfo from '../views/personalCenter/MyInfo.vue'
import PostSaleList from '../views/returnGoods/PostSaleList.vue'
import ReturnGoodsDetail from '../views/returnGoods/ReturnGoodsDetail.vue'

// 余额
import Balance from '../views/personalCenter/balance/Balance.vue'
import Recharge from '../views/personalCenter/balance/Recharge.vue'
import RechargeRecord from '../views/personalCenter/balance/RechargeRecord.vue'
import Withdraw from '../views/personalCenter/balance/Withdraw.vue'
import UserBalanceLog from "../views/personalCenter/balance/UserBalanceLog.vue";


let routes = [
    {
        path: '/activateGiftCard',
        name: 'ActivateGiftCard',
        component: ActivateGiftCard,
    },
    {
        path: '/address/:type?/:storeId?', // 是哪种方式进入的
        name: 'Address',
        component: Address,
        props:true
    },
    {
        path: '/addAdress/:type?/:storeId?',
        name: 'AddAdress',
        component: AddAdress,
    },
    {
        path: '/balance',
        name: 'Balance',
        component: Balance,
    },
    {
        path: '/postSaleList',
        name: 'PostSaleList',
        component: PostSaleList,
    },
    {
        path: '/returnGoodsDetail',
        name: 'ReturnGoodsDetail',
        component: ReturnGoodsDetail,
        props:true
    },
    {
        path: '/editAdress/:addressId/:type?/:storeId?',
        name: 'EditAdress',
        component: AddAdress,
        props:true,
    },
    {
        path: '/collection',
        name: 'Collection',
        component: Collection,
   
    },
    {
        path: '/comment',
        name: 'Comment',
        component: Comment,
     
    },
    {
        path: '/coupon',
        name: 'Coupon',
        component: Coupon,
        meta: {
            showTab: true
        }
    },
    {
        path: '/feedback',
        name: 'Feedback',
        component: Feedback
    },
    {
        path: '/myFeedback',
        name: 'MyFeedback',
        component: MyFeedback
    },
    {
        path: '/goFeedback',
        name: 'GoFeedback',
        component: GoFeedback
    },
    {
        path: '/history',
        name: 'History',
        component: History
    },
    {
        path: '/giftcard',
        name: 'GiftCard',
        component: GiftCard
    },
    {
        path: '/myInfo',
        name: 'MyInfo',
        component: MyInfo
    },
    {
        path: '/recharge',
        name: 'Recharge',
        component: Recharge
    },
    {
        path: '/rechargeRecord',
        name: 'RechargeRecord',
        component: RechargeRecord
    },
    {
        path: '/withdraw',
        name: 'Withdraw',
        component: Withdraw
    },{
        path:"/UserBalanceLog",
        name: "UserBalanceLog",
           component: UserBalanceLog,
           props: true,
       },
]

export default routes