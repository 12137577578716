<template>
  <div @click.stop class="stepper">
    <button
      :class="{ disabled: count_self <= '1' }"
      :disabled="count_self <= '1'"
      @click="onMinus"
      class="minus"
    ></button>
    <input
      ref="input"
      @input.prevent="$emit('input', $event.target.value)"
      @click.prevent="onFocus"
      :value="count_self"
      class="input"
      type="tel"
    />
    <button :disabled='Number(count_self)>=maxnum' @click="onPlus" class="plus"></button>
  </div>
</template>

<script>
export default {
  model: {
    prop: "count_self", //指向props的参数名
  },
  props: {
    maxnum:Number,
    mininum:Number,
    focus: {
      type: Boolean,
      default: false,
    },
    count_self: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
      this.$refs.input.select();
    }
  },
  methods: {
    onInput(v) {
      console.log(v, 123321);
      if (this.focus) console.log(v, 12);
    },
    onFocus(e) {
      if (!this.focus) {
        document.activeElement.blur();
        console.log(123);
      }
      document.activeElement.blur();

      console.log(321);

      e.currentTarget.select();

      this.$emit("foucs");
    },
    onPlus() {
      this.$emit("update", Number(this.count_self) + 1);
    },
    onMinus() {
      this.$emit("update", Number(this.count_self) - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  font-size: 0;
  -webkit-user-select: none;
  user-select: none;

  .minus.disabled {
      color:#999;
      background: #eee;
      // background: rgba(255, 255, 255, 0.1);
  }

  .plus {
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: currentColor;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      content: "";
    }
  }
  .input {
    box-sizing: border-box;
    width: 8.533vw;
    height: 7.467vw;
    margin: 0 0.533vw;
    padding: 0;
    color: #323233;
    font-size: 3.733vw;
    line-height: normal;
    text-align: center;
    vertical-align: middle;
    background-color: #f2f3f5;
    border: 0;
    border-width: 1px 0;
    border-radius: 0;
    -webkit-appearance: none;
  }
  .plus,
  .minus {
    position: relative;
    box-sizing: border-box;
    width: 7.467vw;
    height: 7.467vw;
    margin: 0;
    padding: 0;
    color: #323233;
    vertical-align: middle;
    background-color: #f2f3f5;
    border: 0;
    cursor: pointer;
    &::before {
      width: 50%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: currentColor;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      content: "";
    }
    &::after {
      width: 1px;
      height: 50%;
    }
  }
}
</style>
