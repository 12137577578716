import InputStudents from '../views/textbook/InputStudents.vue'
import ChooseBook from '../views/textbook/chooseBook/'
import ConfirmOrder from '../views/textbook/ConfirmOrder.vue'
import JcPayment from '../views/textbook/JcPayment.vue'
import JcCart from '../views/textbook/JcCart.vue'
import JcOrder from '../views/textbook/JcOrder.vue'
import JcReturnBook from '../views/textbook/JcReturnBook.vue'
import JcOrderList from '../views/textbook/JcOrderList.vue'
const routes=[
    {
        path:"/inputStudents",
        name:"InputStudents",
        component:InputStudents,
    },
    {
        path:"/jcPayment",
        name:"JcPayment",
        component:JcPayment,
        props:true
    },
    {
        path:"/chooseBook",
        name:"ChooseBook",
        component:ChooseBook,
    },
    {
        path:"/confirmOrder",
        name:"ConfirmOrder",
        component:ConfirmOrder,
        props:true
    },
    {
        path:"/jcCart",
        name:"JcCart",
        component:JcCart,
    },
    {
        path:"/jcOrder",
        name:"JcOrder",
        component:JcOrder,
    },
    {
        path:"/jcReturnBook",
        name:"JcReturnBook",
        component:JcReturnBook,
    },{
      path:"/JcOrderList",
      name:"JcOrderList",
      component:JcOrderList,
    }
]

export default routes