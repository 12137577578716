<template>
  <lDfault
    class="collection"
    title="我的收藏"
    @click-right="onManager"
    :rb='bookList1.length>0&&"管理"'
  >
  <div v-if="bookList1.length">
        <!-- 列表 -->
        <van-list
          v-model="loading1"
          :finished="finished1"
          finished-text="没有更多了"
          @load="onLoad(1)"
        >
          <van-checkbox-group
            :class="{ no_active: !isManager }"
            v-model="delBookMarkList"
          >
            <van-checkbox
            class=" mg-v shadow"
              v-for="(item, index) in bookList1"
              :key="index"
              :name="item.bookId"
            >
              <van-card
                class="bg-white card"
                :price="item.nowPrice | money"
                :origin-price="item.bprice"
                :title="item.name"
                @click="$push({ name: 'BookDetail', params: {skuId:item.bookId} })"
              >
                <template #thumb>
                  <van-image
                    height="87"
                    width="87"
                    fit="contain"
                    :src="'http://img.welan.com/s/' + item.imgSrc"
                  />
                </template>

                <template #num>
                  <div>
                    <van-goods-action-icon
                      icon="cart-o"
                      color="red"
                      @click.native.stop="$addShoppingCart(item.bookId)"
                    />
                  </div>
                </template>
              </van-card>
            </van-checkbox>
          </van-checkbox-group>
        </van-list>

  </div>
    <div v-else>
      <div class="fixed-center">
        <img src="../../images/bookmarkDefault.png" alt="" />
        <div class="mg-t text-lg text-black-400 tac">暂无收藏记录</div>
      </div>
    </div>
    <van-button
      v-if="isManager"
      @click="onDelBookMarks"
      class="deleteButton"
      type="danger"
      round
      block
      >删除</van-button
    >
  </lDfault>
</template>

<script>
// @ is an alias to /src

import { rqGetBookmark, rqDelBookmarks } from "@/api/index";
export default {
  components: {
  },
  data() {
    return {
      random:'',
      result: [],

      isManager: false,
      active: 1,
      subActive: 1,
      bookList1: [],
      loading1: false,
      finished1: false,
      pageOn1: 1,

      delBookMarkList: [],
    };
  },
  watch: {
    active() {
      this.result.splice(0, this.result.length);
    },
  },
  mounted() {
    this.onLoad(1)
  },
  methods: {

    onManager() {
      if (this.isManager) {
        this.isManager = false;
      } else {
        this.isManager = true;
      }
    },
    onDelBookMarks() {
      rqDelBookmarks({ skuIds: this.delBookMarkList }).then((res) => {
        if(res){
          location.reload()
        }
      });
    },

    onLoad(orderType) {
      const that = this;
      const data = {
        page: this["pageOn" + orderType],
        typeId: 0, //收藏夹id,0 未分类,-1降价商品
        orderType, //排序的类型 收藏时间1 降价幅度2
      };
      rqGetBookmark(data).then((res) => {
        // 加载状态结束
        that["loading" + orderType] = false;
        res.bookMarkDTOs.forEach((item) => {
          that["bookList" + orderType].push(item);
        });

        that["pageOn" + orderType]++;
        if (res.bookMarkDTOs.length === 0) {
          // 数据全部加载完成
          that["finished" + orderType] = true;
        }
      });
    },

  },
};
</script>
<style>
.collection .van-icon.van-icon-cart-o.van-goods-action-icon__icon {
  color: red;
  border: 1px solid red;
  padding: 2px;
  border-radius: 6px;
}
.van-checkbox-group.no_active .van-checkbox__icon.van-checkbox__icon--round {
  display: none;
}
</style>
<style lang="scss" scoped>
.collection {
  font-size: 4vw;
}
.van-card__price {
  color: #f22b2b !important;
}
.deleteButton {
  position: fixed;
  bottom: 15px;
  width: 90%;
  margin: auto;

  // width: 80%;
  left: 5%;
  // right: 10px;
}
</style>
<style scoped>
.collection .bg-white.card{
  background-color: white ;
  margin: 10px;
}
</style>
