<template>
  <lDfault class="order-result" title="订单结算">
    <van-address-list
      class="tebiedete"
      :list="areaList"
      :switchable="false"
      default-tag-text="默认"
      @click-item="onSelectAddress"
    />

    <OrderCard
      :useGiftUnpaidMoney="useGiftUnpaidMoney"
      :useGiftMoneyCount="useGiftMoneyCount"
      :orderInfo="orderInfo"
      @getInvoice="getInvoice"
      @getCouponInfo="getCouponInfo"
      @getMessage="getMessage"
      @getBalance="getBalance"
      @getGiftCard="getGiftCard"
      @hiddenGift="hiddenGift"
      v-on="$listeners"
    ></OrderCard>

    <div class="dianpian"></div>
    <van-submit-bar
      :price="totalMoney * 100"
      button-text="提交订单"
      @submit="onSubmit"
    />
  </lDfault>
</template>

<script>
import { rqOrderConfirm, rqOrderSave } from "@/api";
import mixin from "@/mixins";
import OrderCard from "./children/OrderCard";
export default {
  name: "OrderConfirm",
  components: {
    OrderCard,
  },
  mixins: [mixin],
  provide: {
    componentType: "order",
  },

  data() {
    return {
      // addressInfo:this.$store.state.app.currentAddress,
      // 地址
      addressInfo: {},

      storeId: this.$route.query.storeId,
      bookId: this.$route.query.bookId || "",
      buyNum: this.$route.query.buyNum || "",

      orderStatus: "", //订单保存状态
      count: "", // 数量
      buyStatus: "", // 是否是直接购买
      buyList: "",

      coupons: [],
      disabledCoupons: [],
      // showList: true,

      chosendCoupon: {},
      // 发票
      invoiceTitle: "",
      invoiceCode: "",
      // 留言
      message: "",
      //用户余额
      useBalance: "",
      // 使用余额
      //优惠券ID
      couponId: "",
      //优惠金额
      couponMoney: "",

      giftIds: "",
      giftMoney: 0,

      areaList: [],
      orderInfo: {},
    };
  },
  computed: {
    // 优先扣除优惠券的钱 再次扣除礼品卡 最后扣除余额
    // 礼品卡跟着优惠券走 余额跟着礼品卡走

    // 使用优惠券后还需支付
    useCouponedCount() {
      return this.orderInfo.sumPrice - Number(this.chosendCoupon?.reduce ?? 0);
    },
    // 礼品卡抵用了多少钱
    useGiftMoneyCount() {
      return Number(this.giftMoney ?? 0) > this.useCouponedCount
        ? this.useCouponedCount
        : Number(this.giftMoney ?? 0);
    },
    // 使用优惠券和礼品卡后还需支付多少钱 | 余额最多能抵用多少钱
    useGiftUnpaidMoney() {
      return this.useCouponedCount - this.useGiftMoneyCount;
    },
    totalMoney() {
      let count = (this.useGiftUnpaidMoney*10*10 - Number(this.useBalance ?? 0)*10* 10)/100;

      return isNaN(count) ? 0 : count.toFixed(2);
    },
  },
  watch: {
    totalMoney(nv) {
      this.$store.commit("setTotalMoney", nv);
    },
  },

  async mounted() {
    await this.getOrderInfo();
    if (this.$route.query.buyNum) {
      // 单行本
      this.setSingleOrderInfo();
    }
  },
  methods: {
    setSingleOrderInfo() {
      this.orderInfo.itemInfo.splice(
        0,
        this.orderInfo.itemInfo.length,
        ...[
          {
            bookId: this.$route.query.bookId,
            imgUrl: this.$getPicture(this.$route.query.imgUrl),
            bookName: this.$route.query.bookName,
            currentPrice: Number(this.$route.query.money),
            count: Number(this.$route.query.buyNum),
            storeId: this.$route.query.storeId,
          },
        ]
      );
    },
    async getOrderInfo() {
      const data = {
        shopId: this.storeId,
        addressId: this.$store.state.app.currentAddressId || "",
        sumPrice: this.bookId && this.bookId + "_" + this.buyNum,
      };
      // 检查订单
      const orderInfoResult = await rqOrderConfirm(data);
      // res.status || this.addressInfo;
      this.hasAdress(orderInfoResult.status);
      this.setAddress(orderInfoResult?.item[0]?.userRecieve[0]);

      this.orderInfo = orderInfoResult.item[0];
    },
    hasAdress(recieveStatus) {
      if (recieveStatus == "false") {
        this.$dialog
          .confirm({
            title: "提示",
            message: "请填写收货地址",
          })
          .then(() => {
            // on confirm  //确定 进入收货地址系只能
            this.$router.push({
              name: "AddAdress",
              params: {
                storeId: this.storeId,
                type: "OrderConfirm",
                isSelect: true,
              },
              query: this.$route.query,
            });
          })
          .catch(() => {
            // on cancel //取消 返回购物车
            this.$router.push({ name: "ShoppingCart" });
          });
      }
    },

    setAddress(address) {
      this.areaList[0] = address;
      this.areaList[0].name = address.name;
      this.areaList[0].tel = address.phoneNum;
      this.areaList[0].address = address.streetAdd;
      this.areaList[0].isDefault = address.isDefault;
    },

    onSelectAddress() {
      this.$router.push({
        name: "Address",
        params: { type: "OrderConfirm", storeId: this.storeId },
        query: this.$route.query,
      });
    },

    hiddenGift() {
      this.giftMoney = "";
      this.giftIds = "";
    },
    onSubmit() {
      const data = {
        shopId: this.storeId, //店铺ID 1蔚蓝  2教材
        payType: 1, // 默认1
        receiverId: this.areaList[0].id, //收货地址ID

        couponId: this.chosendCoupon.NO || "", // 1  // 优惠券ID
        couponMoney: this.chosendCoupon.reduce || "", // 优惠券金额

        giftCartId: this.giftIds, // 1 //礼品卡ID
        giftMoney: this.useGiftMoneyCount, // 礼品卡金额

        useBalanceMoney: this.useBalance||'', // 使用余额
        pwd: "",
        invoiceTitle: this.invoiceTitle, //
        invTaxNo: this.invoiceCode,
        customerMessage: this.message, //
        bookids: this.bookId ? this.bookId + "_" + this.buyNum : "", //
      };

      rqOrderSave(data).then((res) => {
        this.orderStatus = res.flag;
        if (this.orderStatus) {
          if (res.noPay) {
            this.$router.replace({ name: "OrderList", query: { active: 2 } });
            return;
          } else {
            this.goPayment(this.totalMoney, res.orderId, false);
          }
        } else {
          this.$toast(res.message);
        }
      });
    },

    getInvoice(invoiceTitle, invoiceCode) {
      this.invoiceTitle = invoiceTitle;
      this.invoiceCode = invoiceCode;
    },
    getCouponInfo(coupon) {
      console.log(coupon);
      this.chosendCoupon = coupon;
    },
    getGiftCard(giftCards) {
      console.log(giftCards)
      this.giftIds=''
      giftCards.forEach((item, index) => {
        this.giftIds += item?.id;
        console.log(this.giftIds)
        if (index !== giftCards.length - 1) {
          this.giftIds += ",";
        }
        this.giftMoney += Number(item?.money);
      });
    },
    getBalance(balance) {
      this.useBalance = balance;
    },
    getMessage(message) {
      this.message = message;
    },
  },
};
</script>
<style>
.order-result .van-card__thumb {
  text-align: center;
}
.order-result .van-card__thumb .van-image .van-image__img {
  object-fit: contain !important;
}

.tebiedete .van-address-item .van-icon-edit::before {
  content: "\F00A" !important;
  color: "#ccc";
}
.tebiedete .van-address-list__bottom {
  display: none !important;
}
.van-address-list {
  padding-bottom: 20px !important;
}
.content .van-field__control {
  border-bottom: 1px solid #bbb !important;
}
</style>
<style lang="scss" scoped>
.dianpian {
  height: 70px;
}
.content {
  padding: 20px;
}
</style>
