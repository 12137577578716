<template>
  <IDfault
    :rb="addressId && '删除'"
    :title="title"
    isNotDefault
    :button="button"
    @click-right="onDelete"
  >
    <van-address-edit
      show-postal
      show-set-default
      :address-info="AddressInfo"
      :area-list="areaList"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @change-area="onChangeArea"
    />
  </IDfault>
</template>

<script>
// @ is an alias to /src
import IDfault from "@/templates/Default.vue";
import { Toast } from "vant";
import areaList from "@/assets/js/areaList.js";
import cityList from "@/assets/js/city.js";
import {
  rqAddAdreess,
  rqGetAddressOne,
  rqDeleteAddress,
  rqEditAddress,
} from "@/api";
export default {
  components: {
    IDfault,
  },
  props: ["addressId"],
  data() {
    return {
      title: "新增地址",
      button: "", //删除按钮

      areaList, //可选地址列表

      AddressInfo: {},
      provId: "",
      cityId: "",
      areaId: "",
    };
  },

  mounted() {
    if (this.addressId) {
      const that = this;

      // 设置基本样式
      this.title = "编辑地址";
      this.button = "删除";
      rqGetAddressOne({ addressId: this.$route.params.addressId }).then(
        // 获取地址基本信息
        (res) => {
          that.AddressInfo = res;

          this.cityId = res.cityId;
          this.provId = res.provId;
          this.areaId = res.counId;
          that.AddressInfo.tel = res.phoneNum;
          that.AddressInfo.addressDetail = res.streetAdd;
          that.AddressInfo.postalCode = res.zipcode;
          that.AddressInfo.isDefault = res.isDefault == "1" ? true : false;

          // 过滤 服务端转本地
          let areaName = "";
          let cityName = "";
          cityList.areas[res.cityId].some((item) => {
            if (item.id == res.counId) {
              areaName = item.name;
              return true;
            }
          });

          cityList.citys[res.provId].some((item) => {
            if (item.id == res.cityId) {
              cityName = item.name;
              return;
            }
          });

          let entries = Object.entries(areaList.county_list);
          let entriesCity = Object.entries(areaList.city_list);
          let cityCode;
          entriesCity.some((item) => {
            if (item[1] === cityName) {
              cityCode = item[0];
            }
          });

          let areacode;
          cityCode = (cityCode + "").substr(0, 4);
          entries.some((item) => {
            if (cityCode===item[0].substr(0,4)&&item[1] === areaName) {
              console.log(item[0]);
              areacode = item[0];
              return true;
            }
          });
          that.AddressInfo.areaCode = areacode;
        }
      );
    }
  },
  methods: {
    onChangeArea(value) {
      // 本地转服务端
      const that = this;
      cityList.provinces.some((item) => {
        if (item.name === value[0].name) {
          that.provId = item.id;
        }
      });
      cityList.citys[that.provId].some((item) => {
        if (item.name === value[1].name) {
          that.cityId = item.id;
        }
      });
      cityList.areas[that.cityId].some((item) => {
        if (item.name === value[2].name) {
          that.areaId = item.id;
        }
      });
    },
    onSave(content) {
      const data = {
        name: content.name,
        provID: this.provId,
        cityID: this.cityId,
        counId: this.areaId,
        phoneNum: content.tel,
        streetAdd: content.addressDetail,
        zipcode: content.postalCode,
        isDefault: content.isDefault ? 1 : 0,
      };
      console.log(data);
      if (this.addressId && this.$route.params.storeId) {
        // 跳 改
        data.recieveId = this.AddressInfo.id;
        rqEditAddress(data).then((res) => {
          // let addressInfo = {};
          // addressInfo.tel = res.useDefaul.phoneNum;
          // addressInfo.address = res.useDefaul.streetAdd;
          // addressInfo.id = res.useDefaul.id;
          // addressInfo.name = res.useDefaul.name;
          // addressInfo.isDefault = res.useDefaul.isDefault;
          // console.log(addressInfo);
          this.$store.commit("set_currentAddress", res.useDefaul.id);

          this.$router.replace({
            name: "OrderConfirm",
            query: this.$route.query,
          });
          Toast("修改成功");
        });
      } else if (this.$route.params.storeId) {
        // 跳
        rqAddAdreess(data).then((res) => {
          // let addressInfo = {};
          // addressInfo.tel = res.useDefaul.phoneNum;
          // addressInfo.address = res.useDefaul.streetAdd;
          // addressInfo.id = res.useDefaul.id;
          // addressInfo.name = res.useDefaul.name;
          // addressInfo.isDefault = res.useDefaul.isDefault;
          // console.log(addressInfo);

          this.$store.commit("set_currentAddress", res.useDefaul.id);

          this.$router.replace({
            name: "OrderConfirm",
            query: this.$route.query,
            // params: { addressInfo, storeId: this.$route.params.storeId },
          });
          Toast("添加成功");
        });
      } else if (this.addressId) {
        // 修改一条

        data.recieveId = this.AddressInfo.id;
        console.log(data);
        rqEditAddress(data).then(() => {
          this.$router.replace({
            name: "Address",
            params: { type: this.$route.params.type },
          });
          Toast("修改成功");
        });
      } else {
        // 添加一条
        rqAddAdreess(data).then(() => {
          this.$router.replace({
            name: "Address",
            params: { type: this.$route.params.type },
          });
          Toast("添加成功");
        });
      }
    },
    onDelete() {
      const data = {
        addressId: this.AddressInfo.id,
      };
      rqDeleteAddress(data).then((res) => {
        console.log(res);
        this.$toast(res.msg);
        if (res.status === "200") {
          this.$router.go(-1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.address {
  border-bottom: 1px solid #000;
}
</style>
