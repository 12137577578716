import request from '@/utils/request'

// 获得书籍详情
export function getBook(data) {
  return request({
    url: '/mobile/book/detail',
    method: 'get',
    params:data
  })
}

// 详情页推荐1
export function getRecommentd1(data) {
  return request({
    url: '/mobile/book/categoryRecommend',
    method: 'get',
    params:data
  })
}

// 详情页推荐2
export function getRecommentd2(data) {
  return request({
    url: '/mobile/book/userRecommend',
    method: 'get',
    params:data
  })
}