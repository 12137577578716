<template>
  <lDfault class="order-detail" title="订单详情">
    <van-address-list class="tebiedete" :list="areaList" :switchable="false" />
    <van-cell-group>
      <van-cell
        title="物流信息"
        :value="sendMethodName"
        @click="toLogistsList"
      />
    </van-cell-group>
    <div class="order-list">
      <div v-for="(item, index) in orderItem" :key="index">
        <OrderItem :order="item"></OrderItem>
      </div>
    </div>

    <van-cell-group>
      <van-cell title="订单ID" :value="id" />
      <van-cell title="订单创建时间" :value="createDate | datetime" />

      <!-- <van-cell title="支付方式"  v-if="payTypeId !== 0" :value="payTypeId === '17'?'微信':'支付宝'" />-->

       <van-cell title="支付方式"  v-if="payTypeId === 17" value="微信" />
      <van-cell title="支付方式"   v-else-if="payTypeId === 1" value="支付宝" />

      <van-cell title="商品总额" :value="'￥'+totalPrice | money" />
      <!-- <van-cell v-if="sellerPrice" title="优惠金额" :value="totalPrice " /> -->
      <van-cell title="运费" :value="'￥'+freightPrice | money" />
      <van-cell title="已经支付" :value="'￥'+alreadyPayed | money" />
      <van-cell class="yingfujine" title="应付金额" :value="'￥'+userPayment | money" />
    </van-cell-group>
  </lDfault>
</template>

<script>
// @ is an alias to /src

import OrderItem from "./children/OrderItem.vue";
import { selectOrderInfo } from "@/api";
export default {
  components: {
    OrderItem,
  },
  data() {
    return {
      orderId: this.$route.params.orderId,
      orderItem: [],
      areaList: [],
      order: "",
      id: "", //订单ID
      payTypeId: "", //支付方式
      totalPrice: "", //商品原价
      freightPrice: "", //运费
      sellerPrice: "", //优惠金额
      alreadyPayed:"",//支付金额
      userPayment: "",
      createDate: "", //订单创建时间
      sendMethodName: "", //快递配送上
    };
  },
  mounted() {
    const data = {
      orderId: this.orderId,
    };
    selectOrderInfo(data).then((res) => {
      this.orderItem = res.item;
      this.order = res.order;
      this.areaList[0] = res.order;
      this.areaList[0].name = res.order.fullName;
      this.areaList[0].tel = res.order.mobile;
      this.areaList[0].address = res.order.fullAddress;
      this.id = res.order.id;
      this.payTypeId = res.order.payTypeId;
      this.totalPrice = res.order.totalPrice;
      this.freightPrice = res.order.freightPrice;
      this.sellerPrice = res.order.sellerPrice;
      this.userPayment = res.order.userPayment;
      this.createDate = res.order.createDate;
      this.alreadyPayed=res.order.alreadyPayed;
      if(res.order.expressId==null){
        this.sendMethodName = "暂无物流信息!";
      }else{
        this.sendMethodName = res.order.companyName;
      }
    });
    /*getLogistics(data).then((res) => {
      this.sendMethodName = res.companyName;
    });*/
  },
  methods: {
    toLogistsList() {
      if (this.sendMethodName === "暂无物流信息!") {
        return;
      }
      this.$router.push({
        name: "LogistsLits",
        params: { toOrderId: this.orderId },
      });
    },
  },
};
</script>
<style>
.order-detail .van-address-item__edit {
  display: none;
}
</style>
<style lang='scss' scoped>
.feedback {
}
.yingfujine{
  .van-cell__value{
    color:#F3344A;
    font-size: 16px;

  }
}
</style>
