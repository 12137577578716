<template>
  <lDfault title="充值">
    <div class="cash-value mg-t">
      <!--            <em>0</em>￥-->
      <van-field
        v-model="paymentAmount"
        placeholder="请输入充值金额"
        type="number"
        label="充值金额"
      />
    </div>
    <van-radio-group v-model="radio">
      <van-cell-group>
        <div v-if="ua == 'JSAPI'">
          <van-cell
            clickable
            size="large"
            @click="radio = '17'"
            title="微信支付"
            icon="shop-o"
          >
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #icon>
              <icon class="m-icon" title="weixindenglu" />
            </template>
            <template #right-icon>
              <van-radio name="17" checked-color="#ff463c" />
            </template>
          </van-cell>
        </div>
        <div v-else>
          <div v-for="(item, index) in payTypes" :key="index">
            <van-cell
              size="large"
              clickable
              @click="getId(item.bindPayId)"
              :title="item.name"
              icon="shop-o"
            >
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template #icon>
                <icon
                  class="m-icon"
                  :title="index ? 'weixindenglu' : 'zhifubao'"
                />
              </template>
              <template #right-icon>
                <van-radio :name="item.bindPayId" checked-color="#ff463c" />
              </template>
            </van-cell>
          </div>
        </div>
      </van-cell-group>
    </van-radio-group>
    <div v-html="payurl"></div>

    <div class="pd-h submitgroup">
      <van-button
        color="#ff463c"
        round
        class="submit"
        type="primary"
        @click="onPayment"
        block
        >立即支付
      </van-button>
    </div>
    <div class="text-black-300 mg">
      *充值资金不能用于退款申请 ；如果需要提现，请致电蔚蓝网客服办理。
    </div>
  </lDfault>
</template>

<script>
import { queryPayType, recharge } from "@/api";

function jsSDK(params, that) {
  if (typeof window.WeixinJSBridge === "undefined") {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", function () {
        onBridgeReady(params, that);
      });
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", function () {
        onBridgeReady(params, that);
      });
      document.attachEvent("onWeixinJSBridgeReady", function () {
        onBridgeReady(params, that);
      });
    }
  } else {
    onBridgeReady(params, that);
  }
}

function onBridgeReady(params, that) {
  window.WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId: params.appId, // 公众号名称，由商户传入
      timeStamp: params.timeStamp, // 时间戳，自1970年以来的秒数
      nonceStr: params.nonceStr, // 随机串
      package: params.package,
      signType: params.signType, // 微信签名方式：
      paySign: params.paySign, // 微信签名
    },
    function (res) {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        // alert("test---"+JSON.stringify(that));
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        that.$router.push({name:"Balance"});
      } else {
        // alert('test==='+JSON.stringify(res));
      }
    }
  );
}
export default {
  data() {
    return {
      paymentAmount: "",

      payTypes: [],
      radio: 1,
      ua: "", // "JSAPI", ""
      params: {},
      payurl: "",
    };
  },
  async mounted() {
    if (this.$isWeChatBrowser()) {
      this.ua = "JSAPI";
    }
    const res = await queryPayType();
    this.payTypes = res.item;
  },
  methods: {
    async onPayment() {
      if (this.paymentAmount <= 0) {
        this.$toast("充值金额必填必须大于0！");
        return;
      }
      //微信浏览器内支付
      if (this.ua === "JSAPI") {
        const res = await recharge({
          rechargeMoney: this.paymentAmount,
          payMethod: this.radio,
          payType: this.ua,
        });
        if (res.d) {
          this.payurl = res.d;
          this.$nextTick(function () {
            eval(res.s);
          });
        } else {
          let o = {
            appId: res.appId, //公众号名称，由商户传入
            timeStamp: res.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: res.nonceStr, //随机串
            package: res.package,
            signType: res.signType, //微信签名方式：
            paySign: res.paySign, //微信签名
            Url: "/Balance", //支付成功跳转的地址
          };

          this.params = o;
          jsSDK(o, this);
        }
      } else {
        recharge({
          rechargeMoney: this.paymentAmount,
          payMethod: this.radio,
          payType: this.ua,
        }).then((res) => {
          this.loginHtml = res.d;

          if (this.radio === 1) {
            //支付保
            this.payurl = res.requst_Html;
            this.$nextTick(function () {
              eval(res.requst_Script);
            });
          } else {
            this.$nextTick(function () {
              //this.loginHtml = res.requst_Html;
              window.location.replace(res.requst_Html);
            });
          }
        });
      }
    },
    getId(id) {
      this.radio = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-msg {
  display: flex;
  align-items: center;
  background-color: #fcfcfc;
  padding: 10px 0px;

  .left {
    padding: 0 20px;
  }

  .right {
    p {
      margin: 5px 0;
    }
  }

  // justify-content: space-around;
}

.submitgroup {
  margin-top: 40px;
}

.cash-value {
  overflow: hidden;
  line-height: 8rem;
  text-align: center;
  font-size: 4rem;
  font-family: Arial;
  color: #ddd;
}

.text-black-300.mg {
  margin-top: 30px;
}
</style>
