<template>
  <div>
    <van-cell
      title="订单留言"
      :value="value"
      @click="show = true"
      is-link
    ></van-cell>
    <van-action-sheet v-model="show" title="留言">
      <van-field
        v-model="message"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入留言"
        show-word-limit
      />
      <div class="button">
        <van-button type="primary" @click="onSubmit">确定</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  data() {
    return {
      show: false,
      value: "",
      message: "",
    };
  },
  methods: {
    onSubmit() {
      this.show = false;
      if (this.message.length > 1) {
        this.value = "已留言";
        console.log(this.message)
        this.$emit("getMessage", this.message);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.button {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 20px 0;
}
</style>
