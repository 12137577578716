<template>
  <div class="book-list">
    <van-sticky>
      <van-nav-bar @click-left="$router.go(-1)" left-arrow :border="false">
        <template #title>
          <!-- <search msg="搜索书籍/作者"/> -->
          <van-search
            disabled
            @click="$push('/search')"
            v-model="keywords"
            placeholder="请输入搜索关键词"
          />
        </template>
        <template #right>
          <van-icon @click="goSearch" name="search" size="18" />
        </template>
      </van-nav-bar>
      <sort-bar @reLoad="reLoad"></sort-bar>
    </van-sticky>

    <!-- 列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-card
        @click="goBookDetail(item.skuId)"
        v-for="(item, index) in bookList"
        :key="index"
        :price="item.wlPrice | money"
        origin-price="10.00"
        desc="六七小"
        :title="item.linkName"
        :thumb="getPicture(item)"
      >
        <!-- <template #bottom>
          <div v-if="item.pressName" v-html="item.pressName" class="tar">
          </div>
        </template> -->
        <template #tags>
          <div class="cart">
            <van-goods-action-icon
              icon="cart-o"
              color="red"
              @click.native.stop="$addShoppingCart(item.id)"
            />
          </div>
        </template>
        <template #origin-price>
          <span class="origin-price" v-if="item.price !== item.wlPrice"
            >￥{{ item.price }}</span
          >
          <span class="discount" v-if="item.discount && item.discount != 100">
            {{ item.discount | discount }} 折</span
          >
        </template>
        <template #desc>
          <div class="author text-color-3" v-html="item.authorName"></div>
        </template>
      </van-card>
    </van-list>

  </div>
</template>

<script>
// @ is an alias to /src
import sortBar from "@/components/SortBar";
import { rqSearch } from "@/api";
export default {
  components: {
    "sort-bar": sortBar,
    // GoodsFilter
  },
  data() {
    return {
      keywords: this.$route.query.keywords,
      bookList: [],
      list: [],
      loading: false,
      finished: false,
      pageOn: 1,

      reloadLock: false,
    };
  },
  mounted() {
    // this.bookName=this.$router.params.name
    // categoryType查询的分类id是1
    // keywords搜索关键词是
    // wl_price_begin搜索的蔚蓝价格范围开始
    // wl_price_end搜索的蔚蓝价格范围结束
    // orderBy排序的参数
    // discount_begin折扣范围
    // discount_end折扣范围
    // pageNo分页页数
  },
  methods: {
    getPicture(item) {
      if(item.imgUrl){
        return process.env.VUE_APP_IMG_BASE1 + item.imgUrl
      }else{
       return require('../images/6729154.jpg')
      }

    },

    goSearch() {
      this.onLoad(0, true);
    },
    goBookDetail(skuId) {
      // const skuId=e.currentTarget.dataset.skuid
      this.$router.push({ name: "BookDetail", params: { skuId } });
    },
    reLoad(index) {
      this.pageOn = 1;
      const isReload = true;
      const orderBy = index ?? 1;
      console.log(index,orderBy)
      this.onLoad(orderBy, isReload);
    },
    onLoad(orderBy=0, isReload) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
        loadingType: "spinner",
      });

      const that = this;

      const data = {
        keywords: this.keywords || "",
        pageNo: this.pageOn, // 第几页
       // isSale:1,
        orderBy,
        categoryType: this.$route.query.category || "",
      };

      rqSearch(data).then((res) => {
        if (isReload) {
          console.log(data, that.bookList);
          that.bookList = [];
        }
        // 加载状态结束
        that.loading = false;
        res.books.forEach((item) => {
          that.bookList.push(item);
        });

        that.pageOn++;
        if (res.books.length === 0) {
          // 数据全部加载完成
          that.finished = true;
        }
      });
    },
  },
};
</script>

<style>
.book-list .van-card__thumb {
  height: 120px;
  width: 120px;
}
.van-card__price {
  color: #f22b2b !important;
}
.book-list .van-card__thumb .van-image .van-image__img {
  object-fit: contain !important;
}
.book-list .van-goods-action-icon__icon {
  font-size: 1.7rem;
}
</style>
<style lang="scss" scoped>
.cart {
  position: relative;
  .van-goods-action-icon {
    position: absolute;
    top: 30px;
    right: 0;
  }
}

.book-list {
  font-size: 4vw;

  .van-card__origin-price {
    text-decoration: none !important;
    .origin-price {
      text-decoration: line-through;
    }
    .save-price {
      color: red;
    }
  }
  .desc {
    margin: 2px;
  }
}
.author {
  margin: 5px 0;
}
</style>
