<template>
  <div class="pd paymentsuccess">
    <icon :color='titleIcon.color' class="icon" :title="titleIcon.icon"  size='18rem'/>
       <p class="text-xl title" :style="{'color':titleIcon.color}">{{titleIcon.title}}</p>
        <van-button
        color="rgb(104, 191, 123)"
        round
        class="submit text-xl"
        type="primary"
        @click="$router.replace({name:$route.query.purpose})"
        block
        >确定</van-button
      >
  </div>
</template>

<script>
import icon from "@/components/Icon.vue";

export default {
    components:{icon},
    props: {
        success: {
            type: String,
        },
    },
    computed: {
        titleIcon() {
            return this.success==='true'?{
                icon:'tijiaochenggong',
                title:'支付成功',
                color:'rgb(104, 191, 123)'
            }:{icon:'zhifushibai',title:'支付失败',color:'#555'}
        }
    },
    methods: {
    },
}
</script>

<style scoped>
.paymentsuccess{
    text-align: center;
    padding-top :7rem;
}
.paymentsuccess .title{
    margin:20px auto;
}
.submit{
    margin-top: 80px;
}
</style>
