<template>
    <lDfault :title="loginText+'登陆'">
        <div v-html="loginHtml"></div>
    </lDfault>
</template>

<script>
    // @ is an alias to /src

    import lDfault from "@/templates/Default.vue";
    import {aliPayLogin, qqLogin, wxLogin} from "@/api";
    // import icon from "@/components/Icon.vue";
    export default {
        components: {
            lDfault,
            // icon,
        },
        data() {
            return {loginHtml: "", loginText: ""};
        },
        mounted() {
            if (this.$route.params.way === "wx") {
                wxLogin().then((res) => {
                    this.loginHtml = res.d;
                    this.$nextTick(function () {
                        eval(res.s)
                    });

                    this.loginText = '微信';
                });
            } else if (this.$route.params.way === "qq") {
                qqLogin().then((res) => {
                    this.loginHtml = res;
                    this.loginText = 'QQ';

                });
            } else {
                aliPayLogin().then((res) => {
                    this.loginHtml = res;
                    this.loginText = '支付宝';

                });
            }
        },
    };
</script>

<style lang='scss' scoped>
    .feedback {
    }
</style>
