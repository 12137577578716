<template>
  <div class="base-share">
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
    />
    <van-icon name="share-o" @click="showShare = true" />
  </div>
</template>

<script>
// import
// import wx from "weixin-js-sdk";

export default {
  name: "BaseShare",
  props: {
    shareInfo:{
      type:Object,
    },

  },
  data() {
    return {
      showShare: false,
      options: [
        { name: "微信", icon: "wechat" },
        { name: "微博", icon: "weibo" },
        { name: "复制链接", icon: "link" },
        { name: "分享海报", icon: "poster" },
        { name: "二维码", icon: "qrcode" },
      ],
    };
  },
  mounted () {
      },
  methods: {
    onSelect(option) {
      this.$toast(option.name);
      this.showShare = false;
    },
    // ttt() {

    //   // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。

    // },
  },
};
</script>

<style lang="scss" scoped>
.base-share {
  display: flex;
  align-items: center;
}
</style>
