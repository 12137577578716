<template>
  <lDfault title="用户反馈">
    <div class="wrapper pd-h">
      <p class="text-2xl">用户反馈</p>
      <van-form @submit="onSubmit">
        <van-field
          v-model="message"
          rows="5"
          autosize
          label="留言"
          type="textarea"
          maxlength="500"
          placeholder="请输入留言"
          show-word-limit
        />
        <van-cell is-link title="选择反馈类型" @click="show = true" >
          {{type}}
        </van-cell>
        <van-action-sheet
          description="请选择一项分类"
          v-model="show"
          :actions="actions"
          @select="onSelect"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </lDfault>
</template>

<script>
// @ is an alias to /src

import lDfault from "@/templates/Default.vue";
// import icon from "@/components/Icon.vue";
import {rqComplainAdd} from '@/api';
export default {
  components: {
    lDfault,
    // icon,
  },
  data() {
    return {
      message: "",
      type:"",
      typeID:"",
      show: false,
      actions: [
        { name: "产品相关", value: 1 },
        { name: "价格相关", value: 2 },
        { name: "服务相关", value: 3 },
        { name: "物流相关", value: 4 },
        { name: "售后相关", value: 5 },
        { name: "活动相关", value: 6 },
        { name: "其它相关", value: 7 },
      ],
    };
  },
  methods: {
    onSubmit(values) {
      console.log("submit", values);
      if(this.message.length<10){
        this.$toast('请至少输入10个汉字，更详细的描述您的问题')
        return 
      }else if(!this.typeID){
        this.$toast('请选择一项分类项')
        return 
      }
      const data ={
        type:this.typeID,
        inttitle:"用户手机web端投诉",
        content:this.message
      }

      rqComplainAdd(data).then(res=>{
        console.log(res)
        if(res){
          this.$toast('反馈成功，请耐心等待')
          this.$router.go(-1)
        }
      })
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.type=item.name
      this.typeID=item.value
    },
  },
};
</script>

<style lang='scss' scoped>
.wrapper {
}
</style>
