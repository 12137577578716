<template>
  <lDfault title="我的资料">
    <van-cell-group class="mg-t shadow">
      <van-cell title="头像" >
         <template #extra>
          <div>
            <img class="avatar" width="37" height="37" :src="avatar" alt="">
          </div>
        </template>
      </van-cell>
      <van-cell v-if="userName" title="昵称" :value="userName" />
      <van-cell v-if="userPhone" title="手机" :value="userPhone" />
      <van-cell v-if="userGender" title="性别" :value="userGender"/>
      <van-cell v-if="userBirthday" title="生日" :value="userBirthday | date"/>
    </van-cell-group>
      <!-- <van-cell class="mg-t shadow" title="修改密码" is-link :to="{name:'ModifyPasswd',params:{phone:$store.state.userInfo.phone}}"/> -->

    <van-button class="fixed-b mg-t" plain block @click="logout" >退出登录</van-button>
  </lDfault>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
export default {
  computed:mapState({
    userBirthday:state=>state.user.userBirthday,
    userName:state=>state.user.username,
    userPhone:state=>state.user.userPhone,
    userGender:state=>state.user.userGender,
    avatar:state=>state.user.avatar,
    // userBalance:state=>state.user.userBalance,
  }),
  methods:{
    logout(){
      this.$logout()
    }
  }
};
</script>

<style lang='scss' scoped>
.avatar{
  border-radius: 50%;
}

.fixed-b{
  position: fixed;
  bottom:20px;
}
</style>
