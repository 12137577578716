<template>
  <popper
    class="default-poper"
    trigger="clickToOpen"
    :options="{
      placement: 'top',
      modifiers: { offset: { offset: '-50px,10px' } },
    }"
  >
    <div class="popper">
      <ul>
        <li
          @click="onClick(item)"
          class="item "
          v-for="(item, index) in list"
          :key="index"
        >
          <van-icon class="icon" color="#fff" :name="item.icon"></van-icon>
          <div class="title">{{ item.title }}</div>
        </li>
      </ul>
    </div>
    <icon slot="reference" title="DMiconzhuanqu-"></icon>
  </popper>
</template>

<script>
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

export default {
  components: {
    popper: Popper,
  },
  data() {
    return {
      list: [
        { title: "首页", route: { name: "Home" }, icon: "home-o", badge: "" },
        { title: "客服", route: undefined, url:'http://cs.welan.com:80/im/text/0AdQth.html', icon: "chat-o", badge: "" },
        { title: "分类", route: { name: "Category" }, icon: "qr", badge: "" },
        {
          title: "购物车",
          route: { name: "ShoppingCart" },
          icon: "shopping-cart-o",
          badge: "",
        },
        { title: "我的", route: { name: "My" }, icon: "user-o", badge: "" },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem("token")) {
      const item = {
        title: "教材",
        route: { name: "ChooseBook" },
        icon: "notes-o",
        badge: "",
      };
      this.list.splice(1, 0, item);
    }
  },
  methods: {
    onClick(item){
      if(item.route){
        this.$push(item.route)
      }else{
        location.href=item.url
      }
    }
  }
};
</script>
<style>
.default-poper .popper__arrow {
  border-bottom-color: #181818 !important;
}
</style>
<style scoped>
.popper {
  color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background: #181818;
}
.item {
  width: 125px;
  height: 40px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.item .icon {
  width: 40px;
}
.item .title {
  height: 40px;
  line-height: 40px;
  flex-grow: 1;
  text-align: left;
  border-bottom: 1px solid #999;
}
.item:last-child .title {
  border-bottom: none;
}
</style>
