<template>
  <van-sticky>
    <div class="top">
      <router-link to="/search"> <search msg="搜索书籍/作者" /></router-link>
      <van-icon
        @click="goPersonal"
        class="loginclass"
        size="6vw"
        name="user-o"
      />
    </div>
  </van-sticky>
</template>

<script>
// @ is an alias to /src

import search from "@/components/Search.vue";
export default {
  components: {
    search,
    // icon,
  },
  methods:{
          goPersonal(){
      if(this.$store.state.user.isLogin){
        this.$push('my')
      }else{
        this.$push('Login')
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.top {
  background-color: #fff;
  padding: 5px 0;
  display: flex;
  align-self: center;
  align-items: center;
  .loginclass {
    margin-left: 3.5vw;
  }
}
</style>
