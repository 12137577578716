import { login, logOut, getUserInfo } from "@/api";
import {Toast} from 'vant';
const state = {
  isLogin:localStorage.getItem(`isLogin`) || false,
  h5Token: localStorage.getItem(`h5Token`) || "",

  avatar: "",
  username: "无名氏",
  userPhone:"",
  userScore: "0",
  userBalance: "0",
  userGiftCardNum: "0",
  userBirthday: "2000-01-01",
  userGender: "男",

};

const actions = {
  passwdLogin({ commit }, userInfo) {
    return new Promise((resolve) => {
      login(userInfo).then((res) => {
        if (res.status) {
          commit("SET_TOKEN", res.token);
          resolve();
        }else{
          Toast.fail(res.msg)
        }
      });
    });
  },
  phoneLogin(context, data) {

    const loginInfo = login(data);
    context;
    loginInfo;
  },
  getUserInfo({ commit }) {
    getUserInfo().then((res) => {
      commit(
        "SET_IS_LOGIN",
        (state.isLogin = res.status !== -11 ? true : false)
      );
      if(res.status!==-11){
        const userInfoDTO = res.userInfoDTO;

        commit("SET_USERNAME", userInfoDTO.uname);
        commit("SET_USER_SCORE", userInfoDTO.score);
        commit("SET_USER_BALANCE", userInfoDTO.money);
        commit("SET_USER_GENDER", userInfoDTO.sex ? "男" : "女");
        commit("SET_USER_BIRTHDAY", userInfoDTO.birthday);
        commit("SET_USER_GIFT_CARD_NUM", userInfoDTO.catNum);
        commit("SET_AVATAR", res.userPhoto);
        commit("SET_USER_PHONE", userInfoDTO.phone);
      }

    });
  },
  logout({ commit, state, dispatch }) {
    commit;
    state;
    dispatch;
  },
};

const mutations = {
  logOut(state) {
    logOut;
    state.isLogin = false;
  },
  SET_IS_LOGIN(state,isLogin){
    state.isLogin=isLogin
    localStorage.setItem("isLogin", isLogin);

  },
  SET_USERNAME(state, username) {
    state.username = username;
  },
  SET_USER_SCORE(state, userScore) {
    state.userScore = userScore;
  },
  SET_USER_BALANCE(state, userBalance) {
    state.userBalance = userBalance;
  },
  SET_USER_GENDER(state, userGender) {
    state.userGender = userGender;
  },
  SET_USER_BIRTHDAY(state, userBirthday) {
    state.userBirthday = userBirthday;
  },
  SET_USER_GIFT_CARD_NUM(state, userGiftCardNum) {
    state.userGiftCardNum = userGiftCardNum;
  },
  SET_USER_PHONE(state, userPhone) {
    state.userPhone = userPhone;
  },
  SET_AVATAR(state, avatar) {
    if (avatar?.indexOf("https") !== -1) {
      state.avatar = avatar;
    } else {
      state.avatar = process.env.VUE_APP_IMG_BASE2 + avatar;
    }
  },
  SET_TOKEN(state, token) {
    state.h5Token = token;
    localStorage.setItem("h5Token", token);
  },
};

export default {
  state,
  mutations,
  actions,
};
