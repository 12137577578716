<template>
  <lDfault
    rb="myinfo"
    buttonIcon="setting-o"
    :footer="true"
    class="my"
    title="我的蔚蓝"
  >
    <div class="topbar">
      <div
        class="login-after"
        v-if="isLogin"
      >
        <router-link to="MyInfo">
          <div class="avatar">
            <img
              :src="avatar"
              height="100%"
              width="100%"
              alt=""
            />
          </div>
        </router-link>
        <div class="user-info">
          <div class="text-lg">{{ userName }}</div>
          <router-link :to="{ name: 'Balance' }">
            <p class="text-sm">
              余额 {{ userBalance | money }} 礼品卡
              {{ userGiftCardNum | zero }} 积分
              {{ userScore | zero }}
            </p>
          </router-link>
        </div>
      </div>
      <div v-else class="login text-xl bd-radius bd-white">
        <router-link class="text-white" to="login">登陆/注册</router-link>
      </div>
    </div>

    <div class="order">
      <van-cell value="查看全部订单" title="我的订单" is-link to="orderList">
        <template #icon>
          <icon class="m-icon" title="dingdan" />
        </template>
      </van-cell>

      <div class="submain">
        <router-link :to="{ name: 'OrderList', params: { active: 1 } }">
          <div class="item">
            <icon title="nopayment" />
            <span>待付款</span>
          </div>
        </router-link>
        <router-link :to="{ name: 'OrderList', params: { active: 2 } }">
          <div class="item">
            <icon title="daishouhuo-" />
            <span>待收货</span>
          </div>
        </router-link>
        <router-link :to="{ name: 'PostSaleList' }">
          <div class="item">
            <icon title="tuihuanhuo" />
            <span>退换货</span>
          </div>
        </router-link>
      </div>
    </div>

    <div class="menulist">
      <van-cell title="我的余额" is-link to="balance">
        <template #icon>
          <icon class="m-icon" title="yue" />
        </template>
      </van-cell>
      <van-cell title="我的收藏" is-link to="collection">
        <template #icon>
          <icon class="m-icon" title="shoucang1" />
        </template>
      </van-cell>
      <van-cell title="我的足迹" is-link to="history">
        <template #icon>
          <icon class="m-icon" title="zuji" />
        </template>
      </van-cell>

      <van-cell title="我的礼品卡" is-link to="giftcard">
        <template #icon>
          <icon class="m-icon" title="lipin" />
        </template>
      </van-cell>
      <van-cell title="我的优惠券" is-link to="coupon">
        <template #icon>
          <icon class="m-icon" title="bianpinghuatubiaosheji-1" />
        </template>
      </van-cell>
      <van-cell title="收货地址" is-link to="address">
        <template #icon>
          <icon class="m-icon" title="dizhi2" />
        </template>
      </van-cell>

      <Recommend>
        <template #title>
          <!-- re-title -->
          <div class="tac text-black-700 mg-t">
            <!-- 更多你感兴趣的书籍 -->
            <img
              width="100%"
              height="100%"
              src="../images/gengduo.png"
              alt=""
              srcset=""
            />
          </div>
        </template>
      </Recommend>
    </div>
  </lDfault>
</template>

<script>
// @ is an alias to /src
// import {isLogin} from '@/api'
import { mapState } from "vuex";
export default {
  name: "My",
  computed:mapState({
    isLogin:state=>state.user.isLogin,
    userBalance:state=>state.user.userBalance,
    userName:state=>state.user.username,
    userScore:state=>state.user.userScore,
    userGiftCardNum:state=>state.user.userGiftCardNum,
    avatar:state=>state.user.avatar,
    // userBalance:state=>state.user.userBalance,
  }),
  data() {
    return {
      isLogined:false
    }
  },
  mounted() {
    this.$store.commit("setOrderInfoState");
    this.$store.dispatch("getUserInfo");
      // isLogin()
  },

};
</script>
<style lang="scss" scope>
.re-title {
  margin-top: 22px;
  margin-bottom: 12px;
}
.van-cell__title {
  //  align-self: center;
}
.m-icon {
  margin-right: 10px;
}
.my {
  .topbar::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(8px);
    background-image: url(../images/user-bg.jpg);
    z-index: -1;
    // margin:-30px;
  }
  .topbar {
    padding: 16px 10px;
    position: relative;
    display: flex;
    height: 99px;
    background-size: cover;
    .text-sm {
      color: #fff !important;
    }
    .login-after {
      display: flex;
      .avatar {
        height: 80px;
        width: 80px;
        // background-color: white;
        border-radius: 12px;
        overflow: hidden;
        // background-image: url(../images/timg.jpg);
      }
      .user-info {
        color: white;
        margin-left: 14px;
      }
    }
    .login {
      line-height: 34px;
      height: 34px;
      padding: 0 18px;
      margin: auto;
      align-self: center;
    }
  }

  .order {
    margin-top: -14px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    margin-bottom: 10px;
    .submain {
      background-color: white;
      display: flex;
      justify-content: space-around;
      height: 58px;
      padding: 8px 0;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        span {
          line-height: 30px;
        }
      }
    }
  }

  .menulist {
    .feedback {
      margin: 10px 0;
    }
  }
}
</style>
