<template>
  <div id="app">
  <tabbar/>
  </div>
</template>
<script>
import tabbar from '@/views/Tabbar'
export default {
  components:{tabbar},
  mounted(){
  }
};
</script>

<style lang='scss'>

@import url(~@/styles/normalize.css);
@import url(~@/styles/index.scss);
@import url(~@/styles/icons/iconfont.css);
body{ 
  background-color: #f7f7f7;
  color:#323232;
}
</style>
