<template>
  <lDfault title="操作记录">
    <!-- <template v-if="accountActivitys.length">
    <div
      class="shadow bg-white mg pd bd-radius-4"
      v-for="(item, index) in accountActivitys"
      :key="index"
    >
      <div class="flex-j-b line-height-10 ">
          <span>{{item.remark}}</span>
          <div><span>{{item.income?"+":''}} </span> <span>{{item.income?item.income:item.expenses}}</span></div>
          </div>
      <div class="text-black-300">{{ item.time }}</div>
    </div>
    </template>
 -->

    <BaseFor
      listName="accountActivitys"
      :getData="queryRechargeRecord"
      :rqData="data"
      :defaultOpt='{image:"https://img.yzcdn.cn/vant/custom-empty-image.png",description:"暂无记录"}'
    >
      <template #default="{ dataList }">
        <div
          class="shadow bg-white mg pd bd-radius-4"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="flex-j-b line-height-10">
            <span>{{ item.remark }}</span>
            <div>
              <span>{{ item.income ? "+" : "" }} </span>
              <span>{{ item.income ? item.income : item.expenses }}</span>
            </div>
          </div>
          <div class="text-black-300">{{ item.time }}</div>
        </div>
      </template>
    </BaseFor>
  </lDfault>
</template>

<script>
import { queryRechargeRecord } from "@/api";
export default {
  name: "RechargeRecord",
  data() {
    return {
      data: { type: 2 }, //0表示查询近三个月交易名细 ,1表示查询三个月以前的交易明细
      queryRechargeRecord,
    };
  },
};
</script>

<style scoped></style>
