<template>
  <div>
    <van-cell title="申请发票" :value="value" @click="show = true" is-link />
    <van-action-sheet v-model="show" title="使用账户余额">
      <div class="invoice content">
        <van-cell :border="false" title="发票内容"> 图书 </van-cell>

        <van-field
          :border="false"
          v-model="invoiceTitle"
          label="发票抬头"
          :colon="true"
          @input="isOk"
        />

        <van-field
        @input="isOk"
          :border="false"
          v-model="code"
          label="纳税人识别号"
          :colon="true"
        />

        <div class="fapiao text-black-400">
          发票抬头填写个人姓名或单位全称。
          <br />
          2017-7-1后企业开具发票需提供纳税人识别号
          商城默认开电子发票，纸质发票需订单备注
        </div>
        <div class="button">
          <van-button type="primary" @click="onSubmit"
            >确定</van-button
          >
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
  data() {
    return {
      show: false,
      value:'',
      invoiceTitle: "",
      code: "",
    };
  },
  methods: {
    isOk(){
         if(this.invoiceTitle&&this.code){
              this.value='已准备'
          }else{
            this.value=''
          }
    },
      onSubmit(){
          this.show=false
          if(this.invoiceTitle&&this.code){
              this.value='已准备'
              this.$emit('getInvoice', this.invoiceTitle,this.code)
          }else{
            this.value=''
          }
      }
  },
};
</script>
<style >
.invoice.content .van-cell.van-cell--borderless {
  color: #646566 !important;
}
</style>
<style lang='scss' scoped>
.fapiao {
  padding: 20px;
}
.button {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 20px 0;
}
</style>
