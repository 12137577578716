<template>
  <div class="book-detail">
    <div class="left-arrow" @click="$back()">
      <van-icon name="arrow-left" />
    </div>
    <van-tabs id="tabs" @scroll="tabScroll" v-model="active" scrollspy sticky>
      <template #nav-right>
        <div class="right-nav flex">
          <Popper></Popper>
        </div>
      </template>
      <van-tab title="书籍">
        <div class="banner lh-none bd-light-b">
          <van-swipe
            class="my-swipe img"
            :autoplay="3000"
            indicator-color="white"
          >
            <van-swipe-item v-for="(item, index) in detail.imgs" :key="index">
              <img
                class="img"
                width="100%"
                :src="'https://img.welan.com/s/' + item.imageUrl"
                alt=""
              />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="status pd text-gray-800 bg-white mg-b">
          <p
            @click="hasTruncate3Class = !hasTruncate3Class"
            :class="{ 'truncate-3': hasTruncate3Class }"
            class="mg-none-t text-gray-900 font-bold text-lg pd-t"
          >
            {{ detail.products && detail.products.name }}
          </p>
          <p
            v-if="detail.authorName"
            class="text-sm"
            @click="
              $push({
                name: 'BookList',
                query: { keywords: detail.authorName },
              })
            "
          >
            作者：<span style="color: #1989fa">{{ detail.authorName }}</span>
          </p>

          <p
            class="text-sm"
            v-if="detail.pressName"
            @click="
              $push({ name: 'BookList', query: { keywords: detail.pressName } })
            "
          >
            出版社：<span style="color: #1989fa">{{ detail.pressName }}</span>
          </p>
          <div class="">
            <!-- 蔚蓝价： -->
            <div class="text-red-600 first-letter-sm flex">
              <money :money="detail.sku.wlPrice + ''" :size="30"></money>

              <span
                v-if="detail.discount != '100'"
                class="mg-l wuzhe text-gray-400"
              >
                ({{ detail.discount / 10 }}折)
                <!-- ({{ detail.discount / 10 }}.{{ detail.discount % 10 }}折) -->
              </span>
            </div>
          </div>
          <p
            v-if="detail.sku.price !== detail.sku.wlPrice"
            class="text-sm text-gray-400 mg-none-b"
          >
            定价：<span class="text-d-lt"
              >￥{{ detail.sku && detail.sku.price | money }}</span
            >
          </p>
        </div>

        <div class="book-recomend">
          <Slide
            v-if="recommentd1.length"
            title="该分类热销商品推荐"
            :list="recommentd1"
          ></Slide>

          <Slide
            v-if="recommentd2.length"
            title="购买本商品的用户还买过"
            :list="recommentd2"
          ></Slide>
        </div>
      </van-tab>
      <van-tab title="详情">
        <Detail :book="detail"></Detail>
      </van-tab>
    </van-tabs>
    <div class="book">
      <div class="jiasai"></div>
      <van-goods-action>
        <van-goods-action-icon
          url="http://cs.welan.com:80/im/text/0AdQth.html"
          icon="chat-o"
          text="客服"
        />
        <van-goods-action-icon
          icon="star"
          @click="addBookmark"
          text="收藏"
          :color="detail.favorites === true ? '#ff5000' : '#ccc'"
        />

        <van-goods-action-icon
          icon="cart-o"
          text="购物车"
          :badge="$store.state.cartCount"
          @click="onClickIcon"
          to="ShoppingCart"
        />
        <!-- <van-goods-action-button
            type="danger"
            text="立即购买"
            @click="goBuy"
          /> -->
        <van-goods-action-button
          @click="addShoppingCart"
          type="warning"
          text="加入购物车"
        />
        <van-goods-action-button
          @click="skuShow = true"
          type="danger"
          text="立即购买"
        />
      </van-goods-action>
    </div>

    <van-sku
      v-model="skuShow"
      :sku="sku"
      :goods="goods"
      :hide-stock="true"
      :show-add-cart-btn="false"
      @buy-clicked="onBuyClicked"
      @stepper-change="onBuyNumUpdate"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Slide from "@/components/Slide.vue";
import Popper from "@/templates/children/Popper";
import Detail from "./Detail.vue";
// import JSApi from '@/utils/jsapi';
import {
  getBook,
  getRecommentd1,
  getRecommentd2,
  rqAddBookmark,
  rqDelBookmark,
  rqGetCartCount,
} from "@/api/index";

export default {
  components: { Detail, Slide, Popper },
  data() {
    return {
      buyBookNum: 1,
      skuShow: false,
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [
          {
            k: "数量", // skuKeyName：规格类目名称
            k_s: "s1", // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
            v: [{}],
            largeImageMode: true, //  是否展示大图模式
          },
        ],

        price: "", // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: true, // 是否无规格商品
      },

      goods: {
        // 默认商品 sku 缩略图
        picture: "https://img.yzcdn.cn/2.jpg",
      },

      isBook: true,
      active: 2,
      cardNum: 1,
      skuId: this.$route.params.skuId,
      detail: {},
      recommentd1: [],
      recommentd2: [],

      bookMarkLock: false,
      addCartLock: false,

      hasTruncate3Class: true,
    };
  },
  computed: {
    shareInfo() {
      return {
        title: this.detail?.products?.name,
        imgUrl: this.goods?.picture,
        // link: "http://weilan.free.idcfengye.com" + this.$route.fullPath,
        link:'https://m.welan.com' + this.$route.fullPath,
        desc:
          "售价：￥" +
          this.detail?.sku?.price +
          " | 蔚蓝书城 | " +
          this.dewatering(this.detail?.recommend),
      };
    },
  },
  watch: {
    shareInfo(nv) {
      console.log(nv);
      this.$wxShare(nv);
    },
  },
  mounted() {
    this.getBookDetailInfo();
    //     console.log(wx)
    //     wx.config({
    //   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //   appId: '', // 必填，公众号的唯一标识
    //   timestamp: , // 必填，生成签名的时间戳
    //   nonceStr: '', // 必填，生成签名的随机串
    //   signature: '',// 必填，签名
    //   jsApiList: [] // 必填，需要使用的JS接口列表
    // });
    // console.log(JSApi)
  },
  methods: {
    dewatering(htmlStr = "") {
      var dd = htmlStr.replace(/<\/?.+?>/g, "");
      return dd.replace(/ /g, ""); //dds为得到后的内容
    },
    copyShare() {
      // shareText = "《"+this.detail.products.name+"》"+'https://m.wl.cn'+this.$route.fullPath
    },
    onBuyNumUpdate(value) {
      this.buyBookNum = value;
    },
    async getBookDetailInfo() {
      const data = {
        skuId: this.skuId,
      };

      const bookResult = await getBook(data);
      this.detail = bookResult;
      console.log(bookResult.sku);
      this.sku.price = bookResult?.sku?.wlPrice;
      this.goods.picture = this.$getPicture(bookResult.imgs[0].imageUrl);

      const recommentd1Data = {
        skuId: this.skuId,
        cids: this.detail.cid,
      };
      const recommentd1Result = await getRecommentd1(recommentd1Data);
      this.recommentd1 = recommentd1Result;

      const recommentd2Result = await getRecommentd2(data);
      this.recommentd2 = recommentd2Result;
      const cartCount = await rqGetCartCount();
      this.$store.commit("SET_CART_COUNT", cartCount);
    },
    onBuyClicked() {
      console.log(this.detail.imgs[0].imgUrl, this.detail.imgs);
      const data = {
        storeId: 1,
        bookId: this.skuId,
        buyNum: this.buyBookNum,
        imgUrl: this.detail.imgs[0].imageUrl,
        bookName: this.detail.products.name,
        money: this.detail.sku?.wlPrice,
      };
      this.$genOrder(data);
    },

    tabScroll(scroll) {
      const tabs = document.querySelector(".van-sticky");
      tabs.style.opacity = scroll.scrollTop / 150;

      const fixedArrow = document.querySelector(".left-arrow");

      if (scroll.scrollTop > 50) {
        fixedArrow.style.background = "#fff";
        fixedArrow.style.color = "#666";
        fixedArrow.classList.add("ok");
      } else {
        const sticky = document.querySelector("#tabs .van-sticky");
        sticky.classList.add("van-sticky--fixed");
        fixedArrow.style.background = "#aaa ";
        fixedArrow.style.color = "#fff";
        fixedArrow.classList.remove("ok");
      }
    },

    async addBookmark() {
      if (this.bookMarkLock) {
        return;
      }
      this.bookMarkLock = true;

      const data = {
        skuId: this.skuId,
      };

      if (!this.detail.favorites) {
        await rqAddBookmark(data);
        this.detail.favorites = true;

        this.$toast("添加成功");
      } else {
        await rqDelBookmark(data);
        this.detail.favorites = false;

        this.$toast("删除成功");
      }
      this.bookMarkLock = false;
    },

    addShoppingCart() {
      if (this.detail.sku.stockNum === 0) {
        this.$toast("该商品没有库存");
        return;
      }

      this.$addShoppingCart(this.skuId);
    },
    onClickIcon() {
      this.$router.push({ name: "ShoppingCart" });
      // Toast("点击图标");
    },
    goBuy() {
      this.skuShow = true;
      // Toast("点击按钮");
    },
  },
};
</script>
<style>
.book-detail .right-nav {
  margin-top: 5px;
}
</style>
<style>
.book-detail .van-sticky {
  position: fixed;
  width: 100%;
}
</style>
<style lang="scss" scoped>
.left-arrow {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: RGB(185 185 185, 1);
  color: white;
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 300;
  text-align: center;
}
.book-detail {
  .banner {
    .img {
      height: 100vw;
    }
  }

  .right-nav {
    position: fixed;
    right: 10px;
  }

  .jiasai {
    height: 50px;
  }
}
.wuzhe {
  align-self: flex-end;
  line-height: 25px;
}
</style>
<style>
.van-sku-group-container {
  display: none;
}
</style>
