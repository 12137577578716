<template>
  <div class="category">
    <top-search></top-search>
    <!-- <div class="bg-white gg shadow">
      <img class="img" src="http://static.welan.com/upload/zhuanti/20181116/728x266/728x266%E6%B5%B7%E6%8A%A5-6.jpg" alt="">
    </div> -->
    <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in swipeList" :key="index">
        <a :href="item.url">
          <img :src="item.img" height="100%" width="100%" />
        </a>
      </van-swipe-item>
    </van-swipe>
    <van-tree-select
      @click-item="t2"
      @click-nav="tt"
      height="100%"
      :items="firstCategories"
      :main-active-index.sync="active"
    >
      <template #content>
        <!-- <van-image
          v-if="active === 2"
          src="https://img.yzcdn.cn/vant/apple-1.jpg"
        />
        <van-image
          v-if="active === 1"
          src="https://img.yzcdn.cn/vant/apple-2.jpg"
        /> -->
        <div
          v-for="(secondCategories, index) in firstCategoriesDetail"
          :key="index"
        >
          <!-- <div v-if="active === index">
          dsafdsfds  {{ item.name }}
          </div> -->
          <CategoryDetail
            v-if="active === index"
            :secondCategories="secondCategories"
          />
        </div>
      </template>
    </van-tree-select>
  </div>
</template>
<script>
import TopSearch from "@/components/TopSearch.vue";
import CategoryDetail from "@/components/CategoryDetail.vue";
import { category } from "@/api";
import { swipe } from "@/api";

export default {
  components: { CategoryDetail, "top-search": TopSearch },
  data() {
    return {
      swipeList: [],

      active: 0,
      categories: [],
      firstCategories: [],
      firstCategoriesDetail: [],
    };
  },
  mounted() {
        swipe().then((res) => {
      this.swipeList = res;
    });
    category().then((res) => {
      const vm = this;
      const categories = res.categorys;

      this.categories = categories;
      categories.forEach((category) => {
        vm.firstCategories.push({ text: category.name });
        vm.firstCategoriesDetail.push(category.childCategorys);
      });
      // console.log( vm.secondCategory
    });
  },
  methods: {
    tt(e) {
      console.log(e);
    },
    t2(e) {
      console.log(e);
    },
  },
};
</script>
<style scoped>
  .swipe {
    height: 30vw;
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      line-height: 150px;
      text-align: center;
      background-color: #39a9ed;
    }
  }
.category {
  height: calc(100vh - 50px);
}

.van-tree-select__content {
  background-color: #ccc;
}
.gg {
  height: 80px;
  margin: 10px;
  border-radius: 8px;
  overflow: hidden;
}
.img {
  width: 100%;
  height: 100%;
}
</style>
