<template>
  <lDfault title="我的足迹">
    <!-- 列表 -->
    <div v-if="bookList.length">
      <van-list
        class="his-goods-list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-card
          class="shadow mg-t"
          @click="goBookDetail(item.id)"
          v-for="(item, index) in bookList"
          :key="index"
          :price="item.price | money"
          :title="item.bookName"
          :thumb="'http://img.welan.com/s/' + item.img_url"
        >
          <template #tags>
            <div class="cart">
              <van-goods-action-icon
                icon="cart-o"
                color="red"
                @click.native.stop="$addShoppingCart(item.id)"
              />
            </div>
          </template>
        </van-card>
      </van-list>
    </div>
    <div v-else>
      <div class="fixed-center">
        <img src="../../images/historyDefault.png" alt="" />
        <div class="mg-t text-lg text-black-400 tac">暂无浏览记录</div>
      </div>
    </div>
  </lDfault>
</template>

<script>
// @ is an alias to /src

import lDfault from "@/templates/Default.vue";
// import icon from "@/components/Icon.vue";
import { rqGetHistory } from "@/api";
export default {
  data() {
    return {
      bookList: [],
      loading: false,
      finished: false,
      pageNum: 1,
    };
  },
  components: {
    lDfault,
    // icon,
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    goBookDetail(skuId) {
      // const skuId=e.currentTarget.dataset.skuid
      this.$router.push({ name: "BookDetail", params: { skuId } });
    },

    onLoad() {
      const data = {
        pageNum: this.pageNum,
      };

      rqGetHistory(data).then((res) => {
        // 加载状态结束
        // console.log(res)
        this.loading = false;
        res.item.forEach((item) => {
          this.bookList.push(item);
        });
        this.pageNum++;
        if (res.item.length === 0) {
          // 数据全部加载完成
          this.finished = true;
        }
      });
    },
  },
};
</script>
<style>
.his-goods-list .van-card__thumb {
  text-align: center;
}
.his-goods-list .van-card__thumb .van-image {
  height: 80% !important;
  width: 80% !important;
}
</style>
<style lang="scss" scoped>
.cart {
  position: relative;
  .van-goods-action-icon {
    position: absolute;
    top: 30px;
    right: 0;
  }
}
</style>
