<template>
  <div class="homenav">
    <van-grid class="shadow-sm" icon-size="60" :border="false">
      <van-grid-item
        v-for="(item, index) in navList"
        :key="'nav' + index"
        @click="$push(item.url)"
        :icon="item.img"
        :text="item.name"
      />
    </van-grid>
    <div class="saizi"></div>
  </div>
</template>

<script>
import { navigation } from "@/api";
export default {
  data() {
    return {
      navList: [],
    };
  },
  mounted() {
    navigation().then((res) => {
      this.navList = res;
    });
  },
};
</script>
<style>
.homenav .saizi {
  background: white;
  height: 10px;
}

.homenav .van-grid-item__text {
  margin-top: 0 !important;
}
.homenav .van-grid-item__content {
  padding: 0;
}
</style>
<style lang="scss" scoped>

</style>
