<template>
  <svg @click="emitClick" class="icon" :style="vsize" aria-hidden="true">
    <use :xlink:href="'#icon-' + title"></use>
  </svg>
</template>

<script>
import "@/styles/icons/iconfont.js";
export default {
  name:'icon',
  props: {
    title: String,
    size: {
      type: String,
      default: "2rem",
    },
    color: String,
  },
  computed: {
    vsize() {
      const data = {
        width: this.size,
        height: this.size,
      };
      if (this.color) {
        data.color = this.color;
      }
      return data;
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
