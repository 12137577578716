<template>
  <!-- amountSended:0
authorName:"本书编写组[编]"
brandName:"高等教育出版社"
buyNum:1
discount:100
grantNum:0
id:78887
isbn:"9787040494839"
itemId:9239679
itemName:"中国近现代史纲要:2018年版"
outItemId:"9156817"
price:26
returnNum:0
skuId:9229805
stockOut:1
wlPrice:26 -->

  <div class="shadow order-wrapper">
    <van-card
      :num="order.buyNum"
      :price="order.wlPrice"
      :title="order.itemName"
      :thumb="order.itemImageUrl"
    />
    <div class="bottom tar bd-radius">
      <div class="mg-v text-black-800">共{{order.buyNum}}件商品，金额 ￥{{order.price}}</div>
      <!--<van-button round plain type="primary">再次购买</van-button>-->
      <van-button round plain type="primary"   @click=" $push({ name: 'OrderDetail', params: { orderId: order.orderId } })">查看明细</van-button>
      <van-button round plain v-if="order.orderStatus == 10 " @click=" $push({name: 'Payment', params: { sumMoney: order.price, orderID: order.orderId } })" type="primary">支付</van-button>
      <van-button round plain type="primary"  v-else @click=" $push({ name: 'ReturnGoodsDetail', params: { serviceId: order.orderId } })">申请退换货</van-button>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {
    // icon,
  },
  props: {
    order: Object,
  },
};
</script>

<style lang='scss' scoped>
.order-wrapper{
  position: relative;
  background: white;
  border-radius: 6px;
  margin: 15px;
  padding: 10px;
  .status{
    position: absolute;
    left:10px;
    bottom:10px;
    color:#444;
  }
  .flex{
    justify-content: space-around;
  }
}
.van-button--plain.van-button--primary{
  height: 28px;
}
</style>
