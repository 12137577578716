
<template>
  <div class="trianle">
    <div :class="{active:!active&&isActive}" class="top"></div>
    <div :class="{active:active&&isActive}" class="bottom"></div>
  </div>
</template>

<script>
// @ is an alias to /src

// import icon from "@/components/Icon.vue";
export default {
  props:['isActive','active']
}
</script>

<style lang='scss' scoped>
.trianle{
  display: inline-block;
}
.top{
  border: 4px solid transparent;
  // border-left-width: 6px;
  // border-top-width: 3px;
  border-bottom: 4px solid #777f86 ;
  width: 0;
  margin-bottom: 1px;
}
.bottom{
  border:4px solid transparent;
  border-top: 4px solid #777f86 ;
  width: 0;
}
.top.active{
  border-bottom-color: #ff463c ;
}
.bottom.active{
  border-top-color: #ff463c ;
}
</style>
