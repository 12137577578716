<template>
  <lDfault :toRouter="{ name: 'Home' }" :title="pageTitle">
    <!-- <div class="consumerName">

    </div> -->
    <van-dialog v-model="consumerNameShow">
      <img class="consumerNameImg" :src="consumerImg" alt="" />
    </van-dialog>
    <div class="consumerName">
      <van-field
        readonly
        clickable
        name="banJiPicker"
        label="筛选"
        :value="banJiName"
        placeholder="选择班级"
        :rules="[{ required: true, message: '请选择班级' }]"
        @click="banJiEmpty()"
      ></van-field>
      <icon style="align-self:center" @click='consumerNameShow=true' title="lingdang"></icon>
    </div>

    <van-popup v-model="showBanJi" round position="bottom">
      <van-picker
        show-toolbar
        :columns="banJi"
        @cancel="showBanJi = false"
        @confirm="onBanJiConfirm"
      />
    </van-popup>

    <van-checkbox-group
      v-model="result"
      @change="checkChange()"
      ref="checkboxGroup"
    >
      <div class="book-wrapper">
        <div class="book-item" v-for="(item, index) in bookList" :key="index">
          <div class="book-item-detail bd-radius-4 shadow">
            <div class="book-img">
              <van-image fit="contain" :src="item.bOOKIMAGEURL" />
            </div>
            <div class="book-item-texts pd text-black-700 line-height-4">
              <div class="truncate-2">
                {{ item.bookName }}
              </div>
              <div class="truncate">作者： {{ item.author }}</div>
              <div class="truncate">出版社：{{ item.press }}</div>
              <div>ISBN： {{ item.isbn }}</div>
            </div>
            <div class="flex-j-b pd pt-0">
              <van-button
                type="danger"
                size="small"
                @click="onAddBookToCart(item.skuId)"
              >
                <!-- <icon size='1rem' color='#fff' title='cart-fuben'></icon> -->
                订购
              </van-button>
              <van-checkbox
                checked-color="#ee0a24"
                :name="item"
                ref="checkboxes"
              >
              </van-checkbox>
            </div>
          </div>
        </div>
      </div>
    </van-checkbox-group>
    <div v-html="loginHtml"></div>
    <div class="stuffing"></div>
    <van-submit-bar text-align="left" button-text="批量购买" @submit="batchAdd">
      <template>
        <div class="flex-j-b" style="margin-right: auto">
          <van-checkbox
            @click="toggleAll"
            checked-color="#ee0a24"
            v-model="checked"
            >全选</van-checkbox
          >
          <span class="select-text" style="color: #323233"
            >已选择 {{ result.length }} 件</span
          >
        </div>
      </template>
    </van-submit-bar>
    <!--
    <div class="fixed-bottom flex-j-b bg-white shadow">
      <van-button type="info" @click="toggleAll">反选</van-button>
      <van-button type="info" @click="batchAdd">批量添加购物车</van-button>
    </div> -->
    <div class="fixed-right">
      <van-icon
        :badge="orderNums"
        @click="$push('jcCart')"
        name="cart-circle-o"
        size="2.6rem"
        color="#aaa"
      />
    </div>
  </lDfault>
</template>

<script>
import {
  getCascadeInfo,
  getBookInClass,
  addBookToCart,
  addAllBookToCart,
  queryOpenId,
  getConsumerPicture,
} from "@/api";
import { Dialog } from "vant";
export default {
  name: "ChooseBook",
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      pageTitle:(localStorage.getItem('consumer')||'敬请')+"·选书",
      consumerNameShow: false,
      checked: false,
      showBanJi: false,
      banJiName: "",
      banJi: [],
      bCode: "",
      bookList: [],
      orderNums: 0,
      result: [],
      loginHtml: "",
      consumerImg: "",
    };
  },
  watch: {
    result(nv) {
      this.checked = nv.length === this.bookList.length ? true : false;
    },
  },
  beforeRouteEnter(from, to, next) {
    if (!localStorage.getItem("consumerCode")) {
      next("inputStudents");
    } else {
      next();
    }
  },
  mounted() {
    if(!localStorage.getItem('consumerNameShow')){
      this.consumerNameShow=true
    }
    localStorage.setItem('consumerNameShow',true)
    getConsumerPicture().then((res) => {

      let thisCode = localStorage.getItem("consumerCode");
      for(let i in res){
         if(thisCode == res[i].xuexiao_dm){
           this.consumerImg = res[i].centre_img;
           break;
         }
      }

    });
    if (navigator.userAgent.toLowerCase().indexOf("micromessenger") != -1) {
      // 微信浏览器
      queryOpenId().then((res) => {
        if (res.d) {
          this.loginHtml = res.d;
          this.$nextTick(function () {
            eval(res.s);
          });
        }
      });
    }

    let data = {
      consumerCode: localStorage.getItem("consumerCode"),
      yid: "",
      zid: "",
      //班级id
      bid: "",
      uid: localStorage.getItem("uid"),
      curPage: 1,
      pageSize: 40,
    };
    getBookInClass(data).then((res) => {
      this.bookList = res.bookList;
      this.orderNums = res.orderNums;
    });
    getCascadeInfo({ consumerCode: localStorage.getItem("consumerCode") }).then(
      (res) => {
        this.banJi = res;
      }
    );
  },
  methods: {
    //弹出选择框
    banJiEmpty() {
      this.showBanJi = true;
    },
    onBanJiConfirm(value, index) {
      if (value === undefined) {
        return;
      }
      this.banJiName = value[0] + "," + value[1] + "," + value[2];
      this.showBanJi = false;
      //获取一级分类
      let one = this.banJi[index[0]];

      //获取二级分类
      let two = one.children[index[1]];

      //获取三级分类
      let three = two.children[index[2]];

      this.bCode = three.code;

      //查询班级下的图书
      let data = {
        consumerCode: localStorage.getItem("consumerCode"),
        yid: "",
        zid: "",
        //班级id
        bid: three.code,
        uid: localStorage.getItem("uid"),
      };
      getBookInClass(data).then((res) => {
        this.bookList = res.bookList;
      });
    },
    //订购
    onAddBookToCart(skuId) {
      let data = {
        skuId: skuId,
        uid: localStorage.getItem("uid"),
        buyNum: 1,
        consumerCode: localStorage.getItem("consumerCode"),
      };
      addBookToCart(data).then((res) => {
        console.log(" 状态吗" + res.statusCode);
        if (res.statusCode === "2000") {
          //购物车数量
          this.orderNums = res.orderNums;

          this.$toast(res.failMessage);
        }
        //购物车数量
        this.orderNums = res.orderNums;
      });
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    checkChange() {
      console.log(this.result);
    },
    toggleAll() {
      this.checked
        ? this.$refs.checkboxGroup.toggleAll(true)
        : this.$refs.checkboxGroup.toggleAll(false);
      console.log(this.result);
    },
    batchAdd() {
      let skuIds = [];
      for (let i = 0; i < this.result.length; i++) {
        skuIds.push(this.result[i].skuId);
      }
      if (skuIds.length === 0) {
        this.$toast("选择商品后再次添加");
        return;
      }
      let data = {
        skuIds: skuIds,
        uid: localStorage.getItem("uid"),
        consumerCode: localStorage.getItem("consumerCode"),
      };
      addAllBookToCart(data).then((res) => {
        //购物车数量
        this.orderNums = res.orderNums;
      });
    },
  },
};
</script>
<style>
.book-img .van-image {
  height: 172px !important;
}
</style>
<style scoped>
.book-item-texts {
  height: 95px;
}
.book-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
  background: burlywood;
}
.book-item {
  margin-bottom: 10px;
  width: 50%;

  box-sizing: border-box;
}
.book-item:nth-child(2n + 1) {
  padding-right: 5px;
}
.book-item:nth-child(2n) {
  padding-left: 5px;
}
.book-item-detail {
  background: white;
}
.book-img .van-image {
  height: 172px !important;
}
.bd-radius {
  border: 1px solid #aaa;
  padding: 5px;
}
.select-text {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.consumerName {
  display: flex;
  background: white;
  justify-content: space-between;
  padding: 0 10px;
  line-height: 3;
}
.consumerNameImg{
  width: 319.98px;
  height: 391px;
}
</style>
