<template>
  <KnoCard :item='goods.list'  @click="goReturnGoodsDetail"
  >
  <template #header>
    <div  class="text-color-2">
      <div class="flex-j-b pd-h-3 pd-v">
        <div >
          服务单号:{{goods.id}}
        </div>
        
        <div  v-if="goods.deprecated_type == 0" >状态：退货</div>
         <div  v-if="goods.deprecated_type == 1" >状态：换货</div>
      </div>
      <hr class="hr2 mg-h">
    </div>
  </template>
      <template #footer>
      <div>
      <div class="result text-color-3">
        <div class="result-1">服务单取消</div>
        <div class='result-2 truncate-2'>服务已取消，如有需要可在售后申请页重新提交服务单</div>
      </div>
      <div class="button" v-if="false">
        <van-button style="margin-left:auto" hairline  plain round type="primary">朴素按钮</van-button>
      </div>
      </div>
    </template>
  </KnoCard>
</template>

<script>
import KnoCard from "@/components/card/KnoCard.vue";
export default {
  components: { KnoCard },
  props: ['goods'],

  methods:{
    goReturnGoodsDetail(){
      // this.$router.push({name:"ReturnGoodsDetail",params:{serviceId:'123'}})
    }
  }
};
</script>

<style lang='scss' scoped>
.van-button--plain.van-button--primary {
  height: 28px;
}

.result{
background-color: rgb(246, 246, 246);
  border-radius: 4px;
  margin: 0 15px ;
  display: flex;
      padding: 5px;

  .result-1{
    align-self: center;
    padding: 0 15px;
    white-space:nowrap;
  }
}
.button{
  margin-top: 7.5px;
  display: flex;
  justify-self: flex-end;
  padding-right: 15px;
}
</style>
