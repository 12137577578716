<template>
  <div class="stepper">
    <BaseStepper1
      @update="onUpdate"
      v-model="count_self"
      v-bind="$attrs"
      @foucs="onFocus"
      :mininum='mininum'
      :maxnum="maxnum"
    ></BaseStepper1>

    <!-- 输入 -->
    <van-number-keyboard
      :z-index="1000"
      close-button-text="完成"
      v-model="count_self"
      :maxlength="3"
      :show="show"
      @close="onClose"
      @blur="onBlur"
    >
      <template #title-left>
        <div @click='onCancel' class="keyboard-left-title">
          取消
        </div>
      </template>
    </van-number-keyboard>
    <!-- @confirm="$emit('update-num',  count_self)"
      @close="$emit('cancel-update-num')" -->
    <!-- <BaseOverlay v-if="show">
      <BaseDialog @confirm='onConfirm' @cancel='onCancel'>
        <BaseStepper @update='onUpdate' v-model='count_self' v-bind='$attrs' focus class="dialog-stepper"></BaseStepper>
      </BaseDialog>
    </BaseOverlay> -->
  </div>
</template>

<script>
import BaseStepper1 from "./BaseStepper1";
export default {
  name: "BaseStepper",
  components: {
    BaseStepper1,
  },
  props: {
    mininum:{
      type:Number,
      default:1,
    },
    maxnum:{
      type:Number
    },
    count: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      show: false,

      count_temp: "",
      count_self: this.count + "", // 数量
      keyboardValue: "", // 键盘输入数量
    };
  },
  watch:{
    count(nv){
      this.count_self=nv+''
    }
  },
  methods: {
    onFocus() {
       document.activeElement.blur();
      this.show = true;
      this.count_temp = this.count_self;
      this.count_self = "";
    },
    onConfirm(value) {
      this.count_self = value;
      this.show = false;
    },
    onCancel() {
      this.show = false;
      this.count_self =     this.count_temp;
    },

    onUpdate(value) {
      if (this.count_self) {
        this.$emit("change", value);
      }
    },

    onClose() {
      this.count_self=Number(this.count_self)
      if(this.count_self>this.maxnum||this.count_self<this.mininum){
        this.$toast("申请数量不正确，请检查后再试")
        return
      }
      this.onUpdate(this.count_self);
    },
    onBlur() {
      this.show = false;
      this.count_self = this.count_temp;
    },
  },
};
</script>
<style>
.van-number-keyboard__close {
  color: #f22b2b;
}
</style>
<style lang="scss" scoped>
.keyboard-left-title {
  padding-left: 14px;
}
.dialog-stepper {
  padding: 30px 0 25px;
  text-align: center;
}
</style>
<style scoped>
.stepper{
  display: inline-block;
}
</style>
