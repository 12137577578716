<template>
  <div>
    <van-list
      v-if="dataCount"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <slot :dataList="dataList"></slot>
    </van-list>
    <van-empty
      v-else
      class="custom-image"
      :image="defaultOpt.image"
      :description="defaultOpt.description"
    />
  </div>
</template>

<script>
export default {
  name: "BaseFor",

  props: {
    rqData: Object,
    listName: String,
    getData: {
      type: Function,
    },
    defaultOpt: Object,
  },

  data() {
    return {
      dataList: [],
      dataCount: 1,
      loading: false,
      finished: false,
    };
  },
  methods: {
    onLoad() {
      this.rqData.page = this.rqData.page || 1;
      this.getData(this.rqData).then((res) => {
        this.dataCount = res.dataCount;
        this.rqData.page += 1;
        this.loading = false;
        let dataList = res;
        if (this.listName) {
          dataList = res[this.listName];
        }
        if (!dataList.length) {
          this.finished = true;
        }
        this.dataList.splice(this.dataList.length, 0, ...dataList);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
