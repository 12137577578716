<template>
  <lDfault title="激活礼品卡">
    <div class="mg-t">
      <van-cell-group>
        <van-field
          v-model="cardNum"
          label="卡号"
          placeholder="请输入礼品卡卡号"
        />
        <van-field
          v-model="password"
          type="password"
          label="密码"
          placeholder="请输入礼品卡密码"
        />
      </van-cell-group>

      <div class="pd-h submitgroup">
        <van-button color="#ff463c" round type="primary" @click="onActive" block
          >激活新的礼品卡
        </van-button>
      </div>
    </div>
  </lDfault>
</template>

<script>
import lDfault from "@/templates/Default.vue";
import { rqGiftCardActive } from "@/api";
export default {
  name: "ActivateGiftCard",
  components: {
    lDfault,
  },
  data() {
    return {
      cardNum: "",
      password: "",
    };
  },
  methods: {
    onActive() {
      rqGiftCardActive({ cardNum: this.cardNum, password: this.password }).then(
        (res) => {
          if (res === 0) {
            this.$toast({
              duration: 3000,
              message: "卡号错误",
            });
          } else if (res === 1) {
            this.$toast("礼品卡已经被激活过");
          } else if (res === 2) {
            this.$toast("激活成功");
            setTimeout(() => {
              this.$router.go(-1);
            }, 800);
          } else if (res === -1) {
            this.$toast("激活失败");
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.submitgroup {
  margin-top: 40px;
}
</style>
