<template>
  <div>
    <mcode
      @imgCodePassed="imgCodePassed"
      @click="showCodeModal = false"
      :valiImg="imgCode"
      :show="showCodeModal"
    ></mcode>

    <van-tabs class="van-tab__pane___" v-model="active">
      <van-tab name="passwd" title="密码登录">
        <van-field
          v-model="username"
          label="手机号"
          placeholder="请输入用户名/邮箱/手机号"
        />
        <van-field
          v-model="password"
          label="密码"
          :type="passwdEye ? 'password' : 'text'"
          :right-icon="passwdEye ? 'closed-eye' : 'eye-o'"
          placeholder="请输入密码"
          @click-right-icon="passwdEye = !passwdEye"
        />
      </van-tab>
      <van-tab name="phone" title="短信登陆">
        <van-field v-model="phone" label="用户名" placeholder="请输入手机号" />
      </van-tab>
    </van-tabs>

    <van-button
      :disabled="!activeButton"
      class="submit"
      type="primary"
      @click="onLogin"
      block
      >{{ loginText }}</van-button
    >
  </div>
</template>

<script>
// @ is an alias to /src
import validator from "validator";
import mcode from "./Code.vue";
import { isRegister, getImgCode } from "@/api";

export default {
  components: { mcode },
  props: {
    fromRoute: {
      type: Object,
    },
  },

  data() {
    return {
      loginText: "登录",
      active: "passwd",
      activeButton: false,
      showCodeModal: false,
      passwdEye: true,

      username: "",
      password: "",

      phone: "",
      imgCode: {},
    };
  },
  watch: {
    username(nv) {
      if (this.active === 0 && nv.length === 0) {
        this.activeButton = false;
      } else {
        this.activeButton = true;
      }
    },
    phone(nv) {
      if (this.active === 1 && nv.length === 0) {
        this.activeButton = false;
      } else {
        this.activeButton = true;
      }
    },
    active(nv) {
      this.loginText = nv !== 'passwd' ? "获取验证码" : "登陆";
    },
  },

  methods: {
    onLogin() {
      if (this.active === "phone") {
        // 短信登陆
        this.phoneLogin(validator, isRegister);
      } else {
        // 密码登陆
        this.$store.dispatch("passwdLogin", {
          name: this.username,
          passwd: this.password,
        }).then(()=>{
          this.$router.replace(this.fromRoute)
        });
      }
    },

    phoneLogin: async function (validator, isRegister) {
      if (!validator.isMobilePhone(this.phone, "zh-CN")) {
        this.$toast("这不是一个手机号");
        return;
      }
      const isReg = await isRegister({ phone: this.phone });
      if (!isReg) {
        this.$toast("该手机号尚未注册");
        return;
      }
      this.showCodeModal = true;
      this.imgCode = await getImgCode();
    },
    imgCodePassed(code) {
      sessionStorage.setItem(
        "verify",
        JSON.stringify({
          way: "login",
          phone: this.phone,
          getCode: "getCode",
          handler: "loginPhone",
          imgCodeValue: code,
          nextStation: { name: "My" },
          textGroup: {
            title: "登录校验",
            subTitle: "",
            placeholder: "请输入验证码",
            button: "快速登录",
          },
        })
      );

      this.$router.push({
        name: "VerifyCode",
        // params:
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.submit {
  margin-top: 15px !important;
}
</style>
