<template>
  <van-card
    @click="$push({ name: 'BookDetail', params: { skuId: item.bookId } })"
    class="w-100   bg-white base-goodscard"
    :price="item.currentPrice | money"
    :title="item.bookName"
  >
    <template #thumb>
      <van-image height="87" width="87" fit="contain" :src="item.imgUrl" />
    </template>
    <template #footer>
      <div @click.stop="deleteCart(item.bookId, item.activeId, item.storeId)">
        删除
      </div>
    </template>
    <template #desc>
      <div class="tar">
        <BaseStepper
          @click.native.stop
          :count="item.count"
          @change="onChange"
        ></BaseStepper>
      </div>
    </template>
    <template #num> </template>
  </van-card>
</template>

<script>
import { rqCartUpdate, rqCartDelete, rqDefCart } from "@/api";

export default {
  name: "BaseGoodsCard",
  props: {
    item: Object,
  },
  data() {
    return {
      tempNum: 0,
    };
  },
  methods: {
    onChange(v) {
      cartNumUpdate.call(this, this.item, v);
    },
    deleteCart(skuid, activeId, shopId) {
      const data = [{ skuid, activeId, shopId }];

      rqDefCart();
      rqCartDelete(data).then((res) => {
        this.$store.commit('SET_CART_COUNT',res.totalCount)
        this.$emit("updateGoods", res);
      });
    },
  },
};

async function cartNumUpdate(item, num) {
  const data = {
    skuId: item.bookId, //书ID
    buyNum: num, //数量
    storeId: item.storeId, //店铺ID
  };
  const res = await rqCartUpdate(data);
  if (res.failMessage) {
    // 失败1.告诉用户
    this.$toast(res.failMessage);
  } else {
    this.count_self = num;
    this.$emit("updateGoods", res);
  }
}
</script>

<style>
.base-goodscard {
  background: white !important;
}
.base-goodscard .van-stepper {
  text-align: right;
}

.base-goodscard .van-card__thumb {
  text-align: center;
  height: 120px;
  width: 120px;
}
.base-goodscard .van-card__thumb .van-image {
  height: 100% !important;
  width: 100% !important;
}

.base-goodscard .van-submit-bar {
  bottom: 50px;
}
.re-title {
  margin-top: 22px;
  margin-bottom: 12px;
}
.van-checkbox {
  background-color: white;
  padding: 0 10px;
}
.van-checkbox__label {
  width: 100%;
}
.van-card {
  padding-left: 0px !important;
}
.check-box {
  margin-top: 8px;
}
.goods-desc {
  margin-top: -30px;
  margin-bottom: 60px;
}

.check-box.van-checkbox {
  display: none;
}
</style>
