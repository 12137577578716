<template>
  <div class="shopcard mg-v">
    <van-radio :name="storeId" class="check-title"  @click="$emit('change-checked')">
      <div>
        <span>{{storeTitle}}</span>
      </div>
    </van-radio>
    <div class="flex" v-for="item in list" :key="item.bookId">
      <BaseGoodsCard v-on='$listeners' :item="item"></BaseGoodsCard>
    </div>
  </div>
</template>

<script>
export default {
    name:"ShopCard",
    props:{
        storeId:String,
        list:Array,
        storeTitle:String,
    },
    data() {
      return {
        checked: true
      }
    },
};
</script>
<style>
.shopcard .van-checkbox{
      margin: 12px 0;
    padding: 6px 0;
    font-weight: 600;
}
</style>
<style scoped>
.shopcard{
  border-radius: 4px;
  overflow: hidden;
}
.check-title{
    margin: 12px 0 0;
    padding: 18px 12px 18px;
    background-color: white;
}
</style>
