<template>
  <lDfault title="操作记录">
      <div v-if="accountActivitys.length">
    <div v-for="(item,index) in accountActivitys" :key="index">
            <van-cell :title=item.remark is-link :arrow-direction=item.time :value=item.expenses />
        </div>
            </div>
    <van-empty
     v-else
      class="custom-image"
      image="https://img.yzcdn.cn/vant/custom-empty-image.png"
      description="暂无记录"
    />
  </lDfault>
</template>

<script>
import lDfault from "@/templates/Default.vue";
import { queryRechargeRecord } from "@/api";
export default {
  components: {
    lDfault,
  },
  data() {
    return {
      page: 1,
      type: 1, //0表示查询近三个月交易名细 ,1表示查询三个月以前的交易明细
      accountActivitys: [], //充值记录
      dataCount: "", //总条数
    };
  },
  mounted() {
    queryRechargeRecord({ page: this.page, type: this.type }).then((res) => {
      this.accountActivitys = res.accountActivitys;
      this.dataCount = res.dataCount;
    });
  },
};
</script>

<style scoped></style>
