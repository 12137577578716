<template>
    <lDfault title="我的订单">
        <van-tabs v-model="active_">
            <van-tab title="全部订单">
                <div v-if="orderAll.length" class="order-list">
                    <div v-for="(item, index) in orderAll" :key="index">
                        <OrderItem
                                v-if="item.list.length === 1"
                                :order="item.list[0]"
                        ></OrderItem>
                        <OrderItemMul v-else :order="item"></OrderItemMul>
                    </div>
                </div>
                <div v-else>
                    <div class="tac height">
                        <img src="../../images/orderDefault.png" alt="" />
                        <div class="mg-t text-lg text-black-400 tac">暂无订单</div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="未付款">
                <div v-if="unPayOrder.length" class="order-list">
                    <div v-for="(item, index) in unPayOrder" :key="index">
                        <OrderItem
                                v-if="item.list.length === 1"
                                :order="item.list[0]"
                        ></OrderItem>
                        <OrderItemMul v-else :order="item"></OrderItemMul>
                    </div>
                </div>
                <div v-else>
                    <div class="tac height">
                        <img src="../../images/orderDefault.png" alt="" />
                        <div class="mg-t text-lg text-black-400 tac">暂无订单</div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已付款">
                <div v-if="payOrder.length" class="order-list">
                    <div v-for="(item, index) in payOrder" :key="index">
                        <OrderItem
                                v-if="item.list.length === 1"
                                :order="item.list[0]"
                        ></OrderItem>
                        <OrderItemMul v-else :order="item"></OrderItemMul>
                    </div>
                </div>
                <div v-else>
                    <div class="tac height">
                        <img src="../../images/orderDefault.png" alt="" />
                        <div class="mg-t text-lg text-black-400 tac">暂无订单</div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>


        <!--<Recommend class="recommend">
            <template #title>
                <div class="text-black-800 tac icontext">
                    <icon title="xin" size="1.4rem"></icon>
                    你可能还喜欢
                </div>
            </template>
        </Recommend>-->
    </lDfault>
</template>

<script>
    // @ is an alias to /src
    import lDfault from "@/templates/Default.vue";
    import OrderItem from "./children/OrderItem.vue";
    import OrderItemMul from "./children/OrderItemMul.vue";

    import { getJcOrderList } from "@/api/textbook/JcOrder";
    // import icon from "@/components/Icon.vue";
    export default {
        components: {
            lDfault,
            OrderItem,
            OrderItemMul,
            // icon,
        },
        props: {
            active: {
                default: 0,
                type: Number,
            },
        },
        data() {
            return {

                active_: this.active,

                orderAll: "",
                unPayOrder: "",
                payOrder: "",
                unOrder: "",
            };
        },

        mounted() {
            // 查询订单

            const data = {
              // uid:localStorage.getItem("uid"),
                uid:3080189,
                orderStatus:0,
            };
            const payData ={
                // uid:localStorage.getItem("uid"),
                uid:3080189,
                orderStatus:2,
            };
            const  unPayData={
                // uid:localStorage.getItem("uid"),
                uid:3080189,
                orderStatus:1,
            }
            getJcOrderList(data).then((res) => {
                this.orderAll = res;
        });
            getJcOrderList(payData).then((res) => {
                this.payOrder = res;
        });
            getJcOrderList(unPayData).then((res) => {
                this.unPayOrder = res;
        });


        },
        methods: {
            // beforeChange(index) {
            //   this.stateId = index === 0 ? "" : index === 1 ? 100 : 300;
            //   this.$toast.loading({
            //     message: "加载中...",
            //     forbidClick: true,
            //     loadingType: "spinner",
            //   });
            // },
        },
    };
</script>

<style lang="scss" scoped>
    .feedback {
    }
    .icontext {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .fixed-center {
        top: 40%;
    }
    .height{
        height: 300px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
</style>
