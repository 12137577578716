<template>
  <lDfault title="支付完成">
    <p class="flex-center">
      <svg
        t="1609122600014"
        style="width: 16vh; height: 16vh"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4562"
        width="200"
        height="200"
      >
        <path
          d="M365.6601 412.384367l531.839681 0 0 598.319641-531.839681 0 0-598.319641Z"
          fill="#FFCB57"
          p-id="4563"
        ></path>
        <path
          d="M658.171925 478.864327H299.18014a26.591984 26.591984 0 0 1 0-53.183968h358.991785a26.591984 26.591984 0 0 1 0 53.183968zM764.539861 638.416231H299.18014a26.591984 26.591984 0 0 1 0-53.183968h465.359721a26.591984 26.591984 0 0 1 0 53.183968zM631.579941 797.968136H299.18014a26.591984 26.591984 0 0 1 0-53.183969h332.399801a26.591984 26.591984 0 0 1 0 53.183969z"
          fill="#472D22"
          p-id="4564"
        ></path>
        <path
          d="M737.947877 1024H285.884148A186.143888 186.143888 0 0 1 99.74026 837.856112V332.608415a186.143888 186.143888 0 0 1 186.143888-186.143889 26.591984 26.591984 0 0 1 0 53.183968A132.95992 132.95992 0 0 0 152.924228 332.608415v505.247697a132.95992 132.95992 0 0 0 132.95992 132.95992h452.063729a132.95992 132.95992 0 0 0 132.95992-132.95992V332.608415a132.95992 132.95992 0 0 0-132.95992-132.959921 26.591984 26.591984 0 0 1 0-53.183968 186.143888 186.143888 0 0 1 186.143888 186.143889v505.247697a186.143888 186.143888 0 0 1-186.143888 186.143888z"
          fill="#472D22"
          p-id="4565"
        ></path>
        <path
          d="M644.875933 292.720439H378.956092a106.367936 106.367936 0 0 1 0-212.735873h29.915982a106.367936 106.367936 0 0 1 206.087877 0H644.875933a106.367936 106.367936 0 0 1 0 212.735873zM378.956092 133.168534a53.183968 53.183968 0 0 0 0 106.367937h265.919841a53.183968 53.183968 0 0 0 0-106.367937h-53.183968a26.591984 26.591984 0 0 1-26.591984-26.591984 53.183968 53.183968 0 0 0-106.367937 0 26.591984 26.591984 0 0 1-26.591984 26.591984z"
          fill="#472D22"
          p-id="4566"
        ></path>
      </svg>
    </p>
    <p class="flex-center text-lg">你的订单已经生成，是否支付完成？</p>
    <p class="flex-center text-black-600" v-if="orderId">订单号:{{orderMoney}}</p>
    <p class="flex-center text-black-600" v-if="orderMoney">应付金额(元):{{orderMoney}}</p>
    <div class="flex-center">
      <van-button class="zaicizhifu" round type="plain"
       @click='onFailed' >再次支付</van-button
      >
      <van-button @click='onSuccess' round type="primary">支付成功</van-button>
    </div>
  </lDfault>
</template>

<script>
import mixin from "@/mixins";

export default {
  mixins: [mixin],
  data() {
      return {
          orderMoney: this.$route.query.orderMoney,
          orderId: this.$route.query.orderId,
      }
  },
  methods: {
    onSuccess() {
        this.$router.replace({name:"OrderList"})
    },
    onFailed() {
        this.goPayment(this.orderMoney,this.orderId)
    },
  },
};
</script>

<style lang="scss" scoped>
.zaicizhifu {
  margin-right: 20px;
}
</style>
