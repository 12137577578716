<template>
  <div class="default">
    <div v-if="emerge" class="fixed-arrow" @click="onClickLeft">
      <van-icon name="arrow-left" />
    </div>

    <!-- <slot name='secondName'></slot> -->
    <van-sticky id="tabs" @scroll="tabScroll">
      <van-nav-bar
        :title="title"
        :left-arrow="!emerge"
        @click-left="onClickLeft"
      >
        <template v-if="rb === 'myinfo'" #right>
          <div>
            <van-icon
              @click="$push({ name: 'MyInfo' })"
              :name="rightTitle"
              size="22"
            />
          </div>
        </template>
        <template v-else-if="comButton" #right>
          <span @click="onClickRight">{{ rb }}</span>
        </template>

        <template v-else #right>
          <slot name="right-button"></slot>

          <!-- <div v-html='rightMenu'></div> -->
          <div>
            <Popper></Popper>
          </div>
        </template>

        <template v-slot:title>
          <slot name="title"></slot>
        </template>
      </van-nav-bar>

      <!-- <div class="flex-j-b pd-">
        <van-tabbar v-if="$route.meta.showTab" route :placeholder="true">
          <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
          <van-tabbar-item replace to="/category" icon="search"
            >分类</van-tabbar-item
          >
          <van-tabbar-item replace to="/shoppingCart" icon="search"
            >购物车</van-tabbar-item
          >
          <van-tabbar-item replace to="/my" icon="search">我的</van-tabbar-item>
        </van-tabbar>
      </div> -->
    </van-sticky>

    <slot></slot>

    <footer v-if="footer" class="bg-white">
      <div class="footer-login flex-j-b pd-t pd-h mg-b">
        <div v-if="!$store.state.user.isLogin">
          <router-link to="login">
            <span class="pd-h">登陆</span>
          </router-link>
          |
          <router-link to="signup">
            <span class="pd-h">注册</span>
          </router-link>
        </div>
        <div v-else>
          <span>{{ $store.state.user.username }}</span> |

          <!-- <router-link to="login"> -->
          <span @click="logout">退出</span>
          <!-- </router-link> -->
        </div>
        <div>
          <a href="https://www.welan.com/">电脑版</a>
        </div>
      </div>
      <div class="footer-default">
        <!-- <p class="three-text">
          <span>提建议</span>
          <span>
            <a href="https://www.wl.cn/">电脑版</a>
            </span>
          <span>帮助</span>
        </p> -->
        <div class="pd-b">
          Copyright (C)2006-2020 蔚蓝网, All Rights Reserved
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
// @ is an alias to /src
import Popper from "./children/Popper";
export default {
  name: "lDfault",
  components: { Popper },
  props: {
    rightMenu: String,
    rb: { type: String },
    title: String,
    footer: Boolean,

    buttonIcon: {
      type: String,
    },
    emerge: Boolean,

    toRouter: {
      type: Object,
    },
  },
  data() {
    return {
      rightButtonShow: true,
    };
  },
  computed: {
    comButton() {
      return this.rb === "删除" || this.rb === "管理";
    },
    rightTitle() {
      let button;

      if (this.$store.state.user.isLogin && this.buttonIcon) {
        button = this.buttonIcon;
      } else {
        button = this.rightButtonShow ? "DMiconzhuanqu-" : "x";
      }

      return button;
    },
  },
  mounted() {
    if (this.emerge) {
      const tabs = document.querySelector("#tabs");
      tabs.style.position = "fixed";
      const sticky = document.querySelector("#tabs .van-sticky");
      sticky.classList.add("van-sticky--fixed");
    }
  },

  methods: {
    // onEdit1(){console.log(3)},
    onClickLeft() {
      if (this.$route.name.toLowerCase().match("payment")) {
        this.$dialog
          .confirm({
            title: "确认要离开支付页面？",
            message: "您的订单在24小时内未支付将被取消，请尽快完成支付",
            confirmButtonText: "继续支付",
            cancelButtonText: "确认离开",
            closeOnPopstate: false,
          })
          .then(() => {
            // on close
          })
          .catch(() => {
            this.$router.replace(this.toRouter);
          });
      } else if (this.toRouter) {
        // console.log("进入订单列表 参数===》" + this.toRouter);
        this.$router.push(this.toRouter);
      } else {
        // console.log("返回上一个页面参数===》" + this.toRouter);
        this.$router.go(-1);
      }
    },
    onClickRight() {
      this.rightButtonShow = !this.rightButtonShow;
      this.$emit("click-right");
    },
    logout() {
      this.$logout();
      location.reload();
    },
    tabScroll(scroll) {
      if (this.emerge) {
        const tabs = document.querySelector("#tabs");
        tabs.style.opacity = scroll.scrollTop / 150;

        const fixedArrow = document.querySelector(".van-icon-arrow-left");

        if (scroll.scrollTop > 50) {
          fixedArrow.style.background = "#fff";
          fixedArrow.style.color = "#666";
          fixedArrow.classList.add("ok");
        } else {
          const sticky = document.querySelector("#tabs .van-sticky");
          sticky.classList.add("van-sticky--fixed");
          fixedArrow.style.background = "#666";
          fixedArrow.style.color = "#fff";
          fixedArrow.classList.remove("ok");
        }
      }
    },
  },
};
</script>

<style>
.fixed-arrow .van-icon.van-icon-arrow-left {
  position: fixed;
  z-index: 999;
  color: "#fff";
  background: #666;
  border-radius: 50%;
  padding: 4px;
  left: 16px;
  top: 12px;
}
.fixed-arrow .van-icon.van-icon-arrow-left.ok::before {
  color: #666;
}

.fixed-arrow .van-icon.van-icon-arrow-left::before {
  color: white;
}
</style>
<style lang="scss" scoped>
#tabs {
}
.default {
  .footer-default {
    text-align: center;
    .three-text {
      display: flex;
      justify-content: space-around;
      padding: 0 20vw;
    }
  }
}
</style>
