<template>
    <lDfault title="教材退货">
        教材退货
    </lDfault>
</template>

<script>
    import lDfault from "@/templates/Default.vue";
    export default {
        components:{
            lDfault
        },
        name: "JcReturnBook"
    }
</script>

<style scoped>

</style>