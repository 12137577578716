import request from "@/utils/request";


/**
 * 查询退货中的订单
 * @param stateId 状态ID
 * @param pageNo 页码
 * @param dayNum 天数 当前时间为准前几天的数据
 * @return
 */
export function selectReturnOrder(params) {
    return request({
        url: '/mobile/order/return/selectReturnOrders',
        method: 'post',
        params,
        // data:params
    })
}
/**
 * 打开【申请退换货】详情页
 * @param orderId 订单ID
 * @return
 */
export function returnOrderCheck(params) {
    return request({
        url: '/mobile/order/return/returnOrderCheck',
        method: 'post',
        params,
        // data:params
    })
}

/**
 * 提交退换货申请
 * @param order 订单编码
 * @param deprecatedType 退换货类型
 * @param remark 说明
 * @param itemStatus 商品状态
 * @param packageStatus 包装状态
 * @param returnType 自选快递
 * @param receInfo  收货人地址id
 * @param bookIds 书目ID
 * @return
 */
export function returnOrderSubmit(params) {
    return request({
        url: '/mobile/order/return/submitChange',
        method: 'post',
        params,
        // data:params
    })
}
/**
 * 退换货申请成功后填写物流公司和快递单号
 *
 * @param order
 * @param logisticsName 物流公司
 * @param expressNumber 快递单号
 * @return
 */
export function addSuccessinfo(params) {
    return request({
        url: '/mobile/order/return/add/successinfo',
        method: 'post',
        params,
        // data:params
    })
}

