<template>
  <div class="bd-h slide">
    <div class="pd-l pd-v">{{ title }}</div>

    <ul class="book-wrapper">
      <li class="book" v-for="(item, index) in list" :key="index">

          <div @click='goBookDetail(item.skuId)' class="book-item">
            <img
              width="100%"
              height="100%"
              :src="'http://img.welan.com/s/' + item.imgurl"
              alt=""
            />
          </div>
          <div class="text-black-800 text-13 mg-b-half mg-t truncate">
            {{ item.productName }}
          </div>
          <div class="money pd-b text-red-600">￥{{ item.wlPrice | money }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
  props: ["title", "list"],
  data() {
    return {};
  },
  methods:{
    goBookDetail(skuId){
      console.log(skuId)
      this.$router.push({name:"BookDetail",params:{skuId}})
    }
  }
};
</script>

<style lang="scss" scoped>
.slide {
  margin: 5px 0;
  background: white;
}
.book-wrapper {
  display: flex;
  width: 100vw;
  overflow: scroll;
  .book {
    padding: 0 10px;
    width: 106px;

    .book-item {
      height: 122px;
      width: 106px;
      border-radius: 6px;
      overflow: hidden;
    }
  }
}
</style>
