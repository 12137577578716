import request from "@/utils/request";

//微信JSAPI支付
export function rqWXPayByJSAPI(params) {
    return request({
        url: '/mobile/OrderPay/WeiXinPayByJSAPI',
        method: 'post',
        params
    });
}
//微信JSAPI支付
export function rqWXPay(params) {
    return request({
        url: '/mobile/OrderPay/weiXinPayByH5',
        method: 'post',
        params
    });
}